import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import Header from '../containers/Header';
import Sidenav from '../containers/Sidenav';
import Footer from '../containers/Footer';
import MobileMenu from '../containers/Mobilemenu';
import CustomSearchField from '../components/Shared/customSearchTextField';
import {
  setSearchTerm,
  getOptionList,
  setOptionList,
} from '../actions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';
let userNameChanged = '';
class PageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      searchText: '',
      redirectToSearch: false,
      searchError: false,
      showSearch: true,
      activeOption: 0,
    };
  }

  componentDidUpdate(prevProps) {
    
    if (this.props && this.props.history && this.props.history.location && this.props.history.location.pathname === '/') {
      window.scrollTo(0, 0)
    }
    if (
      this.props.updatedProfile[0].updatedProfile.data &&
      this.props.updatedProfile[0].updatedProfile.status === 200
    ) {
      userNameChanged = this.props.updatedProfile[0].updatedProfile
        .data.profile_user;
    }
    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      if (errorMessage.code === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }
  }

  componentWillMount() {
    if (this.props.location) {
      const { location } = this.props;
      if (
        location.pathname.match(/invoice/) ||
        location.pathname.match(/invoiceDetails/) ||
        location.pathname.match(/quickorderform/) ||
        location.pathname.match(/orders/)
      ) {
        this.setState({ showSearch: false });
      }
    }
  }

  toggleMenu = () => {
    if (!this.state.showMenu) {
      document.addEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    }

    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  handleOutsideClick = (e) => {
    this.props.clearOptions();
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.toggleMenu();
  };

  searchTextChangeHandler = (e) => {
    let searchText = e.target.value;
    this.setState({ searchError: false, searchText });
    if (searchText && searchText.trim().length > 2) {
      this.props.getOptionList(searchText);
      this.setState(
        {
          searchText: searchText,
          activeOption: 0,
        },
        () => this.props.setSearchTerm(searchText.trim())
      );
    }
    this.props.clearOptions();
  };

  searchEnterClickHandler = (e) => {
    let searchText = e.target.value;
    const { activeOption } = this.state;
    const { optionList } = this.props;
    let options = optionList ? optionList.response.suggestions : null;
    if (e.keyCode === 13) {
      if (searchText && searchText.trim().length > 2) {
        this.setState({
          redirectToSearch: true,
        });
        this.props.clearOptions();
      } else {
        this.setState({ searchError: true });
      }
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState(
        {
          activeOption: activeOption - 1,
          searchText: options[activeOption - 1].term,
        },
        () => this.props.setSearchTerm(this.state.searchText)
      );
    } else if (e.keyCode === 40 && options.length > 0) {
      if (activeOption === options.length - 1) {
        return;
      }

      this.setState(
        {
          activeOption: activeOption + 1,
          searchText: options[activeOption + 1].term,
        },
        () => () => this.props.setSearchTerm(this.state.searchText)
      );
    }
  };

  searchIconClickHandler = (e) => {
    const { searchText } = this.state;
    if (searchText && searchText.trim().length > 2) {
      this.setState({
        redirectToSearch: true,
        searchError: false,
      });
    } else {
      this.setState({ searchError: true });
    }
    this.props.clearOptions();
  };

  getOption = (e) => {
    this.setState({ searchText: e.term }, () =>
      this.searchIconClickHandler()
    );
    this.props.setSearchTerm(e);
  };

  handleOnBlur = (e) => {
    e.preventDefault();
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget ===
        e.nativeEvent.originalTarget
    ) {
      return;
    }
    setTimeout(() => this.props.clearOptions(), 500);
  };

  render() {
    const {
      showProductSearch,
      searchTextChangeHandler,
      searchEnterClickHandler,
      searchIconClickHandler,
      searchError,
      optionList,
      getOption,
      searchText,
      activeOption,
      showSearch,
    } = this.props;
    const { redirectToSearch } = this.state;
    if (this.state.searchText && redirectToSearch) {
      if (this.props.history.location.pathname.includes('/product')) {
        window.location.href = `/search?query=${this.state.searchText}`;
      } else {
        this.props.history.push(
          `search?query=${this.state.searchText}`
        );
      }
    }
    let options = optionList ? optionList.response.suggestions : null;
    return (
      <div
        ref={(node) => {
          this.node = node;
        }}>
        <Header toggleMenu={this.toggleMenu} {...this.props}/>
        <MobileMenu showMenu={this.state.showMenu} />
        <Grid
          item
          container
          spacing={0}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}>
          <Grid item lg={2} xl={2}>
            <Hidden mdDown className='externalContainer'>
              <div>
                <Sidenav userNameChanged={userNameChanged} />
              </div>
            </Hidden>
          </Grid>
          <Grid
            item
            className='main-panel childComponentSpacing'
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={10}>
            {showSearch !== 'false' && (
              <div className='search-header'>
                {showProductSearch && (
                  <h2 className='header-label'>PRODUCTS</h2>
                )}

                <div className='search-field'>
                  <CustomSearchField
                    placeholder={'Search Products'}
                    searchTextChangeHandler={
                      searchTextChangeHandler
                        ? searchTextChangeHandler
                        : this.searchTextChangeHandler
                    }
                    searchEnterClickHandler={
                      searchEnterClickHandler
                        ? searchEnterClickHandler
                        : this.searchEnterClickHandler
                    }
                    searchIconClickHandler={
                      searchIconClickHandler
                        ? searchIconClickHandler
                        : this.searchIconClickHandler
                    }
                    searchError={
                      searchError
                        ? searchError
                        : this.state.searchError
                    }
                    searchText={
                      searchText ? searchText : this.state.searchText
                    }
                    optionList={options}
                    getOption={getOption ? getOption : this.getOption}
                    activeOption={
                      activeOption
                        ? activeOption
                        : this.state.activeOption
                    }
                    handleOnBlur={this.handleOnBlur}
                  />
                </div>
              </div>
            )}
            <div id='pageLayout'>{this.props.children}</div>
          </Grid>
        </Grid>
        <div className='footer-style'>
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errorResponse: [state.errorReducer.error_data],
  updatedProfile: [state.updateProfileReducer],
  optionList: state.productSearchReducer.optionList,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchTerm: (term) => dispatch(setSearchTerm(term)),
  getOptionList: (term) => dispatch(getOptionList(term)),
  clearOptions: () =>
    dispatch(setOptionList({ response: { suggestions: [] } })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageLayout);
