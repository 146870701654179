import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { borderRadius } from '@material-ui/system';

const customSearchTextField = ({
  placeholder,
  searchTextChangeHandler,
  searchEnterClickHandler,
  searchIconClickHandler,
  searchError,
  optionList,
  getOption,
  searchText,
  activeOption,
  hideSearchIcon,
  handleOnBlur,
  autoFocus,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
    },
    root: {
      padding: '0px 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 40,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 30,
      margin: 4,
    },
    listbox: {
      // borderRadius: 4,
      textAlign: 'start',
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: 'relative',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxHeight: 400,
      border: '1px solid rgba(0,0,0,.25)',
      '& li[data-focus="true"]': {
        backgroundColor: '#edf1f5',
        color: '#edf1f5',
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: '#edf1f5',
        color: '#edf1f5',
      },
    },
  }));
  const classes = useStyles();
  var list = document.getElementById('list'),
    targetLi = document.getElementById('lists'); // id tag of the <li> element
  if (optionList && optionList.length > 0) {
    setTimeout(() => {
      if (
        document.getElementById('lists') &&
        document.getElementById('lists').offsetHeight * activeOption >
          306
      ) {
        list.scrollTop =
          document.getElementById('lists').offsetTop +
          38 * activeOption;
      } else {
        list.scrollTop =
          document.getElementById('lists') &&
          document.getElementById('lists').offsetTop;
      }
    }, 500);
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.root}>
        <InputBase
          type='text'
          autoFocus={autoFocus}
          className={classes.input}
          placeholder={placeholder}
          inputProps={{ 'aria-label': placeholder }}
          value={searchText || ''}
          onChange={searchTextChangeHandler}
          onKeyDown={searchEnterClickHandler}
          onBlur={handleOnBlur}
        />
        {hideSearchIcon ? null : (
          <Divider
            className={classes.divider}
            orientation='vertical'
          />
        )}
        {hideSearchIcon ? null : (
          <IconButton
            className={classes.iconButton}
            aria-label='search'
            onClick={searchIconClickHandler}>
            <SearchIcon />
          </IconButton>
        )}
      </Paper>
      {searchError ? (
        <FormHelperText
          id='component-error-text'
          style={{ color: '#FFA500' }}>
          Please Enter atleast 3 letters
        </FormHelperText>
      ) : null}
      {optionList ? (
        <ul
          className={classes.listbox}
          id='list'
          style={{
            display: optionList.length > 0 ? 'block' : 'none',
          }}>
          {optionList.map((option, index) => (
            <li
              id='lists'
              key={index}
              className={
                index === activeOption ? 'activeList' : 'display-list'
              }
              onClick={getOption.bind(this, option)}>
              {option.term}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default customSearchTextField;
