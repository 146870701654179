import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
//import ImageZoom from '../Shared/imageZoom';
import ImageZoom from './productImageCarausel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

const productImage = ({ imgUrl }) => {
  imgUrl =
    imgUrl ||
    'http://getwallpapers.com/wallpaper/full/3/a/4/416593.jpg';
  const imageProps = {
    img: imgUrl,
    width: 450,
    height: 450,
    zoomWidth: 500,
    offset: {
      vertical: 100,
      horizontal: 50,
    },
    zoomStyle:
      'opacity: 1;z-index:500; box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.2), 2px 4px 5px 0px rgba(0, 0, 0, 0.14), 2px 1px 10px 0px rgba(0, 0, 0, 0.12);',
    zoomLensStyle: 'opacity: 0.3;background-color: gray;',
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
      <div className='image-box image-container'>
        <ImageZoom
          {...imageProps}
          data={imgUrl}
          className='product-image'
        />
      </div>
    </Grid>
  );
};

productImage.propTypes = {
  imgUrl: PropTypes.object.isRequired,
};

export default productImage;
