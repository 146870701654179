import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_MINI_CART_ITEMS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
// import { setMiniCartItems } from '../../actions';
import { getMiniCartItems } from '../../api/cartApi';
import { setCartData } from '../../actions/cartItemAction';

function* workerGetMiniCartItemsSaga() {
  try {
    const response = yield call(getMiniCartItems);

    // yield put(setCartData(cartItems));
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;
    yield put(setCartData(data));
    /*
     *orderNumber: 1000033;
     * let retrievedObject = localStorage.getItem('context');
     * retrievedObject = JSON.parse(retrievedObject);
     */

    // retrievedObject.orderId = data.orderNumber;
    if (data !== null) {
      let retrievedObject = localStorage.getItem('context');
      retrievedObject = JSON.parse(retrievedObject);

      retrievedObject.orderId = data.orderNumber;
      localStorage.setItem(
        'context',
        JSON.stringify(retrievedObject)
      );
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetMiniCartItemsSaga() {
  yield takeLatest(
    GET_MINI_CART_ITEMS_SAGA,
    workerGetMiniCartItemsSaga
  );
}
