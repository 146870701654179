import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_CUSTOMER_SUPPORT_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setCustomerSupport } from '../../actions';
import { getCustomerSupport } from '../../api/api';

function* workerGetCustomerSupportSaga() {
  try {
    const customer_support = yield call(getCustomerSupport);
    yield put(setCustomerSupport(customer_support.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetCustomerSupportSaga() {
  yield takeLatest(
    GET_CUSTOMER_SUPPORT_SAGA,
    workerGetCustomerSupportSaga
  );
}
