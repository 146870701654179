import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CustomError from '../../components/Shared/customErrorText';
import ContentLoader from 'react-content-loader';
import './style.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  addressForm: {
    padding: '20px',
  },
}));

const Address = ({
  addresses,
  selectedAddress,
  handleSelectedAddress,
  organization,
  isUserAdmin,
  buttonDisabled,
}) => {
  const classes = useStyles();

  const { shipping, billing } = selectedAddress;

  return (
    <Grid>
      {isUserAdmin && !buttonDisabled && (
        // <div>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Link
            to={{ pathname: `/account`, address: 'addAddress' }}
            className='addAddress'>
            <AddIcon />
            <span className='addressText'>Add new address</span>
          </Link>
        </Grid>
        // </div>
      )}
      <Grid container direction='row'>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <form className={classes.addressForm}>
            <FormControl className={classes.formControl}>
              <InputLabel
                className='MuiInputLabel-shrink'
                htmlFor='shipping'>
                Select Shipping Address
              </InputLabel>
              <Select
                value={
                  shipping && shipping.repositoryId
                    ? shipping.repositoryId
                    : ''
                }
                className='addressList'
                onChange={handleSelectedAddress}
                // autoWidth
                inputProps={{ name: 'shipping', id: 'shipping' }}>
                {addresses.shipping &&
                  addresses.shipping.map((v, i) => (
                    <MenuItem key={i} value={v.repositoryId}>
                      {v.address1}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </form>
          <div className={classes.addressForm}>
            <h3>Shipping Address:</h3>
            {organization ? (
              shipping && shipping.companyName ? (
                <div>
                  <strong>{shipping.companyName}</strong>
                  <div>{shipping.address1}</div>
                  <div>{shipping.address2}</div>
                  <div>{shipping.address3}</div>
                  <div>{shipping.city}</div>
                  <div>{shipping.country}</div>
                  <div>{shipping.postalCode}</div>
                </div>
              ) : (
                <CustomError
                  masg='No Shipping Address selected'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )
            ) : (
              <ContentLoader />
            )}
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <form className={classes.addressForm}>
            <FormControl className={classes.formControl}>
              <InputLabel
                className='MuiInputLabel-shrink'
                htmlFor='billing'>
                Select Billing Address
              </InputLabel>
              <Select
                value={
                  billing && billing.repositoryId
                    ? billing.repositoryId
                    : ''
                }
                onChange={handleSelectedAddress}
                inputProps={{ name: 'billing', id: 'billing' }}>
                {addresses.billing &&
                  addresses.billing.map((v, i) => (
                    <MenuItem key={i} value={v.repositoryId}>
                      {v.address1}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </form>
          <div className={classes.addressForm}>
            <h3>Billing Address:</h3>
            {organization ? (
              billing && billing.companyName ? (
                <div>
                  <strong>{billing.companyName}</strong>
                  <div>{billing.address1}</div>
                  <div>{billing.address2}</div>
                  <div>{billing.address3}</div>
                  <div>{billing.city}</div>
                  <div>{billing.country}</div>
                  <div>{billing.postalCode}</div>
                </div>
              ) : (
                <CustomError
                  masg='No Billing Address selected'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )
            ) : (
              <ContentLoader />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Address;
