import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HierarchialFilter from './heirarchicalFilter';
import CustomCheckbox from '../Shared/customCheckbox';
import CustomRadioGroup from '../Shared/customRadioGroup';
import './style.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    padding: 0,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const isOptionSelected = (
  adjustments,
  option,
  filterName,
  filterType
) => {
  let isSelected = false;
  adjustments[filterType].map((adjustment) => {
    if (adjustment.name === filterName) {
      if (adjustment.selections.indexOf(option.value) > -1) {
        isSelected = true;
      }
    }
    return adjustment;
  });
  return isSelected;
};

const mobileFilterType = ({
  facetData,
  checked,
  filterOptionClickHandler,
  categoryClickHandler,
  adjustments,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}>
        {facetData.map((option, i) => {
          if (option.type === 'heirarchical')
            return (
              <Tab
                key={`mobile-facet-${option.label}-${i}`}
                label={option.label}
                {...a11yProps(i)}
              />
            );
        })}
        {facetData.map((option, i) => {
          if (option.type !== 'heirarchical')
            return (
              <Tab
                key={`mobile-facet-${option.label}-${i}`}
                label={option.label}
                {...a11yProps(i)}
              />
            );
        })}
      </Tabs>
      {facetData.map((option, i) => {
        if (option.type === 'heirarchical')
          return (
            <TabPanel
              id='mobileFilter'
              key={`mobile-facet-values-${option.label}-${i}`}
              value={value}
              index={i}>
              <>
                <div
                  className='filter-type-container'
                  key={`${option.label}-${i}-facet`}>
                  <div className='heirarchical-option'>
                    <HierarchialFilter
                      data={option}
                      categoryClickHandler={categoryClickHandler}
                      isMobile
                    />
                  </div>
                </div>
              </>
            </TabPanel>
          );
      })}
      {facetData.map((data, i) => {
        if (data.type !== 'heirarchical')
          return (
            <TabPanel
              id='mobileFilter'
              key={`mobile-facet-values-${data.label}-${i}`}
              value={value}
              index={i}>
              <>
                <div
                  className='filter-type-container'
                  key={`${data.label}-${i}-facet`}>
                  <p className='filter-type-label'>{data.label}:</p>
                  {(data.type === 'multiselect' ||
                    data.type === 'range') &&
                    data.options.map((option, i) => {
                      if (option.count !== 0) {
                        return (
                          <div
                            className='filter-type-option'
                            key={`${option.label}-${option.value}-${i}-option`}>
                            <CustomCheckbox
                              name={data.value}
                              filterType={data.type}
                              // defaultCheck={checked}
                              defaultCheck={isOptionSelected(
                                adjustments,
                                option,
                                data.value,
                                data.type
                              )}
                              value={option.value}
                              onChange={filterOptionClickHandler}
                            />
                            <span
                              className='filter-option-label'
                              title={option.label}>
                              {option.label}
                            </span>
                            <span className='filter-option-count'>
                              {option.count}
                            </span>
                          </div>
                        )
                      }
                    })}

                  {data.type === 'singleselect' && (
                    <CustomRadioGroup
                      name={data.value}
                      filterType={data.type}
                      defaultValue={123}
                      options={data.options}
                      clickHandler={filterOptionClickHandler}
                    />
                  )}
                </div>
                <div className='divider' />
              </>
            </TabPanel>
          );
      })}
    </div>
  );
};

export default mobileFilterType;
