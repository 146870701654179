import { APPLICATION_NAME_BE } from '../configurations/config';

export function headerData() {
  const user = JSON.parse(localStorage.getItem('user'));
  const roles = user.contactTypeRole.map((role) => role.roleId);
  const bepsyRoles = {
    'Bepsy-UserRoles': roles.join(','),
  };

  const access_token = `Bearer ${localStorage.getItem(
    'access_token'
  )}`;
  const context = localStorage.getItem('context');
  const data = JSON.parse(context);
  const options = {
    headers: {
      Authorization: access_token,
      'Content-Type': 'application/json',
      'Bepsy-SiteId':
        APPLICATION_NAME_BE === 'occ'
          ? 'siteUS'
          : data && data.siteId,
      'Bepsy-OrganizationId': data && data.organizationId,
      'Bepsy-ContractId': data && data.contractId,
      'Bepsy-CatalogId': data && data.catalogId,
      'Bepsy-PricelistId': data && data.pricelistId,
      'Bepsy-UserRoles': roles.join(','),
    },
  };
  if (APPLICATION_NAME_BE !== 'occ') {
    options.headers['Bepsy-sfid'] = data && data.sfid;
  }

  return options;
}
