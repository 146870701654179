import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_CATEGORY_SEARCH_DATA_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setProductCategoryData } from '../../actions';
import { getProductCategoryData } from '../../api/productlistingApi';

function* workerGetCategoryDataSaga({ payload: { adjustments } }) {
  try {
    const products = yield call(getProductCategoryData, adjustments);
    yield put(setProductCategoryData(products.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetCategoryDataSaga() {
  yield takeLatest(
    GET_CATEGORY_SEARCH_DATA_SAGA,
    workerGetCategoryDataSaga
  );
}
