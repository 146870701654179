import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import './style.css';

/**
 * Component return no outline-no border select drop down
 * @param {options} - a object array contains value in form of{id:1, name:'name'}
 * @param {selectedOption} - by default selected value
 * @param {optionChange} - option change handler function
 */

const noOutlineSelect = ({
  width,
  options,
  selectedOption,
  optionChange,
}) => {
  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      width,
      height: 25,
      borderRadius: 4,
      position: 'relative',
      border: 'none',
      fontSize: 12,
      fontWeight: 'bold',
      transition: theme.transitions.create([
        'border-color',
        'box-shadow',
      ]),
      '&:focus': {
        borderRadius: 4,
        backgroundColor: '#fff',
        width,
        height: 25,
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
      padding: 0,
    },
  }));
  const classes = useStyles();
  return (
    options &&
    options.length && (
      <div className='no-outline-select'>
        <form className={classes.root} autoComplete='off'>
          <FormControl>
            <Select
              native
              value={selectedOption}
              onChange={optionChange}
              input={<BootstrapInput className='no-outline-input' />}>
              {options !== undefined &&
                options.map((e, i) => (
                  <option
                    value={e.value}
                    key={`${i}-${e.value}-${e.label}`}>
                    {e.label}
                  </option>
                ))}
            </Select>
          </FormControl>
        </form>
      </div>
    )
  );
};

export default noOutlineSelect;
