import { SET_SUB_ORGANIZATION_DETAILS } from '../constants';

const initialState = {
  sub_organization_details: null,
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_SUB_ORGANIZATION_DETAILS:
      return {
        ...state,
        sub_organization_details: action.sub_organization_details,
      };

    default:
      return state;
  }
}
