import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_STATE_DETAILS_SAGA } from '../../constants';
import { setStateData } from '../../actions/countryStateAction';
import { getStates } from '../../api/countryStateApi';

function* workerGetStatesSaga({ country }) {
  const states = yield call(getStates, country);

  states.data.states.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
  });
  yield put(setStateData(states));
}

export default function* watchStatesSaga() {
  yield takeLatest(GET_STATE_DETAILS_SAGA, workerGetStatesSaga);
}
