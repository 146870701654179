import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { QuickOrderModal } from './QuickOrderModal';
import Banner from './Banner';
import LatestOrders from './LatestOrders';
import {
  getDashboardSummary,
  getAnnouncements,
} from '../../api/dashboardApi';
import {
  getOrderDetailSaga,
  getOrdersOnPaginationSaga,
  setOrdersOnPagination,
} from '../../actions/orderAction';

import {
  getMultiInventoryValidationSaga,
  addtoQuoteMultipleItemsSaga,
} from '../../actions/quickOrderAction';
import { withToastManager } from 'react-toast-notifications';
import ContentLoader from 'react-content-loader';

class DashboardV2 extends Component {
  constructor(props) {
    super(props);
    let context = localStorage.getItem('context');
    let c;
    if (context) {
      context = JSON.parse(context);
      c = context.organizationId;
    }
    this.state = {
      showMenu: false,
      expanded: false,
      searchInput: '',
      flag: false,
      currentPage: 0,
      ordersUpto: 30,
      startDate: new Date(),
      endDate: new Date(),
      orderRange: false,
      orderRangeError: false,
      searchError: false,
      clearCart: false,
      loader: false,
      sortProp: 'submittedDate',
      sortOrder: 'desc',
      statusData: 'all',
      orgData: c,
      redirectOrder: false,
      goToCheckout: false,
      items: [],
      orderRange: true,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  recordPerPage = 3; // to define records per page

  componentDidMount() {
    this.setState({
      currentPage: 0,
      searchError: false,
      expanded: false,
      loader: false,
      items: [],
      qoClicked: false,
    });

    this.props.getOrdersOnPaginationSaga(
      this.state.searchInput,
      this.state.currentPage,
      this.recordPerPage,
      this.state.ordersUpto,
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      true,
      this.state.sortProp,
      this.state.sortOrder,
      this.state.statusData,
      this.state.orgData
    );

    getAnnouncements().then((res) => {
      const { data } = res;
      const announcement = data.items[0].long_text;

      this.setState({
        announcement,
      });
    });

    getDashboardSummary().then((res) => {
      const { data } = res;

      this.setState({
        dashboardSummary: data,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { inventoryData, cartStatus } = this.props;
    if (this.props.order_detail !== prevProps.order_detail) {
      const details =
        this.props &&
        this.props.order_detail &&
        this.props.order_detail.order_detail &&
        this.props.order_detail.order_detail.EOrderItems;
      let items =
        details &&
        details.map((product) => {
          return {
            skuId: product.sku,
            productId: product.product,
            quantity: product.quantity,
          };
        });
      this.setState({ items: items });
      const reqBody = {
        items: items,
      };
      this.props.getMultiInventoryValidationSaga(reqBody);
    }

    if (this.props.inventoryData !== prevProps.inventoryData) {
      if (inventoryData != null && inventoryData.success) {
        this.props.addtoQuoteMultipleItemsSaga(
          this.state.items,
          this.state.clearCart
        );
        this.setState({
          goToCheckout: true,
        });
      }
    }
    if (cartStatus) {
      this.quickReorderSuccess();
    }
  }
  quickReorderSuccess = () => {
    const { toastManager } = this.props;
    toastManager.add('Quick ReOrder Success', {
      appearance: 'success',
      autoDismiss: true,
    });
  };
  convert = (str) => {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  };
  gotoOrder = (id) => {
    this.props.history.push(`/orders/${id}`);
  };
  toggleMenu = () => {
    // attach/remove event handler
    if (!this.state.showMenu) {
      document.addEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    }

    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.toggleMenu();
  }

  viewOrder = (orderId) => {
    const { cart } = this.props;

    if (cart && cart.EOrderItems && cart.EOrderItems.length > 0) {
      this.setState({
        showQuickOrderModal: true,
        quickOrderId: orderId,
      });
    } else {
      this.setState({
        clearCart: false,
        qoClicked: true,
      });
      this.props.getOrderDetailSaga(orderId);
    }
  };

  handleMergeCart = () => {
    this.setState({
      clearCart: false,
      qoClicked: true,
      showQuickOrderModal: false,
    });
    this.props.getOrderDetailSaga(this.state.quickOrderId);
  };

  handleClearCart = () => {
    this.setState({
      clearCart: true,
      qoClicked: true,
      showQuickOrderModal: false,
    });
    this.props.getOrderDetailSaga(this.state.quickOrderId);
  };

  render() {
    const token = localStorage.getItem('access_token');
    const { orders_list, inventoryData, cartStatus } = this.props;
    if (!token) {
      return <Redirect to='/login' />;
    }
    if (this.state.redirectOrder) {
      return <Redirect to='/orders' />;
    }
    if (cartStatus) {
      return <Redirect to='/checkout' />;
    }
    return (
      <div
        ref={(node) => {
          this.node = node;
        }}>
        <QuickOrderModal
          open={this.state.showQuickOrderModal}
          onMergeCart={this.handleMergeCart}
          onClearCart={this.handleClearCart}
        />
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Use HOC for homepage components */}
            <PageLayoutHOC {...this.props}>
              {!cartStatus && this.state.qoClicked && (
                <ContentLoader />
              )}
              {!this.state.qoClicked && (
                <Banner
                  dashboardSummary={this.state.dashboardSummary}
                  announcement={this.state.announcement}
                />
              )}

              {!this.state.qoClicked && (
                <LatestOrders
                  latestOrders={orders_list}
                  gotoOrder={this.gotoOrder}
                  convert={this.convert}
                  viewOrder={this.viewOrder}></LatestOrders>
              )}
            </PageLayoutHOC>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.token,
  orders_list: state.ordersOnPagination.orders_list,
  order_detail: state.orderDetailReducer,
  inventoryData: state.quickOrderReducer.inventoryData,
  cartStatus: state.quickOrderReducer.cartStatus,
  cart: state.orderReducer.cart,
});

const mapDispatchToProps = (dispatch) => ({
  getMultiInventoryValidationSaga: (products) =>
    dispatch(getMultiInventoryValidationSaga(products)),
  getOrderDetailSaga: (order) => dispatch(getOrderDetailSaga(order)),
  getOrdersOnPaginationSaga: (
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    startDate,
    endDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData
  ) =>
    dispatch(
      getOrdersOnPaginationSaga(
        searchInput,
        currentPage,
        recordPerPage,
        ordersUpto,
        startDate,
        endDate,
        orderRange,
        sortProp,
        sortOrder,
        statusData,
        orgData
      )
    ),
  addtoQuoteMultipleItemsSaga: (products, clearCart) =>
    dispatch(addtoQuoteMultipleItemsSaga(products, clearCart)),

  setOrdersOnPagination: (order) =>
    dispatch(setOrdersOnPagination(order)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(DashboardV2));
