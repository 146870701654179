import { SET_ORDERS_ON_PAGINATION, SET_ORDER_STATES } from '../constants';

const initialState = {
  orders_list: null,
  orderStates: [],
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_STATES:
      return {
        ...state,
        orderStates: action.payload,
      };
    case SET_ORDERS_ON_PAGINATION:
      return {
        ...state,
        orders_list: action.payload,
      };
    default:
      return state;
  }
}
