import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_RECOMMENDED_PRODUCTS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setRecommendedProducts } from '../../actions';
import { getRecommendedProducts } from '../../api/api';

function* workerGetRecommendedProductsSaga() {
  try {
    const recommended_products = yield call(getRecommendedProducts);
    yield put(setRecommendedProducts(recommended_products.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetRecommendedProductsSaga() {
  yield takeLatest(
    GET_RECOMMENDED_PRODUCTS_SAGA,
    workerGetRecommendedProductsSaga
  );
}
