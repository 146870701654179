import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { Grid, Divider, TextField, Button } from '@material-ui/core';
import FileUpload from '../../components/Shared/uploadButtons';

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from '@material-ui/core';
import './Style.css';

import { getSubOrganizationDetailsSaga, setSubOrganization } from '../../actions/organizationAction';

import {
  getQuotesOnPaginationSaga,
  setQuotesOnPagination,
  getQuoteDetails,
  getRejectQuoteData,
  resetQuoteDetails,
} from '../../actions/quoteAction';
import OrderList from '../../components/Shared/OrderList';
import './Style.css';
import { getOrderStates } from '../../actions/orderAction';

class QuoteList extends Component {
  constructor(props) {
    super(props);
    let context = localStorage.getItem('context');
    let c;
    if (context) {
      context = JSON.parse(context);
      c = context.organizationId;
    }

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      expanded: false,
      searchInput: urlParams.get('search') || '',
      currentPage: parseInt(urlParams.get('page') || 1) - 1,
      statusData: urlParams.get('quoteStatus') || 'all',
      flag: false,
      ordersUpto: 30,
      startDate: new Date(),
      endDate: new Date(),
      orderRange: false,
      orderRangeError: false,
      searchError: false,
      loader: false,
      sortProp: 'lastModifiedDate',
      sortOrder: 'desc',
      orgData: c,
    };
  }
  recordPerPage = 10; // to define records per page

  componentDidMount() {
    if (!(this.props.quoteStates && this.props.quoteStates.length)) {
      this.props.getOrderStates();
    }

    this.setState({
      currentPage: this.state.currentPage,
      searchError: false,
      expanded: false,
      loader: false,
    });

    this.props.getQuotesOnPaginationSaga(
      this.state.searchInput,
      this.state.currentPage,
      this.recordPerPage,
      this.state.ordersUpto,
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      this.state.sortProp,
      this.state.sortOrder,
      this.state.statusData,
      this.state.orgData
    );
    //this.props.getQuotesSaga();
    this.props.getSubOrganizationDetailsSaga();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quoteData !== this.props.quoteData) {
      if (this.props.quoteData.status === 200) {
        prevProps.toastManager.add('Success!', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.props.getQuotesOnPaginationSaga(
          this.state.searchInput,
          0,
          this.recordPerPage,
          this.state.ordersUpto,
          this.state.startDate,
          this.state.endDate,
          this.state.orderRange,
          this.state.sortProp,
          this.state.sortOrder,
          this.state.statusData,
          this.state.orgData
        );
      }
    }
  }

  handleSearch = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };
  handleEnterSearch = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };
  handleSubmit = () => {
    if (this.state.searchInput.length < 2) {
      this.setState({
        searchError: true,
      });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('page');
      urlParams.delete('quoteStatus');
      urlParams.set('search', this.state.searchInput);

      window.history.pushState({}, null, `${window.location.pathname}?${urlParams.toString()}`);

      this.setState({
        loader: false,
        currentPage: 0,
        searchError: false,
        expanded: false,
        statusData: 'all'
      });
      this.props.setQuotesOnPagination(null);
      this.props.getQuotesOnPaginationSaga(
        this.state.searchInput,
        0,
        this.recordPerPage,
        this.state.ordersUpto,
        this.convert(this.state.startDate),
        this.convert(this.state.endDate),
        false,
        this.state.sortProp,
        this.state.sortOrder,
        'all',
        this.state.orgData
      );
    }
  };
  handleChange = (id) => (event, _expanded) => {
    this.setState({
      expanded: _expanded ? id : false,
    });
  };

  viewOrder = (orderHistory) => {
    this.props.history.push(`/quotes/${orderHistory.sfid}`);
  };

  pageChangeClickHandler = (data) => {
    this.props.setQuotesOnPagination(null);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', data.selected + 1);
    window.history.pushState({}, null, `${window.location.pathname}?${urlParams.toString()}`);

    this.setState(
      {
        currentPage: data.selected,
        searchError: false,
        expanded: false,
        loader: false,
      },
      () => {
        this.props.getQuotesOnPaginationSaga(
          this.state.searchInput,
          this.state.currentPage,
          this.recordPerPage,
          this.state.ordersUpto,
          this.state.startDate,
          this.state.endDate,
          this.state.orderRange,
          this.state.sortProp,
          this.state.sortOrder,
          this.state.statusData,
          this.state.orgData
        );
      }
    );
  };

  handleSelect = (e) => {
    const sortData = e.target.value.split('-');

    this.props.setQuotesOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchError: false,
      expanded: false,
      loader: false,
    });
    this.setState({ sortProp: sortData[0], sortOrder: sortData[1] });

    this.props.getQuotesOnPaginationSaga(
      this.state.searchInput,
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      sortData[0],
      sortData[1],
      this.state.statusData,
      this.state.orgData
    );
  };

  viewDetails = (data) => {
    this.props.history.push(`/quotes/${data.sfid}`);
  };

  handleSelectStatus = (e) => {
    // const statusData = e.target.value;

    let statusData1 = e.target.value.split('-');
    let statusDataValue = statusData1[1].toLowerCase();
    this.props.setQuotesOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchInput: '',
      searchError: false,
      expanded: false,
      loader: false,
    });
    const orderStatus = statusData1[1];
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('quoteStatus', orderStatus);
    urlParams.set('page', 1);
    urlParams.delete('search');
    window.history.pushState({}, null, `${window.location.pathname}?${urlParams.toString()}`);

    this.setState({ statusData: orderStatus });

    this.props.getQuotesOnPaginationSaga(
      '',
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      //statusData1,

      this.state.sortProp,
      this.state.sortOrder,
      //this.state.statusData,
      statusDataValue,
      //statusData1[1],
      this.state.orgData
    );
  };
  placeOrder = () => {
    this.setState({
      quoteCheckout: true,
    });
  };

  handleSelectOrg = (e) => {
    this.props.setQuotesOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchError: false,
      expanded: false,
      loader: false,
    });
    this.setState({ orgData: e.target.value });
    this.props.setSubOrganization(e.target.value);
    this.props.getQuotesOnPaginationSaga(
      this.state.searchInput,
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      //orgData

      this.state.sortProp,
      this.state.sortOrder,
      this.state.statusData,
      e.target.value
    );
  };

  selectStartDate = (e) => {
    this.setState({
      startDate: e,
    });
  };
  convert = (str) => {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  };
  selectEndDate = (e) => {
    this.setState({
      endDate: e,
    });
  };
  handleDateRange = () => {
    this.setState({
      orderRange: true,
    });
    if (this.state.startDate > this.state.endDate) {
      this.setState({
        orderRangeError: true,
      });
    } else {
      this.setState({
        orderRangeError: false,
      });
      this.props.getQuotesOnPaginationSaga(
        this.state.searchInput,
        this.state.currentPage,
        this.recordPerPage,
        this.state.ordersUpto,
        this.convert(this.state.startDate),
        this.convert(this.state.endDate),
        true,
        this.state.sortProp,
        this.state.sortOrder,
        this.state.statusData,
        this.state.orgData
      );
    }
  };
  clearOrderSearchResult = (e) => {
    this.props.setQuotesOnPagination(null);
    let searchInput = '';
    this.setState({
      loader: true,
      currentPage: 0,
      searchError: false,
      expanded: false,
      searchInput: '',
    });
    this.props.getQuotesOnPaginationSaga(
      searchInput,
      this.state.currentPage,
      this.recordPerPage,
      this.state.ordersUpto,
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      this.state.sortProp,
      this.state.sortOrder,
      //this.state.statusData,
      this.state.statusData,
      this.state.orgData
    );
  };

  rejectQuote = () => {
    this.props.getRejectQuoteData();
    this.setState({ expanded: false });
  };

  render() {
    const tableCell = [
      'Id',
      'Creator',
      'Nickname',
      'Expires In',
      'Amount',
      'Status',
      'Details',
    ];

    const filterValues = this.props.quoteStates;

    const sortValues = [
      {
        key: 'Date - Newest to Oldest',
        value: 'lastModifiedDate-desc',
      },
      {
        key: 'Date - Oldest to Newest',
        value: 'lastModifiedDate-asc',
      },
    ];

    const token = localStorage.getItem('access_token');
    const {
      orderHistory,
      order_detail,
      orders_list,
      subOrganizations,
      quoteList,
      quoteDetails,
    } = this.props;

    let data, oDetails;

    if (orders_list && orders_list.orders_list) {
      //data = orders_list !== undefined ? orders_list : null;
      data = orders_list.orders_list.orderList;
    }

    if (!token) {
      return <Redirect to='/login' />;
    }
    console.log('state status....', this.state.statusData);

    console.log('stuff', { props: this.props, state: this.state });

    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            paddingLeft: '1em',
          }}></div>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OrderList
            data={data}
            orgList={
              subOrganizations && subOrganizations.data &&
              subOrganizations.data.items.length > 0
                ? subOrganizations && subOrganizations.data && subOrganizations.data.items
                : ''
            }
            expanded={this.state.expanded}
            handleSearch={this.handleSearch}
            handleChange={this.handleChange}
            filteredOrders={
              orders_list &&
              orders_list.orders_list &&
              orders_list.orders_list.totalRecords > 0
                ? orders_list && orders_list.orders_list.orderList
                : ''
            }
            quoteDetails={quoteDetails}
            rejectQuote={this.rejectQuote}
            viewOrder={this.viewDetails}
            order_detail={order_detail}
            orderNumber={this.state.orderNumber}
            currentPage={this.state.currentPage}
            recordPerPage={this.recordPerPage}
            totalRecords={
              orders_list && orders_list.orders_list
                ? orders_list.orders_list.totalRecords
                : null
            }
            handlePageClick={this.pageChangeClickHandler}
            handleSelect={this.handleSelect}
            handleSelectStatus={this.handleSelectStatus}
            handleSelectOrg={this.handleSelectOrg}
            orgData={this.state.orgData} // for now - later make api call to fetch this
            statusData={this.state.statusData}
            ordersUpto={this.state.ordersUpto}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selectStartDate={this.selectStartDate}
            selectEndDate={this.selectEndDate}
            handleDateRange={this.handleDateRange}
            orderRangeError={this.state.orderRangeError}
            handleSubmit={this.handleSubmit}
            handleEnterSearch={this.handleEnterSearch}
            searchError={this.state.searchError}
            searchText={this.state.searchInput}
            orderList={
              orders_list &&
              orders_list.orders_list &&
              orders_list.orders_list.orderList
            }
            clearOrderSearchResult={this.clearOrderSearchResult}
            sortProp={this.state.sortProp}
            sortOrder={this.state.sortOrder}
            tableCell={tableCell}
            pageTitle='Quotes'
            filterValues={filterValues}
            sortValues={sortValues}
          />
        </Grid>
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  orders_list: state.quotesOnPagination.orders_list,
  quoteStates: state.quotesOnPagination.quoteStates,
  quoteList: state.quoteReducer.quoteList.data,
  quoteDetails: state.quoteReducer,
  subOrganizations:
    state.organizationDetailsReducer.organization_details,
  quoteData: state.quoteReducer.quoteData,
});

const mapDispatchToProps = (dispatch) => ({
  // getQuotesSaga: (data) => dispatch(getQuotesSaga(data)),
  // setQuoteListSuccess: (i) => dispatch(setQuoteListSuccess(i)),
  getQuotesOnPaginationSaga: (
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    startDate,
    endDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData
  ) =>
    dispatch(
      getQuotesOnPaginationSaga(
        searchInput,
        currentPage,
        recordPerPage,
        ordersUpto,
        startDate,
        endDate,
        orderRange,
        sortProp,
        sortOrder,
        statusData,
        orgData
      )
    ),
  getOrderStates: () => dispatch(getOrderStates()),
  setQuotesOnPagination: (order) =>
    dispatch(setQuotesOnPagination(order)),
  getQuoteDetails: (data) => dispatch(getQuoteDetails(data)),
  getSubOrganizationDetailsSaga: (order) =>
    dispatch(getSubOrganizationDetailsSaga(order)),
  getRejectQuoteData: () => dispatch(getRejectQuoteData()),
  resetQuoteDetails: () => dispatch(resetQuoteDetails()),
  setSubOrganization: (data) => dispatch(setSubOrganization(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(QuoteList));
