import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_ORDERS_SAGA, SET_ERROR_STATE } from '../../constants';
import { setOrders } from '../../actions/orderAction';
import { getOrders } from '../../api/orderApi';

function* workerGetOrdersSaga() {
  try {
    const orders = yield call(getOrders);
    yield put(setOrders(orders));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchOrdersSaga() {
  yield takeLatest(GET_ORDERS_SAGA, workerGetOrdersSaga);
}
