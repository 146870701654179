import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_PRODUCT_SEARCH_DATA_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setProductListingData } from '../../actions';
import { getProductListingData } from '../../api/productlistingApi';

function* workerGetProductListingDataSaga({
  payload: { searchTerm, adjustments },
}) {
  try {
    const products = yield call(
      getProductListingData,
      searchTerm,
      adjustments
    );
    yield put(setProductListingData(products.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetProductListingDataSaga() {
  yield takeLatest(
    GET_PRODUCT_SEARCH_DATA_SAGA,
    workerGetProductListingDataSaga
  );
}
