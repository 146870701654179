import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_ORDER_HISTORY_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setOrderHistory } from '../../actions/orderAction';
import { getOrders } from '../../api/orderApi';

function* workerGetOrderHistorySaga({ payload: { params } }) {
  try {
    const orderHistory = yield call(getOrders, params);
    yield put(setOrderHistory(orderHistory.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetOrderHistorySaga() {
  yield takeLatest(GET_ORDER_HISTORY_SAGA, workerGetOrderHistorySaga);
}
