import React from 'react';
import { Link } from 'react-router-dom';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import Grid from '@material-ui/core/Grid';
import './style.css';

const ContactUs = () => {
  return (
    <PageLayoutHOC showSearch='false'>
      <Grid className=' contactPadding externalContainer'>
        <h2>Contact us</h2>
        <p>Can't find what you are looking for?</p>
        <p>
          If you need to speak to a person, chat online, send us an
          email, and get questions answered, we’re here for you.
        </p>
        <Grid className='contactFields'>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className='contactPadding externalContainer'>
            <i class='fa fa-envelope' aria-hidden='true'></i>
            <br />
            {/* <p>Send Us an Email</p>
            <p>mailto: contact@bepsy.com</p> */}
            <a
              href='mailto:contact@bepsy.com?Subject=Hello%20again'
              target='_top'>
              Send Us an Email
            </a>
            <p>
              A dedicated representative will get back to you during
              standard business hours, 8am - 5pm US Central Time
              Monday - Friday.{' '}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className='contactPadding externalContainer'>
            <i class='fa fa-phone' aria-hidden='true'></i>
            <p>Call Us</p>
            <p> 8am - 5pm US Central Time Monday - Friday.</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className='contactPadding externalContainer'>
            <i class='fa fa-map-marker' aria-hidden='true'></i>
            <p>Locate Our Office</p>
            <p>
              Company Name
              <br />
              Company Address Line 1<br />
              Company Address Line 2<br />
            </p>
          </Grid>
        </Grid>
      </Grid>
    </PageLayoutHOC>
  );
};

export default ContactUs;
