import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config';

const getAccessTokenApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/login`;
const getProfileApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profiles/current?profileId=`;

export function getUserAuthentication(login, password) {
  const bt = 'Bearer ' + localStorage.getItem('basic_token');

  const data = {
    username: login,
    password,
  };
  const headers = {
    Authorization: bt,
    'Content-Type': 'application/json',
    'Bepsy-OrganizationId': localStorage.getItem('organizationId'),
  };

  return axios.post(getAccessTokenApi, data, { headers });
}

function getCurrentContext() {
  try {
    return JSON.parse(localStorage.getItem('context'));
  } catch (err) {
    return null;
  }
}

export const getUserProfile = (userId, siteId) =>
  new Promise((resolve, reject) => {
    const profileApi = getProfileApi + userId;
    const token = localStorage.getItem('access_token');
    let currentContext = getCurrentContext();
    siteId = siteId || (currentContext && currentContext.siteId);

    const headersProfile = `Bearer ${token}`;

    axios
      .get(profileApi, {
        headers: {
          Authorization: headersProfile,
          'Content-Type': 'application/json',
          'Bepsy-SiteId': siteId,
          'Bepsy-OrganizationId': localStorage.getItem(
            'organizationId'
          ),
        },
      })
      .then((response) => {
        //console.log('response====> on login====>', response);
        // adding context object and user details to
        const context = {
          siteId,
          profileId: response.data.profile_user.profileId,
          organizationId: response.data.profile_user.accountId,
          organizationName: response.data.profile_user.companyName,
          contractId: response.data.profile_user.contractId,
          catalogId: response.data.profile_user.catalogId,
          pricelistId: response.data.profile_user.pricelistId,
          orderId: '',
          sfid: response.data.profile_user.sfid,
        };
        //get organizations associated with account
        const organizationApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/${response.data.profile_user.accountId}/subOrganizations`;
        axios
          .get(organizationApi, {
            Authorization: headersProfile,
            'Bepsy-UserRoles':
              response.data.profile_user.contactTypeRole,
            'Content-Type': 'application/json',
          })
          .then((organizationRes) => {
            const OrganizationList = [];

            organizationRes.data &&
              organizationRes.data.items.forEach((element) => {
                OrganizationList.push(element.name);
                localStorage.setItem(
                  'OrganizationList',
                  OrganizationList
                );
              });
          });
        localStorage.setItem('context', JSON.stringify(context));
        const user = {
          firstName: response.data.profile_user.firstName,
          lastName: response.data.profile_user.lastName,
          companyName: response.data.profile_user.companyName,
          email: response.data.profile_user.email,
          contactTypeRole: response.data.profile_user.contactTypeRole,
        };
        localStorage.setItem('user', JSON.stringify(user));

        if (
          localStorage.getItem('user') !== null ||
          localStorage.getItem('user') !== undefined ||
          localStorage.getItem('context') !== null ||
          localStorage.getItem('context') !== undefined
        ) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.log(`error in profile api${error}`);
      });
  });
