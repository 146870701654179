import React from 'react';
import { Grid } from '@material-ui/core'
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import AImg1 from '../../assets/AboutUs/aboutusImg1.jpeg'
import AImg2 from '../../assets/AboutUs/aboutusImg2.jpeg'
import AImg3 from '../../assets/AboutUs/aboutusImg3.jpeg'

function AboutUs() {
  return (
    <PageLayoutHOC showSearch='false'>
      <div className='externalContainer contactPadding'>
        <div>
          <h2>About us</h2>
           <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <img className='about_img' src={AImg1} />
          </Grid>
          <p>
            <h3 id='A-Humble-Beginning'>A Humble Beginning</h3>
          </p>
          <p>
            When I was twelve years old, my father came home from work
            one night and proclaimed that we were going to buy
            bicycles. He and I were going on a bicycle trip.
          </p>
          <p>“Okay, Dad,” I told him.</p>
          <p>
            As we drove down to pick out bicycles, my dad told me that
            he had met a guy named Bevil Hogg, a South African who
            owned a bicycle store in Madison, Wisconsin. Bevil was
            looking for someone to invest in his store. I had my mind
            on a new bicycle and didn’t really pay any attention. We
            bought two bicycles, some bicycle packs and a few maps,
            and my dad announced that the following week we would be
            riding from Beaver Lake, our home, up to Fond du Lac and
            back.
          </p>
          <p>
            It is a beautiful ride through the hills and valleys of
            the Kettle Moraine region. I don’t think that I had ever
            ridden my bike more than ten miles in one crack, and now
            we were going to ride seventy miles in a single day. I
            don’t remember much about this trip, with the exception
            that I crashed going over some railroad tracks, and that
            we were on a really busy road for the last few miles of
            Day 1 when a semi came past me and almost blew me off the
            road. On the second day of our two-day journey, we stopped
            at a bar outside of Monches. I drank root beer, and my dad
            had a couple of beers before we got back on the bikes and
            headed home. We had a great time.
          </p>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <img className='about_img_2' src={AImg2} />
          </Grid>
          <p>
            Not long after the bike ride, my dad told me that he had
            bought a bike store in Madison with Bevil named Stella
            Bicycle Shop. My dad loved business and was always looking
            for great opportunities. When serendipity led to him
            meeting Bevil Hogg on a plane, my dad decided to pursue
            his interest in bicycles.
          </p>
          <p>
            After beginning with one store in Madison, they opened a
            second Stella Bicycle Shop in Champaign, Illinois. My dad
            always thought big, and his idea was to set up a
            nationwide chain of bicycle stores in college towns. One
            problem, though. It didn’t work. The stores lost money,
            and my dad and Bevil closed them in the fall of 1975. My
            father and Bevil learned that in order to succeed, they
            could not just sell any brand; they needed something
            special. None of the good brands were available to
            purchase, so their solution was to create their own brand
            of bicycles.
          </p>
          <p>
            No one was building really good bikes in the United
            States. Bevil thought that instead of being a retailer,
            they should manufacture high-end bikes built in the United
            States. Schwinn sold a lot of low-end bikes and kids’
            bikes and there were some high-end European brands, but
            nothing from the middle price points all the way to the
            top that was made in America. It was a unique idea,
            something completely different.
          </p>
          <p>John Burke</p>
          <p>
            President, Trek Bicycle
            <br />
            <em>(Pictured: Dick Burke)</em>
          </p>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <img className='about_img' src={AImg3} />
          </Grid>
          <h3 id='Relentless-Progression'>Relentless Progression</h3>
          <p>
            The four decades following our inception have witnessed
            greater innovation than the previous four hundred.
            Bicycles have not been immune to this explosion in
            technological advancement, and Trek has been at the
            forefront of the movement, continually challenging the
            limits of the bicycle's capability. Our commitment to
            relentless innovation while honoring our founding
            principles to build bikes people love that we are proud to
            stand behind has led us to where we are today. But we know
            that there is so much more that we can do. This world
            needs the bicycle more today than ever before. And Trek is
            building a better world through this simple, elegant
            machine. Come ride with us.
          </p>
          <p>Raised on rocket science read more</p>
          <p>Racing to make bikes better read more</p>
          <p>Warrantied for life read more</p>
        </div>
      </div>
    </PageLayoutHOC>
  );
}

export default AboutUs;
