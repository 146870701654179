import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { Grid, Divider, TextField, Button } from '@material-ui/core';
import FileUpload from '../../components/Shared/uploadButtons';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from '@material-ui/core';
import { CustomTextField } from '../../components/Shared/customTextField';
import './Style.css';

import {
  requestQuote,
  resetRequestQuote,
} from '../../actions/quoteAction';

class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteName: null,
      quoteComment: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requestQuoteRes !== this.props.requestQuoteRes) {
      if (this.props.requestQuoteRes.status === 200) {
        prevProps.toastManager.add('Successfully sent the quote!', {
          appearance: 'success',
          autoDismiss: true,
        });
      }
    }
  }

  clearForm = (e) => {
    alert('clear');
    this.setState({
      quoteName: '',
      quoteComment: '',
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { quoteName, quoteComment } = this.state;

    this.props.requestQuote({ quoteName, quoteComment, history });
    this.setState({
      quoteName: '',
      quoteComment: '',
    });
  };

  handleQuoteNameChange = (e) => {
    // console.log('input quote name');
    this.setState({ quoteName: e.target.value });
  };

  handleCommentChange = (e) => {
    // console.log('input quote comment');
    this.setState({ quoteComment: e.target.value });
  };

  render() {
    console.log(
      ' render...did update.....',
      this.props.requestQuoteRes
    );
    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <Grid item className='externalContainer quoteContainer'>
          <h1>Request A Quote</h1>
          <br />
          <form className='login-form' onSubmit={this.onFormSubmit}>
            <Grid item xl={6} lg={6} md={8} sm={8}>
              <CustomTextField
                required
                fullwidth='true'
                name='quoteName'
                autoComplete='quoteName'
                placeholder='Quote Name'
                type='text'
                placeholder='Quote Name'
                value={this.state.quoteName}
                onchange={
                  this.handleQuoteNameChange
                }></CustomTextField>
            </Grid>

            <Grid item xl={6} lg={6} md={8} sm={8}>
              <CustomTextField
                required
                name='quoteComment'
                placeholder='Add comments ...'
                type='quoteComment'
                value={this.state.quoteComment}
                multiline={true}
                rows={4}
                onchange={this.handleCommentChange}></CustomTextField>
            </Grid>

            <br />
            {/* <br />
            <FileUpload></FileUpload>
            <br /> */}
            <br />
            <div
              style={{
                display: 'inline-flex',
                textAlign: 'center',
              }}>
              <Button
                // variant='contained'
                // color='secondary'
                //type='submit'
                onClick={this.clearForm}
                style={{
                  // backgroundColor: '#d5d5d5',
                  // color: '#000000',
                  marginBottom: 10,
                  width: 100,
                  height: 41,
                }}>
                Clear
              </Button>
              &nbsp;&nbsp;
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                onClick={this.onFormSubmit}
                style={{
                  backgroundColor: '#e41e26',
                  color: '#ffffff',
                  marginBottom: 10,
                  width: 100,
                  height: 41,
                }}>
                Send
              </Button>
            </div>
          </form>
        </Grid>
      </PageLayoutHOC>
    );
  }
}

Quote.propTypes = {};

const mapStateToProps = (state) => ({
  requestQuoteRes: state.quoteReducer.requestQuoteRes,
});

const mapDispatchToProps = (dispatch) => ({
  requestQuote: (data) => dispatch(requestQuote(data)),
  resetRequestQuote: () => dispatch(resetRequestQuote()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Quote));
