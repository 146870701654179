import React from 'react';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
// import './style.css';
import { CustomTextField } from '../../components/Shared/customTextField';
import CustomError from '../../components/Shared/customErrorText';
import { Grid, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import CustomSelect from '../../components/Shared/customizedSelect';
import { getRegistrationSaga } from '../../actions/registrationAction';
import getPasswordSaga from '../../actions/setPasswordAction';
import Loader from '../../components/Shared/circularProgressBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../Footer';
import regImg from '../../assets/Registration/registration.jpeg';
import Logo from '../../assets/Logo/logo.png';
import backToLogin from '../../assets/ForgotPassword/backArrow.png';
import {
  isValidEmail,
  isStringOnlyAlphabet,
  isOnlyNumber,
} from '../../utils/common';
import { stateList, countryList } from '../../constants';
import Loading from '../../containers/Loading';
import CircularProgressBar from '../../components/Shared/circularProgressBar';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PASSWORD_SAVED,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_MISMATCH,
  ERROR_PASSWORD_VALIDATION,
  PASSWORD_LENGTH,
  PASSWORD_SHOULD_BE,
  PASSWORD_LIMITATION,
  GET_PASSWORD_SAGA,
} from '../../constants';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Update password related states
      email: '',
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      showPwLoader: false,
      pwdisabled: true,
      showEmailPwError: false,
      showOldPwError: false,
      showNewPwError: false,
      shownewConfirmPwError: false,
      invalidPassword: false,
      passwordMismatch: false,
    };
  }
  componentDidUpdate(prevProps) {
    this.toastManager = prevProps.toastManager;

    if (
      this.props.password[0].password !==
      prevProps.password[0].password
    ) {
      if (
        this.props.password[0].password &&
        this.props.password[0].password.status === 200
      ) {
        this.toastManager.add('Password set successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({
          showPwLoader: false,
          email: '',
          oldPassword: '',
          newPassword: '',
          newConfirmPassword: '',
          showPwLoader: false,
          pwdisabled: true,
          showEmailPwError: false,
          showOldPwError: false,
          showNewPwError: false,
          shownewConfirmPwError: false,
          invalidPassword: false,
          passwordMismatch: false,
        });
      }
    }

    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: true,
      });
      this.setState({
        showPwLoader: false,
      });
    }
  }

  validateForm = () => {
    return (
      this.state.email.length > 0 &&
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      !this.state.invalidPassword &&
      !this.state.showPwLoader &&
      this.state.newPassword === this.state.newConfirmPassword
    );
  };
  passwordChangeHandler = (event) => {
    let pwName = event.target.name;
    let pwValue = event.target.value;
    if (pwName === 'email') {
      const emailValid = isValidEmail(pwValue);
      if (!emailValid) {
        this.setState({
          showEmailPwError: true,
        });
      } else if (emailValid) {
        this.setState({
          showEmailPwError: false,
        });
      }
    } else if (
      pwValue === null ||
      (pwValue === '' && pwName === 'oldPassword')
    ) {
      this.setState({
        pwdisabled: true,
        showOldPwError: true,
      });
    } else if (
      pwValue === null ||
      (pwValue === '' && pwName === 'newPassword')
    ) {
      this.setState({
        pwdisabled: true,
        showNewPwError: true,
      });
    } else if (
      pwValue === null ||
      (pwValue === '' && pwName === 'newConfirmPassword')
    ) {
      this.setState({
        pwdisabled: true,
        shownewConfirmPwError: true,
      });
    } else if (pwValue !== null || pwValue !== '') {
      this.setState({
        // showEmailPwError: false,
        showOldPwError: false,
        showNewPwError: false,
        shownewConfirmPwError: false,
        pwdisabled: false,
      });
    }
    this.setState({
      [pwName]: pwValue,
    });
    this.validateForm();
  };

  validatePassword = () => {
    let newPassword = this.state.newPassword;
    let patternMatch = /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{7,15})$/i;
    let requiredPassword = newPassword.match(patternMatch);
    if (requiredPassword !== null) {
      this.setState({
        invalidPassword: false,
      });
    } else {
      this.setState({
        invalidPassword: true,
      });
    }
    if (this.state.newPassword !== this.state.newConfirmPassword) {
      this.setState({
        passwordMismatch: true,
      });
    } else {
      this.setState({
        passwordMismatch: false,
      });
    }
  };

  handleSavePassword = (e) => {
    e.preventDefault();
    const email = this.state.email;
    const oldPassword = this.state.oldPassword;
    const newPassword = this.state.newPassword;
    const newConfirmPassword = this.state.newConfirmPassword;

    if (email && oldPassword && newPassword && newConfirmPassword) {
      this.setState({
        showPwLoader: true,
        pwdisabled: true,
      });
      this.props.getPasswordSaga({
        email,
        oldPassword,
        newPassword,
        newConfirmPassword,
      });
    } else {
      this.toastManager.add('Please enter all the details', {
        appearance: 'warning',
        autoDismiss: true,
      });

      this.setState({
        showPwLoader: false,
        pwdisabled: false,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      email: '',
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      showPwLoader: false,
      pwdisabled: true,
      showEmailPwError: false,
      showOldPwError: false,
      showNewPwError: false,
      shownewConfirmPwError: false,
      invalidPassword: false,
      passwordMismatch: false,
    });
  };
  render() {
    return (
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className='set-margin'
          style={{ display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={12}
            className='set-border'>
            <Grid item className='align-center'>
              <CssBaseline />
              <div className='formPadding'>
                <div>
                  <img src={Logo} alt='logo' />
                </div>
                <div></div>
                <h1 className='textReg'> Customer Login </h1>
                <p>
                  As a B2B customer logging in for the first
                  time,please update your site generated password with
                  your new password.
                </p>
                <form onSubmit={this.handleSubmit}>
                  {
                    <Grid>
                      <Grid
                        item
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        className='required'>
                        <CustomTextField
                          label='Email:'
                          required={true}
                          onchange={this.passwordChangeHandler}
                          value={this.state.email}
                          type='email'
                          name='email'
                        />
                        {this.state.showEmailPwError && (
                          <CustomError
                            masg='Please enter a valid Email id'
                            displayType='block'
                            margin='8px'
                          />
                        )}
                        <CustomTextField
                          label='Expired(site generated)Password:'
                          required={true}
                          onchange={this.passwordChangeHandler}
                          value={this.state.oldPassword}
                          type='password'
                          name='oldPassword'
                        />
                        {this.state.showOldPwError && (
                          <CustomError
                            masg='Please enter a valid Old Password'
                            displayType='block'
                            margin='8px'
                          />
                        )}
                      </Grid>
                      <div>
                        <Hidden smDown>
                          <Grid style={{ display: 'flex' }}>
                            <Grid
                              item
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className='required'>
                              <CustomTextField
                                label='New Password:'
                                required={true}
                                onchange={this.passwordChangeHandler}
                                value={this.state.newPassword}
                                type='password'
                                name='newPassword'
                                onblur={this.validatePassword}
                              />
                            </Grid>
                            <span className='toopTip'>
                              <Tooltip
                                title={
                                  <ul className='tooltipFont'>
                                    <li>{PASSWORD_LENGTH}</li>
                                    <li>{PASSWORD_SHOULD_BE}</li>
                                    <li>{PASSWORD_LIMITATION}</li>
                                  </ul>
                                }
                                style={{ alignSelf: 'center' }}>
                                <InfoIcon />
                              </Tooltip>
                            </span>
                          </Grid>
                          {this.state.invalidPassword && (
                            <CustomError
                              masg={ERROR_PASSWORD_VALIDATION}
                              displayType={
                                this.state.invalidPassword
                                  ? 'block'
                                  : 'none'
                              }
                              margin='8px'
                            />
                          )}
                        </Hidden>{' '}
                      </div>
                      <div>
                        <Hidden mdUp>
                          <Grid>
                            <Grid
                              item
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className='required'>
                              <CustomTextField
                                label='New Password:'
                                required={true}
                                onchange={this.passwordChangeHandler}
                                value={this.state.newPassword}
                                type='password'
                                name='newPassword'
                                onblur={this.validatePassword}
                              />
                            </Grid>

                            <Grid
                              item
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{
                                alignSelf: 'center',
                              }}
                              className='mobPadding'>
                              <ul className='tooltipFont'>
                                <li>{PASSWORD_LENGTH}</li>
                                <li>{PASSWORD_SHOULD_BE}</li>
                                <li>{PASSWORD_LIMITATION}</li>
                              </ul>
                            </Grid>
                          </Grid>
                          {this.state.invalidPassword && (
                            <CustomError
                              masg={ERROR_PASSWORD_VALIDATION}
                              displayType={
                                this.state.invalidPassword
                                  ? 'block'
                                  : 'none'
                              }
                              margin='8px'
                            />
                          )}
                        </Hidden>{' '}
                      </div>
                      <Grid
                        item
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        className='required'>
                        <CustomTextField
                          label='New Confirm Password:'
                          required={true}
                          onchange={this.passwordChangeHandler}
                          value={this.state.newConfirmPassword}
                          type='password'
                          name='newConfirmPassword'
                          onblur={this.validatePassword}
                        />
                        {this.state.shownewConfirmPwError && (
                          <CustomError
                            masg='Please enter a valid new Confirmation Password'
                            displayType='block'
                            margin='8px'
                          />
                        )}
                        {/* passwordMismatch */}
                        {this.state.passwordMismatch && (
                          <CustomError
                            masg={PASSWORD_MISMATCH}
                            displayType='block'
                            margin='8px'
                          />
                        )}
                      </Grid>
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={!this.validateForm()}
                        onClick={this.handleSavePassword}
                        className='buttonClass'
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor: '#e41e26',
                          color: '#ffffff',
                          marginRight: '20px',
                          marginLeft: '8px',
                        }}>
                        Submit
                      </Button>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={this.handleCancel}
                        className='buttonClass'
                        style={{
                          textTransform: 'capitalize',
                          border: '1px solid #e41e26',
                          color: '#e41e26',
                        }}>
                        Cancel
                      </Button>
                      <span style={{ position: 'absolute' }}>
                        {this.state.showPwLoader &&
                          this.props.updatedPassword !== null && (
                            <CircularProgressBar />
                          )}{' '}
                      </span>
                    </Grid>
                  }
                </form>
                <div
                  style={{ marginTop: '20px', marginLeft: '-8px' }}>
                  <Link href='/' variant='body2'>
                    <img
                      className='backToLogin'
                      src={backToLogin}></img>
                    Back To Login
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              lg={6}
              md={12}
              className='MuiContainer-root align-center img-set set-border full-height'
              style={{
                backgroundImage: `url(${regImg})`,
              }}
            />
          </Hidden>
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  password: [state.setPasswordReducer.password],
  errorResponse: [state.errorReducer.error_data],
});
const mapDispatchToProps = (dispatch) => ({
  getPasswordSaga: (password) => dispatch(getPasswordSaga(password)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ResetPassword));
