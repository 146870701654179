import { put, takeLatest, call } from 'redux-saga/effects';

import {
  UPDATE_CART_SAGA,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERROR,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setCartData,
  updateCartState,
} from '../../actions/cartItemAction';
import { updateCart } from '../../api/cartApi';

function* workerUpdateCart({
  payload: { skuId, orderId, productId, quantity, commerceItemId },
}) {
  try {
    const response = yield call(
      updateCart,
      skuId,
      orderId,
      productId,
      quantity,
      commerceItemId
    );
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;
    if (data) {
      yield put(updateCartState(UPDATE_CART_SUCCESS));
    } else {
      yield put(updateCartState(UPDATE_CART_ERROR));
    }
    // console.log('log card data from saga===>', data);
    yield put(setCartData(data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }

  /*
   * const users = yield call(updateCart);
   * yield put(setCartData(users.data.orderList[0]));
   *console.log('log card data from saga===>', users.data.orderList[0]);
   */
}

export default function* watchUpdateCartSaga() {
  yield takeLatest(UPDATE_CART_SAGA, workerUpdateCart);
}
