import React from 'react';
import {
  createMuiTheme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';

const bootstrapButton = ({
  btnLabel,
  margin,
  padding,
  bgColor,
  fontSize,
  buttonClick,
  buttonClickParams,
  isDisabled,
}) => {
  const BootstrapButton = withStyles({
    root: {
      display: 'inline-block',
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      margin,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#000',
      borderColor: '#ccc',
      // position:'absolute',
      //marginTop: '20px',

      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: '#000',
        borderColor: '#000',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#000',
        borderColor: '#000',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
  })(Button);

  return (
    <div style={{ display: 'inline-block' }}>
      <BootstrapButton
        variant='contained'
        color='primary'
        onClick={
          buttonClickParams
            ? (e) => buttonClick(e, ...buttonClickParams)
            : buttonClick
        }
        disabled={isDisabled}>
        {btnLabel}
      </BootstrapButton>
    </div>
  );
};

export default bootstrapButton;
