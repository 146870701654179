import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const QuickOrderModal = ({
  open,
  onMergeCart,
  onClearCart,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={() => {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <div className={`${classes.paper} custom-model`}>
          <h2 id='transition-modal-title'>Quick Order</h2>
          <p id='transition-modal-description'>
            You have items in the cart, please choose an option to
            proceed.
          </p>
          <Button
            onClick={onClearCart}
            variant='contained'
            color='secondary'
            style={{ marginRight: '10px' }}>
            Clear the cart
          </Button>

          <Button onClick={onMergeCart} variant='contained'>
            Merge the cart
          </Button>
        </div>
      </Modal>
    </div>
  );
};
