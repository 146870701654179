import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_USERS_SAGA, SET_ERROR_STATE } from '../../constants';
import { setUsers } from '../../actions';
import { getUsers } from '../../api/api';

function* workerGetUsersSaga() {
  try {
    const users = yield call(getUsers);
    yield put(setUsers(users.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetUsersSaga() {
  yield takeLatest(GET_USERS_SAGA, workerGetUsersSaga);
}
