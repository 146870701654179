import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import {
  getOrderDetailSaga,
  getOrderDetailSagaReset,
  getOrderHistorySaga,
  getOrdersOnPaginationSaga,
  setOrdersOnPagination,
  setOrderApproved,
  getOrderStates,
} from '../../actions/orderAction';
import {
  getSubOrganizationDetailsSaga,
  setSubOrganization,
} from '../../actions/organizationAction';
import OrderList from '../../components/Shared/OrderList';
import './Styles.css';

class Orders extends Component {
  constructor(props) {
    super(props);
    let context = localStorage.getItem('context');
    let c;
    if (context) {
      context = JSON.parse(context);
      c = context.organizationId;
    }

    const urlParams = new URLSearchParams(props.location.search);

    this.state = {
      expanded: false,
      searchInput: urlParams.get('search') || '',
      currentPage: parseInt(urlParams.get('page') || 1) - 1,
      statusData: urlParams.get('orderStatus') || 'all',
      flag: false,
      ordersUpto: 30,
      startDate: new Date(),
      endDate: new Date(),
      orderRange: false,
      orderRangeError: false,
      searchError: false,
      loader: false,
      sortProp: 'submittedDate',
      sortOrder: 'desc',
      orgData: c,
    };
  }
  recordPerPage = 10; // to define records per page

  componentDidMount() {
    if (!(this.props.orderStates && this.props.orderStates.length)) {
      this.props.getOrderStates();
    }

    const dashboardOrderId = localStorage.getItem('dashboardOrderId');
    let isUserAdmin;

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.contactTypeRole) {
        user.contactTypeRole.map((data) => {
          if (data.roleId === 'admin') {
            isUserAdmin = true;
          }
        });
      }
    } catch (err) {
      console.error(err);
    }

    console.log('dashboardOrderId', dashboardOrderId);
    this.setState({
      currentPage: this.state.currentPage,
      searchError: false,
      expanded: false,
      loader: false,
      searchInput: dashboardOrderId,
      isUserAdmin,
    });

    this.props.getOrdersOnPaginationSaga(
      dashboardOrderId,
      this.state.currentPage,
      this.recordPerPage,
      this.state.ordersUpto,
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      this.state.sortProp,
      this.state.sortOrder,
      this.state.statusData,
      this.state.orgData
    );
    this.props.getSubOrganizationDetailsSaga();
    localStorage.setItem('dashboardOrderId', '');
  }
  handleSearch = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };
  handleEnterSearch = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };
  handleSubmit = () => {
    if (this.state.searchInput.length < 2) {
      this.setState({
        searchError: true,
      });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('page');
      urlParams.delete('orderStatus');
      urlParams.set('search', this.state.searchInput);

      window.history.pushState(
        {},
        null,
        `${window.location.pathname}?${urlParams.toString()}`
      );

      this.setState({
        loader: false,
        currentPage: 0,
        searchError: false,
        expanded: false,
        statusData: 'all',
      });
      this.props.setOrdersOnPagination(null);
      this.props.getOrdersOnPaginationSaga(
        this.state.searchInput,
        0,
        this.recordPerPage,
        this.state.ordersUpto,
        this.convert(this.state.startDate),
        this.convert(this.state.endDate),
        false,
        this.state.sortProp,
        this.state.sortOrder,
        'all',
        this.state.orgData
      );
    }
  };
  handleChange = (id) => (event, _expanded) => {
    this.setState({
      expanded: _expanded ? id : false,
    });
  };

  viewOrder = (orderHistory) => {
    this.props.history.push(`/orders/${orderHistory.orderNumber}`);
  };

  pageChangeClickHandler = (data) => {
    this.props.setOrdersOnPagination(null);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', data.selected + 1);
    window.history.pushState(
      {},
      null,
      `${window.location.pathname}?${urlParams.toString()}`
    );

    this.setState(
      {
        currentPage: data.selected,
        searchError: false,
        expanded: false,
        loader: false,
      },
      () => {
        this.props.getOrdersOnPaginationSaga(
          this.state.searchInput,
          this.state.currentPage,
          this.recordPerPage,
          this.state.ordersUpto,
          this.state.startDate,
          this.state.endDate,
          this.state.orderRange,
          this.state.sortProp,
          this.state.sortOrder,
          this.state.statusData,
          this.state.orgData
        );
      }
    );
  };

  handleSelect = (e) => {
    const sortData = e.target.value.split('-');

    this.props.setOrdersOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchError: false,
      expanded: false,
      loader: false,
    });
    this.setState({ sortProp: sortData[0], sortOrder: sortData[1] });

    this.props.getOrdersOnPaginationSaga(
      this.state.searchInput,
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      sortData[0],
      sortData[1],
      this.state.statusData,
      this.state.orgData
    );
  };

  handleSelectStatus = (e) => {
    const statusData1 = e.target.value.split('-');

    this.props.setOrdersOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchError: false,
      searchInput: '',
      expanded: false,
      loader: false,
    });

    const orderStatus = statusData1[1];
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('orderStatus', orderStatus);
    urlParams.set('page', 1);
    urlParams.delete('search');
    window.history.pushState(
      {},
      null,
      `${window.location.pathname}?${urlParams.toString()}`
    );

    this.setState({ statusData: orderStatus });

    this.props.getOrdersOnPaginationSaga(
      '',
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      //statusData1,

      this.state.sortProp,
      this.state.sortOrder,
      //this.state.statusData,
      statusData1[1],
      this.state.orgData
    );
  };

  handleSelectOrg = (e) => {
    this.props.setOrdersOnPagination(null);
    this.setState({
      currentPage: 0,
      ordersUpto: e.target.value,
      orderRangeError: false,
      startDate: new Date(),
      endDate: new Date(),
      searchError: false,
      expanded: false,
      loader: false,
    });
    this.setState({ orgData: e.target.value });
    this.props.setSubOrganization(e.target.value);
    this.props.getOrdersOnPaginationSaga(
      this.state.searchInput,
      0,
      this.recordPerPage,
      '',
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      //orgData

      this.state.sortProp,
      this.state.sortOrder,
      this.state.statusData,
      e.target.value
    );
  };

  selectStartDate = (e) => {
    this.setState({
      startDate: e,
    });
  };
  convert = (str) => {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  };
  selectEndDate = (e) => {
    this.setState({
      endDate: e,
    });
  };
  handleDateRange = () => {
    this.setState({
      orderRange: true,
    });
    if (this.state.startDate > this.state.endDate) {
      this.setState({
        orderRangeError: true,
      });
    } else {
      this.setState({
        orderRangeError: false,
      });
      this.props.getOrdersOnPaginationSaga(
        this.state.searchInput,
        this.state.currentPage,
        this.recordPerPage,
        this.state.ordersUpto,
        this.convert(this.state.startDate),
        this.convert(this.state.endDate),
        true,
        this.state.sortProp,
        this.state.sortOrder,
        this.state.statusData,
        this.state.orgData
      );
    }
  };
  clearOrderSearchResult = (e) => {
    this.props.setOrdersOnPagination(null);
    let searchInput = '';
    this.setState({
      loader: true,
      currentPage: 0,
      searchError: false,
      expanded: false,
      searchInput: '',
    });
    this.props.getOrdersOnPaginationSaga(
      searchInput,
      this.state.currentPage,
      this.recordPerPage,
      this.state.ordersUpto,
      this.convert(this.state.startDate),
      this.convert(this.state.endDate),
      false,
      this.state.sortProp,
      this.state.sortOrder,
      //this.state.statusData,
      this.state.statusData,
      this.state.orgData
    );
  };

  handleSignedQuote = (event) => {
    event.preventDefault();
    this.props.history.push('quoteslist');
  };

  getOrderDetails() {
    const { order_detail } = this.props;
    return order_detail && order_detail.order_detail;
  }

  handleApprove = () => {
    const orderDetails = this.getOrderDetails();
    this.props.setOrderApproved(orderDetails.orderNumber, true);
  };

  handleReject = () => {
    const orderDetails = this.getOrderDetails();
    this.props.setOrderApproved(orderDetails.orderNumber, false);
  };

  render() {
    const { expanded, searchInput } = this.state;

    const tableCell = ['Id', 'Date', 'Amount', 'Status', 'Details'];

    const filterValues = this.props.orderStates || [];

    const sortValues = [
      {
        key: 'Date - Newest to Oldest',
        value: 'submittedDate-desc',
      },
      {
        key: 'Date - Oldest to Newest',
        value: 'submittedDate-asc',
      },
      {
        key: 'Price - Low to High',
        value: 'orderTotal-asc',
      },
      {
        key: 'Price - High to Low',
        value: 'orderTotal-desc',
      },
    ];

    const token = localStorage.getItem('access_token');
    const {
      orderHistory,
      order_detail,
      orders_list,
      subOrganizations,
    } = this.props;
    console.log('orders_list IN ORDER', orders_list);
    let data, oDetails;

    if (orders_list && orders_list.orders_list) {
      //data = orders_list !== undefined ? orders_list : null;
      data = orders_list.orders_list.orderList;
    }

    if (
      order_detail &&
      order_detail.order_detail &&
      order_detail.order_detail.orderList
    ) {
      //data = orders_list !== undefined ? orders_list : null;
      oDetails = order_detail.order_detail.orderList;
    }

    const orderDetails = this.getOrderDetails();
    const orderStatus = orderDetails && orderDetails.orderStatus;
    const hasApprovalActions =
      orderStatus === 'pendingApproval' && this.state.isUserAdmin;

    if (!token) {
      return <Redirect to='/login' />;
    }

    let orgList = '';

    try {
      const context = JSON.parse(localStorage.getItem('context'));
      orgList = [
        {
          name: context.organizationName,
        },
      ];
    } catch (err) {}

    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            paddingLeft: '1em',
          }}></div>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OrderList
            data={data}
            orgList={orgList}
            expanded={this.state.expanded}
            handleSearch={this.handleSearch}
            handleChange={this.handleChange}
            filteredOrders={
              orders_list &&
              orders_list.orders_list &&
              orders_list.orders_list.totalRecords > 0
                ? orders_list && orders_list.orders_list.orderList
                : ''
            }
            viewOrder={this.viewOrder}
            order_detail={order_detail}
            // order_detail={oDetails}
            orderNumber={this.state.orderNumber}
            currentPage={this.state.currentPage}
            recordPerPage={this.recordPerPage}
            totalRecords={
              orders_list && orders_list.orders_list
                ? orders_list.orders_list.totalRecords
                : null
            }
            handlePageClick={this.pageChangeClickHandler}
            handleSelect={this.handleSelect}
            handleSelectStatus={this.handleSelectStatus}
            handleSelectOrg={this.handleSelectOrg}
            orgData={this.state.orgData} // for now - later make api call to fetch this
            statusData={this.state.statusData}
            ordersUpto={this.state.ordersUpto}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selectStartDate={this.selectStartDate}
            selectEndDate={this.selectEndDate}
            handleDateRange={this.handleDateRange}
            orderRangeError={this.state.orderRangeError}
            handleSubmit={this.handleSubmit}
            handleEnterSearch={this.handleEnterSearch}
            searchError={this.state.searchError}
            searchText={this.state.searchInput}
            orderList={
              orders_list &&
              orders_list.orders_list &&
              orders_list.orders_list.orderList
            }
            clearOrderSearchResult={this.clearOrderSearchResult}
            sortProp={this.state.sortProp}
            sortOrder={this.state.sortOrder}
            tableCell={tableCell}
            pageTitle='Orders'
            filterValues={filterValues}
            sortValues={sortValues}
            handleSignedQuote={this.handleSignedQuote}
            onApprove={this.handleApprove}
            onReject={this.handleReject}
            hasApprovalActions={hasApprovalActions}
            {...this.props}
          />
        </Grid>
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  orderHistory: state.orderHistoryReducer.orderHistory,
  order_detail: state.orderDetailReducer,
  orders_list: state.ordersOnPagination.orders_list,
  orderStates: state.ordersOnPagination.orderStates,
  subOrganizations:
    state.organizationDetailsReducer.organization_details,
});

const mapDispatchToProps = (dispatch) => ({
  getOrderHistorySaga: () => dispatch(getOrderHistorySaga()),
  setOrderApproved: (orderId, isApproved) =>
    dispatch(setOrderApproved(orderId, isApproved)),
  getOrderDetailSaga: (order) => dispatch(getOrderDetailSaga(order)),
  getOrderStates: () => dispatch(getOrderStates()),
  getOrdersOnPaginationSaga: (
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    startDate,
    endDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData
  ) =>
    dispatch(
      getOrdersOnPaginationSaga(
        searchInput,
        currentPage,
        recordPerPage,
        ordersUpto,
        startDate,
        endDate,
        orderRange,
        sortProp,
        sortOrder,
        statusData,
        orgData
      )
    ),
  getOrderDetailSagaReset: (order) =>
    dispatch(getOrderDetailSagaReset(order)),
  setOrdersOnPagination: (order) =>
    dispatch(setOrdersOnPagination(order)),

  getSubOrganizationDetailsSaga: (order) =>
    dispatch(getSubOrganizationDetailsSaga(order)),
  setSubOrganization: (data) => dispatch(setSubOrganization(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
