import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import AccoutParent from '../../components/Account/AccoutParent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  getAddressData,
  addAddressData,
  deleteAddressData,
  updateAddressData,
} from '../../actions/addressAction';
import {
  getCountryDataSaga,
  getStateDataSaga,
} from '../../actions/countryStateAction';
import {
  getOrganizationDetailsSaga,
  resetOrganization,
} from '../../actions/organizationAction';
import {
  addContactSaga,
  updateContact,
} from '../../actions/contactAction';
import { addressTypeList } from '../../constants';
import {
  isOnlyNumber,
  isStringOnlyAlphabet,
  validateCity,
  isValidEmail,
} from '../../utils/common';

class Account extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      addAddress: false,
      form: {
        isDefaultBillingAddress: false,
        isDefaultShippingAddress: false,
      },
      contactForm: {
        isDisable: true,
      },
      isContact: false,
      addContact: false,
      editContact: false,
      submitEdit: false,
      userData: null,
      numberError: false,
      zipCodeError: false,
      isLoading: true,
      disableButton: true,
      deleteAddress: false,
      editAddress: false,
      stateList: null,
    };
  }

  componentDidMount() {
    this.props.getCountryDataSaga();
    this.props.getOrganizationDetailsSaga();
    let userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    let form = { ...this.state.form };
    form.addressType = addressTypeList[0].componentId;
    if (user !== null) form.companyName = user.companyName;
    this.setState({ userData: user, form });
    //  open address form for checkout address
    if (this.props.location.address === 'addAddress') {
      this.setState({ addAddress: true });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let stateList = { ...state.stateList };
    let form = { ...state.form };

    if (props.states[0] && props.states[0].data) {
      // Set state based on Add New Address OR Edit Address

      if (state.editAddress == true && state.addAddress == true) {
        state.stateList = props.states[0].data.data.states;
        if (props.states[0].data.data.country == form.country) {
          if (form.state == null) {
            state.form.state =
              props.states[0].data.data.states[0].code;
          }
        }
      } else if (
        state.editAddress == false &&
        state.addAddress == true
      ) {
        if (
          (form.state == null || form.state == undefined) &&
          form.country != -1
        ) {
          if (props.states[0].data.data.country == form.country) {
            state.form.state =
              props.states[0].data.data.states[0].code;
            state.stateList = props.states[0].data.data.states;
          }
        }
      }
    }
    if (form.country == -1) {
      state.stateList = [];
      state.form.state = null;
    }
    return {
      ...state,
      stateList: state.stateList,
      form: state.form,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.countries[0] != prevProps.countries[0]) {
      if (this.props.countries[0].data[1].code) {
        this.props.getStateDataSaga(
          this.props.countries[0].data[1].code
        );
        //  clear address form and fill the state dropdown for checkout
        if (this.props.location.address === 'addAddress') {
          this.handleAddAddress();
        }
      }
    }

    this.toastManager = prevProps.toastManager;
    //  scrollTo address form for checkout
    if (
      this.props.organizationDetails[0] !==
      prevProps.organizationDetails[0]
    ) {
      if (
        this.props.location.address === 'addAddress' &&
        this.state.addAddress
      ) {
        const scrollView = document.getElementById('addAddress');
        if (scrollView) {
          setTimeout(
            () =>
              document.getElementById('addAddress').scrollIntoView(),
            500
          );
        }
      }
    }

    if (this.props.addressList[0] !== prevProps.addressList[0]) {
      if (!this.state.deleteAddress) {
        this.clearAndCloseAddress();
        this.toastManager.add('Successfully added', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({
          isLoading: false,
          addAddress: false,
        });
      } else {
        this.toastManager.add('Successfully deleted', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({ deleteAddress: false });
      }
    }
    if (this.props.contactList[0] !== prevProps.contactList[0]) {
      this.setState({ isLoading: false });
      this.cancelContact();
      if (this.state.editContact && this.state.submitEdit) {
        this.toastManager.add('Success! Contact updated', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({ editContact: false, submitEdit: false });
      }
      if (this.state.addContact) {
        this.toastManager.add('Success! Contact added', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({ addContact: false });
      }
    }
    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: true,
      });
      this.setState({
        isLoading: false,
        disableButton: false,
        // editAddress: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetOrganization();
    clearTimeout();
  }

  scrollToMyRef = () => {
    setTimeout(() => {
      window.scrollTo(0, this.myRef.current.offsetTop);
    }, 1000);
  };

  handleEditAddress = (data) => {
    if (data.country && data.country != -1) {
      this.props.getStateDataSaga(data.country);
    }
    let form = { ...this.state.form };
    delete data['errorCity'];
    delete data['errorAddress1'];
    form.address1 = data.address1;
    form.address2 = data.address2 ? data.address2 : '';
    form.addressType = data.addressType && data.addressType.toLowerCase();
    form.city = data.city;
    form.companyName = data.companyName;
    form.nickName = data.nickName;
    form.country = data.country;
    form.phoneNumber = data.phoneNumber;
    form.postalCode = data.postalCode;
    form.state = data.state;
    form.isDefaultBillingAddress = data.isDefaultBillingAddress
      ? true
      : false;
    form.isDefaultShippingAddress = data.isDefaultShippingAddress
      ? true
      : false;
    form.repositoryId = data.repositoryId;
    form.errorCity = false;
    form.errorAddress1 = false;

    this.setState(
      {
        form,
        editAddress: true,
        addAddress: true,
        zipCodeError: false,
        numberError: false,
      },
      () => this.isDisable()
    );

    this.scrollToMyRef();
  };

  handleDeleteAddress = (data) => {
    var answer = window.confirm(
      'Are you sure, You want to delete this address?'
    );
    if (answer) {
      this.setState({ deleteAddress: true });
      this.props.deleteAddressData(data);
    }
  };

  handleChange = (e) => {
    // Get states on Country selection
    if (e.target.name == 'country' && e.target.value != -1) {
      this.props.getStateDataSaga(e.target.value);
    }
    let form = { ...this.state.form };
    form[e.target.name] = e.target.value;
    if (e.target.name === 'address1') {
      if (form.address1.length > 1) {
        form.errorAddress1 = false;
      } else {
        form.errorAddress1 = true;
      }
    }
    if (e.target.name === 'city') {
      let validCity = validateCity(form.city);
      if (!validCity) {
        form.errorCity = true;
      } else {
        form.errorCity = false;
      }
    }
    if (e.target.name === 'postalCode') {
      let validNumber = isOnlyNumber(form.postalCode);
      if (!validNumber) {
        this.setState({ zipCodeError: true });
      } else if (form.postalCode.length < 5) {
        this.setState({ zipCodeError: true });
      } else {
        this.setState({ zipCodeError: false });
      }
    }
    if (e.target.name === 'phoneNumber') {
      let numberTest = isOnlyNumber(form.phoneNumber);
      if (!numberTest) {
        this.setState({ numberError: true });
      } else if (form.phoneNumber.length < 10) {
        this.setState({ numberError: true });
      } else {
        this.setState({ numberError: false });
      }
    }
    if (e.target.name === 'addressType') {
      form.isDefaultBillingAddress = false;
      form.isDefaultShippingAddress = false;
    }
    if (form.country != this.props.states[0].data.data.country) {
      form.state = null;
    }
    this.setState({ form }, () => this.isDisable());
  };

  isDisable = (e) => {
    let form = { ...this.state.form };
    if (
      form.address1 &&
      form.address1.length > 2 &&
      form.city &&
      form.city.length > 2 &&
      form.postalCode &&
      form.postalCode.length > 4 &&
      form.phoneNumber &&
      form.phoneNumber.length > 9 &&
      !form.errorAddress1 &&
      !form.errorCity &&
      !this.state.zipCodeError &&
      !this.state.numberError &&
      form.state !== '-1' &&
      form.country !== '-1' &&
      form.addressType !== '-1'
    ) {
      this.setState({
        disableButton: false,
        zipCodeError: false,
        numberError: false,
      });
    } else {
      this.setState({ disableButton: true });
    }
  };

  handleAddressSubmit = () => {
    let data = { ...this.state.form };
    if (data.postalCode.length !== 5) {
      this.setState({ zipCodeError: true });
    } else if (data.phoneNumber.length !== 10) {
      this.setState({ numberError: true });
    } else {
      delete data['errorCity'];
      delete data['errorAddress1'];

      this.setState({ isLoading: true, disableButton: true });
      if (!this.state.editAddress) {
        this.props.addAddressData(data);
      } else {
        this.props.updateAddressData(data);
      }
    }
  };

  handleAddAddress = () => {
    this.clearAndCloseAddress();
    this.setState({ addAddress: true });
  };

  clearAndCloseAddress = () => {
    if (
      this.props.states &&
      this.props.states[0].data &&
      this.props.states[0].data.data.states
    ) {
      this.setState({
        stateList: this.props.states[0].data.data.states,
      });
    }
    let form = { ...this.state.form };
    let user = { ...this.state.userData };
    form = {
      nickName: '',
      address1: '',
      address2: '',
      city: '',
      state:
        this.props.states[0].data &&
        this.props.states[0].data.data.states[0].code,
      country: this.props.countries[0].data[1].code,
      addressType: addressTypeList[0].componentId,
      postalCode: '',
      phoneNumber: '',
      companyName: user.companyName,
      isDefaultBillingAddress: false,
      isDefaultShippingAddress: false,
    };

    this.setState({
      form,
      disableButton: true,
      isLoading: false,
      zipCodeError: false,
      numberError: false,
      editAddress: false,
    });
  };

  handleCancel = () => {
    this.clearAndCloseAddress();
    this.setState({ addAddress: false });
  };

  defaultBillingClickHandler = (e) => {
    let form = { ...this.state.form };
    if (e.target.name === 'isDefaultShippingAddress-singleSelect') {
      form.isDefaultShippingAddress = !form.isDefaultShippingAddress;
    } else {
      form.isDefaultBillingAddress = !form.isDefaultBillingAddress;
    }
    this.setState({ form });
  };

  handleContact = () => {
    this.setState({
      isContact: true,
      addContact: true,
      editContact: false,
      contactForm: {
        role: ['buyer'],
        isDisable: true,
        lastName: '',
        firstName: '',
        email: '',
      },
    });
  };

  cancelContact = () => {
    this.setState({
      isContact: false,
      contactForm: {},
      addContact: false,
      editContact: false,
    });
  };

  handleContactChange = (e) => {
    let form = { ...this.state.contactForm };
    form[e.target.name] = e.target.value;
    if (e.target.name === 'firstName') {
      const validateName = isStringOnlyAlphabet(form.firstName);
      if (form.firstName.trim().length > 1 && validateName) {
        form.errorFirstName = false;
      } else {
        form.errorFirstName = true;
      }
    }
    if (e.target.name === 'lastName') {
      const validateName = isStringOnlyAlphabet(form.lastName);
      if (form.lastName.trim().length > 1 && validateName) {
        form.errorLastName = false;
      } else {
        form.errorLastName = true;
      }
    }
    if (e.target.name === 'email') {
      const emailValid = isValidEmail(form.email);
      if (!emailValid) {
        form.errorEmail = true;
      } else {
        form.errorEmail = false;
      }
    }
    if (e.target.name === 'role') {
      form.role = [...e.target.value];
    }
    if (
      form.errorFirstName === false &&
      form.errorLastName === false &&
      form.errorEmail === false &&
      form.role.length !== 0
    ) {
      form.isDisable = false;
    } else {
      form.isDisable = true;
    }

    this.setState({ contactForm: form });
  };

  submitContact = () => {
    const { contactForm } = this.state;
    const data = {
      firstName: contactForm.firstName,
      lastName: contactForm.lastName,
      email: contactForm.email,
      contactTypeRole: [],
      active: true,
      profileId: contactForm.profileId,
    };
    contactForm.role.map((item) => {
      data.contactTypeRole.push({ roleId: item });
    });
    contactForm.isDisable = true;
    this.setState({ isLoading: true, contactForm });
    if (this.state.editContact) {
      this.setState({ submitEdit: true })
      this.props.updateContact(data);
    } else {
      this.props.addContactSaga(data);
    }
  };

  handleEditContact = (e) => {
    this.handleContact();
    const { contactForm } = this.state;
    contactForm.firstName = e.firstName;
    contactForm.lastName = e.lastName;
    contactForm.email = e.email;
    contactForm.role = [];
    contactForm.errorFirstName = false;
    contactForm.errorLastName = false;
    contactForm.errorEmail = false;
    contactForm.profileId = e.profileId;
    contactForm.isDisable = false;
    e.contactTypeRole.map((item) => {
      contactForm.role.push(item.roleId);
    });
    this.setState({
      editContact: true,
      addContact: false,
      contactForm,
    });
    this.scrollToMyRef();
  };

  render() {
    const token = localStorage.getItem('access_token');
    let form = { ...this.state.form };
    const { organizationDetails, addressList } = this.props;
    let address_list = addressList[0]
      ? addressList[0] && addressList[0].organizationAddress
      : organizationDetails[0] && organizationDetails[0].account;
    if (!token) {
      return <Redirect to='/login' />;
    }
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PageLayoutHOC showSearch='false'>
          <AccoutParent
            refProp={this.myRef}
            addNewAddress={this.handleAddAddress}
            addAddress={this.state.addAddress}
            isLoading={this.state.isLoading}
            disableButton={this.state.disableButton}
            organizationDetails={organizationDetails}
            addressList={address_list}
            handleEditAddress={this.handleEditAddress}
            handleDeleteAddress={this.handleDeleteAddress}
            handleChange={this.handleChange}
            stateList={this.state.stateList}
            countryList={this.props.countries[0].data}
            addressTypeList={addressTypeList}
            handleAddressSubmit={this.handleAddressSubmit}
            defaultBillingClickHandler={
              this.defaultBillingClickHandler
            }
            zipCodeError={this.state.zipCodeError}
            showNumberError={this.state.numberError}
            handleCancel={this.handleCancel}
            formData={form}
            editAddress={this.state.editAddress}
            deleteAddress={this.state.deleteAddress}
            handleContact={this.handleContact}
            handleContactChange={this.handleContactChange}
            state={this.state}
            cancelContact={this.cancelContact}
            submitContact={this.submitContact}
            handleEditContact={this.handleEditContact}
          />
        </PageLayoutHOC>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token,
  organizationDetails: [
    state.organizationDetailsReducer.organization_details,
  ],
  addressList: [state.organizationDetailsReducer.address_list],
  contactList: [state.organizationDetailsReducer.contact_list],
  errorResponse: [state.errorReducer.error_data],
  countries: [state.countryStatesReducer.countries],
  states: [state.countryStatesReducer.states],
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetailsSaga: () =>
    dispatch(getOrganizationDetailsSaga()),
  getAddressData: () => dispatch(getAddressData()),
  getCountryDataSaga: () => dispatch(getCountryDataSaga()),
  getStateDataSaga: (data) => dispatch(getStateDataSaga(data)),
  addAddressData: (data) => dispatch(addAddressData(data)),
  deleteAddressData: (data) => dispatch(deleteAddressData(data)),
  updateAddressData: (data) => dispatch(updateAddressData(data)),
  addContactSaga: (data) => dispatch(addContactSaga(data)),
  updateContact: (data) => dispatch(updateContact(data)),
  resetOrganization: () => dispatch(resetOrganization()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withToastManager(Account));
