import React from 'react';
import './style.css';

export default function CustomPageLoader({ loader }) {
  return (
    <div
      id='cover-spin'
      style={{ display: loader ? 'block' : 'none' }}></div>
  );
}
