import { SET_INVOICE_LIST, SET_INVOICE_DETAILS } from '../constants';

const initialState = { invoiceList: [], invoiceDetails: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.data ? action : [],
      };
    case SET_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: action,
      };
    default:
      return state;
  }
}
