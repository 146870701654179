import { put, takeLatest, call } from 'redux-saga/effects';

import {
  SEARCH_CONTACT_DATA,
  SET_ERROR_STATE,
} from '../../constants';
import { setContactList } from '../../actions/organizationAction';
import { getSearchContactList } from '../../api/contactApi';

function* workerGetContactSearchSaga(searchData) {
  try {
    const contact_list = yield call(
      getSearchContactList,
      searchData.data
    );
    const { data } = contact_list;
    if (data.contacts.length === 0 || data.contacts === null)
      data.message = 'No contact found.';
    yield put(setContactList(contact_list.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetContactSearchListSaga() {
  yield takeLatest(SEARCH_CONTACT_DATA, workerGetContactSearchSaga);
}
