import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_REGISTRATION_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setRegistration } from '../../actions/registrationAction';
import { getRegistration } from '../../api/registrationApi';

function* workerGetRegistrationSaga({
  payload: {
    firstName,
    lastName,
    phoneNumber,
    email,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
    companyName,
    comments,
  },
}) {
  try {
    const registration = yield call(
      getRegistration,
      firstName,
      lastName,
      phoneNumber,
      email,
      address1,
      address2,
      city,
      state,
      country,
      postalCode,
      companyName,
      comments
    );
    yield put(setRegistration(registration));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetRegistrationSaga() {
  yield takeLatest(GET_REGISTRATION_SAGA, workerGetRegistrationSaga);
}
