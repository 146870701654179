import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

const circularProgressBar = () => (
  <CircularProgress color='secondary' size={40} thickness={5} />
);

export default circularProgressBar;
