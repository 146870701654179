import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';
import { Button, Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { CustomTextField } from '../Shared/customTextField';
import CustomSelect from '../Shared/customMultiSelect';
import CustomError from '../Shared/customErrorText';
import Loader from '../Shared/spanLoader';
// import { roleList } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '98%',
  },
  button: {
    marginLeft: 10,
    marginTop: 10,
    width: '100%',
    height: 41,
  },
}));

export default function ContactForm({
  state,
  handleContactChange,
  cancelContact,
  submitContact,
  roleList,
}) {
  const classes = useStyles();
  const { contactForm } = state;
  return (
    <form className={classes.root}>
      <Grid item xs={12}>
        <div className='cancel-contact-mobile'>
          <div style={{ float: 'right', marginTop: 10 }}>
            <Close onClick={cancelContact} />
          </div>
        </div>
        <Grid item xs={12}>
          <CustomTextField
            label='First Name:'
            name='firstName'
            maxLength={20}
            required={true}
            type='text'
            onchange={handleContactChange}
            value={contactForm.firstName}
          />
          {contactForm.errorFirstName && (
            <CustomError
              masg='Please enter valid First Name'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label='Last Name:'
            name='lastName'
            type='text'
            required={true}
            onchange={handleContactChange}
            value={contactForm.lastName}
          />
          {contactForm.errorLastName && (
            <CustomError
              masg='Please enter valid Last Name'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id='email'
            label='Email:'
            name='email'
            maxLength={100}
            type='text'
            onchange={handleContactChange}
            value={contactForm.email}
          />
          {contactForm.errorEmail && (
            <CustomError
              masg='Please enter valid email'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <div className='addressSelect' style={{ marginTop: 7 }}>
            <InputLabel shrink htmlFor='bootstrap-input'>
              State:
            </InputLabel>
            <CustomSelect
              name='role'
              variants={roleList}
              defaultValue='Select Any'
              selectedVariant={contactForm.role}
              variantChange={handleContactChange}
            />
          </div>
        </Grid>
        <Button
          variant='contained'
          color='secondary'
          disabled={contactForm.isDisable}
          onClick={submitContact}
          className={classes.button}
          style={{
            backgroundColor: '#e41e26',
            color: '#ffffff',
          }}>
          {state.editContact ? 'Save Changes' : 'Add New User'}
        </Button>{' '}
        <Button
          variant='outlined'
          color='secondary'
          className={classes.button}
          onClick={cancelContact}
          style={{
            border: '1px solid #e41e26',
            color: '#e41e26',
          }}>
          Cancel
        </Button>
        <div style={{ textAlign: 'center' }}>
          {state.isLoading ? <Loader /> : null}
        </div>
      </Grid>
    </form>
  );
}
