import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withToastManager } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import {
  getMultiInventoryValidationSaga,
  addtoQuoteMultipleItemsSaga,
  getInventoryDetailsSaga,
  updateProductQuantity,
  removeProductQuickOrderForm,
  setMultiInventoryValidation,
  clearQuickOrderState,
} from '../../actions/quickOrderAction';
import Card from '../../components/QuickOrderForm/Card';
import CustomSearchFieldOrderForm from '../../components/Shared/customSearchTextField';
import {
  setSearchTerm,
  getOrderOptionList,
  setOrderOptionList,
} from '../../actions';
import { Redirect } from 'react-router-dom';
import CircularProgressBar from '../../components/Shared/spanLoader';
import './style.css';
import { stringify } from 'querystring';
import { isOnlyNumber } from '../../utils/common';
import Hidden from '@material-ui/core/Hidden';
import Upload from './Upload';

class QuickOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidationDone: false,
      activeOption: 0,
      isDisabled: false,
      upload: false,
    };
  }

  validation = () => {
    const { products } = this.props;
    let items = products.map((product) => {
      return {
        skuId: product.SKU,
        productId: product.productId,
        quantity: product.quantity,
      };
    });

    const reqBody = {
      items: items,
    };
    this.setState({ isValidationDone: true, isDisabled: true });
    this.props.getMultiInventoryValidationSaga(reqBody);
  };

  addToQuote = (items) => {
    this.props.addtoQuoteMultipleItemsSaga(items);
  };

  showError = (items) => {
    const { toastManager } = this.props;
    items.map((product) => {
      const message = `skuId: ${product.skuId} Available Quantity: ${product.quantity}`;
      const element = document.getElementById(product.skuId);
      element.classList.add('showError');
      toastManager.add(message, {
        appearance: 'error',
        autoDismiss: true,
      });
    });
  };

  addToQuoteSuccess = () => {
    const { toastManager } = this.props;
    toastManager.add('Add to Quote Successfully', {
      appearance: 'success',
      autoDismiss: true,
    });
    this.props.clearQuickOrderState();
  };

  componentDidUpdate(prevProps) {
    const { inventoryData, products, cartStatus } = this.props;
    let items = products.map((product) => {
      return {
        skuId: product.SKU,
        productId: product.productId,
        quantity: product.quantity,
      };
    });
    if (this.state.isValidationDone) {
      if (inventoryData != null) {
        if (inventoryData.success) this.addToQuote(items);
        else this.showError(inventoryData.items);

        this.setState({
          isValidationDone: false,
          isDisabled: false,
        });
      }
    }

    if (cartStatus) {
      this.addToQuoteSuccess();
    }
  }

  handleChange = (e, product) => {
    const checkValue = isOnlyNumber(e.target.value);
    if (checkValue !== null) {
      let value = e.target.value;
      const { quantity } = product;
      this.props.updateProductQuantity({ product, value });

      if (value === '' || value === '0') {
        this.setState({ isDisabled: true });
      } else {
        this.setState({ isDisabled: false });
      }
      if (product.quantity < 1000 && value <= product.inventory) {
        this.props.updateProductQuantity({ product, value });
      } else {
        this.showToastMessage('inputUpdate');
      }
    }
  };

  handleRemoveItem = (index) => {
    var answer = window.confirm(
      'Are you sure you want to remove this item?'
    );
    if (answer) {
      if (index !== -1) {
        this.props.removeProductQuickOrderForm(index);
      }
    }
  };

  showToastMessage = (type) => {
    let message;
    if (type === 'increase') {
      message = 'Quantity cannot exceed the items left in Inventory';
    } else if (type === 'decrease') {
      message = 'Quantity cannot be decreased beyond 1';
    } else if (type === 'already') {
      message = 'Item already exists';
    } else if (type === 'inputUpdate') {
      message = 'Quantity cannot exceed';
    }

    this.props.toastManager.add(message, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  stepperClickHandler = (product, inventory, type) => {
    const { quantity } = product;
    if (type === 'increase' && quantity < 1000) {
      if (quantity === inventory) {
        this.showToastMessage('increase');
      } else {
        const value =
          typeof quantity === 'string'
            ? Number(quantity) + 1
            : quantity + 1;
        this.setState({ isDisabled: false });
        this.props.updateProductQuantity({ product, value });
      }
    } else if (type === 'decrease') {
      if (quantity > 1) {
        const value = quantity - 1;
        this.props.updateProductQuantity({ product, value });
      } else if (quantity === 1) {
        this.showToastMessage('decrease');
      }
    }
  };

  searchTextChangeHandler = (e) => {
    e.preventDefault();
    let searchText = e.target.value;
    this.setState({ searchError: false, searchText });
    if (searchText && searchText.trim().length > 2) {
      this.props.getOrderOptionList(searchText);
      this.setState(
        {
          searchText: searchText,
          activeOption: 0,
        },
        () => this.props.setSearchTerm(searchText.trim())
      );
    }
    this.props.clearOptions();
  };

  searchEnterClickHandler = (e) => {
    let searchText = e.target.value;
    const { activeOption } = this.state;
    const { orderoptionList } = this.props;
    let options = orderoptionList
      ? orderoptionList.response.records
      : null;
    if (e.keyCode === 13) {
      if (searchText && searchText.trim().length > 2) {
        this.setState({
          searchError: false,
        });
      } else {
        this.setState({ searchError: true });
      }
      this.getOrderOption(options[activeOption]);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState(
        {
          activeOption: activeOption - 1,
          searchText: options[activeOption - 1].term,
        },
        () => this.props.setSearchTerm(this.state.searchText)
      );
    } else if (e.keyCode === 40) {
      if (activeOption === options.length - 1) {
        return;
      }
      this.setState(
        {
          activeOption: activeOption + 1,
          searchText: options[activeOption + 1].term,
        },
        () => () => this.props.setSearchTerm(this.state.searchText)
      );
    }
  };

  inventoryCheck = (item,qty) => {
    this.props.getInventoryDetailsSaga(item,qty);
  };

  checkDuplicate = (item,qty) => {
    const { products } = this.props;

    if (item) {
      let flag = true;

      if (products.length > 0) {
        products.map((product) => {
          if (product.SKU === item.SKU) {
            this.showToastMessage('already');
            flag = false;
          }
        });
      }
      if (flag === true) {
        this.inventoryCheck(item,qty);
      }
    }
  };

  getOrderOption = (option,qty) => {
    if (option && option.term)
      this.setState({ searchText: option.term });
    this.checkDuplicate(option, qty);
    this.props.clearOptions();
    this.setState({
      searchText: ''
    });
  };

  handleOutsideClick = (e) => {
    setTimeout(() => this.props.clearOptions(), 200);
  };
  clearOrder=()=>{
    this.props.clearQuickOrderState();
    this.setState({
      searchText:''
    });
  }
  componentWillUnmount() {
    clearTimeout();
  }

  render() {
    const { products, orderoptionList, cartStatus } = this.props;
    if (cartStatus) {
      return <Redirect to='/cart' />;
    }
    const listProducts = products.map((product, index) => {
      return (
        <Card
          quantityChangeHandler={this.quantityChangeHandler}
          stepperClickHandler={this.stepperClickHandler}
          handleRemoveItem={this.handleRemoveItem}
          key={product.SKU}
          product={product}
          index={index}
          id={product.SKU}
          handleChange={this.handleChange}
        />
      );
    });

    let options = orderoptionList
      ? orderoptionList.response.records.map((option) => {
          option.term = `${option.SKU}-${option.sfdcName ||
            option.alternateName} - $ ${
            option.compositeProducts[0].priceEntry.listPrice
          }`;
        })
      : null;

    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <h3>Quick Order Form</h3>
        <div className='orderProd'>
             < div className = "orderSearch" >
    {
      < Hidden xsDown >
      < Upload
      optionList = {
        orderoptionList && orderoptionList
      }
       getOption={this.getOrderOption}
       getOrderOptionList = {
         this.getOrderOptionList
       }
       sku={this.state.sku}
       qty={this.state.qty}
       clearOrder = {
         this.clearOrder
       }
       listProductsLength = {
         listProducts.length
       }
       
      />
      </ Hidden>}
  </div>
          {' '}
          <div className='orderSearch'>
            <CustomSearchFieldOrderForm
              placeholder={'Search Products'}
              searchTextChangeHandler={this.searchTextChangeHandler}
              searchEnterClickHandler={this.searchEnterClickHandler}
              hideSearchIcon={true}
              searchError={this.state.searchError}
              searchText={this.state.searchText}
              optionList={
                orderoptionList && orderoptionList.response.records
              }
              getOption={this.getOrderOption}
              activeOption={this.state.activeOption}
              handleOnBlur={this.handleOutsideClick}
              autoFocus={true}
            />
          </div>
          {listProducts.length > 0 ? (
            <div className='cards'>{listProducts}</div>
          ) : null}
          <div className='right-align-search'>
            <span>
              {this.state.isValidationDone ? (
                <CircularProgressBar />
              ) : null}
            </span>

            <span>
              <Button
                variant='contained'
                color='inherit'
                size='large'
                id='addToQuote'
                disabled={
                  listProducts.length === 0 || this.state.isDisabled
                }
                onClick={this.validation}>
                Add to Quote
              </Button>
            </span>
          </div>
        </div>
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  inventoryData: state.quickOrderReducer.inventoryData,
  orderoptionList: state.orderReducer.orderoptionList,
  inventory: state.quickOrderReducer.inventory,
  orderoptionList: state.orderReducer.orderoptionList,
  products: state.quickOrderReducer.products,
  cartStatus: state.quickOrderReducer.cartStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getMultiInventoryValidationSaga: (products) =>
    dispatch(getMultiInventoryValidationSaga(products)),
  addtoQuoteMultipleItemsSaga: (products) =>
    dispatch(addtoQuoteMultipleItemsSaga(products)),
  getInventoryDetailsSaga: (sku,qty) =>
    dispatch(getInventoryDetailsSaga(sku,qty)),
  setSearchTerm: (term) => dispatch(setSearchTerm(term)),
  getOrderOptionList: (term) => dispatch(getOrderOptionList(term)),
  clearOptions: () =>
    dispatch(setOrderOptionList({ response: { records: [] } })),
  removeProductQuickOrderForm: (index) =>
    dispatch(removeProductQuickOrderForm(index)),
  updateProductQuantity: (data) =>
    dispatch(updateProductQuantity(data)),
  setMultiInventoryValidation: (data) =>
    dispatch(setMultiInventoryValidation(data)),
  clearQuickOrderState: () => dispatch(clearQuickOrderState()),
});

const QuickOrderFormWithHOC = compose(
  withToastManager,
  connect(mapStateToProps, mapDispatchToProps)
)(QuickOrderForm);
export default QuickOrderFormWithHOC;
