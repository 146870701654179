import React from 'react';
import {
  makeStyles,
  withStyles,
  fade,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    padding: 0,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#e41e26',
      boxShadow: `${fade('#e41e26', 0.25)} 0 0 0 0.2rem`,
    },
  },
}))(InputBase);

export default ({ variants, selectedColor, clickHandler }) => {
  const colorCode = [];
  let colorName = [];
  let defaultColor = '';

  if (variants) {
    colorName = Object.keys(variants.components);
    defaultColor = colorName[0];
    colorName.map((e, i) => {
      colorCode.push(variants.components[e]);
    });
  }

  // defaultColor = selectedColor || defaultColor;

  return (
    variants && (
      <>
        <div className='color-swatch'>
          <div className='color-box'>
            <span className='color-label'>
              {variants.componentDisplayName}:{' '}
            </span>
            <span className='color-name'>{selectedColor}</span>
          </div>
          <div className='color-swatch-box'>
            {/* {
              colorName.map(item => (
                <option key={item} value={item}>{item}</option>
              )) */}

            <FormControl>
              <Select
                native
                name={variants.componentDisplayName}
                value={selectedColor}
                onChange={clickHandler}
                input={<BootstrapInput id='customized-select' />}>
                <option value={-1}>
                  {variants.componentDisplayName}
                </option>
                {colorName !== undefined &&
                  colorName.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </>
    )
  );
};
