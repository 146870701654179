import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import QuantityStepper from './quantityStepper';
import ColorSwatch from '../Shared/colorSwatch';
import VariantSelect from '../Shared/variantSelect';
import BootstrapButton from '../Shared/bootstrapButton';
import CustomizedSelect from '../Shared/customizedSelect';
import CustomError from '../Shared/customErrorText';
import CustomLoader from '../Shared/spanLoader';
import {
  ADD_TO_CART_INITIATED,
  ADD_TO_CART_ERROR_MASG,
} from '../../constants';
import './style.css';

const sizeLabelMap = {
  x_size: 'Size',
  x_Available_at: 'Available At',
  x_Availability: 'Data Size',
  x_No_of_Boxes: 'Frequency per Month',
  x_Quantity: 'Quantity',
  x_No_of_Files: 'Number of files',
  x_Quantity_Discounts: 'Quantity Discounts',
};

function getSizeLabel(props) {
  if (props.primaryVariantType === 'x_color') {
    return sizeLabelMap[props.secondaryVariantType];
  }

  return sizeLabelMap[props.primaryVariantType];
}

const productDetails = (props) => {
  const {
    productName,
    selectedSKU,
    skuDetails,
    onPrimaryVariantSelect,
    productSpecs,
    variants,
    colorClick,
    selectedColor,
    sizeList,
    selectedSize,
    sizeChangeHandler,
    selectedQuantity,
    quantityChangeHandler,
    stepperClickHandler,
    addToQuoteClickHandler,
    showError,
    showSizeError,
    addToCartState,
  } = props;

  const showAvailableQty = () => {
    return sizeList.map((e, i) =>
      e.componentId == selectedSize ? (
        skuDetails.skuInventoryItem.qtyAvailable > 0 &&
        skuDetails.skuInventoryItem.qtyAvailable !== null ? (
          <span className='product-status' key={selectedSize}>
            Available (Qty: {skuDetails.skuInventoryItem.qtyAvailable}
            )
          </span>
        ) : (
          <span className='product-status outofstock-color' key={i}>
            Out Of Stock
          </span>
        )
      ) : null
    );
  };

  return (
    <Grid
      className='product-details-box'
      item
      xs={12}
      sm={6}
      md={6}
      lg={6}
      xl={6}>
      <div>
        <p className='product-name'>{productName}</p>
      </div>
      <div className='price-box'>
        <span className='product-price'>
          ${skuDetails.priceEntry.listPrice}
        </span>
        {showAvailableQty()}
      </div>
      <div className='product-dimensions'>
        {productSpecs.map((e, i) => {
          if (i < 4)
            return (
              <p key={`specs-${i}-${e.spec}`}>
                {e.spec} : {e.specValue}
              </p>
            );
        })}
      </div>
      {(props.primaryVariantType === 'x_Availability' ||
        props.primaryVariantType === 'x_No_of_Boxes') && (
        <VariantSelect
          // variants={Object.keys(variants.components).map(key => variants.components[key])}
          // selectedVariant={selectedColor}
          // variantChange={onPrimaryVariantSelect}
          // defaultValue={getSizeLabel(props)}
          variants={variants}
          selectedOption={selectedColor}
          clickHandler={onPrimaryVariantSelect}
        />
      )}
      {props.primaryVariantType === 'x_color' && (
        <ColorSwatch
          variants={variants}
          selectedColor={selectedColor}
          clickHandler={colorClick}
        />
      )}
      {sizeList.length > 0 && (
        <CustomizedSelect
          variants={sizeList}
          selectedVariant={selectedSize}
          variantChange={sizeChangeHandler}
          defaultValue={getSizeLabel(props)}
        />
      )}
      {showSizeError && (
        <CustomError
          masg='Please Select Size'
          displayType='block'
          margin='5px 0 0 0'
        />
      )}
      <QuantityStepper
        selectedQuantity={selectedQuantity}
        quantityChangeHandler={quantityChangeHandler}
        stepperClickHandler={stepperClickHandler}
      />
      {showError && (
        <CustomError
          masg='Entered quantity is not available'
          displayType='block'
          margin='5px 0 0 0'
        />
      )}
      <div className='add-to-quote'>
        <BootstrapButton
          // isDisabled={!selectedSKU}
          isDisabled={
            skuDetails.skuInventoryItem.availabilityMessage ===
            'outOfStock'
              ? true
              : false
          }
          btnLabel='ADD TO CART'
          margin='0'
          buttonClick={(e) => addToQuoteClickHandler(e)}
        />
        &nbsp;
        {addToCartState === ADD_TO_CART_INITIATED ? (
          <CustomLoader />
        ) : null}
      </div>
      {addToCartState === ADD_TO_CART_ERROR_MASG && (
        <p className='error'>{ADD_TO_CART_ERROR_MASG}</p>
      )}
    </Grid>
  );
};

productDetails.propTypes = {
  selectedSKU: PropTypes.string.isRequired,
  skuDetails: PropTypes.object.isRequired,
  productSpecs: PropTypes.array.isRequired,
  variants: PropTypes.object.isRequired,
  colorClick: PropTypes.func.isRequired,
  selectedColor: PropTypes.string.isRequired,
  sizeList: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  selectedQuantity: PropTypes.number,
  quantityChangeHandler: PropTypes.func,
  stepperClickHandler: PropTypes.func,
  addToQuoteClickHandler: PropTypes.func,
  showError: PropTypes.bool,
  // addToCartState: PropTypes.string.isRequired,
};

export default productDetails;
