export const getProductIdFromURL = () => {
  const url = window.location.pathname;
  const urlKeys = url.split('/');
  const productId = urlKeys[urlKeys.length - 1];
  return productId;
};

export function isValidNumber(p) {
  var numberRe = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  var digits = p.replace(/\D/g, '');
  return numberRe.test(digits);
}

export function isValidEmail(email) {
  var emailRe = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return emailRe.test(email);
}

export function validateCity(value) {
  return /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/.test(value);
}

export function isStringOnlyAlphabet(str) {
  return str !== null && str !== '' && str.match('^[a-zA-Z]*$');
}

export function isOnlyNumber(p) {
  return p !== null && p !== '' && p.match('^[0-9]*$');
}

export default function convert(str) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join('/');
}
