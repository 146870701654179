import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  getInvoiceDetailSaga,
  setInvoiceDetail,
} from '../../actions/invoiceAction';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import InvoiceDetailsView from './InvoiceDetailsView';
import CustomSearchField from '../../components/Shared/customSearchTextField';

import './Styles.css';
import circularProgressBar from '../../components/Shared/circularProgressBar';

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _x: this.props.location.state,
      searchText: '',
      selectedSort: 'new asc',
      resultPerPage: 20,
      clearAll: false,
      currentPage: 0,
      adjustments: {
        term: '*',
        sortBy: 'new asc',
        page: 0,
        recordsPerPage: 20,
        heirarchical: [],
        multiselect: [],
        singleselect: [],
        range: [],
      },
      isMobileFilterOpen: false,
      selectedProduct: null,
      searchError: false,
    };
  }

  componentDidMount() {
    const { invoiceId } = this.props.location.state;

    this.props.getInvoiceDetailSaga(invoiceId);

    console.log('Details ====>', invoiceId, this.props);
  }

  gotoInvoice = () => {
    this.props.history.push('invoice');
  };

  render() {
    const { invoiceId } = this.props.location.state;
    const { invoiceDetails } = this.props;
    console.log('Details ====>', invoiceId, invoiceDetails.data);
    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <div className='search-field-invoice'>
          <Button
            variant='contained'
            className='view-details-btn paymentStatus'
            onClick={this.gotoInvoice}>
            Go to Invoice
          </Button>
        </div>
        <div style={{ height: '50px' }}></div>

        {invoiceDetails.data ? (
          <InvoiceDetailsView data={invoiceDetails.data}>
            {' '}
          </InvoiceDetailsView>
        ) : (
          <circularProgressBar />
        )}
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  invoiceDetails: state.invoiceListReducer.invoiceDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetailSaga: (id) => dispatch(getInvoiceDetailSaga(id)),
  setInvoiceDetail: (i) => dispatch(setInvoiceDetail(i)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetails);
