import { SET_FEATURED_CATEGORIES } from '../constants';

const initialState = { featured_categories: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_FEATURED_CATEGORIES:
      return {
        ...state,
        featured_categories: action.featured_categories,
      };
    default:
      return state;
  }
}
