import { SET_PROMOTION } from '../constants';

const initialState = { promotion: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_PROMOTION:
      return {
        ...state,
        promotion: action.promotion
      };
    default:
      return state;
  }
}
