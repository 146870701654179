import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { organizationHeader } from './organizationApi';

export function addContactDetails(data) {
  const addContactURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/contacts`;
  let options = organizationHeader();
  return axios.post(addContactURL, data, options);
}

export const updateContactList = (data) => {
  const contactData = {
    profile_user: data,
  };
  const updateContactURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/contacts/${data.profileId}`;
  let options = organizationHeader();
  return axios.put(updateContactURL, contactData, options);
};

//  search contact api
export const getSearchContactList = (data) => {
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  let headerContent = organizationHeader();
  const getSearchContactURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profiles?profileId=${context.profileId}&query=${data.query}&page=${data.page}&recordsPerPage=${data.recordsPerPage}`;
  return axios.get(getSearchContactURL, headerContent);
};
