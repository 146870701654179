import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { getProductIdFromURL } from '../utils/common';
import { headerData } from './headerData';

export const getProducts = (id) => {
  const productId = getProductIdFromURL();

  const productApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/products/${
    id ? id : productId
  }?includeErelatedProducts=true`;

  let options = headerData();
  return axios.get(productApiUrl, options);
};

export const getProductListingData = (searchTerm, adjustments) => {
  const options = headerData();
  if (searchTerm !== null && searchTerm !== '') {
    let searchGetURL = `${BASE_URL}/oe_commerce_api/solr/${VERSION}/search?`;
    if (searchTerm.includes('term')) {
      const newTerm = decodeURI(searchTerm);
      searchGetURL = `${searchGetURL}query=${encodeURI(newTerm)}`;
    } else {
      const searchQuery = {
        term: searchTerm.indexOf('%') !== -1 ? decodeURI(searchTerm) : searchTerm,
      };
      searchGetURL = `${searchGetURL}query=${encodeURI(
        JSON.stringify(searchQuery)
      )}`;
    }
    return axios.get(searchGetURL, options);
  } else {
    if (adjustments.term.includes('term')) {
      // check apply when hit the dynamic action url
      const newTerm = decodeURI(adjustments.term);
      const searchByTerm = JSON.parse(
        newTerm.replace('&category=true', '')
      );
      adjustments.term = searchByTerm.term;      
    }
    adjustments.term = adjustments.term.indexOf('%') !== -1 ? decodeURI(adjustments.term) : adjustments.term;
    const searchPOSTURL = `${BASE_URL}/oe_commerce_api/solr/${VERSION}/search`;
    return axios.post(searchPOSTURL, adjustments, options);
  }
};

// typeAhead for quick order result api
export const getQuickOrderProductWithTypeAhead = (searchTerm) => {
  const options = headerData();
  const searchGetURL = `${BASE_URL}/oe_commerce_api/solr/${VERSION}/search/typeahead/productdetails?term=${encodeURI(
    JSON.stringify(searchTerm)
  )}`;

  return axios.get(searchGetURL, options);
};

// typeAhead for product list
export const getProductListingDataWithTypeAhead = (searchTerm) => {
  const options = headerData();
  const searchGetURL = `${BASE_URL}/oe_commerce_api/solr/${VERSION}/search/typeahead?term=${encodeURI(
    JSON.stringify(searchTerm)
  )}`;

  return axios.get(searchGetURL, options);
};

export const getProductCategoryData = (adjustments) => {
  const options = headerData();
  const searchPOSTURL = `${BASE_URL}/oe_commerce_api/solr/${VERSION}/search?facetOnly=true`;
  return axios.post(searchPOSTURL, adjustments, options);
};
