import {
  SET_PROFILE,
  GET_PROFILE_SAGA,
  SET_UPDATE_PROFILE,
  GET_UPDATE_PROFILE_SAGA,
  SET_UPDATE_PASSWORD,
  GET_UPDATE_PASSWORD_SAGA,
} from '../constants';

// Added for profile
export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile,
  };
}

// Sagas
export function getProfileSaga() {
  return {
    type: GET_PROFILE_SAGA,
  };
}

// Added for Update Profile
export function setUpdateProfile(updatedProfile) {
  return {
    type: SET_UPDATE_PROFILE,
    payload: {
      updatedProfile,
    },
  };
}

//  get Sagas
export const getUpdateProfileSaga = (props) => {
  const firstName = props.fName;
  const lastName = props.lName;
  return {
    type: GET_UPDATE_PROFILE_SAGA,
    payload: {
      firstName,
      lastName,
    },
  };
};

// Added for Update Password
export function setUpdatePassword(updatedPassword) {
  return {
    type: SET_UPDATE_PASSWORD,
    payload: {
      updatedPassword,
    },
  };
}

//Sagas
export const getUpdatePasswordSaga = (props) => {
  const oldPassword = props.oldPassword;
  const newPassword = props.newPassword;
  const newConfirmPassword = props.newConfirmPassword;
  return {
    type: GET_UPDATE_PASSWORD_SAGA,
    payload: {
      oldPassword,
      newPassword,
      newConfirmPassword,
    },
  };
};
