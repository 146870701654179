import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

const circularProgressBar = () => (
  <div className='circular-progress-bar'>
    <CircularProgress color='secondary' />
  </div>
);

export default circularProgressBar;
