import {
  SET_INVOICE_LIST,
  GET_INVOICE_LIST,
  GET_INVOICE_DETAILS,
  SET_INVOICE_DETAILS,
} from '../constants';

export function setInvoice(data) {
  return {
    type: SET_INVOICE_LIST,
    data,
  };
}

// Sagas
export function getInvoiceAction(data, searchTerm) {
  return {
    type: GET_INVOICE_LIST,
    payload: { data, searchTerm },
  };
}

// Sagas invoice Details
export function getInvoiceDetailSaga(invoiceId) {
  return {
    type: GET_INVOICE_DETAILS,
    payload: { invoiceId },
  };
}

export const setInvoiceDetail = (data) => ({
  type: SET_INVOICE_DETAILS,
  data,
});
