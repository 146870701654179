import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_MULTI_INVENTORY_VALIDATION_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setMultiInventoryValidation } from '../../actions/quickOrderAction';
import { getMultiInventoryValidation } from '../../api/quickOrderApi';

function* workerGetMultiInventoryValidationSaga({
  payload: { body },
}) {
  try {
    const response = yield call(getMultiInventoryValidation, body);
    if (response.status === 200) {
      const data = response.data;
      yield put(setMultiInventoryValidation(data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetMultiInventoryValidationSaga() {
  yield takeLatest(
    GET_MULTI_INVENTORY_VALIDATION_SAGA,
    workerGetMultiInventoryValidationSaga
  );
}
