import { put, takeLatest, call } from 'redux-saga/effects';

import { UPDATE_ITEM_QUANTITY } from '../../constants';
import {
  setCartData,
  updateItemQuantity,
} from '../../actions/cartItemAction';
import { getOrdersSaga } from '../../actions/orderAction';
import { getMiniCartItems } from '../../api/cartApi';

function* workerUpdateItemQuantity({
  payload: { order, skuId, quantity, action },
}) {
  order.EOrderItems.forEach((item) => {
    if (item.sku === skuId) {
      if (action === 'increment') {
        if (item.quantity === '') {
          item.quantity = 0;
        }
        item.quantity = parseInt(item.quantity);
        item.quantity += 1;

        if (item.quantity > 999) {
          item.quantity = 999;
        }
      } else if (action === 'decrement') {
        item.quantity = parseInt(item.quantity);
        item.quantity -= 1;

        if (item.quantity < 1) {
          item.quantity = 1;
        }
      } else {
        item.quantity = quantity;
      }
    }
  });

  yield put(setCartData(order));
}

export default function* watchUpdateItemQuantitySaga() {
  yield takeLatest(UPDATE_ITEM_QUANTITY, workerUpdateItemQuantity);
}
