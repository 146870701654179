import { put, takeLatest, call, select } from 'redux-saga/effects';

import { UPDATE_CONTACT, SET_ERROR_STATE } from '../../constants';
import { setContactList } from '../../actions/organizationAction';
import {
  updateContactList,
  getSearchContactList,
} from '../../api/contactApi';

function* workerUpdateContactDataSaga(contactData) {
  try {
    const contact_response = yield call(
      updateContactList,
      contactData.data
    );
    if (contact_response) {
      //  get the current pagination data
      const paginationData = yield select(
        (state) => state.organizationDetailsReducer.pagination_data
      );
      const contact_list = yield call(
        getSearchContactList,
        paginationData
      );
      yield put(setContactList(contact_list.data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchUpdateContactDataSaga() {
  yield takeLatest(UPDATE_CONTACT, workerUpdateContactDataSaga);
}
