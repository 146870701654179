import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';
import ContentLoader from 'react-content-loader';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import ContactForm from './ContactFormMobile';
import CustomAutoCompleteSearchTextField from '../Shared/customSearchTextField';
import Pagination from '../Shared/customPagination';

export default function CompanyContactListMobile({
  contacts,
  handleSearch,
  searchInput,
  searchError,
  isUserAdmin,
  handleContact,
  state,
  cancelContact,
  handleContactChange,
  submitContact,
  handleEditContact,
  refProp,
  handleSubmit,
  handleEnterSearch,
  currentPage,
  resultPerPage,
  resultPerPageChangeHandler,
  totalContactResult,
  roleList,
  message,
  clearSearchResult,
}) {
  return (
    <Grid>
      <div className='contact-container'>
        <div id='contact-text-mobile'>Contact List</div>
        {isUserAdmin ? (
          <div id='addContact' onClick={handleContact}>
            <div style={{ marginLeft: 5 }}>
              <AddIcon />
            </div>
          </div>
        ) : null}
      </div>
      <Grid>
        <Grid item xs={11} className='search-container'>
          {/* <SearchBox
            classname='search'
            placeholder='Search Name'
            handleChange={handleSearch}
            value={searchInput}
            handleSubmit={handleSubmit}
            handleEnterSearch={handleEnterSearch}
          /> */}
          <CustomAutoCompleteSearchTextField
            placeholder='Search by name/email'
            searchTextChangeHandler={handleSearch}
            searchEnterClickHandler={handleEnterSearch}
            searchIconClickHandler={handleSubmit}
            searchText={searchInput}
            searchError={searchError}
          />
          <Grid item xs={1} className='contactSearch'>
            <Button
              className='marginL'
              onClick={clearSearchResult}
              style={{
                padding: '0px',
                display:
                  searchInput && searchInput.length > 2
                    ? 'block'
                    : 'none',
              }}>
              {' '}
              <a> Clear</a>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {state.isContact ? (
        <div className='add-new-contact-container' ref={refProp}>
          <ContactForm
            state={state}
            cancelContact={cancelContact}
            handleContactChange={handleContactChange}
            submitContact={submitContact}
            roleList={roleList}
          />
        </div>
      ) : null}
      {message ? (
        <Grid item xs={12} sm={12}>
          {' '}
          {message}
        </Grid>
      ) : (
        <Grid className='externalContainer' item xs={12} sm={12}>
          {contacts && contacts.length > 0 ? (
            contacts.map((row, i) => (
              <div className='border-set' key={i}>
                <div>
                  {isUserAdmin && (
                    <div onClick={handleEditContact.bind(this, row)}>
                      <i style={{ float: 'right', padding: 5 }}>
                        <Edit />
                      </i>
                    </div>
                  )}
                  <Grid item xs={12} sm={12}>
                    Name:
                    <br />
                    <p>
                      {row.firstName} {row.lastName}
                    </p>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={12}>
                    Email: <br />
                    <p>{row.email}</p>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={12}>
                    Role: <br />
                    <p>
                      {row.contactTypeRole.map((role, j) => (
                        <span key={j}> {role.roleName} </span>
                      ))}
                    </p>
                  </Grid>
                  <br />
                </div>
              </div>
            ))
          ) : (
            <ContentLoader />
          )}
        </Grid>
      )}
      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
          padding: 10,
        }}>
        <Pagination
          currentPage={currentPage}
          recordPerPage={resultPerPage}
          totalRecords={totalContactResult}
          handlePageClick={resultPerPageChangeHandler}
        />
      </div>
    </Grid>
  );
}
