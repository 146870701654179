import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { CustomTextField } from '../Shared/customTextField';
import CustomCheckbox from '../Shared/customCheckbox';
import CustomSelect from '../Shared/customizedSelect';
import CustomSelectCountryAndState from '../Shared/customizedSelectCountry';
import CustomError from '../Shared/customErrorText';
import Loader from '../Shared/circularProgressBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginLeft: 10,
    marginBottom: 10,
    width: 180,
    height: 41,
  },
}));

export default function AddressForm({
  isLoading,
  disableButton,
  handleChange,
  stateList,
  countryList,
  addressTypeList,
  handleAddressSubmit,
  defaultBillingClickHandler,
  handleCancel,
  showNumberError,
  zipCodeError,
  formData,
  editAddress,
}) {
  const classes = useStyles();
  return (
    <form
      className={classes.root}
      style={{
        width: '98%',
      }}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTextField
            label='Address Nickname:'
            name='nickName'
            maxLength={20}
            required={true}
            type='text'
            onchange={handleChange}
            value={formData.nickName}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='required'>
          <CustomTextField
            label='Company Name:'
            name='companyName'
            type='text'
            disabled={true}
            onchange={handleChange}
            value={formData.companyName}
          />
          <CustomTextField
            label='Address Line 1:'
            name='address1'
            type='text'
            maxLength={100}
            required={true}
            onchange={handleChange}
            value={formData.address1}
          />
          {formData.errorAddress1 && (
            <CustomError
              masg='Please enter the address'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTextField
            id='address2'
            label='Address Line 2:'
            name='address2'
            maxLength={100}
            type='text'
            onchange={handleChange}
            value={formData.address2}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='required'>
          <div
            className={classes.root}
            style={{ justifyContent: 'space-between' }}>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <CustomTextField
                label='City:'
                name='city'
                maxLength={50}
                type='text'
                required={true}
                onchange={handleChange}
                value={formData.city}
              />
              {formData.errorCity && (
                <CustomError
                  masg='Please enter the correct city name'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className='addressSelect' style={{ marginTop: 7 }}>
                <InputLabel shrink htmlFor='bootstrap-input'>
                  State:
                </InputLabel>
                <CustomSelectCountryAndState
                  name='state'
                  variants={stateList}
                  selectedVariant={formData.state}
                  variantChange={handleChange}
                  defaultValue='Select any'
                />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <CustomTextField
                label='Zip Code:'
                name='postalCode'
                maxLength={5}
                type='text'
                required={true}
                value={formData.postalCode}
                onchange={handleChange}
              />
              {zipCodeError && (
                <CustomError
                  masg='Zip code must be number and contain 5 digits'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className='addressSelect' style={{ marginTop: 7 }}>
                <InputLabel shrink htmlFor='bootstrap-input'>
                  Country:
                </InputLabel>
                <CustomSelectCountryAndState
                  name='country'
                  variants={countryList}
                  selectedVariant={formData.country}
                  variantChange={handleChange}
                  defaultValue='Select any'
                />
              </div>
            </Grid>
          </div>
          <div
            className={classes.root}
            style={{ justifyContent: 'space-between' }}>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <CustomTextField
                label='Phone No. :'
                required={true}
                name='phoneNumber'
                maxLength={10}
                value={formData.phoneNumber}
                onchange={handleChange}
              />
              {showNumberError && (
                <CustomError
                  masg='Phone number must contain 10 digits'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )}
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className='addressSelect' style={{ marginTop: 7 }}>
                <InputLabel shrink htmlFor='bootstrap-input'>
                  Address Type:
                </InputLabel>
                <CustomSelect
                  name='addressType'
                  variants={addressTypeList}
                  selectedVariant={formData.addressType}
                  variantChange={handleChange}
                  defaultValue='Select any'
                />
              </div>
            </Grid>
          </div>

          <div className={classes.root} style={{ marginTop: 10 }}>
            {formData.addressType === 'shipping' ||
            formData.addressType === 'both' ? (
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  <CustomCheckbox
                    name='isDefaultShippingAddress'
                    filterType='singleSelect'
                    defaultCheck={formData.isDefaultShippingAddress}
                    value={formData.isDefaultShippingAddress}
                    onChange={defaultBillingClickHandler}
                  />
                  <span
                    className='filter-option-label'
                    title='Default Shipping Address'>
                    Default Shipping Address
                  </span>
                </div>
              </Grid>
            ) : null}
            {formData.addressType === 'billing' ||
            formData.addressType === 'both' ? (
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  <CustomCheckbox
                    name='isDefaultBillingAddress'
                    defaultCheck={formData.isDefaultBillingAddress}
                    filterType='singleSelect'
                    value={formData.isDefaultBillingAddress}
                    onChange={defaultBillingClickHandler}
                  />
                  <span
                    className='filter-option-label'
                    title='Default Shipping Address'>
                    Default Billing Address
                  </span>
                </div>
              </Grid>
            ) : null}
          </div>
          <div className={classes.root} style={{ marginTop: 10 }}>
            <Button
              variant='contained'
              color='secondary'
              disabled={disableButton}
              onClick={handleAddressSubmit}
              className={classes.button}
              style={{
                backgroundColor: '#e41e26',
                color: '#ffffff',
              }}>
              {editAddress ? 'Save' : 'Add Address'}
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              onClick={handleCancel}
              className={classes.button}
              style={{
                border: '1px solid #e41e26',
                color: '#e41e26',
              }}>
              Cancel
            </Button>
            &nbsp;
            {/* <Grid item lg={2} md={2} sm={2} xs={12}> */}
            {isLoading ? <Loader /> : null}
            {/* </Grid> */}
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
