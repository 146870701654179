import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_FEATURED_CATEGORIES_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setFeaturedCategories } from '../../actions';
import { getFeaturedCategories } from '../../api/api';

function* workerGetFeaturedCategoriesSaga() {
  try {
    const featured_categories = yield call(getFeaturedCategories);
    yield put(setFeaturedCategories(featured_categories.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetFeaturedCategoriesSaga() {
  yield takeLatest(
    GET_FEATURED_CATEGORIES_SAGA,
    workerGetFeaturedCategoriesSaga
  );
}
