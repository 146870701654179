import React from 'react';
import PropTypes from 'prop-types';

const quantityStepper = ({
  selectedQuantity,
  quantityChangeHandler,
  stepperClickHandler,
}) => (
  <div className='quantity-stepper-box'>
    <span className='product-quantity'>Quantity</span>
    <div className='quantity-stepper'>
      <input
        type='button'
        value='-'
        className='button-minus'
        data-field='quantity'
        onClick={(e) => stepperClickHandler(e, 'decrease')}
      />
      <input
        type='text'
        value={selectedQuantity}
        name='quantity'
        className='quantity-field'
        maxLength='3'
        onChange={quantityChangeHandler}
      />
      <input
        type='button'
        value='+'
        className='button-plus'
        data-field='quantity'
        onClick={(e) => stepperClickHandler(e, 'increase')}
      />
    </div>
  </div>
);

quantityStepper.propTypes = {
  selectedQuantity: PropTypes.number.isRequired,
  quantityChangeHandler: PropTypes.func.isRequired,
  stepperClickHandler: PropTypes.func.isRequired,
};

export default quantityStepper;
