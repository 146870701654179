import React, { Component } from 'react';
import { Grid, Divider, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import ContentLoader from 'react-content-loader';
import PageLayout from '../../HOC/PageLayoutHOC';
import CustomizedExpansionPanel from '../../components/Shared/customizedExpansionPanel';
import Address from '../../components/ShippingBilling';
import PaymentOptions from '../../components/PaymentOptions';
import ShippingMethods from '../../components/ShippingMethods';
import OrderSummary from '../../components/OrderSummary';
import OrdersList from '../../components/OrdersList';
import { submitOrder } from '../../actions/orderAction';
import { updateItemQuantity } from '../../actions/cartItemAction';
import { updateSelectedAddress } from '../../actions/addressAction';
import {
  getOrganizationDetailsSaga,
  resetOrganization,
} from '../../actions/organizationAction';
import { getMultiInventoryValidationSaga } from '../../actions/quickOrderAction';
import './Styles.css';
import {
  Redirect
} from 'react-router-dom';
import FormDialog from './FormDialog'

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
    this.updateSelectedAddress = this.updateSelectedAddress.bind(
      this
    );
    this.state = {
      isLoading: false,
      selectedAddress: {
        shipping: {},
        billing: {},
      },
      purchaseOrderNumber: '',
      addressList: {},
      isValidationRequire: true,
      showLoader: true,
      selectedInvoiceTerm: '30',
      poOrderSet: false,
      isUserAdmin: false,
    };
  }

  componentDidMount() {
    if (this.props.organization) {
      this.setDefaultAddress();
    }
    this.props.getOrganizationDetailsSaga();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.contactTypeRole) {
      user.contactTypeRole.map((data) => {
        if (data.roleId === 'admin') {
          this.setState({ isUserAdmin: true });
        }
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cart && state.showLoader) {
      return { showLoader: false };
    }
    return {
      ...state,
    };
  }

  componentDidUpdate(prevProps) {
    this.toastManager = prevProps.toastManager;
    if (
      JSON.stringify(this.props.organization) !==
        JSON.stringify(prevProps.organization) &&
      this.props.organization &&
      this.props.organization.account.secondaryAddresses.length > 0
    ) {
      this.setDefaultAddress();
    }
    const {
      cart,
      inventoryData,
      getMultiInventoryValidationSaga,
    } = this.props;
    if (cart && this.state.isValidationRequire) {
      let items = cart.EOrderItems.map((product) => {
        return {
          skuId: product.sku,
          productId: product.product,
          quantity: product.quantity,
        };
      });
      const reqBody = {
        items: items,
      };
      this.setState({ isValidationRequire: false });

      getMultiInventoryValidationSaga(reqBody);
    }
    if (inventoryData) {
      if (!inventoryData.success) {
        inventoryData.items.map((product) => {
          let element = document.getElementById(product.skuId);
          if (element) element.classList.add('highLightElement');
        });
      }
    }

    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: true,
      });
      this.setState({
        isLoading: false,
        disableButton: false,
        editAddress: false,
      });
    }
  }

  setDefaultAddress = () => {
    this.separateAddresses(
      this.props.organization.account.secondaryAddresses
    );
    let selectedAddress = { ...this.state.selectedAddress };
    let secondaryAddress = this.props.organization.account
      .secondaryAddresses;
    let shippingAddress = this.props.organization.account
      .shippingAddress;
    let billingAddress = this.props.organization.account
      .billingAddress;
    let { cart } = this.props;

    if (billingAddress && cart) {
      secondaryAddress.filter((addrs) => {
        if (addrs.repositoryId === billingAddress.repositoryId) {
          selectedAddress.billing = addrs;
        }
      });
      cart.selectedbillingaddress = selectedAddress.billing;
      // this.props.updateSelectedAddress(
      //   this.props.cart,
      //   selectedAddress.billing,
      //   selectedAddress.billing.addressType
      // );
    }

    if (shippingAddress && cart) {
      secondaryAddress.filter((addrs) => {
        if (addrs.repositoryId === shippingAddress.repositoryId) {
          selectedAddress.shipping = addrs;
        }
      });
      cart.selectedshippingaddress = selectedAddress.shipping;
      // this.props.updateSelectedAddress(
      //   this.props.cart,
      //   selectedAddress.shipping,
      //   selectedAddress.shipping.addressType
      // );
    }
    this.setState({ selectedAddress });
  };

  componentWillUnmount() {
    this.props.resetOrganization();
  }

  separateAddresses = (addresses) => {
    let bAddress = [];
    let sAddress = [];
    let addressList = { ...this.state.addressList };
    addresses.forEach((address) => {
      if (address.addressType === 'both') {
        bAddress.push(address);
        sAddress.push(address);
      } else if (address.addressType === 'billing') {
        bAddress.push(address);
      } else if (address.addressType === 'shipping') {
        sAddress.push(address);
      }
      return address;
    });
    addressList = {
      billing: bAddress,
      shipping: sAddress,
    };
    this.setState({ addressList: addressList });
  };

  handlePlaceOrder() {
    const { selectedAddress } = this.state;
    if (
      selectedAddress.billing.address1 &&
      selectedAddress.shipping.address1
    ) {
      this.setState({ isLoading: true, showLoader: true });
      const { cart, submitOrderState } = this.props;
      let user = localStorage.getItem('user');
      let selectedInvoiceTerm = this.state.poOrderSet
        ? this.state.selectedInvoiceTerm
        : null;
      user = JSON.parse(user);
      if (
        cart.selectedshippingaddress &&
        cart.selectedbillingaddress
      ) {
        cart.selectedshippingaddress.firstName = user.firstName;
        cart.selectedshippingaddress.lastName = user.lastName;
        cart.selectedshippingaddress.email = user.email;
        cart.selectedbillingaddress.firstName = user.firstName;
        cart.selectedbillingaddress.lastName = user.lastName;
        cart.selectedbillingaddress.email = user.email;
      }
      const body = {
        orderId: cart.orderNumber,
        payments: {
          amount: cart.finalOrderAmount,
          PONumber: this.state.purchaseOrderNumber,
          type: 'invoice',
          creditTerms: selectedInvoiceTerm,
        },
        shippingMethod: {
          shippingTax: 0.0,
          value: 'hardgoodShippingGroup',
          cost: 0.0,
        },
        shippingAddress: cart.selectedshippingaddress,
        billingAddress: cart.selectedbillingaddress,
      };
      this.props.submitOrder(body);
      if (submitOrderState === true) {
        this.toastManager.add('Something went wrong', {
          appearance: 'warning',
          autoDismiss: true,
        });
        this.setState({ isLoading: false, showLoader: false });
      }
    } else {
      this.toastManager.add('Please select an address', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  }

  updateSelectedAddress(e) {
    let secondaryAddresses = this.props.organization.account
      .secondaryAddresses;

    const { cart } = this.props;
    const { selectedAddress } = this.state;
    if (cart) {
      if (e.target.name === 'shipping') {
        secondaryAddresses.filter((addrs) => {
          if (addrs.repositoryId === e.target.value) {
            selectedAddress.shipping = addrs;
          }
        });
        cart.selectedshippingaddress = selectedAddress.shipping;
        this.props.updateSelectedAddress(
          this.props.cart,
          selectedAddress.shipping,
          selectedAddress.shipping.addressType
        );
      } else {
        secondaryAddresses.filter((addrs) => {
          if (addrs.repositoryId === e.target.value) {
            selectedAddress.billing = addrs;
          }
        });
        cart.selectedbillingaddress = selectedAddress.billing;
        this.props.updateSelectedAddress(
          this.props.cart,
          selectedAddress.billing,
          selectedAddress.billing.addressType
        );
      }
      this.setState({ selectedAddress });
    }
  }

  updateItemQuantityCart = (skuId, quantity, action) => {
    const { cart } = this.props;
    this.props.updateItemQuantity(cart, skuId, quantity, action);
  };

  purchaseOrderNumberChangeHandler = (event) => {
    this.setState({
      poOrderSet: true,
    });
    const regEX = /^[A-Za-z0-9]+$/;
    if (event.target.value === '') {
      this.setState({
        purchaseOrderNumber: event.target.value,
        poOrderSet: false,
      });
    }
    if (regEX.test(event.target.value))
      this.setState({
        purchaseOrderNumber: event.target.value,
      });
  };
  handleSelect = (event) => {
    this.setState({
      selectedInvoiceTerm: event.target.value,
    });
  };

  render() {
    const { organization, cart } = this.props;
    const secondaryAddresses =
      organization && organization.account.secondaryAddresses;
    const billingAddress =
      (organization && organization.account.billingAddress) || {};
    const shippingAddress =
      (organization && organization.account.shippingAddress) || {};
    const { purchaseOrderNumber } = this.state;

      if (this.state.gotoHome) {
        return <Redirect to = '/' / > ;
      }
    return (
      <PageLayout {...this.props}>
        <div className='checkout-wrapper'>
          <div className='ordersTopRow'>
            <Grid container direction='row' justify='space-between'>
              <Grid item>
                <div className='page-title'> Checkout </div>
              </Grid>
              <Grid item />
            </Grid>
          </div>
          {!this.state.isLoading ? (
            <Grid
              container
              className='externalContainer checkoutContainer'>
              <Grid container direction='row'>
                <Grid
                  item
                  xl={9}
                  lg={9}
                  md={9}
                  sm={12}
                  xs={12}
                  className='paddedContent'>
                  <h2 className='orderHeading'> Your Order </h2>{' '}
                  <Divider />
                </Grid>
                <Grid item lg={3} md={3} xl={3} />
              </Grid>
              <Grid container direction='row'>
                <Grid
                  item
                  xl={9}
                  lg={9}
                  md={9}
                  sm={12}
                  xs={12}
                  className='paddedContent'>
                  <OrdersList
                    handleUpdate={() => {}}
                    handleRemove={() => {}}
                    order={cart}
                    displayQuantityIncr={false}
                    displayRemoveBtn={false}
                    updateItemQuantity={this.updateItemQuantityCart}
                    showLoader={this.state.showLoader}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                  <OrderSummary
                    summary={cart}
                    placeOrder={this.handlePlaceOrder}
                    displayAcceptTerms
                  />
                </Grid>
                 <div className='continueShopping'>
                  <FormDialog/>
                  </div>
              </Grid>
             
              <Grid container direction='row'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomizedExpansionPanel
                    name='Shipping & Billing'
                    contentType='component'
                    data={
                      <Address
                        handleSelectedAddress={
                          this.updateSelectedAddress
                        }
                        // shippingAddressList={shippingAddressList}
                        // billingAddressList={billingAddressList}
                        selectedAddress={this.state.selectedAddress}
                        addresses={this.state.addressList}
                        billingAddress={billingAddress}
                        shippingAddress={shippingAddress}
                        organization={organization}
                        isUserAdmin={this.state.isUserAdmin}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomizedExpansionPanel
                    name='Payment Options'
                    contentType='component'
                    data={
                      <PaymentOptions
                        selectedInvoiceTerm={
                          this.state.selectedInvoiceTerm
                        }
                        handleSelect={this.handleSelect}
                        poOrderSet={this.state.poOrderSet}
                        purchaseOrderNumber={
                          this.state.purchaseOrderNumber
                        }
                        purchaseOrderNumberChangeHandler={
                          this.purchaseOrderNumberChangeHandler
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomizedExpansionPanel
                    name='Shipping Methods'
                    contentType='component'
                    data={<ShippingMethods />}
                  />
                </Grid>
              </Grid>
              {/* <Grid container direction='row'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className='po-container'>
                    <h3> Purchase Order </h3>{' '}
                    <TextField
                      type='text'
                      inputRef={(po) => (this.po = po)}
                      variant='outlined'
                      inputProps={{
                        maxLength: 10,
                        placeholder: 'Purchase Order Number',
                        value: purchaseOrderNumber,
                        id: 'padding',
                        onChange: this
                          .purchaseOrderNumberChangeHandler,
                      }}
                    />
                  </div>
                </Grid>
              </Grid> */}
            </Grid>
          ) : (
            <ContentLoader />
          )}
        </div>
      </PageLayout>
    );
  }
}
Checkout.propTypes = {
  // cart: PropTypes.object.isRequired,
  // organization: PropTypes.object.isRequired,
  getOrganizationDetailsSaga: PropTypes.func.isRequired,
  toastManager: PropTypes.object.isRequired,
  submitOrder: PropTypes.func.isRequired,
  submitOrderState: PropTypes.bool.isRequired,
  updateItemQuantity: PropTypes.func.isRequired,
  updateSelectedAddress: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  inventoryData: state.quickOrderReducer.inventoryData,
  cart: state.orderReducer.cart,
  organization: state.organizationDetailsReducer.organization_details,
  orders: state.orderReducer.orders,
  submitOrderState: state.orderReducer.submitOrderState,
  errorResponse: [state.errorReducer.error_data],
});

const mapDispatchToProps = (dispatch) => ({
  getMultiInventoryValidationSaga: (products) =>
    dispatch(getMultiInventoryValidationSaga(products)),
  getOrganizationDetailsSaga: () =>
    dispatch(getOrganizationDetailsSaga()),
  updateItemQuantity: (order, skuId, quantity, action) =>
    dispatch(updateItemQuantity(order, skuId, quantity, action)),
  updateSelectedAddress: (order, address, addressType) =>
    dispatch(updateSelectedAddress(order, address, addressType)),
  submitOrder: (body) => dispatch(submitOrder(body)),
  resetOrganization: () => dispatch(resetOrganization()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Checkout));
