import React from 'react';
import { Link } from 'react-router-dom';
import pageNotFound from '../../assets/PageNotFound/PageNotfound.png';

const NotFound = () => (
  <div className='externalContainer contactPadding'>
    <Link to='/'>
      <img className='pageImages' src={pageNotFound} />
    </Link>
    {/* <center>
      <p>
        404 page not found. It seems the page you are looking for is
        unavailable.{' '}
      </p>
      <Link to='/'>Back to homepage</Link>
    </center> */}
  </div>
);
export default NotFound;
