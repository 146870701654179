import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRecommendedProductsSaga } from '../../actions';
import 'react-multi-carousel/lib/styles.css';
import Grid from '@material-ui/core/Grid';
import ContentLoader from 'react-content-loader';
import CustomCarousel from '../../components/Shared/customCarousel';
import {
  Route,
  Switch,
  Link,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

class RecommendedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
    };
  }

  componentDidMount() {
    this.props.getRecommendedProductsSaga();
  }
  recentProductClickHandler = (e, productId) => {
    this.setState({
      productId: productId,
    });
  };
  render() {
    let { recommended_products } = this.props;
    recommended_products = recommended_products[0].items;
    if (!recommended_products) return <ContentLoader />;

    let recommendedProducts = [];
    const recmdProducts = recommended_products;
    recmdProducts.map((data, i) => {
      let stubRecomndProducts = {
        id: '',
        name: '',
        title: '',
        imgUrl: '',
        altMsg: '',
        price: '',
      };
      stubRecomndProducts.id = data['product_id'];
      stubRecomndProducts.name = data['displayName'];
      stubRecomndProducts.title = data['sfdcName'];
      stubRecomndProducts.imgUrl = data['img_url'];
      stubRecomndProducts.altMsg = data['fullAltMessage'];
      stubRecomndProducts.price = data['listPrice'];
      recommendedProducts.push(stubRecomndProducts);
    });
    if (this.state.productId) {
      return <Redirect to={`/product/${this.state.productId}`} />;
    }
    return (
      <Grid>
        <CustomCarousel
          headerText='Recently Viewed'
          dataList={recommendedProducts}
          btnLabel='Know More'
          clickHandler={this.recentProductClickHandler}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  recommended_products: [state.productReducer.recommended_products],
});

const mapDispatchToProps = (dispatch) => ({
  getRecommendedProductsSaga: () =>
    dispatch(getRecommendedProductsSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedProducts);
