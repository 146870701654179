import React, { Component } from 'react';

class Header extends Component {
  // const classes = useStyles();
  render() {
    return (
      <div className='externalContainer childComponentSpacing'>
        loading ....
      </div>
    );
  }
}

export default Header;
