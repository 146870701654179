import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';
import { Grid } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Shipping from '@material-ui/icons/LocalShippingOutlined';
import Billing from '@material-ui/icons/AccountBalanceWallet';
import AddressForm from './AddressForm';
import CompanyContactList from './ContactListDesktop';
import CompanyContactListMobile from './ContactListMobile';
import CompanyDetails from './Companydetails';
import CommonAddressComponent from './CommonAddress';
import './Styles.css';
import ContentLoader from 'react-content-loader';
import {
  getOrganizationDetailsSaga,
  clearContactList,
} from '../../actions/organizationAction';
import { getSearchContact } from '../../actions/contactAction';
import CustomError from '../../components/Shared/customErrorText';
import CustomPageLoader from '../Shared/customPageLoader';

class CompanyContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      isUserAdmin: false,
      resultPerPage: 10,
      currentPage: 0,
      searchError: false,
    };
  }

  componentDidMount() {
    let userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    const role = user.contactTypeRole.map((item) => {
      if (item.roleId === 'admin')
        this.setState({ isUserAdmin: true });
    });
    const { searchInput, resultPerPage } = this.state;
    const data = {
      query: searchInput,
      recordsPerPage: resultPerPage,
      page: 0,
    };
    this.props.getSearchContact(data);
  }

  handleChange = (e) => {
    this.setState({ searchInput: e.target.value });
    if (e.target.value.trim().length < 3) {
      this.setState({ searchError: true });
    } else {
      this.setState({ searchError: false });
    }
  };

  handleSubmit = () => {
    const { searchInput, resultPerPage } = this.state;
    if (searchInput.trim().length > 2) {
      const data = {
        query: searchInput,
        recordsPerPage: resultPerPage,
        page: 0,
      };
      this.props.cancelContact();
      this.props.clearContactList();
      this.setState({ currentPage: 0 }, () =>
        this.props.getSearchContact(data)
      );
    }
  };

  resultPerPageChangeHandler = (e) => {
    const { searchInput, resultPerPage } = this.state;
    this.setState({
      currentPage: e.selected,
    });
    this.props.cancelContact();
    this.props.clearContactList();
    const data = {
      query: searchInput,
      recordsPerPage: resultPerPage,
      page: e.selected,
    };
    this.props.getSearchContact(data);
  };

  handleEnterSearch = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };
  clearSearchResult = (e) => {
    if (
      this.state.searchInput !== null &&
      this.state.searchInput !== ''
    ) {
      const { resultPerPage } = this.state;

      const data = {
        query: '',
        recordsPerPage: resultPerPage,
        page: 0,
      };
      this.props.clearContactList();
      this.setState(
        {
          currentPage: 0,
          searchInput: '',
          searchError: false,
        },
        () => this.props.getSearchContact(data)
      );
    }
  };

  render() {
    let {
      searchInput,
      isUserAdmin,
      resultPerPage,
      currentPage,
      searchError,
    } = this.state;

    let {
      addAddress,
      addNewAddress,
      isLoading,
      disableButton,
      accountData,
      addressList,
      handleEditAddress,
      handleDeleteAddress,
      handleChange,
      stateList,
      countryList,
      addressTypeList,
      handleAddressSubmit,
      defaultBillingClickHandler,
      showNumberError,
      zipCodeError,
      handleCancel,
      formData,
      editAddress,
      deleteAddress,
      refProp,
      handleContact,
      state,
      cancelContact,
      handleContactChange,
      submitContact,
      handleEditContact,
      organizationRoles,
    } = this.props;
    let organizationDetails =
      accountData[0] && accountData[0].account;
    let addressData = addressList;
    if (!organizationDetails) return <ContentLoader />;
    const { contacts } = organizationDetails;
    const contactList = this.props.contactList[0]
      ? this.props.contactList[0].contacts
      : contacts;
    const totalContactResult =
      this.props.contactList[0] && this.props.contactList[0].total;
    const { companyName } = organizationDetails;
    const companyAddress = organizationDetails.shippingAddress;
    const { primaryPhone } = organizationDetails;
    let roleList = [];
    if (organizationRoles[0]) {
      organizationRoles[0].roles.map((role) => {
        role.value = role.roleId;
        role.label = role.roleName;
      });
      roleList = organizationRoles[0].roles;
    }
    if (!organizationDetails.sfid) return <ContentLoader />;
    return (
      <div>
        <Grid>
          {isUserAdmin ? (
            <div>
              <Hidden xsDown>
                <CompanyContactList
                  refProp={refProp}
                  contacts={contactList}
                  totalContactResult={totalContactResult}
                  handleSearch={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  handleEnterSearch={this.handleEnterSearch}
                  handleContact={handleContact}
                  searchInput={searchInput}
                  searchError={searchError}
                  isUserAdmin={isUserAdmin}
                  state={state}
                  cancelContact={cancelContact}
                  handleContactChange={handleContactChange}
                  submitContact={submitContact}
                  handleEditContact={handleEditContact}
                  currentPage={currentPage}
                  resultPerPage={resultPerPage}
                  resultPerPageChangeHandler={
                    this.resultPerPageChangeHandler
                  }
                  roleList={roleList}
                  message={
                    this.props.contactList[0] &&
                    this.props.contactList[0].message
                  }
                  clearSearchResult={this.clearSearchResult}
                />
              </Hidden>

              <Hidden smUp>
                <CompanyContactListMobile
                  refProp={refProp}
                  contacts={contactList}
                  totalContactResult={totalContactResult}
                  handleSearch={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  handleEnterSearch={this.handleEnterSearch}
                  handleContact={handleContact}
                  searchInput={searchInput}
                  searchError={searchError}
                  isUserAdmin={isUserAdmin}
                  state={state}
                  cancelContact={cancelContact}
                  handleContactChange={handleContactChange}
                  submitContact={submitContact}
                  handleEditContact={handleEditContact}
                  currentPage={currentPage}
                  resultPerPage={resultPerPage}
                  resultPerPageChangeHandler={
                    this.resultPerPageChangeHandler
                  }
                  roleList={roleList}
                  message={
                    this.props.contactList[0] &&
                    this.props.contactList[0].message
                  }
                  clearSearchResult={this.clearSearchResult}
                />
              </Hidden>
            </div>
          ) : (
            <div />
          )}

          {/* Address */}
          <div className='address-wrapper externalContainer'>
            <div className='address-container'>
              <div id='address'>Addresses</div>
              {isUserAdmin ? (
                <div id='addNewAddress' onClick={addNewAddress}>
                  <div style={{ marginTop: 2 }}>Add new address</div>
                  <div style={{ marginLeft: 5 }}>
                    <AddIcon />
                  </div>
                </div>
              ) : null}
            </div>
            {addAddress ? (
              <div
                className='add-address-container'
                id='addAddress'
                ref={refProp}>
                <AddressForm
                  isLoading={isLoading}
                  disableButton={disableButton}
                  handleChange={handleChange}
                  stateList={stateList}
                  countryList={countryList}
                  addressTypeList={addressTypeList}
                  handleAddressSubmit={handleAddressSubmit}
                  defaultBillingClickHandler={
                    defaultBillingClickHandler
                  }
                  showNumberError={showNumberError}
                  zipCodeError={zipCodeError}
                  handleCancel={handleCancel}
                  formData={formData}
                  editAddress={editAddress}
                />
              </div>
            ) : null}

            <Grid
              className='display-desktop'
              style={{ flexWrap: 'wrap' }}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className='address-head'>
                  <Shipping className='head-address' />
                  <div className='head-address'>Default Shipping</div>
                </div>
                {addressData.shippingAddress ? (
                  <CommonAddressComponent
                    display
                    Address={addressData.shippingAddress}
                  />
                ) : (
                  <div className='address-card'>
                    <CustomError
                      masg='No Default Shipping Address'
                      displayType='block'
                      margin='5px 0 0 0'
                    />
                  </div>
                )}
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className='address-head'>
                  <Billing className='head-address' />
                  <div className='head-address'>Default Billing</div>
                </div>
                {addressData.billingAddress ? (
                  <CommonAddressComponent
                    display
                    Address={addressData.billingAddress}
                  />
                ) : (
                  <div className='address-card'>
                    <CustomError
                      masg='No Default Billing Address'
                      displayType='block'
                      margin='5px 0 0 0'
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            {/* Secondary addresses */}
            <Grid className='display-desktop'>
              <CustomPageLoader loader={deleteAddress} />
              {/* <div id="cover-spin"></div> */}
              {addressData.secondaryAddresses.length > 0 ? (
                addressData.secondaryAddresses.map((address, i) => (
                  <Grid
                    key={i}
                    className='display-address'
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}>
                    {isUserAdmin ? (
                      <div>
                        <div
                          className='addressButtons'
                          onClick={handleEditAddress.bind(
                            this,
                            address
                          )}>
                          <Edit />
                        </div>
                        <div
                          className='addressDeleteButton'
                          onClick={handleDeleteAddress.bind(
                            this,
                            address
                          )}>
                          <Delete />
                        </div>
                      </div>
                    ) : null}
                    <CommonAddressComponent
                      display
                      Address={address}
                    />
                  </Grid>
                ))
              ) : (
                <CustomError
                  masg='No Address'
                  displayType='block'
                  margin='5px 0 0 0'
                />
              )}
            </Grid>
          </div>

          {/* Company Details */}
          <div className='company-wrapper externalContainer'>
            <div id='company-information'>Company Information</div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{ display: 'flex' }}>
                <div className='circle-account'>CN</div>

                <CompanyDetails
                  display
                  organizationDetails={organizationDetails}
                  Address={companyAddress}
                  companyName={companyName}
                  primaryPhone={primaryPhone}
                />
                <div>
                  <i style={{ float: 'right', padding: 5 }} />
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}
CompanyContact.propTypes = {
  organizationRoles: PropTypes.array.isRequired,
  // profile: PropTypes.array.isRequired,
  getOrganizationDetailsSaga: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contactList: [state.organizationDetailsReducer.contact_list],
  organizationRoles: [
    state.organizationDetailsReducer.organization_roles,
  ],
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetailsSaga: () =>
    dispatch(getOrganizationDetailsSaga()),
  getSearchContact: (data) => dispatch(getSearchContact(data)),
  clearContactList: () => dispatch(clearContactList()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyContact);
