import {
  SET_REQUEST_QUOTE,
  REQUEST_QUOTE,
  RESET_REQUEST_QUOTE,
  GET_QUOTE_LIST,
  SET_QUOTE_LIST,
  GET_QUOTE_ID,
  SET_QUOTE_DETAILS,
  RESET_QUOTE_DETAILS,
  SET_REJECT_QUOTE_SUCCESS,
  GET_REJECT_QUOTE,
  SET_QUOTES_ON_PAGINATION,
  GET_QUOTES_ON_PAGINATION_SAGA,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILED,
  SET_QUOTE_STATES,
  SET_QUOTE_ID,
} from '../constants';
import convert from '../utils/common';

export function setQuotesOnPagination(orders_list) {
  return {
    type: SET_QUOTES_ON_PAGINATION,
    payload: { orders_list },
  };
}

export function getQuotesOnPaginationSaga(
  searchInput,
  currentPage,
  recordPerPage,
  ordersUpto,
  startDate,
  endDate,
  orderRange,
  sortProp,
  sortOrder,
  statusData,
  orgData
) {
  let setOrdersUpto = orderRange === true ? '' : ordersUpto;
  let setStartdate = convert(startDate);
  let setEndDate = convert(endDate);

  return {
    type: GET_QUOTES_ON_PAGINATION_SAGA,
    payload: {
      searchInput,
      currentPage,
      recordPerPage,
      ordersUpto,
      setOrdersUpto,
      setStartdate,
      setEndDate,
      orderRange,
      sortProp,
      sortOrder,
      statusData,
      orgData,
    },
  };
}

export function getQuotesSaga() {
  return {
    type: GET_QUOTE_LIST,
  };
}

export const setQuoteListSuccess = (data) => ({
  type: SET_QUOTE_LIST,
  data,
});

export const getQuoteDetails = (data) => ({
  type: GET_QUOTE_ID,
  data,
});

export const setQuoteId = (data) => ({
  type: SET_QUOTE_ID,
  data,
});

export const setQuoteStates = (payload) => ({
  type: SET_QUOTE_STATES,
  payload,
});

export const setQuoteDetails = (data) => ({
  type: SET_QUOTE_DETAILS,
  data,
});

export const resetQuoteDetails = () => ({
  type: RESET_QUOTE_DETAILS,
});

export const setRejectQuoteSuccess = (data) => ({
  type: SET_REJECT_QUOTE_SUCCESS,
  data,
});

export const getRejectQuoteData = (quoteId) => ({
  type: GET_REJECT_QUOTE,
  payload: { quoteId }
});

export const requestQuote = (data) => ({
  type: REQUEST_QUOTE,
  data,
  //payload: { data }
});

export const setRequestQuote = (data) => ({
  type: SET_REQUEST_QUOTE,
  data,
});

export const resetRequestQuote = () => ({
  type: RESET_REQUEST_QUOTE,
});

export const submitOrder = (requestBody) => ({
  type: SUBMIT_ORDER,
  payload: { requestBody },
});

export const submitOrderSuccess = (data) => ({
  type: SUBMIT_ORDER_SUCCESS,
  data,
});

export const submitOrderFailed = (data) => ({
  type: SUBMIT_ORDER_FAILED,
  data,
});
