import { put, takeLatest, call } from 'redux-saga/effects';

import { REQUEST_QUOTE, SET_ERROR_STATE } from '../../constants';
import { setRequestQuote } from '../../actions/quoteAction';
import { addRequestQuote } from '../../api/quoteApi';

function* workerAddRequestQuoteSaga(requestData) {
  const { history } = requestData.data;

  try {
    const quote_response = yield call(
      addRequestQuote,
      requestData.data
    );
    yield put(setRequestQuote(quote_response));
    history.push('/quoteslist');
  } catch (err) {
    //history.push('/quoteslist');
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchAddRequestQuoteSaga() {
  yield takeLatest(REQUEST_QUOTE, workerAddRequestQuoteSaga);
}
