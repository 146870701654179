import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const customPagination = ({
  currentPage,
  recordPerPage,
  totalRecords,
  handlePageClick,
}) => {
  let totalPages = null;
  const records = totalRecords ? parseInt(totalRecords) : 0;
  const perPageRecord = recordPerPage ? parseInt(recordPerPage) : 0;
  if (records > 0 && perPageRecord > 0) {
    if (records % perPageRecord === 0) {
      totalPages = records / perPageRecord;
    } else {
      totalPages = records / perPageRecord;
    }
  }
  return (
    <div className='custom-pagination'>
      {totalPages && (
        <ReactPaginate
          initialPage={typeof currentPage === 'number' ? currentPage : Number(currentPage)}
          previousLabel={<img className='previousImg' />}
          nextLabel={<img className='nextImg' />}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          disableInitialCallback={true}
        />
      )}
    </div>
  );
};

export default customPagination;
