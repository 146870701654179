import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { authorize } from '../actions/authAction';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

const initialState = {
  token: localStorage.getItem('basic_token'),
  error: null,
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case AUTH_SUCCESS: {
      return { ...state, token: action.payload };
    }
    case AUTH_FAILURE: {
      return { ...state, error: action.payload };
    }
    default:
      return state;
  }
}

// const reducer = combineReducers({
//   auth: authReducer,
//   router: routerReducer,
//   authorize,
// });

// export default reducer;

/*
 * const initialState = {
 *   token: localStorage.getItem('basic_token'),
 *   error: null
 * };
 */

/*
 * export default function setBrowserInfo(state = initialState, action) {
 *   switch (action.type) {
 *     case SET_CUSTOMER_SUPPORT:
 *       return {
 *         ...state,
 *         customer_support: action.customer_support
 *       };
 *     default:
 *       return state;
 *   }
 * }
 */
