import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_INVOICE_DETAILS,
  SET_INVOICE_DETAILS,
  SET_ERROR_STATE,
} from '../../constants';
import { setInvoiceDetail } from '../../actions/invoiceAction';
import { setOrderDetailSuccess } from '../../actions/orderAction';
import { getInvoiceDetail } from '../../api/invoiceApi';

function* workerGetInvoiceDetailSaga({ payload: { invoiceId } }) {
  try {
    const response = yield call(getInvoiceDetail, invoiceId);
    //   const data =
    //     response && response.orderList ? response.orderList[0] : null;
    //   if (data) {
    //     yield put(setInvoiceDetail(SET_ORDER_DETAIL_SUCCESS));
    //   }
    yield put(setInvoiceDetail(response));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetinvoiceDetailSaga() {
  yield takeLatest(GET_INVOICE_DETAILS, workerGetInvoiceDetailSaga);
}
