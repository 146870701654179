import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_HOME_CAROUSEL_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setHomeCarousel } from '../../actions';
import { getHomeCarousel } from '../../api/api';

function* workerGetHomecarouselSaga() {
  try {
    const home_carousel = yield call(getHomeCarousel);
    yield put(setHomeCarousel(home_carousel.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetHomeCarouselSaga() {
  yield takeLatest(GET_HOME_CAROUSEL_SAGA, workerGetHomecarouselSaga);
}
