import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CompanyContact from './CompanyContact';

import './Styles.css';
import Profile from './Profile';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AccountParent = ({
  addAddress,
  addNewAddress,
  isLoading,
  disableButton,
  organizationDetails,
  addressList,
  handleEditAddress,
  handleDeleteAddress,
  handleChange,
  stateList,
  countryList,
  addressTypeList,
  handleAddressSubmit,
  defaultBillingClickHandler,
  showNumberError,
  zipCodeError,
  handleCancel,
  formData,
  editAddress,
  deleteAddress,
  refProp,
  handleContact,
  state,
  cancelContact,
  handleContactChange,
  submitContact,
  handleEditContact,
}) => {
  const [value, setValue] = React.useState(0);
  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }
  const token = localStorage.getItem('access_token');

  if (!token) {
    return <Redirect to='/login' />;
  }
  return (
    <div id='accounts'>
      <div className='account-management'>
        <span id='account-management-text'>Account Management</span>
      </div>
      <AppBar position='static' className='tab-padding'>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label='simple tabs example'>
          <Tab
            className='tab-half'
            label='Company Profile'
            {...a11yProps(0)}
          />
          <Tab
            className='tab-half'
            label='Account Settings'
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={{ display: 'flex' }} className='tab-padding'>
          <CompanyContact
            refProp={refProp}
            addAddress={addAddress}
            isLoading={isLoading}
            disableButton={disableButton}
            addNewAddress={addNewAddress}
            accountData={organizationDetails}
            addressList={addressList}
            handleEditAddress={handleEditAddress}
            handleDeleteAddress={handleDeleteAddress}
            handleChange={handleChange}
            stateList={stateList}
            countryList={countryList}
            addressTypeList={addressTypeList}
            handleAddressSubmit={handleAddressSubmit}
            defaultBillingClickHandler={defaultBillingClickHandler}
            showNumberError={showNumberError}
            zipCodeError={zipCodeError}
            handleCancel={handleCancel}
            formData={formData}
            editAddress={editAddress}
            deleteAddress={deleteAddress}
            handleContact={handleContact}
            state={state}
            cancelContact={cancelContact}
            handleContactChange={handleContactChange}
            submitContact={submitContact}
            handleEditContact={handleEditContact}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Profile accountData={organizationDetails} />
      </TabPanel>
    </div>
  );
};

export default AccountParent;
