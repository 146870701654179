import React from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  addressForm: {
    padding: '20px',
  },
}));

const ShippingMethods = () => {
  const classes = useStyles();

  return (
    <Grid container direction='row'>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className={classes.addressForm}>
          <RadioGroup value='standard' row>
            <FormControlLabel
              value='standard'
              control={<Radio color='primary' />}
              label='Standard'
              labelPlacement='end'
            />
          </RadioGroup>
        </div>
      </Grid>
    </Grid>
  );
};

export default ShippingMethods;
