import React, { useEffect, useState } from 'react';
import CustomerDashboard from '../Dashboard';
import AdminDashboard from '../Dashboard-V2';
import { Redirect } from 'react-router-dom';

export default function Dashboard(props) {
  const [isAdmin, setAdmin] = useState(null);

  const token = localStorage.getItem('access_token');

  if (!token) {
    return <Redirect to='/login' />;
  }

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.contactTypeRole) {
        user.contactTypeRole.map((data) => {
          setAdmin(data.roleId === 'admin');
        });
      }
    } catch(err) {
      setAdmin(false);
    }
  }, []);

  if (isAdmin === null) {
    return null;
  }

  // TODO: Implement feature flag here
  return isAdmin ? <AdminDashboard {...props} /> : <CustomerDashboard {...props} />;
}
