import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

// call to Profile api
export function getProfile() {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;

  let options = headerData();
  const getProfileApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profiles/current?profileId=${context.profileId}`;
  return axios.get(getProfileApi, options);
}

// Update Profile
export const getupdateProfile = (firstName, lastName) => {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;
  const profileId = context.profileId;

  const reqBody = {
    profile_user: {
      firstName: firstName,
      lastName: lastName,
    },
  };

  let options = headerData();
  const updateProfileApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profiles/current?profileId=${profileId}`;
  return axios.put(updateProfileApiUrl, reqBody, options);
};

// Update Password
export const getupdatePassword = (
  oldPassword,
  newPassword,
  newConfirmPassword
) => {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;
  const profileId = context.profileId;

  const reqBody = {
    oldPassword: oldPassword,
    newPassword: newPassword,
    newConfirmPassword: newConfirmPassword,
  };

  let options = headerData();
  const updatePasswordApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/resetPassword?profileId=${profileId}`;

  return axios
    .put(updatePasswordApiUrl, reqBody, options)
    .catch((error) => {
      console.log(`error in profile api${error}`);
      return error;
    });
};
