import { SET_ORDERS } from '../constants';

const initialState = { orders: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    default:
      return state;
  }
}
