import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

//call to invoice api
//http://dev-bepsy-api.objectedge.com/oe_commerce_api/occ/v1/invoices
export function getInvoice(data, searchTerm) {
  console.log('search====>', data, searchTerm);
  //const getInvoiceApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices?sortBy=${data}&q=${searchTerm}`;
  let getInvoiceApi;
  if (data === null && searchTerm === null) {
    getInvoiceApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices`;
  } else if (data === null && searchTerm !== null) {
    getInvoiceApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices?q=${searchTerm}`;
  } else if (data !== null && searchTerm === null) {
    getInvoiceApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices?sortBy=${data}`;
  } else {
    getInvoiceApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices?sortBy=${data}&q=${searchTerm}`;
  }

  let headercontent = headerData();
  return axios.get(getInvoiceApi, headercontent);
}

//Api call to  view Invoice Details
export const getInvoiceDetail = async (invoiceId) => {
  const getInvoiceDetailsApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/invoices/${invoiceId}`;
  let options = headerData();
  const response = await fetch(getInvoiceDetailsApi, options);

  return response.json();
};
