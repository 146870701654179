import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

export function organizationHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  let roles = [];
  for (let i = 0; i < user.contactTypeRole.length; i++) {
    roles.push(user.contactTypeRole[i].roleId);
  }
  const bepsyRoles = {
    'Bepsy-UserRoles': roles.join(','),
  };
  const options = headerData();
  options.headers = {
    ...options.headers,
    ...bepsyRoles,
  };
  return options;
}

export function getSubOrganizationDetails(organizationId, profileId) {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;
  const options = organizationHeader();
  let orgId = context.organizationId;
  if (organizationId) {
    options.headers['Bepsy-OrganizationId'] = organizationId;
  }
  
  const getOrganizationApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/${orgId}?profileId=${context.profileId}`;
  return axios.get(getOrganizationApi, options);
}

export function getRoles() {
  const options = organizationHeader();
  const rolesUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/roles`;
  return axios.get(rolesUrl, options);
}
