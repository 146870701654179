import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';

export default function CommonAddressComponent(props) {
  if (props.display) {
    return (
      <div className='address-card'>
        {props.Address.nickName ? (
          <h3>{props.Address.nickName}</h3>
        ) : null}
        <p className='address-one'>{props.Address.address1}</p>
        <p>{props.Address.address2}</p>
        <p>{props.Address.city}</p>
        <p>{props.Address.companyName}</p>
        <p>{props.Address.state}</p>
        <p>{props.Address.country}</p>
        <p>{props.Address.postalCode}</p>
      </div>
    );
  }
}
