import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';

// Set Password
export const getPassword = (
  email,
  oldPassword,
  newPassword,
  newConfirmPassword
) => {
  const basic_token = localStorage.getItem('basic_token');
  const headersProfile = 'Bearer ' + basic_token;
  const reqHeader = {
    Authorization: headersProfile,
    'Content-Type': 'application/json',
  };

  const reqBody = {
    login: email,
    oldPassword: oldPassword,
    newPassword: newPassword,
    newConfirmPassword: newConfirmPassword,
  };

  const PasswordApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/updateExpiredPassword`;

  return axios.put(PasswordApiUrl, reqBody, {
    headers: reqHeader,
  });
};
