import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_FEATURED_PRODUCTS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setFeaturedProducts } from '../../actions';
import { getFeaturedProducts } from '../../api/api';

function* workerGetFeaturedProductsSaga() {
  try {
    const featured_products = yield call(getFeaturedProducts);
    yield put(setFeaturedProducts(featured_products.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetFeaturedProductsSaga() {
  yield takeLatest(
    GET_FEATURED_PRODUCTS_SAGA,
    workerGetFeaturedProductsSaga
  );
}
