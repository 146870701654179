import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CustomCheckbox from '../Shared/customCheckbox';
import CustomRadioGroup from '../Shared/customRadioGroup';
import './style.css';

const formatLabel = (strData) => {
  if (strData.length < 20) return strData;
  else {
    strData = strData.substring(0, 20);
    strData += '...';
    return strData;
  }
};

const isOptionSelected = (
  adjustments,
  option,
  filterName,
  filterType
) => {
  let isSelected = false;
  adjustments[filterType].map((adjustment, index) => {
    if (adjustment.name === filterName) {
      if (adjustment.selections.indexOf(option.value) > -1) {
        isSelected = true;
      }
    }
  });
  return isSelected;
};

const filterType = ({
  facet,
  adjustments,
  filterOptionClickHandler,
}) => {
  
  return (
    <React.Fragment>
      {facet &&
        facet.length &&
        facet.map((data, i) => (
          <React.Fragment key={`${data.label}-${i}-facet`}>
            {(data.type === 'multiselect' ||
              data.type === 'singleselect' ||
              data.type === 'range') &&
              data.options.length > 0 && (
                <React.Fragment>
                  <div className='filter-type-container'>
                    <p className='filter-type-label'>{data.label}:</p>
                    {(data.type === 'multiselect' ||
                      data.type === 'range') &&
                      data.options.map((option, i) => {
                        if (option.count !== 0) {
                          return (
                            <div
                              className='filter-type-option'
                              key={`${option.label}-${option.value}-${i}-option`}>
                              <CustomCheckbox
                                name={data.value}
                                filterType={data.type}
                                defaultCheck={isOptionSelected(
                                  adjustments,
                                  option,
                                  data.value,
                                  data.type
                                )}
                                value={option.value}
                                onChange={filterOptionClickHandler}
                              />
                              <span
                                className='filter-option-label'
                                title={option.label}>
                                {formatLabel(option.label)}
                              </span>
                              <span className='filter-option-count'>
                                {option.count}
                              </span>
                            </div>
                          )
                        }
                      })}

                    {data.type === 'singleselect' && (
                      <CustomRadioGroup
                        name={data.value}
                        filterType={data.type}
                        defaultValue={123}
                        options={data.options}
                        clickHandler={filterOptionClickHandler}
                      />
                    )}
                  </div>
                  <div className='divider'></div>
                </React.Fragment>
              )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default filterType;
