import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_PROMOTION_SAGA, SET_ERROR_STATE } from '../../constants';
import { setPromotion } from '../../actions';
import { getPromotion } from '../../api/api';

// Added for Home Page Featured Promotion
function* workerGetPromotionSaga() {
  try {
    const promotion = yield call(getPromotion);
    yield put(setPromotion(promotion.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetPromotionSaga() {
  yield takeLatest(GET_PROMOTION_SAGA, workerGetPromotionSaga);
}
