import React, { useState, useEffect } from 'react';
import { IconButton, Link, TextField } from '@material-ui/core';
import {
  AddCircleOutline,
  RemoveCircleOutline,
} from '@material-ui/icons';
import './Styles.css';
import ContentLoader from 'react-content-loader';
import { BepsyImage } from '../BepsyImage';

const OrdersList = ({
  order,
  displayQuantityIncr,
  displayRemoveBtn,
  handleUpdate,
  handleRemove,
  updateItemQuantity,
  showLoader,
}) => {
  // console.log('order', order);
  const handleChange = (event, order, item) => {
    let { value } = event.target;
    if (event.target.value === NaN) {
      value = 0;
    }
    updateItemQuantity(order, item.sku, value, 'assign');
  };

  const cartItemLinkToPDP = (item) => {
    window.location = `/product/${item.product}`;
  };
  const showQtyInCart = (item) => {
    return displayQuantityIncr ? (
      <TextField
        type='tel'
        value={item.quantity}
        onChange={(event) => handleChange(event, order, item)}
        disabled={!displayQuantityIncr}
        className='text-center quantity-input'
        inputProps={{ maxLength: 3 }}
      />
    ) : (
      <span className='item-qty-checkout'>{item.quantity}</span>
    );
  };
  return (
    <div className='order-cart-checkout'>
      {order && order !== null && order.EOrderItems ? (
        order.EOrderItems.map((item, index) => (
          <span key={index}>
            <div className='wrapper' id={item.sku}>
              <div className='box-cell'>
                <div
                  className='item-wrapper'
                  onClick={() => {
                    cartItemLinkToPDP(item);
                  }}>
                  <div className='item-header productTitle'></div>
                  <div className='item-content'>
                    <div
                      className='product-image'
                      style={{ height: '80px' }}>
                      {/* {item.EItemMedia !== null || item.EItemMedia !== undefined && (
												<img src={item.EItemMedia.fullURI} alt={item.EItemMedia.fullTitle} />
											) } */}

                      <BepsyImage
                        src={item.EItemMedia.thumbURI}
                        alt='product Image'
                      />
                    </div>
                    <div className='product-info'>
                      <div className='product-name-checkout'>
                        {item.productName}
                      </div>
                      <div className='text-uppercase'>
                        SKU: {item.sku}
                      </div>
                      {item.color && <div>Color: {item.color}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='box-cell'>
                <div className='item-wrapper'>
                  <div className='item-header'>Price</div>
                  <div className='item-content price'>
                    {item.listPrice.toFixed(2)}
                  </div>
                </div>
              </div>
              <div className='box-cell'>
                <div className='item-wrapper'>
                  <div className='item-header'>Quantity</div>
                  <div className='item-content qty'>
                    <div className='quantity-wrapper'>
                      <IconButton
                        size='small'
                        onClick={(event) =>
                          updateItemQuantity(
                            order,
                            item.sku,
                            item.quantity,
                            'decrement'
                          )
                        }
                        style={{
                          display: displayQuantityIncr ? '' : 'none',
                        }}>
                        <RemoveCircleOutline />
                      </IconButton>
                      {showQtyInCart(item)}
                      <IconButton
                        size='small'
                        onClick={(event) =>
                          updateItemQuantity(
                            order,
                            item.sku,
                            item.quantity,
                            'increment'
                          )
                        }
                        style={{
                          display: displayQuantityIncr ? '' : 'none',
                        }}>
                        <AddCircleOutline />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
              <div className='box-cell'>
                <div className='item-wrapper'>
                  <div className='item-header'>Total</div>
                  <div className='item-content'>
                    <div className='price-wrapper'>
                      <div className='price'>
                        {item.finalItemAmount.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='box-cell'>
                <div className='item-wrapper'>
                  <div className='item-content'>
                    <div className='price-wrapper'>
                      <div
                        className='edit-remove-btn'
                        style={{
                          display: displayRemoveBtn ? '' : 'none',
                        }}>
                        <Link onClick={handleUpdate.bind(this, item)}>
                          Update
                        </Link>{' '}
                        |{' '}
                        <Link onClick={handleRemove.bind(this, item)}>
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        ))
      ) : (
        <h4>Your cart is empty</h4>
      )}
    </div>
  );
};

export default OrdersList;
