import React, { PureComponent } from 'react';
import {
  Grid,
  Card,
  Hidden,
  Paper,
  IconButton,
} from '@material-ui/core';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomCarousel from './NotificationCarousel';

import { Link } from 'react-router-dom';

import './Styles.css';
import { getSiteById } from '../../api/api';

function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = Math.abs(decPlaces);
  decPlaces = isNaN(decPlaces) ? 2 : decPlaces;
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  var sign = number < 0 ? '-' : '';
  var i = String(
    parseInt(
      (number = Math.abs(Number(number) || 0).toFixed(decPlaces))
    )
  );
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  );
}

const Banner = ({
  userNameChanged,
  dashboardSummary = {},
  announcement = '',
}) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const accountDetails = JSON.parse(localStorage.getItem('context'));
  const notificationData = [
    { '1': 'data' },
    { '2': 'data' },
    { '3': 'data' },
  ];
  let fName = '';
  let lName = '';
  if (
    userNameChanged &&
    userNameChanged.firstName &&
    userNameChanged.lastName
  ) {
    fName = userNameChanged.firstName;
    lName = userNameChanged.lastName;
  } else if (userDetails) {
    fName = userDetails.firstName;
    lName = userDetails.lastName;
  }

  const bannerClass = `staticbanner ${accountDetails &&
    accountDetails.siteId}`;
  const allOrdersAmount = formatMoney(
    dashboardSummary.allOrdersAmount || 0,
    0,
    '.',
    ','
  );

  const siteImg = getSiteById(accountDetails.siteId).dashboardImg;

  return (
    <div>
      <Grid>
        <div>
          <Hidden mdDown>
            <div
              className={bannerClass}
              style={{
                backgroundImage: `url(${
                  getSiteById(accountDetails.siteId).dashboardImg
                })`,
              }}>
              <Grid
                style={{ marginLeft: '51px', marginRight: '51px' }}>
                <Grid container>
                  <Grid
                    style={{ marginTop: '100px' }}
                    className='bannerWelcomeText'>
                    <div>Welcome back,{fName}!</div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: '20px' }}
                  className='dashboard-description'>
                  <Grid xs={3}>
                    <Grid xs={12} className='bannerSubText'>
                      Account Name:
                    </Grid>
                    <Grid xs={12} className='bannerSubTextValue'>
                      {userDetails.companyName}
                    </Grid>
                  </Grid>
                  <Grid xs={3}>
                    <Grid xs={12} className='bannerSubText'>
                      Customer Number:
                    </Grid>
                    <Grid xs={12} className='bannerSubTextValue'>
                      {accountDetails.profileId}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Hidden>

          <Hidden only={['xl', 'lg']}>
            <div
              className={bannerClass}
              style={{
                backgroundImage: `url(${
                  getSiteById(accountDetails.siteId).dashboardImg
                })`,
              }}>
              <Grid className='vAlign'>
                <Grid container className='bannerTexttablet'>
                  <Grid>
                    <h2>Welcome back,{fName}!</h2>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: '20px' }}
                  className='bannerTexttablet'>
                  <Grid xs={12} sm={3} md={3}>
                    <Grid xs={12} className='bannerSubText'>
                      Account Name:
                    </Grid>
                    <Grid xs={12} className='bannerSubTextValue'>
                      {userDetails.companyName}
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={3} md={3}>
                    <Grid xs={12} className='bannerSubText'>
                      Customer Number:
                    </Grid>
                    <Grid xs={12} className='bannerSubTextValue'>
                      {accountDetails.organizationId}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Hidden>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: '10px',
              width: '95%',
              margin: 'auto',
            }}
            className='desktopCards removeFlexWrap'>
            <Grid
              item
              xs={12}
              md={9}
              lg={9}
              xl={9}
              sm={12}
              className='bannerWidth'>
              <Grid container spacing={3}>
                <Grid item sm={4} xs={12}>
                  <Paper
                    style={{ height: '250px' }}
                    className='Aligner'
                    square={true}>
                    <div className='Aligner-item--top'>
                      <IconButton>
                        <AirplanemodeActiveIcon />
                      </IconButton>
                      Delivery Summary
                    </div>
                    <div className='Aligner-item--center'>
                      <div className='cardNumbers'>
                        ${allOrdersAmount}
                      </div>
                      <div className='cardMainText'>
                        in orders delivered this year
                      </div>
                    </div>
                    <div className='Aligner-item--bottom cardActionLink'>
                      <Link to='/orders'> View My Orders </Link>
                    </div>
                  </Paper>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Paper
                    style={{ height: '250px' }}
                    className='Aligner'
                    square={true}>
                    <div className='Aligner-item--top'>
                      <IconButton>
                        <PostAddIcon />
                      </IconButton>
                      My Quotes
                    </div>
                    <div className='Aligner-item--center'>
                      <div className='cardNumbers'>
                        {dashboardSummary.openQuotes}
                      </div>
                      <div className='cardMainText'>open quotes</div>
                    </div>
                    <div className='Aligner-item--bottom cardActionLink'>
                      <Link to='/quoteslist'>View My Quotes</Link>
                    </div>
                  </Paper>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Paper
                    style={{ height: '250px' }}
                    className='Aligner'
                    square={true}>
                    <div className='Aligner-item--top'>
                      <IconButton>
                        <ReceiptIcon />
                      </IconButton>
                      Invoices
                    </div>
                    <div className='Aligner-item--center'>
                      <div className='cardNumbers'>
                        {dashboardSummary.openInvoices}
                      </div>
                      <div className='cardMainText'>
                        open invoices
                      </div>
                    </div>
                    <div className='Aligner-item--bottom cardActionLink'>
                      <Link to='/invoice'> View My Invoices </Link>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ marginTop: '20px', minHeight: '270px' }}>
                <Paper
                  style={{
                    backgroundColor: '#2e2e2e',
                    textAlign: 'center',
                    color: '#ffffff',
                    // width: '975px',
                  }}
                  square={true}>
                  <Grid item sm={12}>
                    <div className='announcementSectionTitle'>
                      Announcements
                    </div>
                    <div className='announcementSectionMainText'>
                      {announcement}
                    </div>
                    <div className='announcementSectionAction'>
                      <span>
                        Shop Now &nbsp;&nbsp;
                        <IconButton
                          style={{ backgroundColor: '#ffffff' }}>
                          <ForwardOutlinedIcon />
                        </IconButton>
                      </span>
                    </div>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Hidden only={['xs', 'md', 'sm']}>
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                <Paper
                  style={{
                    minHeight: '540px',
                    padding: '15px',
                    backgroundColor: '#2e2e2e',
                    color: '#ffffff',
                  }}
                  square={true}>
                  <Grid item sm={12}>
                    <div className='notificationTitleSection'>
                      <Grid className='notificationTitle'>Tasks</Grid>
                      <Grid className='notificationSubText'>
                        These tasks may require your immediate
                        attention
                      </Grid>
                    </div>
                    <div>
                      <div className='notifyMainSection'>
                        <div className='notifySection'>
                          <div className='notifyTitle'>
                            <span>Invoices</span>
                            <span style={{ float: 'right' }}>
                              INV123456 &nbsp;&nbsp;&nbsp; X
                            </span>
                          </div>
                        </div>
                        <div className='notifyMsg'>
                          This invoice is due in 3 days
                        </div>
                        <div className='notifyData'>
                          <Grid
                            container
                            style={{ marginTop: '20px' }}>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Order Total
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                $1234,56
                              </Grid>
                            </Grid>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Items
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                23
                              </Grid>
                            </Grid>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Due Date
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                01/01/2021
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <div className='notifyAction'>
                          <Grid container>
                            <Grid xs={12} sm={6} md={6}>
                              Dismiss
                            </Grid>
                            <Grid xs={12} sm={6} md={6}>
                              Pay Now
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <br />
                      <div className='notifyMainSection'>
                        <div className='notifySection'>
                          <div className='notifyTitle'>
                            <span>Invoices</span>
                            <span style={{ float: 'right' }}>
                              INV123456 &nbsp;&nbsp;&nbsp; X
                            </span>
                          </div>
                        </div>
                        <div className='notifyMsg'>
                          This invoice is due in 3 days
                        </div>
                        <div className='notifyData'>
                          <Grid
                            container
                            style={{ marginTop: '20px' }}>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Order Total
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                $1234,56
                              </Grid>
                            </Grid>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Items
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                23
                              </Grid>
                            </Grid>
                            <Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeading'>
                                Due Date
                              </Grid>
                              <Grid
                                xs={12}
                                className='notifyDataHeadingValue'>
                                01/01/2021
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                        <div className='notifyAction'>
                          <Grid container>
                            <Grid xs={12} sm={6} md={6}>
                              Dismiss
                            </Grid>
                            <Grid xs={12} sm={6} md={6}>
                              Pay Now
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      <div className='moreSection'>
                        <IconButton
                          style={{ color: '#ffffff', fontSize: 30 }}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                </Paper>
              </Grid>
            </Hidden>
            <Hidden only={['lg', 'xl']}>
              <Grid
                item
                xs={12}
                md={9}
                lg={9}
                xl={9}
                sm={12}
                className='bannerWidth'>
                <CustomCarousel
                  headerText='Tasks'
                  dataList={notificationData}
                  btnLabel='Know More'
                  clickHandler={() => {}}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default Banner;
