import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import HomePageBannerCarousel from '../HomePageBannerCarousel';
import HomeFeaturedPromotion from '../HomeFeaturedPromotion';
import CustomerSupport from '../CustomerSupport';
import RecommendedProducts from '../RecommendedProducts';
import FeaturedCategories from '../FeaturedCategories';
import FeaturedProducts from '../FeaturedProducts';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  toggleMenu = () => {
    // attach/remove event handler
    if (!this.state.showMenu) {
      document.addEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.handleOutsideClick,
        false
      );
    }

    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.toggleMenu();
  }

  render() {
    const token = localStorage.getItem('access_token');

    if (!token) {
      return <Redirect to='/login' />;
    }
    return (
      <div
        ref={(node) => {
          this.node = node;
        }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Use HOC for homepage components */}
            <PageLayoutHOC {...this.props}>
              <HomePageBannerCarousel />
              <FeaturedCategories />
              <RecommendedProducts />
              <HomeFeaturedPromotion />
              <FeaturedProducts />
              <CustomerSupport />
            </PageLayoutHOC>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps)(Dashboard);
