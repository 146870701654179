import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_QUOTE_LIST, SET_QUOTE_LIST } from '../../constants';
import {
  getQuotesSaga,
  setQuoteListSuccess,
} from '../../actions/quoteAction';
import { getQuotes, getQuotesOnPagination } from '../../api/quoteApi';

function* workerGetQuotesListSaga() {
  try {
    // const orders = yield call(getOrders);
    // yield put(setOrders(orders));
    const quoteListData = yield call(getQuotesOnPagination);
    // console.log('saga.....', quoteListData);
    yield put(setQuoteListSuccess(null));
    yield put(setQuoteListSuccess(quoteListData.data.orderList));
    // console.log('saga.....***', quoteListData.data);
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      //yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetQuotesListSaga() {
  yield takeLatest(GET_QUOTE_LIST, workerGetQuotesListSaga);
}
