import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsersSaga } from '../../actions';
import './Styles.css';
import ContentLoader from 'react-content-loader';

import invoices from '../../assets/Navigation/invoices.png';
import orders from '../../assets/Navigation/orders.png';
import quotes from '../../assets/Navigation/quotes.png';
import quickorder from '../../assets/Navigation/qof.png';

function handleLogout() {
  localStorage.removeItem('access_token');
  localStorage.clear();
  window.location.reload();
}

const Sidenav = ({ users, userNameChanged }) => {
  users = users.items;

  const userDetails = JSON.parse(localStorage.getItem('user'));
  let fName = '';
  let lName = '';
  if (
    userNameChanged &&
    userNameChanged.firstName &&
    userNameChanged.lastName
  ) {
    fName = userNameChanged.firstName;
    lName = userNameChanged.lastName;
  } else if (userDetails) {
    fName = userDetails.firstName;
    lName = userDetails.lastName;
  }
  if (!users)
    return (
      <ContentLoader
        height={1100}
        width={400}
        speed={2}
        primaryColor='#f3f3f3'
        secondaryColor='#ecebeb'></ContentLoader>
    );
  return (
    <div className='externalContainer'>
      <div className='navigation'>
        <ul>
          <li>
            <a href='/'>
              <img src={users[0].img_url} alt='logo' />
              {users[0].name}
            </a>
            <ul>
              {users[0].sub_category &&
                users[0].sub_category.map((sub) => (
                  <li key={sub.name}>
                    { sub.destination_url ?
                      <a href={sub.destination_url}>{sub.name}</a>
                      : <a>{sub.name}</a>
                    }
                    <ul>
                      {sub.sub_category &&
                        sub.sub_category.map((sub2) => (
                          <li key={sub2.name}>
                            {/* <a href={sub2.destination_url}>{sub2.name}</a> */}
                            <Link to={sub2.destination_url}>
                              {sub2.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <Link to='/orders'>
              <img src={orders} alt='orders' />
              Orders
            </Link>
          </li>
          <li>
            <Link to='/quoteslist'>
              <img src={quotes} alt='quotes' />
              Quotes
            </Link>
          </li>
          <li>
            <Link to='/invoice'>
              <img src={invoices} alt='invoices' />
              Invoices
            </Link>
          </li>
          <li>
            <Link to='/quickorderform'>
              <img src={quickorder} alt='quickorder' />
              Quick Order
            </Link>
          </li>
        </ul>

        <ul>
          <Divider variant='middle' />
          <li>
            <Link to='/contact'>
              <i
                className='fa fa-question-circle-o fa-2x customFontAwesome'
                aria-hidden='true'
              />
              Need Help
            </Link>
          </li>
          <Divider variant='middle' />
          <li>
            <a>{userDetails.companyName}</a>
          </li>
          <li>
            <a>
              <div>
                <i
                  className='fa fa-user-circle-o fa-2x customFontAwesome'
                  aria-hidden='true'
                />
                <span>
                  {fName} {lName}
                </span>
              </div>
            </a>
            <ul>
              <li>
                <Link to='/account'>
                  <span>Account</span>
                </Link>
              </li>
              <li>
                <a>
                  <span
                    onClick={handleLogout}
                    onKeyDown={handleLogout}
                    role='button'
                    tabIndex={0}>
                    Logout
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className='navigation dFooter footerText'>
        <ul>
          <small>
            <li>
              <Link to='/terms'>Terms and Conditions</Link>
            </li>
            <li>
              <Link to='/privacy'> Privacy </Link>
            </li>
            <li>
              <Link to='/cookie'> Cookie policy </Link>
            </li>
            <li>
              <Link to='/accessibility'> Accessibility </Link>
            </li>
            <li>
              <Link to='/faq'> FAQ's </Link>
            </li>
            <li>
              <Link to='/about'> About us </Link>
            </li>
            <Divider variant='middle' />
            <li className='copyRight'>
              © 2019 Object Edge. All rights reserved.
            </li>
            <li className='buildInfo'>buildversion , buildtime</li>
          </small>
        </ul>
      </div>
    </div>
  );
};
Sidenav.propTypes = {
  users: PropTypes.any.isRequired,
  getUsersSaga: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  users: state.usersReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersSaga: () => dispatch(getUsersSaga()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
