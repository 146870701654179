import React from 'react';
import maintenanceImg from '../../assets/Maintenance/maintenance.jpeg';
import './style.css';
import { Grid } from '@material-ui/core';

function Maintenance() {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      md={12}
      xl={12}
      className='img-maintenance-set'
      style={{
        backgroundImage: `url(${maintenanceImg})`,
      }}
    />
  );
}

export default Maintenance;
