import {
  GET_ORGANIZATION_DETAILS_SAGA,
  SET_ORGANIZATION_DETAILS,
  RESET_ORGANIZATION_DETAILS,
  SET_CONTACT_LIST,
  CLEAR_CONTACT_LIST,
  SET_ORGANIZATION_ROLES,
  GET_ORGANIZATION_LIST,
  SET_ORGANIZATION_LIST,
  GET_CURRENT_ORGANIZATION,
  SET_CURRENT_ORGANIZATION,
  SET_SUB_ORGANIZATION
} from '../constants';

//  get organization details
export function getOrganizationDetailsSaga() {
  return {
    type: GET_ORGANIZATION_DETAILS_SAGA,
  };
}

//  Added for Organization Details
export function setOrganizationDetails(organization_details) {
  return {
    type: SET_ORGANIZATION_DETAILS,
    organization_details,
  };
}

//  set contact list
export function setContactList(data) {
  return {
    type: SET_CONTACT_LIST,
    data,
  };
}

//  reset contact list
export function clearContactList() {
  return {
    type: CLEAR_CONTACT_LIST,
  };
}

export function resetOrganization() {
  return {
    type: RESET_ORGANIZATION_DETAILS,
  };
}

// contact roles
export function setOrganizationRoles(data) {
  return {
    type: SET_ORGANIZATION_ROLES,
    data,
  };
}

//  get sub organization details
export function getSubOrganizationDetailsSaga() {
  return {
    type: GET_ORGANIZATION_LIST,
  };
}

//  Added for Sub Organization Details
export function setSubOrganizationDetails(organization_details) {
  return {
    type: SET_ORGANIZATION_LIST,
    organization_details,
  };
}

export function setSubOrganization(data) {
  return {
    type: SET_SUB_ORGANIZATION,
    data,
  };
}
