import React, {
  useState
} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import {
  Redirect
} from 'react-router-dom';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [gotoHome, setGoToHome] = useState(false);  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOK = () => {
    setGoToHome(true);

  }

  if (gotoHome) {
    return <Redirect exact to = '/' / > ;

  }
  return (
    <div>
      <div className='continueShopping'><a variant="outlined" color="primary" onClick={handleClickOpen}>
        Keep Shopping
      </a></div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            All new information entered will be lost.Are you sure you want to leave Checkout ?
          </DialogContentText>         
        </DialogContent>
        <DialogActions>
          < Button 
          color = "primary"
          onClick = {
            handleClose
          } >
           STAY IN CHECKOUT
          </Button>
          < Button onClick = {
            handleOK
          }
          color = "primary" >
            RETURN TO SHOPPING
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
