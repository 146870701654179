import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Styles.css';
import Grid from '@material-ui/core/Grid';
import ContentLoader from 'react-content-loader';
import { getCustomerSupportSaga } from '../../actions';
import { CUSTOMER_EMAIL, CUSTOMER_CALL } from '../../constants';

class CustomerSupport extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getCustomerSupportSaga();
  }

  render() {
    let { customer_support } = this.props;
    customer_support = customer_support[0].items;

    if (!customer_support) return <ContentLoader />;
    return (
      <Grid>
        <div className='externalContainer containerCustomer'>
          {customer_support.map((image, i) => (
            <div
              className='setWidthCustomer'
              key={`customerSupport-${image.id}-${image.name}`}
              style={{
                background: `url(${image.img_url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top',
              }}
            >
              <div>
                {/* <img
                  className='imageResizeCustomer myslant'
                  src={image.img_url}
                /> */}
              </div>
              <div className='centeredCustomer'>
                <div className='textCenter'>
                  <h1 className='paraText'>{image.short_text}</h1>
                  <p className='paraText'>{image.long_text}</p>
                  <p className='paraText'>
                    <strong>
                      {' '}
                      {CUSTOMER_EMAIL} {image.contact_email}{' '}
                    </strong>
                  </p>
                  <p className='paraText'>
                    <strong>
                      {CUSTOMER_CALL} {image.contact_number}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  customer_support: [state.customerSupportReducer.customer_support],
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerSupportSaga: () => dispatch(getCustomerSupportSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerSupport);
