import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Styles.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getPromotionSaga } from '../../actions';
import ContentLoader from 'react-content-loader';
import BootstrapButton from '../../components/Shared/bootstrapButton';
import {
  Route,
  Switch,
  Link,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

class HomeFeaturedPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionUrl: null,
    };
  }

  componentDidMount() {
    this.props.getPromotionSaga();
  }

  findABikeClickHandler = (e, actionUrl) => {
    this.setState({
      actionUrl: actionUrl,
    });
  };

  render() {
    let { promotion } = this.props;
    promotion = this.props.promotion[0].items;

    if (!promotion) return <ContentLoader />;
    {
      /* // If button clicked redirect page */
    }
    if (this.state.actionUrl) {
      return <Redirect to={`${this.state.actionUrl}`} />;
    }
    return (
      <Grid>
        <div className='externalContainer containerPromotion'>
          {promotion.map((image, i) => {
            return (
              <div
                className='setWidthPromotion'
                key={`homeFeaturedPromotion-${image.id}-${image.name}`}>
                <div>
                  <img
                    className='imageResizePromotion'
                    src={image.img_url}
                  />
                </div>
                <div className='centeredPromotion'>
                  <h1>{image.short_text}</h1>
                  <p className='paraText'>{image.long_text}</p>
                  {/* Add button */}
                  
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  promotion: [state.promotionReducer.promotion],
});

const mapDispatchToProps = (dispatch) => ({
  getPromotionSaga: () => dispatch(getPromotionSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeFeaturedPromotion);
