import { put, takeLatest, call, select } from 'redux-saga/effects';

import { GET_REJECT_QUOTE, SET_ERROR_STATE } from '../../constants';
import { getRejectQuoteData, setQuoteListSuccess, setRejectQuoteSuccess, setQuoteDetails, setQuotesOnPagination } from '../../actions/quoteAction';
import { getRejectQuote } from '../../api/quoteApi';

function* workerGetRejectQuoteSaga({ payload: { quoteId }}) {
  try {
    // const { quoteId, quoteDetails } = yield select((state) => state.quoteReducer);
    // const quoteList = yield select(state => state.quotesOnPagination.orders_list);
    // const orderStatus = 'closed';
    // const orderStatusDisplay = 'Rejected';

    // quoteDetails.orderStatus = orderStatus;
    // quoteDetails.orderStatusDisplay = orderStatusDisplay;

    // const updatedList = {
    //   orderList: quoteList.orders_list.orderList.map(item => {
    //     if (item.orderNumber !== quoteDetails.orderNumber) {
    //       return item;
    //     }
  
    //     return {
    //       ...item,
    //       orderStatus,
    //       orderStatusDisplay,
    //     };
    //   }),
    // }
    
    // yield put(setQuoteDetails(quoteDetails));
    // yield put(setQuotesOnPagination(updatedList));
    const response = yield call(getRejectQuote, quoteId);

    //  on success get the quote list
    if (response.status === 200) {
      yield put(setRejectQuoteSuccess(response.data));      
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetRejectQuoteSaga() {
  yield takeLatest(GET_REJECT_QUOTE, workerGetRejectQuoteSaga);
}
