import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsersSaga } from '../../actions';
import './Styles.css';

import invoices from '../../assets/Navigation/invoices.png';
import orders from '../../assets/Navigation/orders.png';
import quotes from '../../assets/Navigation/quotes.png';
import quickorder from '../../assets/Navigation/qof.png';

function handleLogout() {
  localStorage.removeItem('access_token');
  localStorage.clear();
  window.location.reload();
}

class Menu extends Component {
  constructor() {
    super();
    this.handleBtnOnClick = this.handleBtnOnClick.bind(this);
  }

  componentDidMount() {
    this.handleBtnOnClick();
  }

  handleBtnOnClick() {
    this.props.getUsersSaga();
  }

  render() {
    let { users } = this.props;
    users = users.items;
    const copy = [];

    const userDetails = JSON.parse(localStorage.getItem('user'));

    if (users) {
      users.forEach((item) => {
        copy.push(item);
      });
    }
    return (
      <div className='navigationMob'>
        {this.props.showMenu &&
          copy.length > 0 &&
          copy.map((item, i) => (
            <div key={i}>
              <ul className='mainmenu'>
                <li>
                  <a>
                    <img src={item.img_url} alt='logo' />
                    {item.name}
                  </a>
                  <ul className='submenu'>
                    {item.sub_category &&
                      item.sub_category.map((sub, j) => (
                        <li key={j}>
                          { sub.destination_url ?
                            <a href={sub.destination_url}>{sub.name}</a>
                            : <a>{sub.name}</a>
                          }
                          <ul className='may'>
                            {sub.sub_category &&
                              sub.sub_category.map((sub2, k) => (
                                <li key={k}>
                                  <a href={sub2.destination_url}>
                                    {sub2.name}
                                  </a>
                                  {/* <Link to={sub2.destination_url}>
                                    {sub2.name}
                                  </Link> */}
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </li>
                <li>
                  <a href='/quoteslist'>
                    <img src={quotes} alt='quotes' />
                    Quotes
                  </a>
                </li>
                <li>
                  <a href='/orders'>
                    <img src={orders} alt='orders' />
                    Orders
                  </a>
                </li>
                <li>
                  <a href='/invoice'>
                    <img src={invoices} alt='invoices' />
                    Invoices
                  </a>
                </li>
                <li>
                  <a href='/quickorderform'>
                    <img src={quickorder} alt='quickorder' />
                    Quick Order
                  </a>
                </li>
              </ul>
            </div>
          ))}
        {this.props.showMenu && (
          <div>
            <Divider variant='middle' />
            <ul className='mainmenu'>
              <li>
                <Link to='/contact'>
                  <i
                    className='fa fa-question-circle-o customFontAwesome'
                    aria-hidden='true'
                  />
                  Need Help
                </Link>
              </li>
              <Divider variant='middle' />
              <li>
                {/* This need work around for dynamic organization fetching from api response */}
                <a>{userDetails.companyName}</a>
              </li>
              <li className='has-sub'>
                <span>
                  <a>
                    <i
                      className='fa fa-user-circle-o customFontAwesome'
                      aria-hidden='true'
                    />
                    {userDetails.firstName} {userDetails.lastName}
                  </a>
                </span>

                <ul className='submenu'>
                  <li>
                    <a href='/account'>
                      <span>Account</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span
                        onClick={handleLogout}
                        onKeyDown={handleLogout}
                        role='button'
                        tabIndex={0}>
                        Logout
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <Divider variant='middle' />
            <ul className='mainmenu'>
              <small>
                <li>
                  <Link to='/terms'>Terms and Conditions</Link>
                </li>
                <li>
                  <Link to='/privacy'> Privacy </Link>
                </li>
                <li>
                  <Link to='/cookie'> Cookie policy </Link>
                </li>
                <li>
                  <Link to='/accessibility'> Accessibility </Link>
                </li>
              </small>
            </ul>
          </div>
        )}
      </div>
    );
  }
}
Menu.propTypes = {
  // users: PropTypes.array.isRequired,
  showMenu: PropTypes.bool.isRequired,
  getUsersSaga: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  users: state.usersReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersSaga: () => dispatch(getUsersSaga()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
