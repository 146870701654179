// import { BASE_URL, APPLICATION_NAME, VERSION, ENVIRONMENT, APPLICATION_NAME_BE } from '../configurations/config.js';
import axios from 'axios';
import {
  BASE_URL,
  APPLICATION_NAME,
  VERSION,
  ENVIRONMENT,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';

import loginimgg from '../assets/Login/login.jpeg';
import ppgLoginImg from '../assets/Login/ppg-login-image.jpg';
import ventecImg from '../containers/Dashboard-V2/img/ventec-banner.jpg';
import Logo from '../assets/Logo/logo.png';
import VentecLogo from '../assets/Logo/ventec-logo.png';
import PPGLogo from '../assets/Logo/ppg-logo.png';
import banner from '../containers/Dashboard-V2/img/banner.jpg';
import bannerPpg from '../containers/Dashboard-V2/img/ppg-dashboard-image.jpg';
import bannerVentec from '../containers/Dashboard-V2/img/ventec-banner.jpg';

function getCommonHeaders() {
  try {
    const context = JSON.parse(localStorage.getItem('context'));
    const { siteId } = context;

    return {
      'Bepsy-SiteId': siteId,
    };
  } catch (err) {
    return {};
  }
}

function getOptions() {
  return {
    headers: getCommonHeaders(),
  };
}

export function getBaseToken() {
  // call to get base token
  const getBaseTokenApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/` +
    'token';
  return axios.get(getBaseTokenApi);
}

// call to menu api
export function getUsers() {
  const getMenuApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/menu';
  return axios.get(getMenuApi, getOptions());
}

// call to Home Page Featured Promotion api
export function getPromotion() {
  const getPromotionBannerApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/banners/home_page_featured_promotion';
  return axios.get(getPromotionBannerApi, getOptions());
}

// call to Home Page Customer Contact Support api
export function getCustomerSupport() {
  const getCustomerSupportApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/banners/home_page_customer_support';
  return axios.get(getCustomerSupportApi, getOptions());
}

// call to Home Page Carausel api
export function getHomeCarousel() {
  const getHomeCarouselApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/banners/home_page_hero_banner';
  return axios.get(getHomeCarouselApi, getOptions());
}

// Added for Recommended products
export function getRecommendedProducts() {
  const getRecommendedProductsApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/recommendedProducts';
  return axios.get(getRecommendedProductsApi, getOptions());
}

// call to Featured Categories api
export function getFeaturedCategories() {
  const getFeaturedCategoriesApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/banners/home_page_featured_categories';
  return axios.get(getFeaturedCategoriesApi, getOptions());
}

// call to Featured Products api
export function getFeaturedProducts() {
  const getFeaturedProductsApi =
    `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/` +
    'items/home_page_featured_products';
  return axios.get(getFeaturedProductsApi, getOptions());
}

const mockSites = [
  {
    siteId: 'siteUs',
    displayName: 'Site US',
    logo: Logo,
    dashboardImg: banner,
    loginImg: loginimgg,
  },
  {
    siteId: 'ventec',
    displayName: 'Ventec',
    logo: VentecLogo,
    dashboardImg: bannerVentec,
    loginImg: ventecImg,
  },
  {
    siteId: 'ppg',
    displayName: 'PPG',
    logo: PPGLogo,
    dashboardImg: bannerPpg,
    loginImg: ppgLoginImg,
  },
];

export async function verifyOrganization() {
  const { data } = await axios.get(
    `https://ct-api.objectedge.com/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/verifyOrganization`
  );

  // TODO: Create context and set organizationId
  localStorage.setItem('organizationId', data.organizationId);

  return data;
}

export function getApplicationDetails() {
  return new Promise(async (res) => {
    let sites = {
      siteOptions: mockSites,
    };

    try {
      const { data } = await axios.get(
        'https://ct-api.objectedge.com/oe_commerce_api/cms/v1/published/items/siteOptions'
      );
      sites = data;
    } catch (err) {
      console.error(err);
    }

    localStorage.setItem(
      'availableSites',
      JSON.stringify(sites.siteOptions)
    );

    res(sites);
  });
}

export function getAvailableSites() {
  try {
    return JSON.parse(localStorage.getItem('availableSites'));
  } catch (err) {
    return mockSites;
  }
}

export function getSiteById(id) {
  if (!id) {
    return mockSites[0];
  }

  if (id.indexOf('-') !== -1) {
    id = id.split('-')[1];
  }

  return (
    getAvailableSites().find((item) => item.siteId === id) ||
    mockSites[0]
  );
}
