import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withToastManager } from 'react-toast-notifications';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import 'font-awesome/css/font-awesome.min.css';
import './style.css';
import Hidden from '@material-ui/core/Hidden';
import loginimgg from '../../assets/Login/login.jpeg';
import Footer from '../Footer';
import backToLogin from '../../assets/ForgotPassword/backArrow.png';
import Logo from '../../assets/Logo/logo.png';
import { getForgotPasswordEmailSaga } from '../../actions/forgotPasswordAction';
import CircularProgressBar from '../../components/Shared/spanLoader';

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      template: 'ForgotPasswordTemplate',
      showLoader: false,
    };
  }

  handleEmailChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });
    this.props.resetPassword(this.state.email);
  };

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.email
    ) {
      this.setState({ email: this.props.location.state.email });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.emailSentStatus != this.props.emailSentStatus &&
      this.props.emailSentStatus
    ) {
      if (this.props.emailSentStatus.data == 200) {
        this.setState({
          template: 'checkInboxTemplate',
          showLoader: false,
        });
      }
    }
    if (
      prevProps.emailSentError != this.props.emailSentError &&
      this.props.emailSentError
    ) {
      if (
        this.props.emailSentError &&
        this.props.emailSentError.message
      ) {
        prevProps.toastManager.add(
          this.props.emailSentError.message,
          {
            appearance: 'error',
            autoDismiss: false,
          }
        );
      }
    }
  }

  render() {
    const showLoader = this.state.showLoader ? (
      <span className='loader'>{<CircularProgressBar />}</span>
    ) : null;
    const forgotPasswordTemplate = (
      <>
        <h1 className='text-style'>Forgot Password </h1>
        <p className='emailText'>
          Enter the email address associated with your account.
        </p>
        <form className='login-form' onSubmit={this.onFormSubmit}>
          <div className='emailContainer'>
            <span className='emailLabel'>Email</span>
          </div>
          <br />
          <input
            required
            fullwidth
            id='email'
            name='email'
            autoComplete='email'
            className='text-format emailInput'
            type='email'
            placeholder='Email'
            value={this.state.email}
            onChange={this.handleEmailChange}
            pattern='^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$'
            title='Enter a valid email'
          />
          <div className='loaderContainer'>
            <button
              type='submit'
              variant='contained'
              color='#e41e26'
              size='large'
              className='mob-button submitButton'
              style={{
                backgroundColor: '#e41e26',
                color: '#ffffff',
                textAlign: 'center',
                textTransform: 'capitalize',
                fontWeight: 'bold',
              }}
              onClick={this.handleClick}>
              <span className='mob-text'> Submit </span>
            </button>
            {showLoader}
          </div>
          <div className='loginLink'>
            <Link href='/' variant='body2'>
              <img className='backToLogin' src={backToLogin}></img>
              Back To Login
            </Link>
          </div>
        </form>
      </>
    );

    const resendEmailTemplate = (
      <>
        <h1 className='text-style emailHeader'>Check your inbox </h1>
        <p className='resetEmailText'>
          We’ve sent an email to {this.state.email} in the email to
          create a new password.
        </p>
        <div className='resendEmailContainer'>
          <Link
            className='resendEmailLink linkLeftMargin'
            variant='body2'>
            Resend Link{' '}
          </Link>
          <br />
          <Link href='/' variant='body2' className='resendEmailLink'>
            <img className='backToLogin' src={backToLogin}></img>
            Back To Login
          </Link>
        </div>
      </>
    );

    let template;
    if (this.state.template == 'ForgotPasswordTemplate') {
      template = forgotPasswordTemplate;
    } else if (
      this.state.email &&
      this.state.template == 'checkInboxTemplate'
    ) {
      template = resendEmailTemplate;
    }

    return (
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className='set-margin set-border-bottom'
          style={{ display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={5}
            md={12}
            className='set-border'>
            <Container
              component='main'
              maxWidth='xs'
              className='align-center'>
              <CssBaseline />
              <div className='paper forgotPassword'>
                <div className='logoContainer'>
                  <Link href='/' variant='body2'>
                    <img src={Logo} alt='logo' />
                  </Link>
                </div>
                {template}
              </div>
            </Container>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              lg={7}
              md={12}
              className='MuiContainer-root align-center img-set set-border full-height'
              style={{ backgroundImage: `url(${loginimgg})` }}
            />
          </Hidden>
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  emailSentStatus: state.forgotPasswordReducer.response,
  emailSentError: state.errorReducer.error_data,
});
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) =>
    dispatch(getForgotPasswordEmailSaga(email)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ForgotPassword));
