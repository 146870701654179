import { put, takeLatest, call } from 'redux-saga/effects';

import axios from 'axios';
import { push } from 'react-router-redux';
import {
  GET_ORGANIZATION_DETAILS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setOrganizationDetails,
  setOrganizationRoles,
} from '../../actions/organizationAction';
import {
  getOrganizationDetails,
  getRoles,
} from '../../api/organizationApi';
//

import { history } from '../../store';
import {
  BASE_URL,
  APPLICATION_NAME,
  VERSION,
  APPLICATION_NAME_BE,
} from '../../configurations/config.js';

// IN watcher

const getAccessTokenApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/login`;

const getProfileApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profile`;

/*
 * const getOrganizationDetails= function () {
 *   // const response = await fetch(getOrganizationApi);
 *   // new Promise((resolve, reject) => {
 *     const access_token = localStorage.getItem('access_token');
 *     const headersProfile = 'Bearer ' + access_token;
 *     console.log("headersProfile",headersProfile);
 *             axios
 *             .get('https://bepsy-api.objectedge.com/oe_commerce_api/occ/v1/organization/1100001?profileId=173064', {
 *               headers: { Authorization: headersProfile, 'Content-Type': 'application/json' }
 *             })
 *             .then(function (response) {
 *               console.log("GET Response")
 *               console.log("GET Response",response);
 *               // data = response.data;
 *             })
 *             .catch(error => {
 *               console.log('error' + error);
 *             });
 */

// };

/*
 * function* authorize({ payload: { login, password } }) {
 *   try {
 *     const { token } = yield call(authMock, login, password);
 */

//     console.log('auth mok yield=> should contain access token in our case', token);

/*
 *     yield put({ type: AUTH_SUCCESS, payload: token });
 *     localStorage.setItem('token', token);
 *   } catch (error) {
 *     let message;
 *     switch (error.status) {
 *       case 500:
 *         message = 'Internal Server Error';
 *         break;
 *       case 401:
 *         message = 'Invalid credentials';
 *         break;
 *       default:
 *         message = 'Something went wrong';
 *     }
 *     yield put({ type: AUTH_FAILURE, payload: message });
 *     localStorage.removeItem('token');
 *   }
 * }
 */

/*
 * function* Saga() {
 *   yield takeLatest(AUTH_REQUEST, authorize);
 * }
 */

// export default Saga;

function* workerGetOrganizationDetailsSaga() {
  try {
    const organization_details = yield call(getOrganizationDetails);
    yield put(setOrganizationDetails(organization_details.data));
    const organization_roles = yield call(getRoles);
    yield put(setOrganizationRoles(organization_roles.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetOrganizationDetailsSaga() {
  yield takeLatest(
    GET_ORGANIZATION_DETAILS_SAGA,
    workerGetOrganizationDetailsSaga
  );
}
