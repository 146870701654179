import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';
import ContentLoader from 'react-content-loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import { SearchBox } from '../../containers/Account/Search-box/search-box.component';
import CustomAutoCompleteSearchTextField from '../Shared/customSearchTextField';
import Pagination from '../Shared/customPagination';
import ContactForm from './ContactFormDesktop';

export default function CompanyContactList({
  contacts,
  handleSearch,
  searchInput,
  searchError,
  isUserAdmin,
  handleContact,
  state,
  cancelContact,
  handleContactChange,
  submitContact,
  handleEditContact,
  refProp,
  handleSubmit,
  handleEnterSearch,
  currentPage,
  resultPerPage,
  resultPerPageChangeHandler,
  totalContactResult,
  roleList,
  message,
  clearSearchResult,
}) {
  return (
    <div className='inner-wrap externalContainer'>
      <div className='display-desktop'>
        <div className='contact-container'>
          <div id='address'>Contact List</div>
          <div id='search'>
            {/* <SearchBox
              placeholder='Search by name/email'
              handleChange={handleSearch}
              value={searchInput}
              handleSubmit={handleSubmit}
              handleEnterSearch={handleEnterSearch}
            /> */}
            <CustomAutoCompleteSearchTextField
              placeholder='Search by name/email'
              searchTextChangeHandler={handleSearch}
              searchEnterClickHandler={handleEnterSearch}
              searchIconClickHandler={handleSubmit}
              searchText={searchInput}
              searchError={searchError}
            />
            <Grid item sm={2} md={2} className='buttonContainer'>
              <button
                className='marginL'
                onClick={clearSearchResult}
                style={{
                  padding: '0px',
                  minWidth: '64px',
                  display:
                    searchInput && searchInput.length > 2
                      ? 'block'
                      : 'none',
                }}>
                {' '}
                <a> Clear</a>
              </button>
            </Grid>
          </div>
          {isUserAdmin ? (
            <div id='addNewAddress' onClick={handleContact}>
              <div style={{ marginTop: 2 }}>Add new user</div>
              <div style={{ marginLeft: 5 }}>
                <AddIcon />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              className='head-text text-bold set-padding'
              align='right'>
              First Name
            </TableCell>
            <TableCell
              className='head-text text-bold set-padding'
              align='right'>
              Last Name
            </TableCell>
            <TableCell
              className='head-text text-bold set-padding'
              align='right'>
              E-mail
            </TableCell>
            <TableCell
              className='head-text text-bold set-padding'
              align='right'>
              Role
            </TableCell>
            {isUserAdmin && (
              <TableCell
                className='head-text text-bold set-padding'
                lign='right'>
                Edit
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        {state.isContact ? (
          <ContactForm
            refProp={refProp}
            state={state}
            cancelContact={cancelContact}
            handleContactChange={handleContactChange}
            submitContact={submitContact}
            roleList={roleList}
          />
        ) : null}
        {message ? (
          <TableRow id='tableCenterData'>
            <TableCell colSpan={5} align='center'>
              <div>{message}</div>
            </TableCell>
          </TableRow>
        ) : (
          <TableBody>
            {contacts && contacts.length > 0 ? (
              contacts.map((row, i) => (
                <TableRow key={i} className='row-dimension'>
                  <TableCell className='set-padding' scope='row'>
                    {row.firstName}{' '}
                  </TableCell>
                  <TableCell className='set-padding' align='right'>
                    {row.lastName}
                  </TableCell>
                  <TableCell
                    className='set-padding'
                    align='right'
                    style={{
                      textTransform: 'lowercase',
                    }}>
                    {row.email}
                  </TableCell>
                  <TableCell className='set-padding' align='right'>
                    {row.contactTypeRole.map((role, j) => (
                      <div key={j}> {role.roleName} </div>
                    ))}
                  </TableCell>
                  {isUserAdmin && (
                    <TableCell
                      className='set-padding'
                      onClick={handleEditContact.bind(this, row)}
                      align='right'>
                      {/* <i> */}

                      <div style={{ cursor: 'pointer' }}>
                        <Edit />
                      </div>

                      {/* </i> */}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow id='tableCenterData'>
                <TableCell colSpan={5} align='center'>
                  <div>
                    <ContentLoader />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
          padding: 10,
        }}>
        <Pagination
          currentPage={currentPage}
          recordPerPage={resultPerPage}
          totalRecords={totalContactResult}
          handlePageClick={resultPerPageChangeHandler}
        />
      </div>
    </div>
  );
}
