import { SET_PROFILE } from '../constants';

const initialState = { profile: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    default:
      return state;
  }
}
