import React from 'react';
import { Link } from 'react-router-dom';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';

function Faq() {
  return (
    <PageLayoutHOC showSearch='false'>
      <div className='externalContainer contactPadding'>
        <div class='ak-renderer-document'>
          <h2>Frequently asked questions</h2>
          <p>
            <strong>How do I order a bike? </strong>
            <br />
            We have made our inventory available to all Bepsy
            retailers so that you find the right bike for you when you
            want it. In order to give you the best possible riding
            experience, your retailer will need at least 24 hours to
            build and fine-tune your new bike before it is ready for
            collection or delivery. There are no additional assembly
            charges for bikes. You will be contacted once it is ready
            for pick-up or delivery.
          </p>
          <p>
            <strong>
              How do I check on the status of my order?{' '}
            </strong>
            <br />
            To check the status of your order, please log into My
            Account for order and shipping information. You can also
            track your order through the tracking link in your
            shipping confirmation email.
          </p>
          <p>
            To speak to our customer service team, call 1-800-555-5555
            Monday through Friday 8 am to 6 pm (CST). You can also
            reach us through live chat Monday through Friday 8 am to 8
            pm and Saturday and Sunday 10 am to 4 pm (CST).
          </p>
          <p>
            <strong>How do I change or cancel my order?</strong>
            <br />
            You may cancel an existing order through the Orders
            section of your profile.
          </p>
          <p>
            <strong>What do I do if my order is declined?</strong>
            <br />
            If your order has been declined, please check with the
            financial institution that issued the credit card for
            additional information.
          </p>
          <p>
            <strong>How do I track my order?</strong>
            <br />
            You will receive a shipping confirmation with a tracking
            link after placing your order. You may also log into your
            account for delivery status.
          </p>
          <p>
            <strong>Can I ship internationally? </strong>
            <br />
            At this time, we only offer shipping to addresses within
            the United States. We do not ship to freight forwarding
            addresses.
          </p>
          <p>
            <strong>Can I ship to an APO or post office box?</strong>
            <br />
            At this time, we can only ship to business addresses. If
            you have a PO Box, consider shipping your order to a
            retailer for free!
          </p>
          <p>
            <strong>Can I return or exchange my order? </strong>
            <br />
            To make a return, please follow these guidelines:
          </p>
          <p>
            To qualify for a return:
            <br />
            Returns will be accepted within 30 days of purchase.
            <br />
            The item(s) must be in new, unused condition with original
            packaging.
            <br />
            Proof of purchase required.
            <br />A refund will be applied to the original card used
            in the transaction.
          </p>
          <p>
            Return to Bepsy retailer:
            <br />
            Bring your order, with proof of purchase, to your local
            Bepsy retailer.{' '}
          </p>
          <p>
            Return by mail:
            <br />
            Return shipping is free to customers with registered
            accounts. Log in to your account to print a prepaid
            shipping label. If you do not have an account, mail your
            return with the original packing slip to the Bepsy address
            printed on your packing slip. We suggest return by a
            carrier that can track delivery, such as UPS, FedEx, or
            Registered USPS mail. You are responsible for all return
            shipping charges if you checked out as a guest at{' '}
            <a
              class='sc-bIKvTM caJXDt'
              href='http://bepsy.com'
              title='http://bepsy.com'>
              bepsy.com
            </a>
            .{' '}
          </p>
          <p>
            You will see a credit on your credit card account within
            30 days. However, most of our customers will see it within
            4-10 business days.
          </p>
          <p>
            Bike/frameset returns:
            <br />
            Bikes &amp; framesets can be returned within 30 days if in
            new and unused condition. When you pick up your order or
            have it delivered, your Bepsy retailer will review the
            purchase with you and make sure it meets your
            expectations. Questions regarding your purchase should be
            directed to the Bepsy retailer of delivery. Bepsy
            warehouses will not accept returned bikes or framesets.
          </p>
          <p>
            Exchanges:
            <br />
            <a
              class='sc-bIKvTM caJXDt'
              href='http://bepsy.com'
              title='http://bepsy.com'>
              bepsy.com
            </a>{' '}
            offers refunds rather than exchanges. If you need another
            item in a different size or color, or different product
            altogether, please place a new order and follow the return
            procedure to receive a credit for your original purchase.
          </p>
          <p>
            <strong>When will I see my credit return? </strong>
            <br />
            Upon confirmation of your return, you should see the
            credit within 30 days. However, most customers see it in
            4-10 business days.
          </p>
          <p>
            <strong>
              There was an error in my order, will my shipping charge
              be refunded?
            </strong>
            <br />
            If there was an error on your order such as wrong item
            shipped or damage during shipping, Bepsy will credit your
            original shipping charges (if applicable). Call us at
            1-800-555-5555.
          </p>
          <p>Shipping charges will be refunded if Bepsy:</p>
          <p>
            1) ships the incorrect merchandise; 2) the merchandise was
            shipped damaged or defective; 3) the merchandise was
            damaged in shipping.
          </p>
          <p>
            <strong>How do I create an account? </strong>
            <br />
            Creating an account at{' '}
            <a
              class='sc-bIKvTM caJXDt'
              href='http://bepsy.com'
              title='http://bepsy.com'>
              http://bepsy.com
            </a>{' '}
            is easy. Click on Login/Register anytime.{' '}
          </p>
          <p>
            <strong>How do I update my account? </strong>
            <br />
            You can change your account information by selecting
            Login/Register. Enter your email address and password to
            view your account information. You can update your email
            address, name, password, and billing/shipping information.
          </p>
          <p>
            <strong>What forms of payment do you accept?</strong>{' '}
            <br />
            Forms of payment accepted
            <br />
            Invoice, AHC: Visa, MasterCard, and Amex
          </p>
          <p>&nbsp;</p>
          <p>Still can’t find what you’re looking for? </p>
          <p>
            <strong>
              <u>Contact us</u>
            </strong>
            <br />
            Bepsy is dedicated to ensuring you love cycling as much as
            we do. So if you need to speak to a person, chat online,
            send us an email, and get questions answered, we’re here
            for you. To speak to our customer service team, call
            1-800-555-5555 Monday through Friday 8 am to 6 pm (CST).
            You can also reach us through live chat Monday through
            Friday 8 am to 10 pm and Saturday and Sunday 9 am to 6 pm
            (CST).
          </p>
          <p>
            Get in touch <Link to='/contact'>Contact Us</Link>
          </p>
        </div>
      </div>
    </PageLayoutHOC>
  );
}

export default Faq;
