import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_UPDATE_PASSWORD_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setUpdatePassword } from '../../actions/profileAction';
import { getupdatePassword } from '../../api/profileApi';

function* workerGetUpdatedPasswordSaga({
  payload: { oldPassword, newPassword, newConfirmPassword },
}) {
  try {
    const updatedPassword = yield call(
      getupdatePassword,
      oldPassword,
      newPassword,
      newConfirmPassword
    );
    yield put(setUpdatePassword(updatedPassword));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetUpdatedPasswordSaga() {
  yield takeLatest(
    GET_UPDATE_PASSWORD_SAGA,
    workerGetUpdatedPasswordSaga
  );
}
