import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OrderDetails from '../../components/Details/detail';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { getQuoteDetails } from '../../api/quoteApi';
import { getQuoteDetails as getQuoteDetailsAction, getRejectQuoteData } from '../../actions/quoteAction';

function QuoteDetailsContainer(props) {
  const quoteId = props.match.params.id;
  const [order, setOrder] = useState();

  const handleReject = () => {
    props.getRejectQuoteData(order);
    setOrder({
      ...order,
      orderStatus: 'closed',
      orderStatusDisplay: 'Rejected',
    });
  };

  // Can use internal state since no redux selectors are used inside of QuoteDetails
  useEffect(() => {
    props.getQuoteDetails({ sfid: quoteId });
    getQuoteDetails({ sfid: quoteId })
      .then((res) => {
        const orderDetails = res.data.orderList[0];
        setOrder(orderDetails);
      });
  }, [quoteId]);

  return (
    <PageLayoutHOC {...props} showSearch='false'>
      <OrderDetails
        order={{
          order_detail: order
        }}
        rejectQuote={handleReject}
      />
    </PageLayoutHOC>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  getQuoteDetails: (quoteId) => dispatch(getQuoteDetailsAction(quoteId)),
  getRejectQuoteData: (quoteId) => dispatch(getRejectQuoteData(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDetailsContainer);