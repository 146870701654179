import { put, takeLatest, call, select } from 'redux-saga/effects';

import {
  ADD_TO_CART_SAGA,
  ADD_TO_CART_DONE,
  ADD_TO_CART_ERROR_MASG,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setCartData,
  addToCartState,
} from '../../actions/cartItemAction';
import { addToCart } from '../../api/cartApi';

function* workerAddToCart({ payload: { skuId, quantity } }) {
  const id = yield select((state) => state.productReducer.productId);
  try {
    const response = yield call(addToCart, skuId, quantity, id);
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;
    if (data) {
      yield put(addToCartState(ADD_TO_CART_DONE));
    } else {
      yield put(addToCartState(ADD_TO_CART_ERROR_MASG));
    }

    yield put(setCartData(data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchAddToCartSaga() {
  yield takeLatest(ADD_TO_CART_SAGA, workerAddToCart);
}
