import {
  ADD_CONTACTS,
  UPDATE_CONTACT,
  SEARCH_CONTACT_DATA,
} from '../constants';

export function addContactSaga(data) {
  return {
    type: ADD_CONTACTS,
    data,
  };
}

export function updateContact(data) {
  return {
    type: UPDATE_CONTACT,
    data,
  };
}

export function getSearchContact(data) {
  return {
    type: SEARCH_CONTACT_DATA,
    data,
  };
}
