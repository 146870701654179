import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import SearchFacet from './searchFacet';
import ListingHeaderFilter from './listingHeaderFilter';
import ListingPanel from './listingPanel';
import Pagination from '../Shared/customPagination';
import './style.css';
import { NO_SEARCH_RECORD } from '../../constants';
import CustomPageModal from '../Shared/customPageModal';
import ProductBrief from '../../containers/ProductDetailsPage/productBrief';
import CustomLoader from '../Shared/customLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
  },
  paper: {
    padding: '0',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const productListing = ({
  listingData,
  adjustments,
  selectedSort,
  sortByChangeHandler,
  resultPerPage,
  resultPerPageChangeHandler,
  filterOptionClickHandler,
  categoryClickHandler,
  clearAllFilter,
  currentPage,
  pageChangeClickHandler,
  isMobileFilterOpen,
  mobilerFilterClickHandler,
  mobileFilterApplyHandler,
  buttonClickHandler,
  category,
  scrolled,
  showModal,
  isModalOpen,
  content,
  toggleView,
  activeView,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {listingData && listingData.response && (
        // listingData.response.totalRecords > 0 && (
        <div className={classes.root}>
          <Grid
            item
            container
            spacing={5}
            style={{ margin: 0, width: '100%' }}>
            <Hidden only={['xs', 'sm', 'md']}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <div className='listing-left-panel'>
                  <SearchFacet
                    facetData={listingData.facets}
                    adjustments={adjustments}
                    filterOptionClickHandler={
                      filterOptionClickHandler
                    }
                    categoryClickHandler={categoryClickHandler}
                    clearAllFilter={clearAllFilter}
                  />
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
              <div className='listing-right-panel'>
                <ListingHeaderFilter
                  response={listingData.response}
                  sortOptions={listingData.sortOptions}
                  selectedSort={selectedSort}
                  sortByChangeHandler={sortByChangeHandler}
                  resultPerPage={resultPerPage}
                  resultPerPageChangeHandler={
                    resultPerPageChangeHandler
                  }
                  isMobileFilterOpen={isMobileFilterOpen}
                  mobilerFilterClickHandler={
                    mobilerFilterClickHandler
                  }
                  facetData={listingData.facets}
                  filterOptionClickHandler={filterOptionClickHandler}
                  categoryClickHandler={categoryClickHandler}
                  currentPage={currentPage}
                  pageChangeClickHandler={pageChangeClickHandler}
                  clearAllFilter={clearAllFilter}
                  mobileFilterApplyHandler={mobileFilterApplyHandler}
                  adjustments={adjustments}
                  category={category}
                  toggleView={toggleView}
                  activeView={activeView}
                />
                {listingData !== null &&
                listingData.response.records.length > 0 ? (
                  <ListingPanel
                    listingData={listingData.response.records}
                    buttonClickHandler={buttonClickHandler}
                    showModal={showModal}
                    isModalOpen={isModalOpen}
                    content={content}
                    activeView={activeView}
                  />
                ) : (
                  <p className='no-data-found'>{NO_SEARCH_RECORD}</p>
                )}
              </div>
            </Grid>

            <CustomPageModal show={isModalOpen} showModal={showModal}>
              {content[0] ? <ProductBrief /> : <CustomLoader />}
            </CustomPageModal>

            <Hidden only={['lg', 'xl']}>
              <div
                style={{
                  margin: 'auto',
                  textAlign: 'right',
                  display: 'inline-block',
                }}>
                <Pagination
                  currentPage={currentPage}
                  recordPerPage={resultPerPage}
                  totalRecords={listingData.response.totalRecords}
                  handlePageClick={pageChangeClickHandler}
                />
              </div>
            </Hidden>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};

productListing.propTypes = {};

export default productListing;
