import React from 'react';
import {
  makeStyles,
  withStyles,
  fade,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    padding: 0,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#e41e26',
      boxShadow: `${fade('#e41e26', 0.25)} 0 0 0 0.2rem`,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
    padding: 0,
  },
}));

const customizedSelectCountry = ({
  name,
  variants,
  selectedVariant,
  variantChange,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    variants && (
      <div className='select-input'>
        <FormControl>
          <Select
            native
            name={name}
            value={selectedVariant}
            onChange={variantChange}
            input={<BootstrapInput id='customized-select-country' />}>
            <option value={-1}>{defaultValue}</option>
            {variants !== undefined &&
              variants.map((e, i) => (
                <option value={e.code} key={i}>
                  {e.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  );
};

export default customizedSelectCountry;
