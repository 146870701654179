import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Divider, Button, IconButton,Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { withToastManager } from 'react-toast-notifications';
import { Redirect } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import PageLayout from '../../HOC/PageLayoutHOC';
import OrderSummary from '../../components/OrderSummary';
import OrdersList from '../../components/OrdersList';
import Print from '../../components/Print';
import {
  updateCartSaga,
  removeFromCartSaga,
  updateItemQuantity,
} from '../../actions/cartItemAction';
import './Styles.css';
import { getOrganizationDetailsSaga } from '../../actions/organizationAction';
import { getMultiInventoryValidationSaga } from '../../actions/quickOrderAction';
import CustompageModal from '../../components/Shared/customPageModal';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      gotoCheckout: false,
      isRemoved: false,
      isUpdate: false,
      isModalOpen: false,
      isValidationRequire: true,
      orderDetails: {},
    };
    this.toastManager = props.toastManager;
    this.dataToPrint = '';
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { cart, inventoryData } = this.props;
    const { isValidationRequire, isRemoved, isUpdate } = this.state;
    if (cart && isValidationRequire && isUpdate) {
      this.getInventory();
    }
    if (inventoryData) {
      if (!inventoryData.success) {
        inventoryData.items.map((product) => {
          let element = document.getElementById(product.skuId);
          if (element) element.classList.add('highLightElement');
        });
      }
    }
    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];

      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: true,
      });
      this.setState({
        isLoading: false,
        isUpdate: false,
      });
    }
    if (cart !== prevProps.cart) {
      this.setState({
        isLoading: false,
      });
      this.getInventory();
      //  show toast on item removed
      if (this.props.updateCartState && isRemoved) {
        this.toastManager.add('Item removed from cart', {
          appearance: 'warning',
          autoDismiss: true,
        });
        this.setState({
          isRemoved: false,
          isLoading: false,
        });
      }
      //  show toast on item update
      if (this.props.updateCartState && isUpdate) {
        this.toastManager.add('Item updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({
          isUpdate: false,
          isLoading: false,
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout();
  }

  getInventory = () => {
    const { cart, getMultiInventoryValidationSaga } = this.props;
    if (cart && cart.EOrderItems.length > 0) {
      let items = cart.EOrderItems.map((product) => {
        return {
          skuId: product.sku,
          productId: product.product,
          quantity: product.quantity,
        };
      });
      const reqBody = {
        items: items,
      };
      getMultiInventoryValidationSaga(reqBody);
    }

    this.setState({ isValidationRequire: false });
  };

  handleUpdate = (orderDetails) => {
    this.setState({
      isLoading: true,
      isUpdate: true,
    });

    this.props.updateCartSaga(
      orderDetails.sku,
      orderDetails.order,
      orderDetails.product,
      orderDetails.quantity,
      orderDetails.sfid
    );
    this.setState({ isValidationRequire: true });
  };

  renderButton = () => {
    let prods = [];
    const { cart } = this.props;
    if (cart) {
      cart.EOrderItems.map((item, index) => {
        prods.push({
          productName: item.productName,
          SKU: item.sku,
          listPrice: item.listPrice.toFixed(2),
          quantity: item.quantity,
          finalPrice: item.finalItemAmount.toFixed(2),
        });
      });
      this.dataToPrint = {
        products: prods,
        cartSummary: {
          subTotal: cart.finalSubTotalAmount.toFixed(2),
          tax: cart.finalTaxAmount.toFixed(2),
          shippingCharges: cart.finalShippingAmount.toFixed(2),
          discount: cart.orderDiscountAmount.toFixed(2),
          finalTotal: cart.finalOrderAmount.toFixed(2),
        },
        orderNumber: cart.orderNumber,
      };
    }
    return (
      <IconButton disabled={!cart}>
      <Hidden smDown>
        <PrintIcon />
         </Hidden>
      </IconButton>
     
    );
  };
  handleRemove = () => {
    this.setState({
      isLoading: true,
      isRemoved: true,
      isModalOpen: false,
    });
    const orderDetails = this.state.orderDetails;
    this.props.removeFromCartSaga(
      orderDetails.sku,
      orderDetails.order,
      orderDetails.product,
      orderDetails.quantity,
      orderDetails.sfid
    );
  };

  toggleRemove = (orderDetails) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      orderDetails: orderDetails,
    });
  };

  updateItemQuantityCart = (cart, skuId, quantity, action) => {
    this.props.updateItemQuantity(cart, skuId, quantity, action);
  };

  handleCheckout = () => {
    this.setState({
      gotoCheckout: true,
    });
  };

  render() {
    const { cart } = this.props;
    let isAllProductsAvailable = true;
    if (this.state.gotoCheckout) {
      return <Redirect to='/checkout' />;
    }
    if (this.props.inventoryData) {
      if (!this.props.inventoryData.success) {
        isAllProductsAvailable = false;
      }
    }
    return (
      <PageLayout {...this.props}>
        <div className='cartWrapper'>
          <div className='ordersTopRow'>
            <Grid container direction='row' justify='space-between'>
              <Grid item />
            </Grid>
          </div>

          <Grid
            container
            className='externalContainer checkoutContainer'>
            <Grid container direction='row'>
              <Grid item lg={12} md={12} className='paddedContent'>
                <div className='flexDisplay'>
                  <h2 className='orderHeading'>Cart</h2>
                  <ReactToPrint
                    trigger={this.renderButton}
                    content={() => this.componentRef}
                  />
                  <div style={{ display: 'none' }}>
                    <Print
                      data={this.dataToPrint}
                      ref={(el) => (this.componentRef = el)}
                    />
                  </div>
                </div>

                <Divider />
              </Grid>

              <Grid item lg={3} md={3} />
            </Grid>

            {this.state.isLoading ? (
              <Grid className='paddedContent'>
                <ContentLoader />
              </Grid>
            ) : (
              <Grid container direction='row' justify='flex-end'>
                <Grid item lg={12} md={12} className='paddedContent'>
                  <OrdersList
                    order={cart}
                    displayQuantityIncr={true}
                    displayRemoveBtn={true}
                    handleRemove={this.toggleRemove}
                    handleUpdate={this.handleUpdate}
                    updateItemQuantity={this.updateItemQuantityCart}
                    showLoader={this.state.isLoading}
                  />
                </Grid>
                {cart !== null && (
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <OrderSummary
                      summary={cart}
                      placeOrder={this.handleCheckout}
                      orderSummaryLabel='Checkout'
                      isAllProductsAvailable={isAllProductsAvailable}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid>
              <Link to='/'>
                <Button>Continue Shopping</Button>
              </Link>
            </Grid>
          </Grid>
        </div>
        <div id='cart'>
          <CustompageModal
            show={this.state.isModalOpen}
            showModal={this.toggleRemove}>
            <div className='alert-text-container'>
              <div className='alert-text'>
                Are you sure want to remove this item?
              </div>
              <div className='bepsy-alert-button'>
                <Button color='secondary' onClick={this.toggleRemove}>
                  Cancel
                </Button>
                <Button onClick={this.handleRemove}>Remove</Button>
              </div>
            </div>
          </CustompageModal>
        </div>
      </PageLayout>
    );
  }
}
Cart.propTypes = {
  updateCartState: PropTypes.bool.isRequired,
  // cart: PropTypes.object.isRequired,
  toastManager: PropTypes.object.isRequired,
  updateCartSaga: PropTypes.func.isRequired,
  removeFromCartSaga: PropTypes.func.isRequired,
  updateItemQuantity: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  inventoryData: state.quickOrderReducer.inventoryData,
  cart: state.orderReducer.cart,
  organization: state.organizationDetailsReducer.organization_details,
  updateCartState: state.orderReducer.updateCartState,
  errorResponse: [state.errorReducer.error_data],
});

const mapDispatchToProps = (dispatch) => ({
  getMultiInventoryValidationSaga: (products) =>
    dispatch(getMultiInventoryValidationSaga(products)),
  getOrganizationDetailsSaga: () =>
    dispatch(getOrganizationDetailsSaga()),
  updateCartSaga: (
    skuId,
    orderId,
    productId,
    quantity,
    commerceItemId
  ) =>
    dispatch(
      updateCartSaga(
        skuId,
        orderId,
        productId,
        quantity,
        commerceItemId
      )
    ),

  removeFromCartSaga: (
    skuId,
    orderId,
    productId,
    quantity,
    commerceItemId
  ) =>
    dispatch(
      removeFromCartSaga(
        skuId,
        orderId,
        productId,
        quantity,
        commerceItemId
      )
    ),
  updateItemQuantity: (order, skuId, quantity, action) =>
    dispatch(updateItemQuantity(order, skuId, quantity, action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Cart));
