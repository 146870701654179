import { put, takeLatest, call, select } from 'redux-saga/effects';

import {
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_FAILED,
  SET_ERROR_STATE,
} from '../../constants';
import {
  submitOrder,
  submitOrderSuccess,
  submitOrderFailed,
} from '../../actions/orderAction';
import { submitOrderApi } from '../../api/orderApi';

function* workerOrderPlaced({ payload: { requestBody } }) {
  try {
    const orgData = yield select(
      (state) => state.organizationDetailsReducer.orgData
    );
    const response = yield call(submitOrderApi, requestBody);
    yield put(submitOrderSuccess(response.data));
    if (response.data.orderList.length > 0) {
      sessionStorage.setItem(
        'orderDetails',
        JSON.stringify(response.data.orderList[0])
      );
      let retrievedObject = localStorage.getItem('context');
      retrievedObject = JSON.parse(retrievedObject);

      retrievedObject.orderId = null;
      localStorage.setItem(
        'context',
        JSON.stringify(retrievedObject)
      );
      window.location.href = '/orderConfirmation';
    } else {
      submitOrderFailed(true);
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchOrderPlaced() {
  yield takeLatest(SUBMIT_ORDER, workerOrderPlaced);
}
