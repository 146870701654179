import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_FORGOT_PASSWORD_EMAIL_SAGA,
  SET_FORGOT_PASSWORD_EMAIL_SAGA,
  SET_ERROR_STATE,
} from '../../constants';

import { setForgotPasswordEmailSaga } from '../../actions/forgotPasswordAction';
import { forgotPasswordApi } from '../../api/forgotPasswordApi';

function* workerForgotPaswordSaga(email) {
  try {
    const response = yield call(forgotPasswordApi, email);
    yield put(setForgotPasswordEmailSaga(response.status));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchForgotPaswordSaga() {
  yield takeLatest(
    GET_FORGOT_PASSWORD_EMAIL_SAGA,
    workerForgotPaswordSaga
  );
}
