import React from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  addressForm: {
    padding: '20px',
  },
}));

const PaymentOptions = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction='row'>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          item
          lg={12}
          md={12}
          sm={6}
          xs={12}
          className={classes.addressForm}>
          <RadioGroup value='invoice' row>
            <FormControlLabel
              value='invoice'
              control={<Radio color='primary' />}
              label='Invoice'
              labelPlacement='end'
            />
          </RadioGroup>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='po-container'>
              <h3> Purchase Order </h3>{' '}
              <TextField
                type='text'
                // inputRef={(po) => (this.po = po)}
                variant='outlined'
                inputProps={{
                  maxLength: 10,
                  placeholder: 'Purchase Order Number',
                  value: props.purchaseOrderNumber,
                  id: 'padding',
                  onChange: props.purchaseOrderNumberChangeHandler,
                }}
              />
            </div>
          </Grid>

          {props.poOrderSet ? (
            <div
              style={{
                padding: '20px',
                display: 'flex',
              }}>
              <span>Invoice Credit Terms: </span>{' '}
              <select
                name='invoiceTerm'
                onChange={props.handleSelect}
                value={props.invoiceTermList}
                className='selectDropInvoice'
                default={30}>
                <option value='30'>30 days</option>
                <option value='60'>60 days</option>
                <option value='90'>90 days</option>
              </select>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentOptions;
