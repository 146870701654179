import {
  AUTH_FAILURE,
  AUTH_REQUEST,
  AUTH_SUCCESS,
} from '../constants';

// Added for Auth
export function setAuthRequestSaga(basic_token) {
  return {
    type: AUTH_REQUEST,
    basic_token,
  };
}

// Added for Auth
export function setAuthSuccessSaga(access_token) {
  return {
    type: AUTH_SUCCESS,
    access_token,
  };
}

// Added for Auth
export function setAuthFailureSaga(access_token) {
  return {
    type: AUTH_FAILURE,
    access_token,
  };
}

export function authorize(login, password, siteId) {
  return {
    type: AUTH_REQUEST,
    payload: { login, password, siteId },
  };
}
