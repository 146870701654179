import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Grid,
} from '@material-ui/core';
import Logo from '../../assets/Logo/logo.png';
import './Styles.css';

class Print extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    console.log("data PRINT", data);
    return (
      <Grid container id='toPrint' className='outer-container-print'>
        <Grid container className='marginBottom logoPadding'>
          <span>
            <img src={Logo} alt='logo' />
          </span>
        </Grid>
        <Grid container className='marginBottom'>
          <Grid item xs={6}>
            <small>3500 Lacey Rd #200,</small>
            <br />
            <small>Downers Grove ,IL</small>
            <br />
            <small>60515</small>
          </Grid>
          {data.invoiceDetails ? (
            <Grid item xs={6}>
              <small className='paymentStatus'>
                Wire information,
              </small>
              <br />
              <small className='paymentStatus'>
                Account: 12345678
              </small>
              <br />
              <small className='paymentStatus'>
                Routing: 12345678
              </small>
            </Grid>
          ) : null}
          <div className='borderBottom'></div>
        </Grid>
        <Grid container className='marginBottom dividerClass' />
        {data.invoiceDetails ? (
          <Grid container className='marginBottom logoPadding'>
            <Grid item xs={6}>
              Invoice # :<small>{data.invoiceDetails.invoice}</small>
            </Grid>
            <Grid item xs={6}>
              <small className='paymentStatus'>
                Invoice Credit Terms:{' '}
                {data.invoiceDetails.creditTerms} days{' '}
              </small>
            </Grid>
            <Grid item xs={6}>
              <small>Associated Quote :Lorem Ipsum</small>
              <br />
              <small>Order # :{data.invoiceDetails.order}</small>
            </Grid>
            <Grid item xs={6}>
              <small className='paymentStatus'>
                Date :{data.invoiceDetails.dateIssued}
              </small>
              <br />
              <small className='paymentStatus'>
                Due Date :{data.invoiceDetails.dateDue}
              </small>
            </Grid>
            <div className='borderBottom'></div>
          </Grid>
        ) : null}
        {data.orderNumber ? (
          <Grid container className='marginBottom'>
            <Grid item xs={6}>
              Order # :<small>{data.orderNumber}</small>
            </Grid>
            <div className='borderBottom'></div>
          </Grid>
        ) : null}
        {data ? (
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h5>Item</h5>
                    </TableCell>
                    <TableCell>
                      <h5>Quantity</h5>
                    </TableCell>
                    <TableCell>
                      <h5>Unit Price</h5>
                    </TableCell>
                    <TableCell>
                      <h5>Amount</h5>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.products
                    ? data.products.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <b>{item.productName}</b>
                            <br />
                            SKU:{item.SKU}
                          </TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>${item.listPrice}</TableCell>
                          <TableCell>${item.finalPrice}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </Grid>
            {data.address ? (
              <Grid className='container-print'>
                {
                  data.address.billingAddress && data.address.billingAddress.address1 ? (
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className='inner-container-print'>
                    <div>
                      <strong>
                        Bill To: {data.address.billingAddress.billTo}
                      </strong>
                    </div>
                    <div>{data.address.billingAddress.address1}</div>
                    <div>{data.address.billingAddress.city}</div>
                    <div>
                      {data.address.billingAddress.postalCode}
                    </div>
                    <div>{data.address.billingAddress.country}</div>
                  </Grid>
                ) : null}
                {
                  data.address.shippingAddress && data.address.shippingAddress.address1 ? (
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className='inner-container-print'>
                    <div>
                      <strong>
                        Ship To: {data.address.shippingAddress.billTo}
                      </strong>
                    </div>
                    <div>{data.address.shippingAddress.address1}</div>
                    <div>{data.address.shippingAddress.city}</div>
                    <div>
                      {data.address.shippingAddress.postalCode}
                    </div>
                    <div>{data.address.shippingAddress.country}</div>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {data.cartSummary ? (
              <Grid
                className='container-print'
                container
                direction='row'>
                {data.invoiceDetails && (
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Grid>
                      Customer ID : {data.invoiceDetails.customerId}
                    </Grid>
                    <Grid>
                      Sales Person ID :{' '}
                      {data.invoiceDetails.salesPersonId}
                    </Grid>
                    <Grid>
                      Shipping Method :{' '}
                      {data.invoiceDetails.shippingMethod}
                    </Grid>
                    <Grid className='order-summary-wrapper'>
                      <div className='summary-title headers'>
                        Comments
                      </div>
                      <Grid className='dividerClass marginFromTop' />
                      It is a long established fact that a reader will
                      be distracted by the readable content of a page
                      when looking at its layout.
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className='inner-container-print'>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className='table-cell'>
                          <h5>Sub Total</h5>
                        </TableCell>
                        <TableCell className='table-cell'>
                          $ {data.cartSummary.subTotal}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='table-cell'>
                          <h5>Tax</h5>
                        </TableCell>
                        <TableCell className='table-cell'>
                          ${' '}
                          {data.cartSummary.tax
                            ? data.cartSummary.tax
                            : '0.00'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='table-cell'>
                          <h5>Shipping</h5>
                        </TableCell>
                        <TableCell className='table-cell'>
                          $ {data.cartSummary.shippingCharges}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='table-cell'>
                          <h5>Discounts</h5>
                        </TableCell>
                        <TableCell className='table-cell'>
                          $ {data.cartSummary.discount}
                        </TableCell>
                      </TableRow>
                      {data.cartSummary.tradeDiscount ? (
                        <TableRow>
                          <TableCell className='table-cell'>
                            <h5>Trade Discounts:</h5>
                          </TableCell>
                          <TableCell className='table-cell'>
                            $ {
                              data.cartSummary.discount
                            }
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {data.cartSummary.freightCost ? (
                        <TableRow>
                          <TableCell className='table-cell'>
                            <h5>Freight Costs:</h5>
                          </TableCell>
                          <TableCell className='table-cell'>
                            $ 0
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {data.cartSummary.miscellaneous ? (
                        <TableRow>
                          <TableCell className='table-cell'>
                            <h5>Miscellaneous Costs:</h5>
                          </TableCell>
                          <TableCell className='table-cell'>
                            $ 0
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell className='table-cell'>
                          <h5>Order Total</h5>
                        </TableCell>
                        <TableCell className='table-cell'>
                          <h5>$ {data.cartSummary.finalTotal}</h5>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default Print;
