import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  getProductSearchDataSaga,
  getCategorySearchData,
  setProductCategoryData,
  clearCategoryData,
  setSearchTerm,
  clearSearchResult,
  getOptionList,
  setOptionList,
} from '../../actions';
import {
  getProductSaga,
  clearProduct,
} from '../../actions/productListingAction';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import ProductListing from '../../components/ProductSearchPage/productListing';
import ContentLoader from 'react-content-loader';
import { NO_SEARCH_RECORD } from '../../constants';
import Banner from '../../components/HeroBanner';
import './style.css';

class ProductSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      selectedSort: 'new asc',
      resultPerPage: 20,
      clearAll: false,
      currentPage: 0,
      view: 'grid',
      adjustments: {
        term: '*',
        sortBy: 'new asc',
        page: 0,
        recordsPerPage: 20,
        heirarchical: [],
        multiselect: [],
        singleselect: [],
        range: [],
      },
      isMobileFilterOpen: false,
      selectedProduct: null,
      searchError: false,
      apply: false,
      isModalOpen: false,
      activeOption: 0,
    };
  }

  componentDidMount() {
    const searchTerm = this.props.location.search.replace(
      '?query=',
      ''
    );
    const adjustments = this.state.adjustments;
    this.props.setSearchTerm(searchTerm);
    if (searchTerm) {
      if (!searchTerm.includes('&category=true')) {
        this.setState({ searchText: decodeURI(searchTerm) });
      }
      adjustments.term = searchTerm;
      this.props.getProductSearchDataSaga(searchTerm, adjustments);
    } else {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const adjustments =
        (this.props.productListing[0].listingData &&
          this.props.productListing[0].listingData.adjustments) ||
        this.state.adjustments;
      adjustments.term = this.props.location.search.replace(
        '?query=',
        ''
      );
      this.props.clearSearchResult();
      this.props.setSearchTerm(adjustments.term);
      this.props.getProductSearchDataSaga(
        adjustments.term,
        adjustments
      );
      //  set and clear adjustments after getting the response from productlisting;
      this.setState({ adjustments }, () => {
        this.clearAllFilter();
      });
    }
  }

  searchTextChangeHandler = (e) => {
    let searchText = e.target.value;
    let adjustments = this.state.adjustments;
    adjustments.term = searchText.trim();
    this.setState({
      searchError: false,
      searchText: searchText,
      activeOption: 0,
    }); //  change searchText position from condition scope to outside.
    if (searchText && searchText.trim().length > 2) {
      this.props.getOptionList(searchText);
      this.setState(
        {
          adjustments,
        },
        () => this.props.setSearchTerm(searchText.trim())
      );
    }
    this.props.clearOptions();
  };

  searchEnterClickHandler = (e) => {
    let searchText = e.target.value;
    const { activeOption } = this.state;
    const { optionList } = this.props;
    let options = optionList ? optionList.response.suggestions : null;
    if (e.keyCode === 13) {
      if (searchText && searchText.trim().length > 2) {
        const searchTerm = this.props.productListing[0].searchTerm;
        const adjustments = this.state.adjustments;
        adjustments.term = searchText;
        adjustments.range = [];
        adjustments.heirarchical = [];
        adjustments.singleselect = [];
        adjustments.multiselect = [];
        adjustments.page = 0;
        adjustments.sortBy = 'new asc';
        this.setState({
          adjustments,
          selectedSort: adjustments.sortBy,
        });
        this.props.clearCategoryData();
        this.props.clearSearchResult();
        this.props.getProductSearchDataSaga(null, adjustments);
        this.props.history.push(
          `search?query=${this.state.searchText}`
        );
      } else {
        this.setState({ searchError: true });
      }
      this.props.clearOptions();
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState(
        {
          activeOption: activeOption - 1,
          searchText: options[activeOption - 1].term,
        },
        () => this.props.setSearchTerm(this.state.searchText)
      );
    } else if (e.keyCode === 40) {
      if (activeOption === options.length - 1) {
        return;
      }
      this.setState(
        {
          activeOption: activeOption + 1,
          searchText: options && options[activeOption + 1].term,
        },
        () => this.props.setSearchTerm(this.state.searchText)
      );
    }
  };

  searchIconClickHandler = (e) => {
    const { searchText } = this.state;
    if (searchText && searchText.trim().length > 2) {
      const searchTerm = this.props.productListing[0].searchTerm;
      const adjustments = this.state.adjustments;
      adjustments.term = searchTerm;
      adjustments.range = [];
      adjustments.heirarchical = [];
      adjustments.singleselect = [];
      adjustments.multiselect = [];
      adjustments.page = 0;
      adjustments.sortBy = 'new asc';
      this.setState({
        adjustments,
        searchError: false,
        selectedSort: adjustments.sortBy,
      });
      this.props.clearCategoryData();
      this.props.clearSearchResult();
      this.props.getProductSearchDataSaga(null, adjustments);
      this.props.clearOptions();
      this.props.history.push(
        `search?query=${this.state.searchText}`
      );
    } else {
      this.setState({ searchError: true });
    }
  };

  getOption = (e) => {
    this.setState({ searchText: e.term }, () =>
      this.searchIconClickHandler()
    );
    this.props.setSearchTerm(e.term);
  };

  sortByChangeHandler = (e) => {
    let adjustments = this.state.adjustments;
    adjustments.sortBy = e.target.value;
    adjustments.page = 0;
    this.setState({
      selectedSort: e.target.value,
      currentPage: 0,
      adjustments,
    });
    this.props.clearSearchResult();
    this.props.getProductSearchDataSaga(null, adjustments);
  };

  resultPerPageChangeHandler = (e) => {
    let adjustments = this.state.adjustments;
    adjustments.recordsPerPage = e.target.value;
    adjustments.page = 0;
    this.props.clearSearchResult();
    this.setState({
      resultPerPage: e.target.value,
      currentPage: 0,
      adjustments,
    });
    this.props.getProductSearchDataSaga(null, adjustments);
  };

  filterOptionClickHandler = (e) => {
    const { isMobileFilterOpen } = this.state;
    const searchTerm = this.props.productListing[0].searchTerm;
    let selectedValue = e.target.value;
    let name = e.target.name.split('-');
    let filterType = name[name.length - 1];
    let filterName =
      name.length === 2
        ? name[0]
        : name.slice(0, name.length - 1).join('-');

    let obj = {
      name: filterName,
      selections: [selectedValue],
    };
    let dataPresent = false;
    let adjustments = this.state.adjustments;

    if (filterType === 'multiselect') {
      let multiselect = adjustments.multiselect;
      multiselect.map((option, i) => {
        if (option.name === filterName) {
          dataPresent = true;
          if (
            multiselect[i].selections.indexOf(selectedValue) === -1
          ) {
            multiselect[i].selections.push(selectedValue);
          } else {
            let index = multiselect[i].selections.indexOf(
              selectedValue
            );
            if (index > -1) {
              multiselect[i].selections.splice(index, 1);
            }
            if (multiselect[i].selections.length === 0) {
              multiselect.splice(i, 1);
            }
          }
        }
      });
      if (!dataPresent) {
        multiselect.push(obj);
      }
    } else if (filterType === 'singleselect') {
      let singleselect = adjustments.singleselect;

      singleselect.map((option, i) => {
        if (option.name === filterName) {
          dataPresent = true;
          singleselect[i].selections = [];
          singleselect[i].selections.push(selectedValue);
        }
      });

      if (!dataPresent) {
        singleselect.push(obj);
      }
    } else if (filterType === 'range') {
      let range = adjustments.range;

      range.map((option, i) => {
        if (option.name === filterName) {
          dataPresent = true;
          if (range[i].selections.indexOf(selectedValue) === -1)
            range[i].selections.push(selectedValue);
          else {
            let index = range[i].selections.indexOf(selectedValue);
            if (index > -1) {
              range[i].selections.splice(index, 1);
            }
          }
        }
      });

      if (!dataPresent) {
        range.push(obj);
      }
    }
    adjustments.page = 0;
    adjustments.term = searchTerm;
    this.setState({ currentPage: 0, adjustments: adjustments });
    if (!isMobileFilterOpen) {
      this.props.clearSearchResult();
      this.props.getProductSearchDataSaga(null, adjustments);
    }
  };

  clearAllFilter = (e) => {
    const searchTerm = this.props.productListing[0].searchTerm;
    const { isMobileFilterOpen, adjustments } = this.state;
    if (!isMobileFilterOpen) {
      const state = {
        searchText: null,
        selectedSort: 'new asc',
        resultPerPage: 20,
        clearAll: false,
        currentPage: 0,
        adjustments: {
          term: searchTerm,
          sortBy: 'new asc',
          page: 0,
          recordsPerPage: 20,
          heirarchical: [],
          multiselect: [],
          singleselect: [],
          range: [],
        },
        isMobileFilterOpen: false,
      };
      this.setState({ ...state, clearAll: true });
      this.props.clearSearchResult();
      this.props.getProductSearchDataSaga(
        searchTerm,
        state.adjustments
      );
    } else {
      const state = {
        adjustments: {
          term: searchTerm,
          sortBy: 'new asc',
          page: 0,
          recordsPerPage: 20,
          heirarchical: [],
          multiselect: [],
          singleselect: [],
          range: [],
        },
      };
      this.props.clearCategoryData();
      this.setState(
        {
          clearAll: true,
          adjustments: state.adjustments,
          selectedSort: state.adjustments.sortBy,
          currentPage: 0,
        },
        () => this.mobileFilterApplyHandler()
      );
    }
  };

  categoryClickHandler = (e) => {
    const { isMobileFilterOpen } = this.state;
    let adjustments = this.state.adjustments;
    adjustments.heirarchical = [];
    let obj = {
      selections: [e.target.getAttribute('name')],
      name: 'categories',
    };
    adjustments.heirarchical.push(obj);
    adjustments.page = 0;
    this.setState({ adjustments: adjustments });
    if (!isMobileFilterOpen) {
      this.props.clearSearchResult();
      this.setState({ currentPage: 0 });
      this.props.getProductSearchDataSaga(null, adjustments);
    } else {
      this.props.clearCategoryData();
      this.props.getCategorySearchData(adjustments);
    }
  };

  pageChangeClickHandler = (data) => {
    const searchTerm = this.props.productListing[0].searchTerm;
    let adjustments = this.state.adjustments;
    adjustments.page = data.selected;
    adjustments.term = searchTerm;
    this.setState({
      currentPage: data.selected,
      adjustments: adjustments,
    });
    this.props.clearSearchResult();
    this.props.getProductSearchDataSaga(null, adjustments);
  };

  mobilerFilterClickHandler = () => {
    const { apply, adjustments } = this.state;
    //  without click on apply then clear all selected data.
    if (!apply) {
      this.props.clearCategoryData();
      const adjustment = {
        term: adjustments.term,
        sortBy: 'new asc',
        page: 0,
        recordsPerPage: 20,
        heirarchical: [],
        multiselect: [],
        singleselect: [],
        range: [],
      };
      this.setState({ adjustments: adjustment });
    }
    this.setState({
      isMobileFilterOpen: !this.state.isMobileFilterOpen,
    });
  };

  buttonClickHandler = (e, productId) => {
    this.setState({
      selectedProduct: productId,
    });
    this.props.getProductSaga(productId);
  };

  mobileFilterApplyHandler = () => {
    const { adjustments } = this.state;
    adjustments.page = 0;
    this.setState({ apply: true, currentPage: 0 }, () => {
      this.mobilerFilterClickHandler();
    });
    this.props.clearSearchResult();
    this.props.getProductSearchDataSaga(null, adjustments);
  };

  //  toggle grid and list view
  toggleView = (e, view) => {
    this.setState({ view });
  };

  //  toggle modal on desktop
  showModal = (e, productId) => {
    e.preventDefault();
    this.setState({ isModalOpen: !this.state.isModalOpen }, () => {
      if (this.state.isModalOpen) {
        this.props.getProductSaga(productId);
      } else {
        this.props.clearProduct();
      }
    });
  };

  render() {
    let { productListing } = this.props;

    let listingData =
      productListing !== undefined && productListing[0].listingData
        ? productListing[0].listingData
        : null;

    let categoryData =
      productListing !== undefined && productListing[0].category
        ? productListing[0].category
        : null;
    if (this.state.selectedProduct) {
      this.props.history.push(
        `product/${this.state.selectedProduct}`
      );
      // return (
      // <Redirect to={`/product/${this.state.selectedProduct}`} />
      // );
    }
    return (
      <PageLayoutHOC
        {...this.props}
        showProductSearch={true}
        getOption={this.getOption}
        activeOption={this.state.activeOption}
        searchText={this.state.searchText}
        searchTextChangeHandler={this.searchTextChangeHandler}
        searchEnterClickHandler={this.searchEnterClickHandler}
        searchIconClickHandler={this.searchIconClickHandler}
        searchError={this.state.searchError}>
        {listingData && (
          <div>
            {listingData && listingData.media != null && (
              <Banner
                itemLabel={listingData.media.imageAltText}
                itemId={1234}
                imgUrl={listingData.media.imageURL}
                buttonClickHandler={123456}></Banner>
            )}
            <Grid
              item
              container
              className='product-listing externalContainer'
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}>
              <ProductListing
                listingData={listingData}
                adjustments={this.state.adjustments}
                selectedSort={this.state.selectedSort}
                sortByChangeHandler={this.sortByChangeHandler}
                resultPerPage={this.state.resultPerPage}
                resultPerPageChangeHandler={
                  this.resultPerPageChangeHandler
                }
                filterOptionClickHandler={
                  this.filterOptionClickHandler
                }
                mobileFilterApplyHandler={
                  this.mobileFilterApplyHandler
                }
                categoryClickHandler={this.categoryClickHandler}
                clearAllFilter={this.clearAllFilter}
                currentPage={this.state.currentPage}
                pageChangeClickHandler={this.pageChangeClickHandler}
                isMobileFilterOpen={this.state.isMobileFilterOpen}
                mobilerFilterClickHandler={
                  this.mobilerFilterClickHandler
                }
                buttonClickHandler={this.buttonClickHandler}
                category={categoryData} //  for mobile mode only
                showModal={this.showModal}
                isModalOpen={this.state.isModalOpen}
                content={this.props.products[0].products}
                toggleView={this.toggleView} //  for desktop only
                activeView={this.state.view}
              />
            </Grid>
          </div>
        )}

        {listingData === null && (
          <ContentLoader
            height={190}
            width={400}
            speed={2}
            primaryColor='#f3f3f3'
            secondaryColor='#ecebeb'></ContentLoader>
        )}
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  productListing: [state.productSearchReducer],
  products: [state.productReducer],
  optionList: state.productSearchReducer.optionList,
});

const mapDispatchToProps = (dispatch) => ({
  getProductSearchDataSaga: (searchTerm, adjustments) =>
    dispatch(getProductSearchDataSaga(searchTerm, adjustments)),
  getCategorySearchData: (adjustments) =>
    dispatch(getCategorySearchData(adjustments)),
  setProductCategoryData: (data) =>
    dispatch(setProductCategoryData(data)),
  setSearchTerm: (term) => dispatch(setSearchTerm(term)),
  getOptionList: (term) => dispatch(getOptionList(term)),
  clearOptions: () =>
    dispatch(setOptionList({ response: { suggestions: [] } })),
  clearSearchResult: () => dispatch(clearSearchResult()),
  clearCategoryData: () => dispatch(clearCategoryData()),
  getProductSaga: (productId) => dispatch(getProductSaga(productId)),
  clearProduct: () => dispatch(clearProduct()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSearchPage);
