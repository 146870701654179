import React, { PureComponent } from 'react';
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Hidden,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import './Styles.css';
import cart from '../../assets/dashboard/cart-black.png';
import topCart from '../../assets/dashboard/topCart.png';
import ContentLoader from 'react-content-loader';

const LatestOrders = (props) => {
  const orders =
    props.latestOrders &&
    props.latestOrders.orders_list &&
    props.latestOrders.orders_list.orderList;
  const cartStatus = props.cartStatus;
  if (!orders) return <ContentLoader />;
  return (
    <Grid className='common-style'>
      <Grid className='orderHeader'>
        {/* Hide cart logo for tab and above */}
        <Hidden mdUp>
          <div
            style={{
              backgroundImage: `url(${topCart})`,
              height: '40px',
              width: '40px',
              borderRadius: '50%',
            }}>
            {' '}
          </div>
        </Hidden>
        <div className='latest-orders'> Latest Orders </div>
      </Grid>

      {orders &&
        orders.map((order, index) => (
          <Grid className='common-style common-margin' key={index}>
            {/* Hide for mobile */}
            <Hidden xsDown>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Table className='outerTable'>
                  <TableHead>
                    <TableRow className='dashboard-order'>
                      <TableCell className='dashboard-order'>
                        {' '}
                        Order#{' '}
                      </TableCell>
                      <TableCell className='dashboard-order'></TableCell>
                      <TableCell className='dashboard-order'></TableCell>
                      <TableCell className='dashboard-order'></TableCell>
                      <TableCell className='dashboard-order'>
                        {' '}
                        Placed{' '}
                      </TableCell>
                      <TableCell
                        className='dashboard-order'
                        className='color-text'>
                        {' '}
                        Delivered{' '}
                      </TableCell>
                      <TableCell>
                        <Link
                          onClick={props.gotoOrder.bind(
                            this,
                            order.orderNumber
                          )}>
                          Order Details
                          <OpenInNewIcon className='icon-style' />{' '}
                        </Link>{' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={index} className='headdata'>
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell className='dashboard-order'>
                        {' '}
                      </TableCell>
                      <TableCell className='dashboard-order'>
                        {' '}
                      </TableCell>
                      <TableCell className='dashboard-order'>
                        {' '}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {props.convert(order.orderSubmitDate)}{' '}
                      </TableCell>
                      <TableCell>
                        {props.convert(order.orderSubmitDate)}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Hidden>
            {/* Hide for mobile End  */}

            {/* Hide for Tab and Desktop */}
            <Hidden smUp>
              <Grid className='display-flx add-padding add-background'>
                <Grid item xs={6}>
                  <div className='dashboard-order head-text-db'>
                    {' '}
                    Order#{' '}
                  </div>{' '}
                  <div className='headdata'>
                    {' '}
                    {order.orderNumber}{' '}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className='dashboard-order head-text-db'>
                    {' '}
                    Placed{' '}
                  </div>{' '}
                  <div> {props.convert(order.orderSubmitDate)} </div>
                </Grid>
              </Grid>
              <Grid className='display-flx add-padding add-background'>
                <Grid item xs={6}>
                  <div className='dashboard-order color-text header-text'>
                    Delivered{' '}
                  </div>{' '}
                  <div> {props.convert(order.orderSubmitDate)} </div>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    onClick={props.gotoOrder.bind(
                      this,
                      order.orderNumber
                    )}
                    className='color-link'>
                    Order Details
                    <OpenInNewIcon className='icon-style' />{' '}
                  </Link>{' '}
                </Grid>
              </Grid>
            </Hidden>

            {/* show for Tab and Desktop */}
            <Grid className='display-prop'>
              <Grid
                item
                xl={10}
                lg={10}
                md={12}
                sm={12}
                xs={12}
                className='addscrol'>
                {/* Inner Table */}

                <Table className='inner-table'>
                  <TableHead>
                    <TableRow className='inner-head'>
                      <TableCell> Product# </TableCell>
                      <TableCell> Item </TableCell>
                      <TableCell> Sku </TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell> Price</TableCell>
                      <TableCell> Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='inner-body'>
                    {order.EOrderItems &&
                      order.EOrderItems.map((items, index) => (
                        <TableRow key={index} className='rowText'>
                          <TableCell>{items.product}</TableCell>
                          <TableCell>{items.productName}</TableCell>
                          <TableCell>{items.sku}</TableCell>
                          <TableCell>{items.quantity}</TableCell>
                          <TableCell>{items.listPrice}</TableCell>
                          <TableCell>
                            {items.finalItemAmount}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: 'left',
                              padding: 0,
                            }}></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid>
                <Grid className='latest-order-summary'>
                  <Hidden smDown>
                    <Grid className='summary-head'>Items</Grid>
                    <div className='summary-data'>
                      {order.EOrderItems
                        ? order.EOrderItems.length
                        : 0}
                    </div>
                    <br />
                    <Grid className='summary-head'>Subtotal</Grid>
                    <div className='summary-data'>
                      {order.finalOrderAmount &&
                        parseFloat(order.finalOrderAmount).toFixed(
                          2
                        )}{' '}
                    </div>
                    <br />
                    <div className='quickOrder'>
                      <div>Quick Reorder </div>
                      <a
                        onClick={props.viewOrder.bind(
                          this,
                          order.orderNumber
                        )}>
                        {' '}
                        <div
                          style={{
                            backgroundImage: `url(${cart})`,
                            height: '70px',
                            width: '70px',
                            borderRadius: '50%',
                          }}>
                          {' '}
                        </div>
                      </a>
                    </div>
                  </Hidden>
                  {/* *********** */}
                </Grid>
                <Hidden mdUp>
                  <Grid>
                    <Grid className='add-padding display-flex'>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        className='text-center'>
                        {' '}
                        <p className='summary-head'>Items</p>
                        <p className='summary-data'>
                          {order.EOrderItems
                            ? order.EOrderItems.length
                            : 0}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        className='text-center'>
                        {' '}
                        <p className='summary-head'>Subtotal</p>
                        <p className='summary-data'>
                          {order.finalOrderAmount &&
                            parseFloat(
                              order.finalOrderAmount
                            ).toFixed(2)}
                        </p>
                      </Grid>

                      <Hidden only={['xs']}>
                        <Grid
                          item
                          sm={4}
                          className='text-center add-border'>
                          {' '}
                          <p className='summary-head reset-margin'>
                            {' '}
                            Quick Reorder
                          </p>
                          <a
                            onClick={props.viewOrder.bind(
                              this,
                              order.orderNumber
                            )}>
                            {' '}
                            <div
                              style={{
                                backgroundImage: `url(${cart})`,
                                height: '70px',
                                width: '70px',
                                borderRadius: '50%',
                                margin: 'auto',
                              }}>
                              {' '}
                            </div>{' '}
                          </a>{' '}
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Hidden only={['xl', 'lg', 'md', 'sm']}>
                      <Grid className='text-center add-padding add-border'>
                        {' '}
                        <div> Quick Reorder </div>
                        <a
                          onClick={props.viewOrder.bind(
                            this,
                            order.orderNumber
                          )}>
                          {' '}
                          <div
                            style={{
                              backgroundImage: `url(${cart})`,
                              height: '70px',
                              width: '70px',
                              borderRadius: '50%',
                              margin: 'auto',
                            }}>
                            {' '}
                          </div>
                        </a>{' '}
                      </Grid>
                    </Hidden>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default LatestOrders;
