import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

export function getMultiInventoryValidation(body) {
  let options = headerData();
  const getMultiInventoryValidationApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/inventories/validate`;

  return axios.post(getMultiInventoryValidationApi, body, options);
}

export function addtoQuoteMultipleItems(items, clearCart) {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;
  const requestBody = {
    profileId: context.profileId,
    orderId: '',
    items: items,
  };
  let options = headerData();
  const addtoQuoteMultipleItemsApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current/quickorder?isClearCart=${!!clearCart}`;
  return axios.post(addtoQuoteMultipleItemsApi, requestBody, options);
}
export function getInventory(sku) {
  let options = headerData();
  const getInventoryApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/inventories/skus/${sku}`;
  return axios.get(getInventoryApi, options);
}
