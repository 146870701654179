import {
  SET_SUB_ORGANIZATION_DETAILS,
  GET_SUB_ORGANIZATION_DETAILS_SAGA,
} from '../constants';

//  get sub organization details
export function getSubOrganizationDetailsSaga(
  organizationId,
  profileId
) {
  return {
    type: GET_SUB_ORGANIZATION_DETAILS_SAGA,
    payload: {
      organizationId,
      profileId,
    },
  };
}

//  Added for sub Organization Details
export function setSubOrganizationDetails(sub_organization_details) {
  return {
    type: SET_SUB_ORGANIZATION_DETAILS,
    sub_organization_details,
  };
}
