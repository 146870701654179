import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import './Styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    bottom: 0,
    cursor: 'pointer',
    color: '#939696',
    marginTop: '20px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  custom: {
    borderRight: '0.01em solid #939696',
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden only={['lg', 'xl']}>
        <Grid item xs={12} sm={12} className='miniFooter'>
          <span>
            <Link to='/terms'>Terms and Conditions</Link>
          </span>
          <span>
            <Link to='/privacy'> Privacy </Link>
          </span>
          <span>
            <Link to='/accessibility'> Accessibility </Link>
          </span>
          <span>
            <Link to='/cookie'> Cookie policy </Link>
          </span>
        </Grid>
        <Grid item xs={12} sm={12} className='copyRight'>
          © 2019 Object Edge. All rights reserved.
        </Grid>
      </Hidden>
    </div>
  );
}
