import React from 'react';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import './style.css';
import { CustomTextField } from '../../components/Shared/customTextField';
import CustomError from '../../components/Shared/customErrorText';
import { Grid, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import CustomSelect from '../../components/Shared/customizedSelect';
import CustomSelectCountryAndState from '../../components/Shared/customizedSelectCountry';
import { getRegistrationSaga } from '../../actions/registrationAction';
import Loader from '../../components/Shared/circularProgressBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Footer from '../Footer';
import regImg from '../../assets/Registration/registration.jpeg';
import Logo from '../../assets/Logo/logo.png';
import {
  validateCity,
  isValidEmail,
  isStringOnlyAlphabet,
  isOnlyNumber,
} from '../../utils/common';
import {
  getCountryDataSaga,
  getStateDataSaga,
} from '../../actions/countryStateAction';
import { stateList, countryList } from '../../constants';

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toLogin: false,
      zipCodeError: '',
      numberError: '',
      errorFirstName: false,
      errorLastName: false,
      isLoading: false,
      disableButton: true,
      form: {},
      showSuccessScreen: false,
      countryList: null,
      stateList: null,
    };
  }
  componentDidMount() {
    this.props.getCountryDataSaga();
    this.clearAndCloseForm();
  }

  static getDerivedStateFromProps(props, state) {
    let countryList = { ...state.countryList };
    let stateList = { ...state.stateList };
    let form = { ...state.form };
    if (
      props.countries[0].data &&
      props.countries[0].data.length > 0
    ) {
      state.countryList = props.countries[0].data;
    }
    if (props.states[0] && props.states[0].data) {
      state.stateList = props.states[0].data.data.states;
    }

    if (props.states && props.states[0] != '') {
      if (form.state == null || form.state == undefined) {
        if (
          props.states[0].data.data.country == form.country &&
          form.country != -1
        ) {
          form.state = props.states[0].data.data.states[0].code;
        }
        if (form.country == -1) {
          state.stateList = [];
          form.state = null;
        }
      }
    }

    return {
      ...state,
      countryList: state.countryList,
      stateList: state.stateList,
      form: form,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.states != prevProps.states &&
      prevProps.states[0] == '' &&
      this.state.stateList
    ) {
      this.clearAndCloseForm();
    }
    this.toastManager = prevProps.toastManager;
    if (this.props.countries[0] != prevProps.countries[0]) {
      if (this.state.form.country == null) {
        this.clearAndCloseForm();
      }
    }
    if (
      this.state.form.country &&
      this.state.form.country != -1 &&
      this.state.form.state == null &&
      this.props.states == prevProps.states
    ) {
      this.props.getStateDataSaga(this.state.form.country);
    }
    if (this.props.registration[0] !== prevProps.registration[0]) {
      this.toastManager.add('The email will be sent to you!', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.setState({
        isLoading: false,
        showSuccessScreen: true,
        // disableButton: false,
      });
    }

    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: false,
      });
      this.setState({
        isLoading: false,
        disableButton: false,
      });
    }
  }

  clearAndCloseForm = () => {
    let form = {
      ...this.state.form,
    };

    form = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state:
        this.state.stateList && this.state.stateList[0]
          ? this.state.stateList[0].code
          : null,
      country:
        this.props.countries[0].data &&
        this.props.countries[0].data.length > 0
          ? this.props.countries[0].data[1].code
          : null,
      postalCode: '',
      companyName: '',
      comments: '',
    };
    this.setState({
      form,
      disableButton: true,
      isLoading: false,
      zipCodeError: false,
      numberError: false,
    });
  };

  gotoLogin = () => {
    this.setState({
      toLogin: true,
    });
  };
  handleRegistrationSubmit = (e) => {
    e.preventDefault();
  };
  handleRegistrationSave = (e) => {
    let data = {
      ...this.state.form,
    };
    if (data.postalCode.length !== 5) {
      this.setState({
        zipCodeError: true,
      });
    } else if (data.phoneNumber.length !== 10) {
      this.setState({
        numberError: true,
      });
    } else {
      // delete data['errorFirstName'];
      // delete data['errorLastName'];
      delete data['errorCity'];
      delete data['errorAddress1'];
      delete data['errorCompanyName'];
      this.setState({
        isLoading: true,
        disableButton: true,
      });
      this.props.getRegistrationSaga(data);
    }
  };
  registrationChangeHandler = (e) => {
    if (e.target.name == 'country' && e.target.value != -1) {
      this.props.getStateDataSaga(e.target.value);
    }

    let form = {
      ...this.state.form,
    };

    form[e.target.name] = e.target.value;

    if (e.target.name === 'firstName') {
      let validFirstName = isStringOnlyAlphabet(form.firstName);

      if (validFirstName !== null) {
        if (form.firstName.length > 1) {
          this.setState({
            errorFirstName: false,
          });
        } else {
          this.setState({
            errorFirstName: true,
          });
        }
      } else {
        this.setState({
          errorFirstName: true,
        });
      }
    }
    if (e.target.name === 'lastName') {
      let validLastName = isStringOnlyAlphabet(form.lastName);

      if (validLastName !== null) {
        if (form.lastName.length > 1) {
          this.setState({
            errorLastName: false,
          });
        } else {
          this.setState({
            errorLastName: true,
          });
        }
      } else {
        this.setState({
          errorLastName: true,
        });
      }
    }
    if (e.target.name === 'email') {
      let validEmail = isValidEmail(form.email);
      if (!validEmail) {
        form.errorEmail = true;
      } else {
        form.errorEmail = false;
      }
    }
    if (e.target.name === 'address1') {
      if (form.address1.length > 1) {
        form.errorAddress1 = false;
      } else {
        form.errorAddress1 = true;
      }
    }
    if (e.target.name === 'companyName') {
      if (form.companyName.length > 1) {
        form.errorCompanyName = false;
      } else {
        form.errorCompanyName = true;
      }
    }
    if (e.target.name === 'city') {
      let validCity = validateCity(form.city);
      if (!validCity) {
        form.errorCity = true;
      } else {
        form.errorCity = false;
      }
    }
    if (e.target.name === 'postalCode') {
      let validNumber = isOnlyNumber(form.postalCode);
      if (!validNumber) {
        this.setState({
          zipCodeError: true,
        });
      } else if (form.postalCode.length <= 4) {
        this.setState({
          zipCodeError: true,
        });
      } else {
        this.setState({
          zipCodeError: false,
        });
      }
    }
    if (e.target.name === 'phoneNumber') {
      let numberTest = isOnlyNumber(form.phoneNumber);
      if (!numberTest) {
        this.setState({
          numberError: true,
        });
      } else if (form.phoneNumber.length < 10) {
        this.setState({
          numberError: true,
        });
      } else {
        this.setState({
          numberError: false,
        });
      }
    }
    if (form.country != this.props.states[0].data.data.country) {
      form.state = null;
    }
    this.setState(
      {
        form,
      },
      () => this.isDisable()
    );
  };
  isDisable = (e) => {
    let form = {
      ...this.state.form,
    };
    if (
      form.firstName &&
      form.firstName.length > 2 &&
      form.lastName &&
      form.lastName.length > 2 &&
      form.address1 &&
      form.address1.length > 2 &&
      form.city &&
      form.city.length > 2 &&
      form.companyName &&
      form.companyName.length > 2 &&
      form.postalCode &&
      form.postalCode.length > 4 &&
      form.phoneNumber &&
      form.phoneNumber.length > 9 &&
      !this.state.errorFirstName &&
      !this.state.errorLastName &&
      !form.errorAddress1 &&
      !form.errorCompanyName &&
      !form.errorCity &&
      !form.errorEmail &&
      !this.state.zipCodeError &&
      !this.state.numberError &&
      form.state !== '-1' &&
      form.country !== '-1'
    ) {
      this.setState({
        disableButton: false,
        zipCodeError: false,
        numberError: false,
      });
    } else {
      this.setState({
        disableButton: true,
      });
    }
  };
  render() {
    if (this.state.toLogin) {
      return <Redirect to='/login' />;
    }
    if (this.state.showSuccessScreen) {
      return (
        <Grid>
          <Grid>
            <Grid
              item
              xs={12}
              sm={12}
              className='set-margin'
              style={{ display: 'flex' }}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                md={12}
                className='set-border'>
                <Grid item className='align-center'>
                  <CssBaseline />
                  <div className='successFormPadding'>
                    <div>
                      <a href='/'>
                        <img src={Logo} alt='logo' />
                      </a>
                    </div>
                    <div className='containerDiv'>
                      <div className='verticalcenterDiv'>
                        <h1 className='textReg'> Check your inbox</h1>
                        <p style={{ padding: 5 }}>
                          We will send email to{' '}
                          {this.state.form.email}
                          .Click the link in email to create new
                          password.
                        </p>
                        <Link> Resend link </Link>|
                        <Link onClick={this.gotoLogin}>
                          {' '}
                          Sign In{' '}
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Hidden mdDown>
                <Grid
                  item
                  lg={6}
                  md={12}
                  className='MuiContainer-root align-center img-set set-border full-height'
                  style={{
                    backgroundImage: `url(${regImg})`,
                  }}
                />
              </Hidden>
            </Grid>
          </Grid>
          <Grid className='footerMargin'>
            <Footer />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className='set-margin'
          style={{ display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={12}
            className='set-border'>
            <Grid item className='align-center'>
              <CssBaseline />
              <div className='formPadding'>
                <div>
                  <a href='/'>
                    <img src={Logo} alt='logo' />
                  </a>
                </div>
                <div></div>
                <h1 className='textReg'> Set up your account </h1>
                <p>
                  Welcome to our new platform! Enter your email
                  address below to access your new account or contact
                  us to learn more about account creation.
                </p>
                <form onSubmit={this.handleRegistrationSubmit}>
                  <Grid className='regFields'>
                    <Grid
                      item
                      lg={5}
                      md={5}
                      sm={5}
                      xs={12}
                      className='required regFieldText'>
                      <CustomTextField
                        id='firstName'
                        label='First Name:'
                        required={true}
                        onchange={this.registrationChangeHandler}
                        value={this.state.form.firstName}
                        type='text'
                        name='firstName'
                      />
                      {this.state.errorFirstName && (
                        <CustomError
                          masg='Please enter first name more than 2 characters'
                          displayType='block'
                          margin='2px 0 0 15px'
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      md={5}
                      sm={5}
                      xs={12}
                      className='required regFieldText'>
                      <CustomTextField
                        label='Last Name:'
                        required={true}
                        onchange={this.registrationChangeHandler}
                        value={this.state.form.lastName}
                        type='text'
                        name='lastName'
                      />
                      {this.state.errorLastName && (
                        <CustomError
                          masg='Please enter Last Name more than 2 characters'
                          displayType='block'
                          margin='2px 0 0 15px'
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    {' '}
                    <Grid className='regFields'>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        xs={12}
                        className='required regFieldText'>
                        <CustomTextField
                          label='Phone Number:'
                          required={true}
                          type='text'
                          name='phoneNumber'
                          onchange={this.registrationChangeHandler}
                          value={this.state.form.phoneNumber}
                          maxLength={10}
                        />{' '}
                        {this.state.numberError && (
                          <CustomError
                            masg='Phone number must contain 10 digits'
                            displayType='block'
                            margin='2px 0 0 15px'
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        xs={12}
                        className='required regFieldText'>
                        <CustomTextField
                          label='Email id:'
                          required={true}
                          type='email'
                          name='email'
                          onchange={this.registrationChangeHandler}
                          value={this.state.form.email}
                        />{' '}
                        {this.state.form.errorEmail && (
                          <CustomError
                            masg='Please enter Valid email address'
                            displayType='block'
                            margin='2px 0 0 15px'
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className='regFields'>
                    <Grid
                      item
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      className='required regFieldText'></Grid>
                  </Grid>
                  <Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className='required regFieldText'>
                      <div id='billAddress'>
                        {' '}
                        Primary Bill-To Address{' '}
                      </div>
                      {/* < AddressForm/> */}
                      <CustomTextField
                        label='Address Line 1:'
                        required={true}
                        onchange={this.registrationChangeHandler}
                        name='address1'
                        value={this.state.form.address1}
                      />
                      {this.state.form.errorAddress1 && (
                        <CustomError
                          masg='Please enter Address more than 2 characters'
                          displayType='block'
                          margin='2px 0 0 15px'
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className='regFieldText'>
                      {/* < AddressForm/> */}
                      <CustomTextField
                        label='Address Line 2(optional):'
                        required={true}
                        onchange={this.registrationChangeHandler}
                        name='address2'
                        value={this.state.form.address2}
                      />
                    </Grid>
                    <Grid
                      className='registrationForm'
                      style={{
                        justifyContent: 'space-between',
                      }}>
                      {/* Add here */}

                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={7}
                        xs={12}
                        className='required regFieldText'>
                        <CustomTextField
                          label='City:'
                          required={true}
                          onchange={this.registrationChangeHandler}
                          name='city'
                          value={this.state.form.city}
                        />
                        {this.state.form.errorCity && (
                          <CustomError
                            masg='Please enter city more than 2 characters'
                            displayType='block'
                            margin='2px 0 0 15px'
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className='required regFieldText'>
                        <div className='regAddressSelect'>
                          <InputLabel
                            shrink
                            htmlFor='bootstrap-input'
                            style={{ textAlign: 'left' }}>
                            State:
                          </InputLabel>
                          <CustomSelectCountryAndState
                            variants={this.state.stateList}
                            selectedVariant={this.state.form.state}
                            variantChange={
                              this.registrationChangeHandler
                            }
                            name='state'
                            defaultValue='State'
                            value={this.state.form.state}
                          />
                        </div>
                      </Grid>

                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={7}
                        xs={12}
                        className='required regFieldText'>
                        <CustomTextField
                          label='Postal Code:'
                          required={true}
                          onchange={this.registrationChangeHandler}
                          name='postalCode'
                          value={this.state.form.postalCode}
                          maxLength={5}
                        />
                        {this.state.zipCodeError && (
                          <CustomError
                            masg='Zip code must be number and contain 5 digits'
                            displayType='block'
                            margin='2px 0 0 15px'
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className='required regFieldText'>
                        <div className='regAddressSelect'>
                          <InputLabel
                            shrink
                            htmlFor='bootstrap-input'>
                            Country:
                          </InputLabel>
                          <CustomSelectCountryAndState
                            variants={this.state.countryList}
                            selectedVariant={this.state.form.country}
                            variantChange={
                              this.registrationChangeHandler
                            }
                            defaultValue='Country'
                            name='country'
                            // value={this.state.form.country}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className='regFields'>
                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={7}
                        xs={12}
                        className='regFieldText'>
                        <CustomTextField
                          label='Comments(optional):'
                          onchange={this.registrationChangeHandler}
                          name='comments'
                          value={this.state.form.comments}
                          maxLength={200}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className='required regFieldText'>
                        <CustomTextField
                          label='Company Name:'
                          required={true}
                          onchange={this.registrationChangeHandler}
                          name='companyName'
                          value={this.state.form.companyName}
                          className='companyName'
                        />{' '}
                        {this.state.form.errorCompanyName && (
                          <CustomError
                            masg='Please enter Company Name more than 2 characters'
                            displayType='block'
                            margin='2px 0 0 15px'
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='saveButton'>
                    <Grid>
                      <Button
                        variant='contained'
                        disabled={this.state.disableButton}
                        onClick={this.handleRegistrationSave}
                        className='buttonClass'
                        style={{
                          backgroundColor: '#e41e26',
                          color: '#ffffff',
                          marginRight: '20px',
                          marginLeft: '8px',
                          textTransform: 'capitalize',
                        }}>
                        Submit
                      </Button>
                      &nbsp;
                      <span
                        style={{
                          position: 'absolute',
                        }}>
                        {this.state.isLoading ? <Loader /> : null}
                      </span>
                    </Grid>
                    <Grid>
                      <div>Already have an account?</div>
                      <Link onClick={this.gotoLogin}> Sign In </Link>
                      <span> now or </span>{' '}
                      <Link> Reset your Password </Link>
                      <span> to access your account. </span>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              lg={6}
              md={12}
              className='MuiContainer-root align-center img-set set-border full-height'
              style={{
                backgroundImage: `url(${regImg})`,
              }}
            />
          </Hidden>
        </Grid>
        <Grid>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  registration: [state.registrationReducer],
  errorResponse: [state.errorReducer.error_data],
  countries: [state.countryStatesReducer.countries],
  states: [state.countryStatesReducer.states],
});
const mapDispatchToProps = (dispatch) => ({
  getRegistrationSaga: (registration) =>
    dispatch(getRegistrationSaga(registration)),
  getCountryDataSaga: () => dispatch(getCountryDataSaga()),
  getStateDataSaga: (data) => dispatch(getStateDataSaga(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Registration));
