import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';
import { GET_INVENTORY_VALIDATION_SAGA } from '../../constants';
import { addProductQuickOrderForm } from '../../actions/quickOrderAction';
import { getInventory } from '../../api/quickOrderApi';

function* workerGetInventoryDetailsSaga({ payload: data}) {
  const skuId = data.sku.SKU; 
  const response = yield call(getInventory, skuId);
  const quantityAvailable= response.data && response.data.inventoryList[0].qtyAvailable
  let quantityPlaced = quantityAvailable < data.qty ? quantityAvailable : data.qty;  
  const message = quantityAvailable <= data.qty && quantityAvailable > 0 ? "Max available quantity added" : null;
  const qty = response.data.inventoryList[0].qtyAvailable;
  yield put(
    addProductQuickOrderForm({
      ...data.sku,
      inventory: qty,
      quantity: typeof (quantityPlaced)=== 'number' ? quantityPlaced : 1,
      message: message
    })
  );
}

export default function* watchGetInventoryDetailsSaga() {
  yield takeEvery(
    GET_INVENTORY_VALIDATION_SAGA,
    workerGetInventoryDetailsSaga
  );
}
