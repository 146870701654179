import { call, put, takeLatest } from 'redux-saga/effects';
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
} from '../../reducers/auth.js';
import {
  getUserAuthentication,
  getUserProfile,
} from '../../api/authenticationApi';
// const getAccessTokenApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/login`;

// const getProfileApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/profiles/current?profileId=`;
// const basic_token = `Bearer ${localStorage.getItem('basic_token')}`;

// const authMock = (login, password) =>
//   new Promise((resolve, reject) => {
//     const bt = 'Bearer ' + localStorage.getItem('basic_token');

//     const data = {
//       username: login,
//       password,
//     };
//     const headers = {
//       Authorization: bt,
//       'Content-Type': 'application/json',
//     };

//     axios
//       .post(getAccessTokenApi, data, {
//         headers,
//       })
//       .then((res) => {
//         console.log('RESPONSE RECEIVED: ', res);
//         localStorage.setItem('access_token', res.data.access_token);

//         const headersProfile = `Bearer ${res.data.access_token}`;

//         console.log('access token...', res.data.access_token);

//         const profileApi = getProfileApi + res.data.id;

//         axios
//           .get(profileApi, {
//             headers: {
//               Authorization: headersProfile,
//               'Content-Type': 'application/json',
//             },
//           })
//           .then((response) => {
//             // adding context object and user details to
//             const context = {
//               siteId: 'siteUS',
//               profileId: response.data.profile_user.profileId,
//               organizationId: response.data.profile_user.accountId,
//               contractId: response.data.profile_user.contractId,
//               catalogId: response.data.profile_user.catalogId,
//               pricelistId: response.data.profile_user.pricelistId,
//               orderId: '',
//             };
//             localStorage.setItem('context', JSON.stringify(context));
//             const user = {
//               firstName: response.data.profile_user.firstName,
//               lastName: response.data.profile_user.lastName,
//               companyName: response.data.profile_user.companyName,
//               email: response.data.profile_user.email,
//               contactTypeRole:
//                 response.data.profile_user.contactTypeRole,
//             };
//             localStorage.setItem('user', JSON.stringify(user));

//             if (
//               localStorage.getItem('user') !== null ||
//               localStorage.getItem('user') !== undefined ||
//               localStorage.getItem('context') !== null ||
//               localStorage.getItem('context') !== undefined
//             ) {
//               resolve();
//             } else {
//               reject();
//             }
//           })
//           .catch((error) => {
//             console.log(`error in profile api${error}`);
//           });
//       })
//       .catch((err) => {
//         console.log('Login api ERROR : ', err);
//         localStorage.removeItem('access_token');
//         reject({ status: 401 });
//       });
//   });

function* workerGetAuthentication({ payload: { login, password, siteId } }) {
  yield put({ type: AUTH_FAILURE, payload: null }); // payload set to null so that after every wrong login,state is reinitialize.

  try {
    const res = yield call(getUserAuthentication, login, password);
    localStorage.setItem('access_token', res.data.access_token);
    const token = localStorage.getItem('access_token');
    yield call(getUserProfile, res.data.id, siteId);
    if (token) {
      yield put({ type: AUTH_SUCCESS, payload: token });
    }
  } catch (error) {
    console.log(error.response);
    let message;
    switch (error.response.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      case 403:
        message = 'Password Generated';
        break;
      default:
        message = 'Something went wrong';
    }
    console.log(error);
    yield put({ type: AUTH_FAILURE, payload: message });
  }
}

function* watchGetAuthentication() {
  yield takeLatest(AUTH_REQUEST, workerGetAuthentication);
}

export default watchGetAuthentication;
