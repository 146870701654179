import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_QUOTES_ON_PAGINATION_SAGA } from '../../constants';
import { setQuotesOnPagination } from '../../actions/quoteAction';
import { getQuotesOnPagination } from '../../api/quoteApi';

function* workerGetQuotesOnPaginationSaga({
  payload: {
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    setStartdate,
    setEndDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData,
  },
}) {
  const quotesOnPagination = yield call(
    getQuotesOnPagination,
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    setStartdate,
    setEndDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData
  );
  yield put(setQuotesOnPagination(null));

  yield put(setQuotesOnPagination(quotesOnPagination.data));
}

export default function* watchGetQuotesOnPaginationSaga() {
  yield takeLatest(
    GET_QUOTES_ON_PAGINATION_SAGA,
    workerGetQuotesOnPaginationSaga
  );
}
