import {
  SET_ORDERS,
  GET_ORDERS_SAGA,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SET_ORDER_DETAIL,
  GET_ORDER_DETAIL_SAGA,
  SET_ORDER_DETAIL_SUCCESS,
  SUBMIT_ORDER_FAILED,
  SET_ORDER_HISTORY,
  GET_ORDER_HISTORY_SAGA,
  SET_ORDERS_ON_PAGINATION,
  GET_ORDERS_ON_PAGINATION_SAGA,
  SET_ORDER_DETAIL_RESET,
  GET_ORDER_DETAIL_SAGA_RESET,
  SET_ORDER_APPROVED,
  SET_ORDER_STATES,
  GET_ORDER_STATES,
} from '../constants';
import convert from '../utils/common';

export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    orders,
  };
}

export function getOrdersSaga() {
  return {
    type: GET_ORDERS_SAGA,
  };
}

export function setOrderApproved(orderId, isApproved) {
  return {
    type: SET_ORDER_APPROVED,
    payload: {
      orderId,
      isApproved,
    }
  };
}

export const submitOrder = (requestBody) => ({
  type: SUBMIT_ORDER,
  payload: { requestBody },
});

export const submitOrderSuccess = (data) => ({
  type: SUBMIT_ORDER_SUCCESS,
  data,
});

// Added for order detail
export function setOrderDetail(order_detail) {
  return {
    type: SET_ORDER_DETAIL,
    payload: { order_detail },
  };
}

export function setOrdersOnPagination(orders_list) {
  return {
    type: SET_ORDERS_ON_PAGINATION,
    payload: { orders_list },
  };
}

// Sagas
export function getOrderDetailSaga(orderId) {
  return {
    type: GET_ORDER_DETAIL_SAGA,
    payload: { orderId },
  };
}

export const setOrderDetailSuccess = (data) => ({
  type: SET_ORDER_DETAIL_SUCCESS,
  data,
});

export const setOrderStates = (payload) => ({
  type: SET_ORDER_STATES,
  payload,
});

export const getOrderStates = (payload) => ({
  type: GET_ORDER_STATES,
  payload,
});

export const submitOrderFailed = (data) => ({
  type: SUBMIT_ORDER_FAILED,
  data,
});
// Added for order history
export function setOrderHistory(orderHistory) {
  return {
    type: SET_ORDER_HISTORY,
    payload: {
      orderHistory,
    },
  };
}

// Sagas
export function getOrderHistorySaga() {
  return {
    type: GET_ORDER_HISTORY_SAGA,
  };
}

export function getOrdersOnPaginationSaga(
  searchInput,
  currentPage,
  recordPerPage,
  ordersUpto,
  startDate,
  endDate,
  orderRange,
  sortProp,
  sortOrder,
  statusData,
  orgData
) {
  let setOrdersUpto = orderRange === true ? '' : ordersUpto;
  let setStartdate = convert(startDate);
  let setEndDate = convert(endDate);

  return {
    type: GET_ORDERS_ON_PAGINATION_SAGA,
    payload: {
      searchInput,
      currentPage,
      recordPerPage,
      ordersUpto,
      setOrdersUpto,
      setStartdate,
      setEndDate,
      orderRange,
      sortProp,
      sortOrder,
      statusData,
      orgData,
    },
  };
}
// Added to reset order detail
export function setOrderDetailReset(order_detail) {
  return {
    type: SET_ORDER_DETAIL_RESET,
    payload: {
      order_detail,
    },
  };
}

// Sagas
export function getOrderDetailSagaReset(orderId) {
  return {
    type: GET_ORDER_DETAIL_SAGA_RESET,
    payload: {
      orderId,
    },
  };
}
