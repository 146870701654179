import axios from 'axios';
import {
  BASE_URL,
  APPLICATION_NAME_BE,
  VERSION,
} from '../configurations/config.js';

// call to Country api
export function getCountries() {
  const getCountryApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/location/country`;
  return axios.get(getCountryApi);
}
// call to State api
export function getStates(country) {
  const getStateApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/location/states?country=${country}`;
  return axios.get(getStateApi);
}
