import { all, fork, call, put } from 'redux-saga/effects';

import watchGetUsersSaga from './watchers/getUsers';
import watchGetPromotionSaga from './watchers/getPromotion';
import watchGetCustomerSupportSaga from './watchers/getCustomerSupport';
import watchGetHomeCarouselSaga from './watchers/getHomeCarousel';
import watchGetAuthentication from './watchers/getAuthentication';
import watchGetProductSaga from './watchers/getProduct';
import watchGetRecommendedProductsSaga from './watchers/getRecommendedProducts';
import watchGetFeaturedCategoriesSaga from './watchers/getFeaturedCategories';
import watchGetFeaturedProductsSaga from './watchers/getFeaturedProducts';
import watchGetOrganizationDetailsSaga from './watchers/getOrganizationDetails';
import watchGetProfileSaga from './watchers/getProfile';
import watchGetOrdersSaga from './watchers/getOrders';
import watchAddToCartSaga from './watchers/addToCart';
import watchGetMiniCartItemsSaga from './watchers/getMiniCartItems';
import watchUpdateCartSaga from './watchers/updateCart';
import watchRemoveFromCart from './watchers/removeFromCart';
import watchUpdateItemQuantitySaga from './watchers/updateItemQuantity';
import watchUpdateSelectedAddressSaga from './watchers/updateSelectedAddress';
import watchOrderPlaced from './watchers/orderPlaced';
import watchQuotePlaced from './watchers/quotePlaced';
import watchGetOrderDetailSaga from './watchers/getOrderDetail';
import watchGetOrderHistorySaga from './watchers/getOrderHistory';
import watchGetOrdersOnPaginationSaga from './watchers/getOrdersOnPagination';
import watchSetOrderApproved from './watchers/setOrderApproved';
import watchGetQuotesOnPaginationSaga from './watchers/getQuotesOnPagination';
import watchGetProductListingDataSaga from './watchers/getProductListingData';
import watchGetCategoryDataSaga from './watchers/getProductCategoryData';
import workerGetInvoiceListSaga from './watchers/getInvoiceList';
import watchGetAddressDataSaga from './watchers/getAddress';
import watchGetUpdatedProfileSaga from './watchers/getUpdatedProfile';
import watchAddAddressDataSaga from './watchers/addAddress';
import watchDeleteAddressDataSaga from './watchers/deleteAddress';
import watchUpdateAddressDataSaga from './watchers/updateAddress';
import workerGetUpdatedPasswordSaga from './watchers/getUpdatedPassword';
import workerGetRegistrationSaga from './watchers/getRegistration';
import watchGetinvoiceDetailSaga from './watchers/getInvoiceDetails';
import watchGetCountryDetailsSaga from './watchers/getCountryDetails';
import watchGetStateDeatilsSaga from './watchers/getStateDetails';
import watchGetOrderDetailSagaReset from './watchers/getOrderDetailReset';
import watchGetMultiInventoryValidationSaga from './watchers/getMultiInventoryValidation';
import watchAddtoQuoteMultipleItemsSaga from './watchers/addToQuoteMultipleitem';
import watchGetInventoryDetailsSaga from './watchers/getQuickOrderInventory';
import watchGetOrderOptionListDataSaga from './watchers/getOrderOptionList';
import watchGetPasswordSaga from './watchers/getPassword';
import watchAddContactDataSaga from './watchers/addContact';
import watchUpdateContactDataSaga from './watchers/updateContact';
import workerGetOptionlistDataSaga from './watchers/getOptionList';
import watchForgotPaswordSaga from './watchers/forgotPasswotd';
import watchGetContactSearchListSaga from './watchers/getContactSearch';
import watchGetQuotesListSaga from './watchers/getQuotesList';
import watchGetQuoteDetailsSaga from './watchers/getQuoteDetails';
import watchGetSubOrganizationDetailSaga from './watchers/getSubOrganizationDetails';
import watchGetSubOrganizationSaga from './watchers/getSubOrganization';
import watchGetRejectQuoteSaga from './watchers/rejectQuote';
import watchAddRequestQuoteSaga from './watchers/addRequestQuote';
import watchGetOrderStates from './watchers/getOrderStates';

function* root() {
  yield all([
    fork(watchGetUsersSaga),
    fork(watchGetPromotionSaga),
    fork(watchGetCustomerSupportSaga),
    fork(watchGetHomeCarouselSaga),
    fork(watchGetProductSaga),
    fork(watchGetAuthentication),
    fork(watchGetRecommendedProductsSaga),
    fork(watchGetFeaturedCategoriesSaga),
    fork(watchGetFeaturedProductsSaga),
    fork(watchGetOrganizationDetailsSaga),
    fork(watchGetProfileSaga),
    fork(watchGetOrdersSaga),
    fork(watchAddToCartSaga),
    fork(watchGetMiniCartItemsSaga),
    fork(watchUpdateCartSaga),
    fork(watchRemoveFromCart),
    fork(watchUpdateItemQuantitySaga),
    fork(watchUpdateSelectedAddressSaga),
    fork(watchOrderPlaced),
    fork(watchQuotePlaced),
    fork(watchGetOrderDetailSaga),
    fork(watchGetOrderHistorySaga),
    fork(watchGetOrdersOnPaginationSaga),
    fork(watchSetOrderApproved),
    fork(watchGetQuotesOnPaginationSaga),
    fork(watchGetProductListingDataSaga),
    fork(watchGetCategoryDataSaga),
    fork(workerGetInvoiceListSaga),
    fork(watchGetAddressDataSaga),
    fork(watchAddAddressDataSaga),
    fork(watchUpdateAddressDataSaga),
    fork(watchGetUpdatedProfileSaga),
    fork(watchDeleteAddressDataSaga),
    fork(workerGetUpdatedPasswordSaga),
    fork(workerGetRegistrationSaga),
    fork(watchGetinvoiceDetailSaga),
    fork(watchGetCountryDetailsSaga),
    fork(watchGetStateDeatilsSaga),
    fork(watchGetInventoryDetailsSaga),
    fork(watchGetOrderOptionListDataSaga),
    fork(watchGetPasswordSaga),
    fork(workerGetOptionlistDataSaga),
    fork(watchGetOrderDetailSagaReset),
    fork(watchGetMultiInventoryValidationSaga),
    fork(watchAddtoQuoteMultipleItemsSaga),
    fork(watchAddContactDataSaga),
    fork(watchUpdateContactDataSaga),
    fork(workerGetOptionlistDataSaga),
    fork(watchForgotPaswordSaga),
    fork(watchGetContactSearchListSaga),
    fork(watchGetQuotesListSaga),
    fork(watchGetQuoteDetailsSaga),
    fork(watchGetSubOrganizationDetailSaga),
    fork(watchGetSubOrganizationSaga),
    fork(watchGetRejectQuoteSaga),
    fork(watchAddRequestQuoteSaga),
    fork(watchGetOrderStates),
  ]);
}

export default root;
