import { put, takeLatest, call } from 'redux-saga/effects';

import {
  ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setCartStatus } from '../../actions/quickOrderAction';
import { addtoQuoteMultipleItems } from '../../api/quickOrderApi';
import { setCartData } from '../../actions/cartItemAction';

function* workerAddtoQuoteMultipleItemsSaga({
  payload: { items, clearCart },
}) {
  try {
    const response = yield call(
      addtoQuoteMultipleItems,
      items,
      clearCart
    );
    if (response.status === 200) {
      const data =
        response.data && response.data.orderList
          ? response.data.orderList[0]
          : null;
      yield put(setCartData(data));
      yield put(setCartStatus(true));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchAddtoQuoteMultipleItemsSaga() {
  yield takeLatest(
    ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA,
    workerAddtoQuoteMultipleItemsSaga
  );
}
