import React from 'react';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Hidden,
  Grid,
  Divider,
} from '@material-ui/core';
import './Styles.css';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import CustomLoader from '../../components/Shared/customLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
    background: '#273142',
  },
}));

export default function SimpleTable(data) {
  const classes = useStyles();
  console.log('===>data===>', data.invoiceList);
  let m = data;
  // let may = m.data;
  console.log('===>may===>', may);
  let may = data !== undefined ? m.data : null;

  if (may === null || may === undefined) {
    return (
      <>
        <Hidden only={'xs'}>
          <ContentLoader
            height={834}
            width={400}
            speed={2}
            primaryColor='#f3f3f3'
            secondaryColor='#ecebeb'></ContentLoader>
          {/* <CustomLoader /> */}
        </Hidden>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <ContentLoader
            height={1650}
            width={400}
            speed={2}
            primaryColor='#f3f3f3'
            secondaryColor='#ecebeb'></ContentLoader>
          {/* <CustomLoader /> */}
        </Hidden>
      </>
    );
  }
  const isInvoiceOpen = (invoiceStatus) => {
    let status = invoiceStatus === 'Open' ? true : false;
    return status;
  };
  return (
    <Paper className={classes.root}>
      <Hidden only={'xs'}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Invoice #</TableCell>
              <TableCell align='right'>Due Date</TableCell>
              <TableCell align='right'>Order #</TableCell>
              <TableCell align='right'>Status</TableCell>
              <TableCell align='right'>Amount</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {may &&
              may.length > 0 &&
              may.map((row) => (
                <TableRow key={row.invoice}>
                  <TableCell align='right'>{row.invoice}</TableCell>

                  <TableCell align='right'>{row.dateDue}</TableCell>
                  <TableCell align='right'>{row.order}</TableCell>
                  <TableCell align='right'>{row.status}</TableCell>
                  <TableCell align='right'>
                    $ {row.originalAmount}
                  </TableCell>
                  <TableCell align='right'>
                    <Grid
                      container
                      spacing={1}
                      style={{ margin: 'auto', width: 'auto' }}>
                      <Grid item className='customPadding'>
                        <Button
                          variant='contained'
                          className='view-details-btn'
                          disabled={!isInvoiceOpen(row.status)}>
                          Pay Invoice
                        </Button>
                      </Grid>
                      <Grid item className='customPadding'>
                        <Button
                          variant='outlined'
                          style={{ padding: '7px 12px' }}>
                          <Link
                            to={{
                              pathname: '/invoiceDetails',
                              state: {
                                invoiceId: row.invoice,
                                flag: false,
                              },
                            }}>
                            View Details
                          </Link>
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        {m != undefined &&
          may &&
          may.length > 0 &&
          may.map((row) => (
            <Grid
              container
              spacing={0}
              className='mobileInvoiceLayout'>
              <Grid item xs={7} className='customPadding'>
                <div>
                  <strong>Invoice # : </strong>
                  {row.invoice}
                </div>
              </Grid>
              <Grid item xs={5} className='customPadding'>
                <div>
                  <strong>Status : </strong>
                  {row.status}
                </div>
              </Grid>
              <Grid item xs={7} className='customPadding'>
                <div>
                  <strong>Order # : </strong>
                  {row.order}
                </div>
              </Grid>
              <Grid item xs={5} className='customPadding'>
                <div>
                  <strong>Due Date : </strong>
                  {row.dateDue}
                </div>
              </Grid>

              <Grid item xs={12} className='customPadding'>
                <div>
                  <strong>Amount : </strong>$ {row.originalAmount}
                </div>
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ margin: 'auto', textAlign: 'center' }}>
                <Grid item xs={6} className='customPadding'>
                  <Button
                    variant='contained'
                    className='view-details-btn'
                    disabled={!isInvoiceOpen(row.status)}>
                    Pay Invoice
                  </Button>
                </Grid>
                <Grid item xs={6} className='customPadding'>
                  <Button
                    variant='outlined'
                    style={{ padding: '7px 12px' }}>
                    <Link
                      to={{
                        pathname: '/invoiceDetails',
                        state: { invoiceId: row.invoice },
                      }}>
                      View Details
                    </Link>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Hidden>
    </Paper>
  );
}
