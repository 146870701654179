import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import BootstrapButton from '../Shared/bootstrapButton';
import './style.css';
import CustomPageModal from '../Shared/customPageModal';
import { BepsyImage } from '../BepsyImage';

const customCard = ({
  itemId,
  itemLabel,
  imgUrl,
  imgAltMsg,
  displayName,
  displaySku,
  itemSalePrice,
  itemListPrice,
  buttonClickHandler,
  activeView,
  showModal,
  isModalOpen,
  content,
}) => {
  if (activeView === 'list') {
    return (
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <div className='custom-card-list'>
          <div className='overlay-list'></div>
          <div
            className='button-list'
            onClick={(e) => showModal(e, itemId)}>
            <a href='#'>Quick Shop</a>
          </div>
          <Grid item sm={4} md={4} lg={3} xl={3}>
            <div className='card-image-list'>
              <BepsyImage src={imgUrl} />
            </div>
          </Grid>
          <Grid item sm={8} md={8} lg={9} xl={9}>
            <Grid item sm={8} md={8} lg={9} xl={9}>
              <div className='card-item-name-list'>
                <span>{displayName}</span>
              </div>
              <div
                className='skuColor align-list'
                style={{ padding: '25px 0px 10px 30px' }}>
                SKU : {displaySku}
              </div>
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className='list-bottom-line'>
              <Grid item sm={8} md={8} lg={8} xl={8}>
                {/* <div className='skuColor align-list'>
                  SKU : {displaySku}
                </div> */}
                <div className='card-item-price-list'>
                  <div className='align-list'>
                    ${itemSalePrice ? itemSalePrice : itemListPrice}
                  </div>
                </div>
              </Grid>

              <Grid item sm={4} md={4} lg={4} xl={4}>
                <div className='card-button'>
                  <BootstrapButton
                    isDisabled={false}
                    btnLabel={itemLabel}
                    margin='0'
                    buttonClick={buttonClickHandler}
                    buttonClickParams={[itemId]}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* {content[0] && <CustomPageModal
          show={isModalOpen}
          content={content}
          showModal={showModal}
        />} */}
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <div className='custom-card'>
          <div className='card-image'>
            <BepsyImage src={imgUrl} />
          </div>
          <div className='card-item-name'>
            <span>{displayName}</span>
          </div>
          <div className='skuColor'>SKU : {displaySku}</div>
          <div className='card-item-price'>
            <div>
              ${itemSalePrice ? itemSalePrice : itemListPrice}
            </div>
          </div>
          <div className='overlay'></div>
          <div
            className='button'
            onClick={(e) => showModal(e, itemId)}>
            <a href='#'>Quick Shop</a>
          </div>
          <div className='card-button'>
            <BootstrapButton
              isDisabled={false}
              btnLabel={itemLabel}
              margin='0'
              buttonClick={buttonClickHandler}
              buttonClickParams={[itemId]}
            />
          </div>
        </div>
        {/* {content[0] && (
          <CustomPageModal
            show={isModalOpen}
            content={content}
            showModal={showModal}
          />
        )} */}
      </Grid>
    );
  }
};

export default customCard;
