import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import BootstrapButton from './bootstrapButton';
import './style.css';
import { BepsyImage } from '../BepsyImage';

const customCarousel = ({
  headerText,
  dataList,
  btnLabel,
  clickHandler,
}) => {
  const dataLength = dataList ? dataList.length : 0;
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 1000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    dataList &&
    dataList.length > 0 && (
      <Grid className='externalContainer'>
        <div className='custom-carousel'>
          <p className='carousel-header'>{headerText}</p>
          <Slider {...settings} className='slick'>
            {dataList.map((data, i) => (
              <div
                className='card-container'
                key={`custom-${data.id}-${data.name}`}>
                <div className='card'>
                  <div
                    className='carousel-image'
                    style={{ width: '100%' }}>
                    <BepsyImage
                      title={data.title}
                      src={
                        data.imgUrl !== null
                          ? data.imgUrl
                          : 'https://images-na.ssl-images-amazon.com/images/I/71NDpXAjmpL._SL1500_.jpg'
                      }
                      alt={data.altMsg}
                    />
                  </div>
                  <p className='title-text'>{data.name}</p>
                  <p className='price-text'>${data.price}</p>

                  <BootstrapButton
                    isDisabled={false}
                    btnLabel={btnLabel}
                    margin='0'
                    buttonClick={clickHandler}
                    buttonClickParams={[data.id]}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Grid>
    )
  );
};

customCarousel.propTypes = {
  headerText: PropTypes.string.isRequired,
  dataList: PropTypes.array.isRequired,
  btnLabel: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default customCarousel;
