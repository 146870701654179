import {
  GET_ADDRESS,
  SET_ADDRESS,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_SELECTED_ADDRESS,
} from '../constants';

export const getAddressData = () => {
  return {
    type: GET_ADDRESS,
  };
};

export const setAddressData = (response) => {
  return {
    type: SET_ADDRESS,
    data: response.data,
  };
};

export const addAddressData = (data) => {
  return {
    type: ADD_ADDRESS,
    data,
  };
};

export const deleteAddressData = (data) => {
  return {
    type: DELETE_ADDRESS,
    data,
  };
};

export const updateAddressData = (data) => {
  return {
    type: UPDATE_ADDRESS,
    data,
  };
};

export const updateSelectedAddress = (
  order,
  address,
  addressType
) => {
  return {
    type: UPDATE_SELECTED_ADDRESS,
    payload: { order, address, addressType },
  };
};
