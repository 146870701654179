import React, { Component } from 'react';
import Slider from 'react-slick';
import defaultImg from '../../assets/PDP/default.png';
import defaultVideo from '../../assets/video/Well Seasoned _ Trek Bikes.mp4'

const productImageCarausel = ({ data }) => {
  const settings = {
    imgData: data,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider>
      {data &&
        data.carouselMedia.map((e, i) => {
          const CustomTag = `${e.tag}`;
          return (
            <div style={{ width: '100%' }} key={i}>
              <div>
                {e.tag === 'video' ?
                // use only MP4, WebM, and Ogg extension;
                  <video id="video" autobuffer height="240" width="360" poster={defaultImg} controls>
                    <source src={e.url} /> 
                  </video>
                  : <CustomTag src={e.url} />}
                  {/* <video id="video" autobuffer height="240" width="360" poster={defaultImg} controls>
                    <source src={defaultVideo} /> 
                  </video> */}
                  {/* <iframe src="https://player.vimeo.com/video/48978062?color=ff9933" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}

              </div>
            </div>
          );
        })}

      {data && data.images.fullURIs.length == 0 && (
        <img src={defaultImg}></img>
      )}
    </Slider>
  );
};

export default productImageCarausel;
