import React, { Component } from 'react';
import { Provider } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './store';
import Dashboard from './containers/DashboardV3';
//import Dashboard from './containers/Dashboard-V2';
import ProductDetails from './containers/ProductDetailsPage';
import Login from './containers/Loginv2';
import Account from './containers/Account';
import Cart from './containers/Cart';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import axios from 'axios';
import Checkout from './containers/Checkout';
import ProductSearchPage from './containers/ProductSearchPage';
import Invoice from './containers/InvoiceListing';
import InvoiceDetails from './containers/InvoiceDetails';
import Confirmation from './containers/Confirmation';
import Registration from './containers/Account/Registration';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import Orders from './containers/Orders';
import OrderDetails from './containers/Orders/OrderDetails';
import OrderDetailsContainer from './containers/OrderDetails';
import QuoteDetailsContainer from './containers/QuoteDetails';
import QuickOrderForm from './containers/QuickOrderForm';
import Quote from './containers/Quote';
import Maintenance from './components/StaticPages/Maintenance';
import TermsAndConditions from './components/StaticPages/TermsAndConditions';
import Privacy from './components/StaticPages/Privacy';
import Cookie from './components/StaticPages/Cookie';
import NotFound from './components/StaticPages/NotFound';
import AccessibilityStatement from './components/StaticPages/AccessibilityStatement';
import ContactUS from './components/StaticPages/ContactUs';
import Faq from './components/StaticPages/FAQ';
import AboutUs from './components/StaticPages/AboutUs';
import QuotesList from './containers/QuotesList';
import QuotesCheckout from './containers/QuotesCheckout';

import {
  BASE_URL,
  APPLICATION_NAME,
  VERSION,
  ENVIRONMENT,
  APPLICATION_NAME_BE,
  API_SETTINGS_CONFIG,
} from './configurations/config.js';
//import Login from '../src/containers/Login';
//import ResetPassword from './containers/ResetPassword';
import ResetPassword from './containers/ResetPassword';

import { ToastProvider } from 'react-toast-notifications';
import { getUserProfile } from './api/authenticationApi';
import { verifyOrganization } from './api/api';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false,
    };
  }

  componentDidMount() {
    try {
      if (APPLICATION_NAME_BE === 'ct') {
        this.setState({ isLoading: true });

        verifyOrganization()
          .then((res) => {
            if (!res.organizationId) {
              throw 'invalid organization';
            }

            this.setState({ isLoading: false, error: false });
            localStorage.setItem(
              'organizationId',
              res.organizationId
            );
          })
          .catch(() => {
            // show error message
            this.setState({
              error: true,
              isLoading: false,
            });
          });
      } else {
        this.setState({ isLoading: false });
      }

      const context = JSON.parse(localStorage.getItem('context'));
      const { profileId, siteId } = context;
      getUserProfile(profileId, siteId);
    } catch (err) {
      // console.error(err);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className='centered-text'>Invalid organization</div>
      );
    }

    if (this.state.isLoading) {
      return <div className='centered-text'>Loading...</div>;
    }

    const basic_token = localStorage.getItem('basic_token');
    const access_token = localStorage.getItem('access_token');
    const getBaseTokenApi =
      `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/` +
      'token';
    const refreshAccessTokenApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/verify`;
    const header = 'Bearer ' + access_token;
    if (basic_token && access_token) {
      const data = {};

      const options = {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      };

      axios
        .post(refreshAccessTokenApi, data, options)
        .then((res) => {
          if (res.data.success === false) {
            //verify auth api return true if session is valid

            alert('Session Expired');

            localStorage.clear();

            window.location.href = '/login';
          }
        })
        .catch((err) => {
          console.log('ERROR: ====', err);
        });
    } else {
      fetch(getBaseTokenApi).then(function(response) {
        response.json().then(function(responseText) {
          localStorage.setItem(
            'basic_token',
            responseText.basic_token
          );
        });
      });
    }

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ToastProvider>
            <Switch>
              <Route
                exact
                path='/maintenance'
                component={Maintenance}
              />
              <Route exact path='/privacy' component={Privacy} />
              <Route
                exact
                path='/terms'
                component={TermsAndConditions}
              />
              <Route exact path='/cookie' component={Cookie} />
              <Route
                exact
                path='/accessibility'
                component={AccessibilityStatement}
              />
              <Route exact path='/contact' component={ContactUS} />
              <Route exact path='/faq' component={Faq} />
              <Route exact path='/about' component={AboutUs} />
              <Route path='/quote' component={Quote} />
              <Route
                path='/quotes/:id'
                component={QuoteDetailsContainer}
              />
              <Route path='/quoteslist' component={QuotesList} />
              <Route
                path='/quickorderform'
                // component={QuickOrderForm}
                render={(props) => <QuickOrderForm {...props} />}
              />
              <Route path='/login' component={Login} />
              <Route path='/account' component={Account} />
              <Route
                exact
                path='/registration'
                component={Registration}
              />
              <Route
                exact
                path='/forgotPassword'
                component={ForgotPassword}
              />
              {/* <Route exact path='/orders' component={OrderLayout} />*/}
              <Route
                path='/orders/:id'
                component={OrderDetailsContainer}
              />
              <Route exact path='/orders' component={Orders} />{' '}
              <Route path='/orders/:id' component={Orders} />
              <Route
                exact
                path='/product/:id'
                component={ProductDetails}
              />
              <Route exact path='/checkout' component={Checkout} />
              <Route
                exact
                path='/quotecheckout'
                component={QuotesCheckout}
              />
              <Route path='/search' component={ProductSearchPage} />
              <Route path='/confirmation' component={OrderDetails} />
              <Route path='/cart' component={Cart} />
              <Route path='/invoice' component={Invoice} />
              <Route
                path='/invoiceDetails'
                component={InvoiceDetails}
              />
              <Route
                path='/orderConfirmation'
                component={Confirmation}
              />
              {/* <Route exact path='/' component={Dashboard} /> */}
              <Route exact path='/' component={Dashboard} />
              <Route
                exact
                path='/resetPassword'
                component={ResetPassword}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </ToastProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}
