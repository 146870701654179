import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './style.css';

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: 0,
    marginRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    marginRight: 0,
    padding: 0,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    marginRight: 0,
    padding: 0,
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

const formatLabel = (strData) => {
  if (strData.length < 20) return strData;

  strData = strData.substring(0, 20);
  strData += '...';
  return strData;
};

const customRadioGroup = ({
  name,
  filterType,
  defaultValue,
  options,
  clickHandler,
}) => {
  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        name={props.name}
        onClick={props.clickHandler}
        className={classes.root}
        color='default'
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <>
      {options && options.length && (
        <FormControl
          component='fieldset'
          style={{ display: 'block' }}>
          <RadioGroup
            style={{ display: 'block' }}
            defaultValue={defaultValue}
            aria-label='gender'
            name='customized-radios'>
            {options.map((option, i) => (
              <div
                className='filter-type-option'
                key={`${option.label}-${option.value}-${i}-option`}>
                <FormControlLabel
                  name={`${name}-${filterType}`}
                  style={{ margin: 0 }}
                  value={option.value}
                  control={
                    <StyledRadio clickHandler={clickHandler} />
                  }
                />
                <span
                  className='filter-option-label'
                  title={option.label}>
                  {formatLabel(option.label)}
                </span>
                <span className='filter-option-count'>
                  {option.count}
                </span>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

export default customRadioGroup;
