import {
  SET_PRODUCT,
  SET_FEATURED_PRODUCTS,
  SET_RECOMMENDED_PRODUCTS,
  CLEAR_PDP_PRODUCT,
  GET_PRODUCT_SAGA,
} from '../constants';

const initialState = {
  products: [],
  featured_products: [],
  recommended_products: [],
  productId: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        products: [...action.product.productList],
      };
    case SET_FEATURED_PRODUCTS:
      return {
        ...state,
        featured_products: action.featured_products,
      };
    case SET_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommended_products: action.recommended_products,
      };
    case CLEAR_PDP_PRODUCT:
      // return {
      //   products: [],
      //   featured_products: [],
      //   recommended_products: [],
      // };
      return initialState;
    case GET_PRODUCT_SAGA:
      return {
        ...state,
        productId: action.productId,
      };
    default:
      return state;
  }
};

export default productReducer;
