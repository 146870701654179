import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_INVOICE_LIST,
  SET_INVOICE_LIST,
  SET_ERROR_STATE,
} from '../../constants';
import {
  getInvoiceAction,
  setInvoice,
} from '../../actions/invoiceAction';
import { getInvoice } from '../../api/invoiceApi';

function* workerGetInvoiceListSaga({
  payload: { data, searchTerm },
}) {
  try {
    const invoiceListData = yield call(getInvoice, data, searchTerm);
    yield put(setInvoice(null));
    yield put(setInvoice(invoiceListData.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetInvoiceListSaga() {
  yield takeLatest(GET_INVOICE_LIST, workerGetInvoiceListSaga);
}
