import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const quickOrderQuantityStepper = ({
  selectedQuantity,
  stepperClickHandler,
  handleChange,
  product,
  inventory,
}) => {
  return (
    <>
      <div className='quantity-stepper1'>
        <input
          type='button'
          value='-'
          className='minus'
          data-field='quantity'
          onClick={(e) =>
            stepperClickHandler(product, inventory, 'decrease')
          }
        />
        <input
          value={selectedQuantity || ''}
          name='quantity'
          className='quantity-field1'
          maxLength='3'
          onChange={(event) => handleChange(event, product)}
        />
        <input
          type='button'
          value='+'
          className='plus'
          data-field='quantity'
          onClick={(e) =>
            stepperClickHandler(product, inventory, 'increase')
          }
        />
      </div>
    </>
  );
};

quickOrderQuantityStepper.propTypes = {
  selectedQuantity: PropTypes.number.isRequired,
  stepperClickHandler: PropTypes.func.isRequired,
};

export default quickOrderQuantityStepper;
