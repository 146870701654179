import { SET_COUNTRY_DETAILS, SET_STATE_DETAILS } from '../constants';

const initialState = { countries: [], states: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRY_DETAILS:
      return {
        ...state,
        countries: action,
      };
    case SET_STATE_DETAILS:
      return {
        ...state,
        states: action,
      };
    default:
      return state;
  }
}
