import {
  SET_PRODUCT,
  GET_PRODUCT_SAGA,
  CLEAR_PDP_PRODUCT,
} from '../constants';

export function setProducts(product) {
  return {
    type: SET_PRODUCT,
    product,
  };
}

// Sagas
export function getProductSaga(productId) {
  return {
    type: GET_PRODUCT_SAGA,
    productId,
  };
}

export function clearProduct() {
  return {
    type: CLEAR_PDP_PRODUCT,
  };
}
