import {
  GET_QUOTE_LIST,
  SET_QUOTE_ID,
  SET_QUOTE_LIST,
  SET_QUOTE_DETAILS,
  RESET_QUOTE_DETAILS,
  GET_QUOTE_ID,
  SET_REJECT_QUOTE_SUCCESS,
  SET_REQUEST_QUOTE,
  RESET_REQUEST_QUOTE
} from '../constants';

const initialState = {
  quoteList: [],
  quoteDetails: null,
  quoteId: null,
  quoteData: null,
  requestQuoteRes: null
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_QUOTE_LIST:
      return {
        ...state,
        quoteList: action.data ? action : [],
      };

    case SET_QUOTE_DETAILS:
      return {
        ...state,
        quoteDetails: action.data,
      };

    case RESET_QUOTE_DETAILS:
      return {
        ...state,
        quoteDetails: initialState.quoteDetails,
      };
    case SET_QUOTE_ID:
    case GET_QUOTE_ID:
      return {
        ...state,
        quoteId: action.data
      }
    case SET_REJECT_QUOTE_SUCCESS:
      return {
        ...state,
        quoteData: action.data
      }
    case SET_REQUEST_QUOTE:
      return {
        ...state,
        requestQuoteRes: action.data
      };
      case RESET_REQUEST_QUOTE:
        return {
          ...state,
          requestQuoteRes: null
        }
    default:
      return state;
  }
}
