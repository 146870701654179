import React from 'react';
import Box from '@material-ui/core/Box';
import CustomCard from './customCard';

const listingPanel = ({
  listingData,
  buttonClickHandler,
  showModal,
  isModalOpen,
  content,
  activeView,
}) => {
  return (
    <div className='listing-panel-container'>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Box
          display='flex'
          flexWrap='wrap'
          flexDirection='row'
          p={0}
          m={0}>
          {listingData &&
            listingData.length > 0 &&
            listingData.map((data, i) => (
              <CustomCard
                key={`listing-panel-${data.compositeProducts[0].componentSku}-${i}`}
                itemId={data.sfid} // Change from data.compositeProducts[0].componentSku
                itemLabel={'Know More'}
                activeView={activeView}
                imgUrl={
                  data.compositeProducts[0].EProductMedia.smallURI
                }
                imgAltMsg={
                  data.compositeProducts[0].EProductMedia
                    .smallAltMessage
                }
                displayName={
                  data.sfdcName ? data.sfdcName : data.alternateName
                }
                displaySku={data.SKU}
                itemSalePrice={
                  data.compositeProducts[0].priceEntry.salePrice
                }
                itemListPrice={
                  data.compositeProducts[0].priceEntry.listPrice
                }
                buttonClickHandler={buttonClickHandler}
                showModal={showModal}
                isModalOpen={isModalOpen}
                content={content}
              />
            ))}
        </Box>
      </div>
    </div>
  );
};

export default listingPanel;
