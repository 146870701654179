import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Link as LinkToForgotPassword,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withToastManager } from 'react-toast-notifications';

import { Link, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import 'font-awesome/css/font-awesome.min.css';
import './Styles.css';
import Hidden from '@material-ui/core/Hidden';
import { authorize } from '../../actions/authAction';
import loginimgg from '../../assets/Login/login.jpeg';
import ppgLoginImg from '../../assets/Login/ppg-login-image.jpg';
import ventecImg from '../Dashboard-V2/img/ventec-banner.jpg';
import Logo from '../../assets/Logo/logo.png';
import VentecLogo from '../../assets/Logo/ventec-logo.png';
import PPGLogo from '../../assets/Logo/ppg-logo.png';
import Footer from '../Footer';
import CircularProgressBar from '../../components/Shared/spanLoader';

import ResetPassword from '../ResetPassword';
import { getApplicationDetails } from '../../api/api';

function getSiteIdFromHost() {
  const result = /([a-z0-1-]+)\.([a-z0-1-]+)\./gi.exec(
    window.location.host
  );

  const siteId = result && result[2];

  return siteId && siteId.replace('-qa', '');
}

function getCmsSiteIdFromHost() {
  const result = /([a-z0-1-]+)\.([a-z0-1-]+)\./gi.exec(
    window.location.host
  );
  return result && result[2];
}

class Login extends PureComponent {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    // const siteId = urlParams.get('siteId');

    this.state = {
      email: '',
      password: '',
      isButtonDisabled: false,
      showRegistration: false,
      showResetPassword: false,
      siteId: getCmsSiteIdFromHost() || 'siteUS',
      siteOptions: [],
    };
  }

  handleEmailChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  handlePasswordChange = (evt) => {
    this.setState({
      password: evt.target.value,
      isButtonDisabled: false,
    });
  };

  handleSiteIdChange = (evt) => {
    this.setState({
      siteId: evt.target.value,
    });
  };

  onFormSubmit = (e) => {
    const { authorize } = this.props;
    e.preventDefault();
    const login = this.login.value;
    const password = this.password.value;
    const siteId = getSiteIdFromHost(); // get siteId from local storage

    /**
     * <organizaiton-id>.<site-id>.objectedge.com
     *.ventec.objectedge.com
     *.truckpro.objectedge.com
     *.tigerdirect.objectedge.com
     *.saputo.objectedge.com
     */
    authorize(login, password, siteId);
    this.setState({
      password: '',
      isButtonDisabled: true,
    });
  };
  // Registration
  gotoRegistration = () => {
    this.setState({
      showRegistration: true,
    });
  };

  componentDidMount() {
    getApplicationDetails().then((details) => {
      this.setState({
        siteOptions: details.siteOptions,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.isButtonDisabled &&
      this.props.error !== prevProps.error
    ) {
      // condition added to check with prevProps value and showing current props value
      if (this.props.error) {
        prevProps.toastManager.add(this.props.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        this.setState({
          isButtonDisabled: false,
        });
        if (this.props.error === 'Password Generated') {
          this.setState({
            showResetPassword: true,
          });
        }
      }
    }
    if (
      this.props.password[0].password !==
      prevProps.password[0].password
    ) {
      if (
        this.props.password[0].password &&
        this.props.password[0].password.status === 200
      ) {
        this.setState({
          showResetPassword: false,
        });
      }
    }
  }

  getCurrentSite() {
    return (
      this.state.siteOptions.find(
        (item) => item.siteId === this.state.siteId
      ) || {
        siteId: 'siteUs',
        displayName: 'Site US',
        logo: Logo,
        dashboardImg: 'imgUrl',
        loginImg: loginimgg,
      }
    );
  }

  render() {
    const token = localStorage.getItem('access_token');

    if (token) {
      return <Redirect to='/' />;
    }
    if (this.state.showRegistration) {
      return <Redirect to='/Registration' />;
    }
    if (this.state.showResetPassword) {
      return <ResetPassword />;
    }
    return (
      <Grid>
        <Grid
          item
          xs={12}
          sm={12}
          className='set-margin set-border-bottom'
          style={{ display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={5}
            md={12}
            className='set-border'>
            <Container
              component='main'
              maxWidth='xs'
              className='align-center'>
              <CssBaseline />
              <div className='paper'>
                <div className={`logoContainer ${this.state.siteId}`}>
                  <Link href='/'>
                    <img
                      src={this.getCurrentSite().logo}
                      alt='logo'
                    />
                  </Link>
                </div>
                <div></div>
                <h1 className='text-style'>Log In </h1>
                <form
                  className='login-form'
                  onSubmit={this.onFormSubmit}>
                  <input
                    required
                    fullwidth='true'
                    id='email'
                    name='email'
                    autoComplete='email'
                    className='text-format'
                    ref={(_ref) => (this.login = _ref)}
                    type='email'
                    placeholder='Email'
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                  <br />
                  <br />

                  <input
                    variant='outlined'
                    required
                    fullwidth='true'
                    name='password'
                    type='password'
                    id='password'
                    className='text-format'
                    ref={(_ref) => (this.password = _ref)}
                    placeholder='Password'
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                  <br />
                  <br />
                  {/* <select
                    id='siteId'
                    value={this.state.siteId}
                    className='selectDrop'
                    onChange={this.handleSiteIdChange}>
                    {this.state.siteOptions.map((item) => (
                      <option value={item.siteId}>
                        {item.displayName}
                      </option>
                    ))}
                  </select> */}
                  <br />
                  <br />
                  <div
                    style={{
                      display: 'inline-flex',
                      textAlign: 'center',
                    }}>
                    {/* <button
                      type='submit'
                      variant='contained'
                      color='#e41e26'
                      size='large'
                      className='mob-button'
                      style={{
                        backgroundColor: '#e41e26',
                        color: '#ffffff',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        fontWeight: 'bold'
                      }}
                      disabled={this.state.isButtonDisabled}>
                      <span className='mob-text'> Submit </span>
                    </button> */}
                    <Button
                      variant='contained'
                      color='secondary'
                      type='submit'
                      onClick={this.onFormSubmit}
                      disabled={this.state.isButtonDisabled}
                      style={{
                        backgroundColor: '#e41e26',
                        color: '#ffffff',
                        // marginLeft: 10,
                        marginBottom: 10,
                        width: 100,
                        height: 41,
                      }}>
                      Submit
                    </Button>
                    <span className='circularLoader'>
                      {' '}
                      {this.props.error == null &&
                        this.state.isButtonDisabled && (
                          <CircularProgressBar />
                        )}{' '}
                    </span>
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid container>
                    <Grid item xs>
                      <LinkToForgotPassword
                        className='forgotPasswordLink'
                        to={{
                          pathname: '/forgotPassword',
                          state: { email: this.state.email },
                        }}>
                        {' '}
                        Forgot Password?
                      </LinkToForgotPassword>
                      <br />
                      <br />
                      First time user?
                      <Link onClick={this.gotoRegistration}>
                        Register for Account
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              lg={7}
              md={12}
              className='MuiContainer-root align-center img-set set-border full-height'
              style={{
                backgroundImage: `url(${
                  this.getCurrentSite().homePageImg
                })`,
              }}
            />
          </Hidden>
        </Grid>
        <Grid>{/* <Footer /> */}</Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  error: PropTypes.string,
  token: PropTypes.string,
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.auth.error,
  password: [state.setPasswordReducer.password],
});
const mapDispatchToProps = (dispatch) => ({
  authorize: (login, password, siteId) =>
    dispatch(authorize(login, password, siteId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Login));
