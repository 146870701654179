import React from 'react';
import {
  Grid,
  FormControlLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TableFooter,
  Checkbox,
} from '@material-ui/core';

import './Styles.css';
import CustomError from '../Shared/customErrorText';
import { Link } from 'react-router-dom';
import {emailCartApi} from '../../api/cartApi'
import {
  withToastManager,
  useToasts
} from 'react-toast-notifications'

const OrderSummary = ({
  placeOrder,
  summary,
  orderSummaryLabel = 'Place Order',
  displayAcceptTerms = false,
  isAllProductsAvailable,
}) => {
  if (summary) {
    var {
      finalSubTotalAmount: subTotal,
      finalTaxAmount: tax,
      finalShippingAmount: shipping,
      orderDiscountAmount: discount,
      finalOrderAmount: orderTotal,
    } = summary;
  }

  const [agreeChecked, setAgreeChecked] = React.useState(false);
  const [emailId,setEmailId] = React.useState('');
  const [emailClick, setEmailClick] = React.useState(false);
   const {
     addToast
   } = useToasts();

  const handleAgree = (event) => {
    setAgreeChecked(event.target.checked);
  };
  const emailCart=()=>{
    setEmailClick(true);
  }
  const onFormSubmit=(e)=>{   
      e.preventDefault();
  }
  const handleEmailChange=(e)=>{
    setEmailId(e.target.value);
  }
  async function onFormClick() {
    const res = await emailCartApi(emailId);
    const emailSent= localStorage.getItem("emailFlag");

    if (emailSent === "true"){
      addToast("Email Sent successfully", {
        appearance: 'success',
        autoDismiss: true,
      })
    }
      else if (emailSent=== "false") {
        addToast("Error Sending Email", {
          appearance: 'warning',
          autoDismiss: true,
        })
      }
     setEmailId('');
     setEmailClick(false);
     localStorage.setItem("emailFlag", '');
  }

  return (
    <Grid
      container
      direction='row'
      className='order-summary-container'>
      <Grid item lg={12} md={12} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <h4>Order Summary</h4>
              </TableCell>
            </TableRow>
            {/* <TableRow>
							<TableCell colSpan={2} className="divider">
								<Divider />
							</TableCell>
						</TableRow> */}
          </TableHead>
          <TableBody className='order-summary-tbody'>
            <TableRow>
              <TableCell>Sub Total</TableCell>
              <TableCell>{subTotal && subTotal.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tax</TableCell>
              <TableCell>{tax && tax.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shipping</TableCell>
              <TableCell>{shipping && shipping.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Discounts</TableCell>
              <TableCell>{discount && discount.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter className='order-summary-tfooter'>
            <TableRow>
              <TableCell>
                <h5>Order Total</h5>
              </TableCell>
              <TableCell>{orderTotal}</TableCell>
            </TableRow>
            <TableRow
              style={{ display: displayAcceptTerms ? '' : 'none' }}>
              <TableCell colSpan={2}>
                <FormControlLabel
                  checked={agreeChecked}
                  onChange={handleAgree}
                  control={
                    <Checkbox
                      color='primary'
                      inputProps={{
                        'aria-label': 'uncontrolled-checkbox',
                      }}
                      value='acceptTerms'
                    />
                  }
                  label='Agree to Order Terms & Conditions'
                />
              </TableCell>
            </TableRow>
            <TableRow style={{ display: displayAcceptTerms ? '' : 'none' }}>
              <TableCell colSpan={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      inputProps={{
                        'aria-label': 'uncontrolled-checkbox',
                      }}
                      value='pickUpInStore'
                    />
                  }
                  label='Pick-up in store'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {agreeChecked && (
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={placeOrder}
                    className='placeorder-btn'>
                    {orderSummaryLabel}
                  </Button>
                )}
                {!agreeChecked && orderSummaryLabel == 'Place Order' && (
                  <Button fullWidth variant='contained' disabled>
                    {orderSummaryLabel}
                  </Button>
                )}
                {!isAllProductsAvailable ? (
                  orderSummaryLabel == 'Checkout' && (
                    <>
                      <Button fullWidth variant='contained' disabled>
                        {orderSummaryLabel}
                      </Button>
                      <CustomError
                        masg='Please Remove Out of Stock Products'
                        displayType='block'
                        margin='5px 0 0 0'
                      />
                    </>
                  )
                ) : (
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={placeOrder}
                    className='placeorder-btn'>
                    {orderSummaryLabel}
                  </Button>
                )}
                {orderSummaryLabel == 'Checkout' && (
                  <div className='display-property'>
                    <br />
                    <Button>
                      <Link to='/quote'>Request Quote</Link>
                    </Button>
                    <Button onClick={emailCart}>
                      <i class='fa fa-envelope' aria-hidden='true' style={{padding:'2px'}}></i>           
                        <a>
                          Email Cart
                        </a>
                    </Button>
                  </div>
                )}
                 {
                     summary && emailClick && < form
                  onSubmit={onFormSubmit}
                  >
                  <input
                    required
                    fullwidth='true'
                    id='email'
                    name='email'
                    autoComplete='email'
                    className = 'email-cart'
                    type='text'
                    placeholder='Please enter email comma seperated email id'
                    value = {
                      emailId
                    }
                    onChange={handleEmailChange}
                  />
                  <div
                    className = 'align-div' >
                    <Button
                      variant='contained'
                      color='secondary'
                      type='submit'
                      onClick = {
                        onFormClick
                      }
                      disabled = {
                        emailId === ''
                      }
                      style={{
                        backgroundColor: '#e41e26',
                        color: '#ffffff',
                        // marginLeft: 10,
                        marginBottom: 10,
                        width: 100,
                        height: 41,
                      }}>
                      Submit
                    </Button>
                  </div>
                </form>
                    }
                {/* {isAllProductsAvailable &&
                  orderSummaryLabel == 'Checkout' && (
                    <Button
                      fullWidth
                      variant='contained'
                      onClick={placeOrder}
                      className='placeorder-btn'>
                      {orderSummaryLabel}
                    </Button>
                  )} */}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
