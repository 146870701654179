import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_PASSWORD_SAGA, SET_ERROR_STATE } from '../../constants';
import { setPassword } from '../../actions/setPasswordAction';
import { getPassword } from '../../api/getPasswordApi';

function* workerGetPasswordSaga({
  payload: { email, oldPassword, newPassword, newConfirmPassword },
}) {
  try {
    const password = yield call(
      getPassword,
      email,
      oldPassword,
      newPassword,
      newConfirmPassword
    );
    yield put(setPassword(password));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({
        type: SET_ERROR_STATE,
        errorData,
      });
    }
  }
}

export default function* watchGetPasswordSaga() {
  yield takeLatest(GET_PASSWORD_SAGA, workerGetPasswordSaga);
}
