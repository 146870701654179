import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFeaturedProductsSaga } from '../../actions';
import 'react-multi-carousel/lib/styles.css';
import Grid from '@material-ui/core/Grid';
import ContentLoader from 'react-content-loader';
import CustomCarousel from '../../components/Shared/customCarousel';
import {
  Route,
  Switch,
  Link,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
class FeaturedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { productId: null };
  }

  componentDidMount() {
    this.props.getFeaturedProductsSaga();
  }

  featuredProductClickHandler = (e, productId) => {
    this.setState({ productId: productId });
  };
  render() {
    let { featured_products } = this.props;
    featured_products = featured_products[0].items;
    if (!featured_products) return <ContentLoader />;

    let featuredProducts = [];
    const fturedProducts = featured_products;
    fturedProducts.map((data, i) => {
      let stubRecomndProducts = {
        id: '',
        name: '',
        title: '',
        imgUrl: '',
        altMsg: '',
        price: '',
      };
      stubRecomndProducts.id = data['product_id'];
      stubRecomndProducts.name = data['displayName'];
      stubRecomndProducts.title = data['sfdcName'];
      stubRecomndProducts.imgUrl = data['img_url'];
      stubRecomndProducts.altMsg = data['fullAltMessage'];
      stubRecomndProducts.price = data['listPrice'];
      featuredProducts.push(stubRecomndProducts);
    });

    if (this.state.productId) {
      return <Redirect to={`/product/${this.state.productId}`} />;
    }

    return (
      <Grid>
        <CustomCarousel
          headerText='Featured Products'
          dataList={featuredProducts}
          btnLabel='Know More'
          clickHandler={this.featuredProductClickHandler}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  featured_products: [state.productReducer.featured_products],
});

const mapDispatchToProps = (dispatch) => ({
  getFeaturedProductsSaga: () => dispatch(getFeaturedProductsSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedProducts);
