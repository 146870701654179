import React from 'react';

export default ({ variants, selectedColor, clickHandler }) => {
  const colorCode = [];
  let colorName = [];
  let defaultColor = '';

  if (variants) {
    colorName = Object.keys(variants.components);
    defaultColor = colorName[0];
    colorName.map((e, i) => {
      colorCode.push(variants.components[e]);
    });
  }

  defaultColor = selectedColor || defaultColor;

  return (
    variants && (
      <>
        <div className='color-swatch'>
          <div className='color-box'>
            <span className='color-label'>Color: </span>
            <span className='color-name'>{defaultColor}</span>
          </div>
          <div className='color-swatch-box'>
            {colorCode.map((obj, i) => (
              <div className='outer circle' key={i}>
                <div
                  name={colorName[i]}
                  className={`inner circle ${
                    selectedColor === colorName[i]
                      ? 'shapeborder'
                      : ''
                  }`}
                  //  style = {
                  //    {
                  //      'background': obj.code.split(',').length > 1 ? obj.code.split(',')[1] : obj.code.split(',')[0]
                  //    }
                  //  }

                  style={{
                    background:
                      obj.code && obj.code.split(',').length > 1
                        ? 'linear-gradient( -45deg, black, ' +
                          obj.code.split(',')[0] +
                          ' 49%, white 49%, white 51%,' +
                          obj.code.split(',')[1] +
                          ' 51%)'
                        : obj.code && obj.code.split(',')[0],
                  }}
                  onClick={clickHandler}>
                  &nbsp;
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};
