import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

// Registration
export const getRegistration = (
  firstName,
  lastName,
  phoneNumber,
  email,
  address1,
  address2,
  city,
  state,
  country,
  postalCode,
  companyName,
  comments
) => {
  const access_token = localStorage.getItem('access_token');
  const headersProfile = 'Bearer ' + access_token;

  const reqBody = {
    account: {
      firstName: firstName,
      lastName: lastName,
      // userName: 'sTESTING',
      // primaryPhone: '8885551212',
      companyName: companyName,
      organizationPhone: phoneNumber,
      organizationEmail: email,
      // companyType: 'sRetail',
      // currencyCode: '',
      // language: '',
      // taxExemptFlag: false,
      customField1: comments,
      // customField2: null,
      secondaryAddresses: [
        {
          country: country,
          lastName: lastName,
          // address3: '',
          city: city,
          address2: address2,
          // prefix: '',
          address1: address1,
          postalCode: postalCode,
          // jobTitle: '',
          companyName: companyName,
          county: 'United States of America',
          // suffix: '',
          firstName: 'Test',
          phoneNumber: phoneNumber,
          // faxNumber: '',
          // middleName: '',
          state: state,
          email: email,
        },
      ],
    },
  };

  const reqHeader = {
    Authorization: headersProfile,
    'Bepsy-SiteId': 'siteUS',
    'Content-type': 'application/JSON',
  };

  const registrationApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization`;

  return axios.post(registrationApiUrl, reqBody, {
    headers: reqHeader,
  });
};
