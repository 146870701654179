import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_ORGANIZATION_LIST,
  SET_ERROR_STATE,
} from '../../constants';
import { setSubOrganizationDetails } from '../../actions/organizationAction';
import { getSubOrganizations } from '../../api/organizationApi';
import { getUsers } from '../../api/api';

function* workerGetSubOrganizationSaga() {
  try {
    // const users = yield call(getUsers);
    const users = yield call(getSubOrganizations);
    console.log('===> users ===>', users);
    yield put(setSubOrganizationDetails(users));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetSubOrganizationSaga() {
  yield takeLatest(
    GET_ORGANIZATION_LIST,
    workerGetSubOrganizationSaga
  );
}
