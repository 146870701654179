import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import CustomSelect from '../Shared/noOutlineSelect';
import Pagination from '../Shared/customPagination';
import Filter from '../../assets/icons/preferences.svg';
import MobileFilter from '../Shared/mobileFilter';
import './style.css';

const pageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '50',
    value: '50',
  },
];

const listingHeaderFilter = ({
  response,
  sortOptions,
  selectedSort,
  sortByChangeHandler,
  resultPerPage,
  resultPerPageChangeHandler,
  isMobileFilterOpen,
  mobilerFilterClickHandler,
  facetData,
  filterOptionClickHandler,
  categoryClickHandler,
  currentPage,
  pageChangeClickHandler,
  clearAllFilter,
  mobileFilterApplyHandler,
  adjustments,
  category,
  activeView,
  toggleView,
}) => {
  return (
    <React.Fragment>
      <div className='listing-header'>
        <div
          style={{
            width: '50%',
            padding: '0',
            display: 'inline-block',
          }}>
          <Box display='flex' flexDirection='row'>
            <Box flexGrow={1} width='130px'>
              <span className='sort-by'>Sort by:</span>
              <div className='sort-by-select'>
                <CustomSelect
                  width={110}
                  options={sortOptions}
                  selectedOption={selectedSort}
                  optionChange={sortByChangeHandler}
                />
              </div>
            </Box>
            <Hidden only={['xs', 'sm', 'md']}>
              <Box
                flexGrow={1}
                flexDirection='row'
                position='relative'>
                <span className='sort-by'>Results per Page:</span>
                <div className='results-per-page-select'>
                  <CustomSelect
                    width={20}
                    options={pageOptions}
                    selectedOption={resultPerPage}
                    optionChange={resultPerPageChangeHandler}
                  />
                </div>
              </Box>
            </Hidden>
          </Box>
        </div>
        <Hidden only={['xs', 'sm', 'md']}>
          <div className='pagination-grid-list'>
            <div style={{ display: 'inline-flex' }}>
              <div
                className={
                  activeView === 'grid' ? 'activeView' : 'inActive'
                }
                onClick={(e) => toggleView(e, 'grid')}>
                <ViewComfyIcon />
                <span className='activeView-span'> Grid</span>
              </div>
              <div
                className={
                  activeView === 'list' ? 'activeView' : 'inActive'
                }
                onClick={(e) => toggleView(e, 'list')}>
                <ListIcon />
                <span className='activeView-span'> List</span>
              </div>
            </div>

            <Pagination
              currentPage={currentPage}
              recordPerPage={resultPerPage}
              totalRecords={response.totalRecords}
              handlePageClick={pageChangeClickHandler}
            />
          </div>
        </Hidden>
        <Hidden only={['lg', 'xl']}>
          <div
            style={{
              width: '50%',
              textAlign: 'right',
              display: 'inline-block',
            }}>
            <Hidden only={['xs']}>
              <div
                style={{
                  width: '60%',
                  paddingRight: 60,
                  display: 'inline-flex',
                }}>
                <div
                  className={
                    activeView === 'grid' ? 'activeView' : 'inActive'
                  }
                  onClick={(e) => toggleView(e, 'grid')}>
                  <ViewComfyIcon />
                  <span className='activeView-span'> Grid</span>
                </div>
                <div
                  className={
                    activeView === 'list' ? 'activeView' : 'inActive'
                  }
                  onClick={(e) => toggleView(e, 'list')}>
                  <ListIcon />
                  <span className='activeView-span'> List</span>
                </div>
              </div>
            </Hidden>
            <span className='sort-by filter-label'>Filter: </span>
            <img
              src={Filter}
              className='filter-icon'
              onClick={mobilerFilterClickHandler}></img>

            <MobileFilter
              isMobileFilterOpen={isMobileFilterOpen}
              mobilerFilterClickHandler={mobilerFilterClickHandler}
              facetData={facetData}
              filterOptionClickHandler={filterOptionClickHandler}
              categoryClickHandler={categoryClickHandler}
              clearAllFilter={clearAllFilter}
              mobileFilterApplyHandler={mobileFilterApplyHandler}
              adjustments={adjustments}
              category={category}
            />
          </div>
        </Hidden>
      </div>
      <div className='divider'></div>
    </React.Fragment>
  );
};

export default listingHeaderFilter;
