import { SET_MINI_CART_ITEMS } from '../constants';

const initialState = { minicart_items: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_MINI_CART_ITEMS:
      return {
        ...state,
        minicart_items: action.minicart_items
      };
    default:
      return state;
  }
}
