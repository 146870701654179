import React from 'react';
import {
  makeStyles,
  withStyles,
  fade,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    padding: 0,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 15px',
    transition: theme.transitions.create([
      'border-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#e41e26',
      boxShadow: `${fade('#e41e26', 0.25)} 0 0 0 0.2rem`,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
    padding: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const customizedMultiSelect = ({
  name,
  variants,
  selectedVariant,
  variantChange,
}) => {
  const classes = useStyles();
  return (
    variants && (
      <div className='select-input'>
        <FormControl className={classes.formControl}>
          <Select
            labelId='demo-mutiple-checkbox-label'
            id='demo-mutiple-checkbox'
            name={name}
            multiple
            value={selectedVariant}
            onChange={variantChange}
            input={<BootstrapInput />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}>
            {variants !== undefined &&
              variants.map((e, i) => (
                <MenuItem key={i} value={e.value}>
                  <Checkbox
                    checked={selectedVariant.indexOf(e.value) > -1}
                  />
                  <ListItemText primary={e.label} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  );
};

export default customizedMultiSelect;
