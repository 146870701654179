import {
  SET_MULTI_INVENTORY_VALIDATION,
  ADD_PRODUCT_QUICK_ORDER_FORM,
  REMOVE_PRODUCT_QUICK_ORDER_FORM,
  UPDATE_PRODUCT_QUANTITY,
  SET_CART_STATUS,
  CLEAR_QUICK_ORDER_STATE,
} from '../constants';
const initialState = {
  inventoryData: null,
  cartStatus: false,
  products: [],
};

export default function setInventoryData(
  state = initialState,
  action
) {
  switch (action.type) {
    case ADD_PRODUCT_QUICK_ORDER_FORM:
      return {
        ...state,
        products: [action.product, ...state.products],
      };
    case SET_MULTI_INVENTORY_VALIDATION:
      return {
        ...state,
        inventoryData: action.payload.data,
      };
    case REMOVE_PRODUCT_QUICK_ORDER_FORM:
      let newProduct = [...state.products];
      newProduct.splice(action.index, 1);
      return {
        ...state,
        inventoryData: null,
        products: newProduct,
      };
    case UPDATE_PRODUCT_QUANTITY:
      let newProducts = [...state.products];
      newProducts.map((ob) => {
        if (ob.SKU === action.data.product.SKU) {
          ob.quantity = action.data.value;
        }
      });
      return {
        ...state,
        inventoryData: null,
        products: newProducts,
      };
    case SET_CART_STATUS:
      return {
        ...state,
        cartStatus: action.status,
      };
    case CLEAR_QUICK_ORDER_STATE:
      return {
        inventoryData: null,
        cartStatus: false,
        products: [],
      };
    default:
      return state;
  }
}
