import {
  GET_COUNTRY_DETAILS_SAGA,
  GET_STATE_DETAILS_SAGA,
  SET_COUNTRY_DETAILS,
  SET_STATE_DETAILS,
} from '../constants';

export const getCountryDataSaga = () => {
  return {
    type: GET_COUNTRY_DETAILS_SAGA,
  };
};

export const getStateDataSaga = (country) => {
  return {
    type: GET_STATE_DETAILS_SAGA,
    country,
  };
};
export const setCountryData = (data) => ({
  type: SET_COUNTRY_DETAILS,
  data,
});

export const setStateData = (data) => ({
  type: SET_STATE_DETAILS,
  data,
});
