import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
  APPLICATION_NAME,
  ENVIRONMENT
} from '../configurations/config.js';
import { headerData } from './headerData';

//  order list
export function getDashboardSummary() {
  const options = headerData();
  const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/dashboardInfo`;
  return axios.get(getOrdersApi, options);
}

export function getAnnouncements() {
  const options = headerData();
  const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME}/${VERSION}/${ENVIRONMENT}/items/banners/home_page_announcement`;
  return axios.get(getOrdersApi, options);
}

