import { put, takeEvery, call } from 'redux-saga/effects';

import { GET_ORDER_OPTION_LIST } from '../../constants';
import { setOrderOptionList } from '../../actions';
import { getQuickOrderProductWithTypeAhead } from '../../api/productlistingApi';

function* workerGetOrderOptionlistDataSaga({
  payload: { searchTerm },
}) {
  const optionList = yield call(
    getQuickOrderProductWithTypeAhead,
    searchTerm
  );
  yield put(setOrderOptionList(optionList.data));
}

export default function* watchGetOrderOptionListDataSaga() {
  yield takeEvery(
    GET_ORDER_OPTION_LIST,
    workerGetOrderOptionlistDataSaga
  );
}
