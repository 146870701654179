import { SET_HOME_CAROUSEL } from '../constants';

const initialState = { home_carousel: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_CAROUSEL:
      return {
        ...state,
        home_carousel: action.home_carousel
      };
    default:
      return state;
  }
}
