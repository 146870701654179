import { put, takeLatest, call, select } from 'redux-saga/effects';

import { GET_QUOTE_ID, SET_ERROR_STATE } from '../../constants';
import { setQuoteDetails } from '../../actions/quoteAction';
import { getQuoteDetails } from '../../api/quoteApi';

function* workerGetQuoteDetailsSaga(quoteData) {
  try {
    const orgData = yield select(
      (state) => state.organizationDetailsReducer.orgData
    );
    const response = yield call(getQuoteDetails, quoteData.data, orgData);
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;
    yield put(setQuoteDetails(data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetQuoteDetailsSaga() {
  yield takeLatest(GET_QUOTE_ID, workerGetQuoteDetailsSaga);
}
