const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
const SET_INVOICE_DETAILS = 'SET_INVOICE_DETAILS';
const GET_USERS_SAGA = 'GET_USERS_SAGA';
const SET_USERS = 'SET_USERS';
const GET_PRODUCT_SAGA = 'GET_PRODUCT_SAGA';
const SET_PRODUCT = 'SET_PRODUCT';
const GET_HOME_CAROUSEL_SAGA = 'GET_HOME_CAROUSEL_SAGA';
const SET_HOME_CAROUSEL = 'SET_HOME_CAROUSEL';
const SET_PROMOTION = 'SET_PROMOTION';
const GET_PROMOTION_SAGA = 'GET_PROMOTION_SAGA';
const GET_CUSTOMER_SUPPORT_SAGA = 'GET_CUSTOMER_SUPPORT_SAGA';
const SET_CUSTOMER_SUPPORT = 'SET_CUSTOMER_SUPPORT';
const SET_AUTH = 'SET_AUTH';
const GET_AUTH_SAGA = 'GET_AUTH_SAGA';

const AUTH_REQUEST = 'AUTH_REQUEST';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILURE = 'AUTH_FAILURE';
const GET_RECOMMENDED_PRODUCTS_SAGA = 'GET_RECOMMENDED_PRODUCTS_SAGA';
const SET_RECOMMENDED_PRODUCTS = 'SET_RECOMMENDED_PRODUCTS';
const GET_FEATURED_CATEGORIES_SAGA = 'GET_FEATURED_CATEGORIES_SAGA';
const SET_FEATURED_CATEGORIES = 'SET_FEATURED_CATEGORIES';
const GET_FEATURED_PRODUCTS_SAGA = 'GET_FEATURED_PRODUCTS_SAGA';
const SET_FEATURED_PRODUCTS = 'SET_FEATURED_PRODUCTS';
const SET_ORGANIZATION_DETAILS = 'SET_ORGANIZATION_DETAILS';
const RESET_ORGANIZATION_DETAILS = 'RESET_ORGANIZATION_DETAILS';
const GET_ORGANIZATION_DETAILS_SAGA = 'GET_ORGANIZATION_DETAILS_SAGA';
const SET_PROFILE = 'SET_PROFILE';
const GET_PROFILE_SAGA = 'GET_PROFILE_SAGA';
const ADD_TO_CART_SAGA = 'ADD_TO_CART_SAGA';
const SET_CART_DATA = 'SET_CART_DATA';

const GET_ORDERS_SAGA = 'GET_ORDERS_SAGA';
const SET_ORDERS = 'SET_ORDERS';
const GET_MINI_CART_ITEMS_SAGA = 'GET_MINI_CART_ITEMS_SAGA';
const SET_MINI_CART_ITEMS = 'SET_MINI_CART_ITEMS';

const ADD_TO_CART_STATE = 'ADD_TO_CART_STATE';
const ADD_TO_CART_INITIATED = 'Add to cart clicked';
const ADD_TO_CART_DONE = 'Items added to cart successfully';
const ADD_TO_CART_ERROR_MASG = 'Problem in saving Data';
const GET_PRODUCT_SEARCH_DATA_SAGA = 'GET_PRODUCT_SEARCH_DATA_SAGA';
const SET_PRODUCT_SEARCH_DATA = 'SET_PRODUCT_SEARCH_DATA';
const GET_CATEGORY_SEARCH_DATA_SAGA = 'GET_CATEGORY_SEARCH_DATA_SAGA';
const SET_PRODUCT_CATEGORY_DATA = 'SET_PRODUCT_CATEGORY_DATA';
const CLEAR_CATEGORY_DATA = 'CLEAR_CATEGORY_DATA';

const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';

const UPDATE_CART_SAGA = 'UPDATE_CART_SAGA';
const NO_SEARCH_RECORD = 'No, data found! Please retry.';
const UPDATE_CART_SUCCESS = true;
const UPDATE_CART_ERROR = false;

const REMOVE_FROM_CART_SAGA = 'REMOVE_FROM_CART_SAGA';
const REMOVE_FROM_CART_SUCCESS = true;
const REMOVE_FROM_CART_ERROR = false;

const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';

const UPDATE_SELECTED_ADDRESS = 'UPDATE_SELECTED_ADDRESS';

const SUBMIT_ORDER = 'SUBMIT_ORDER';
const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
const SUBMIT_ORDER_FAILED = 'SUBMIT_ORDER_FAILED';

const GET_ORDER_DETAIL_SAGA = 'GET_ORDER_DETAIL_SAGA';
const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL_DETAILS';
const SET_ORDER_DETAIL_SUCCESS = 'SET_ORDER_DETAIL_SUCCESS';
const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
const GET_ORDER_HISTORY_SAGA = 'GET_ORDER_HISTORY_SAGA';

const SET_ORDERS_ON_PAGINATION = 'SET_ORDERS_ON_PAGINATION';
const SET_ORDER_STATES = 'SET_ORDER_STATES';
const GET_ORDER_STATES = 'GET_ORDER_STATES';
const GET_ORDERS_ON_PAGINATION_SAGA = 'GET_ORDERS_ON_PAGINATION_SAGA';

const SET_ORDER_APPROVED = 'SET_ORDER_APPROVED';

const SET_QUOTES_ON_PAGINATION = 'SET_QUOTES_ON_PAGINATION';
const SET_QUOTE_STATES = 'SET_QUOTE_STATES';
const SET_QUOTE_ID = 'SET_QUOTE_ID';
const GET_QUOTES_ON_PAGINATION_SAGA = 'GET_QUOTES_ON_PAGINATION_SAGA';

const GET_PRODUCT_LISTING_DATA_SAGA = 'GET_PRODUCT_LISTING_DATA_SAGA';
const SET_PRODUCT_LISTING_DATA = 'SET_PRODUCT_LISTING_DATA';
const CUSTOMER_EMAIL = 'Email a Sales Represenative at ';
const CUSTOMER_CALL = 'or call at ';
const GET_UPDATE_PROFILE_SAGA = 'GET_UPDATE_PROFILE_SAGA';
const SET_UPDATE_PROFILE = 'SET_UPDATE_PROFILE';

const GET_ADDRESS = 'GET_ADDRESS';
const SET_ADDRESS = 'SET_ADDRESS';
const ADD_ADDRESS = 'ADD_ADDRESS';
const ERROR_ADDRESS = 'ERROR_ADDRESS';
const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
const RESET_ADDRESS = 'RESET_ADDRESS';
const DELETE_ADDRESS = 'DELETE_ADDRESS';
const GET_UPDATE_PASSWORD_SAGA = 'GET_UPDATE_PASSWORD_SAGA';
const SET_UPDATE_PASSWORD = 'SET_UPDATE_PASSWORD';
const PROFILE_UPDATE_SUCCESS = 'Successfully updated profile.';
const PROFILE_UPDATE_FAIL = 'Update Profile Failed.';
const PASSWORD_SAVED = 'Your new password has been saved.';
const PASSWORD_UPDATE_ERROR =
  'Incorrect value for previous password.';
const PASSWORD_MISMATCH = 'Passwords do not match. Please try again.';
const ERROR_PASSWORD_VALIDATION = 'Please enter a valid New Password';
const PASSWORD_LENGTH =
  'Password must be at least 8 characters and maximum 15 characters.';
const PASSWORD_SHOULD_BE = 'It must have a number and letter.';
const PASSWORD_LIMITATION = 'It must not have special charater.';
const GET_REGISTRATION_SAGA = 'GET_REGISTRATION_SAGA';
const SET_REGISTRATION = 'SET_REGISTRATION';
const GET_MULTI_INVENTORY_VALIDATION_SAGA =
  'GET_MULTI_INVENTORY_VALIDATION_SAGA';
const SET_MULTI_INVENTORY_VALIDATION =
  'SET_MULTI_INVENTORY_VALIDATION';
const ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA =
  'ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA';
const GET_ORDER_OPTION_LIST = 'GET_ORDER_OPTION_LIS';
const SET_ORDER_OPTION_LIST = 'SET_ORDER_OPTION_LIST'; // type ahead list
const SET_ERROR_STATE = 'SET_ERROR_STATE';

const GET_COUNTRY_DETAILS_SAGA = 'GET_COUNTRY_DETAILS';
const GET_STATE_DETAILS_SAGA = 'GET_STATE_DETAILS';
const SET_COUNTRY_DETAILS = 'SET_COUNTRY_DETAILS';
const SET_STATE_DETAILS = 'SET_STATE_DETAILS';
const SET_PASSWORD = 'SET_PASSWORD';
const GET_PASSWORD_SAGA = 'GET_PASSWORD_SAGA';
const ADD_CONTACTS = 'ADD_CONTACTS';
const UPDATE_CONTACT = 'UPDATE_CONTACT';
const GET_OPTION_LIST = 'GET_OPTION_LIST';
const SET_OPTION_LIST = 'SET_OPTION_LIST'; // type ahead list
const SET_ORDER_DETAIL_RESET = 'SET_ORDER_DETAIL_RESET';
const GET_ORDER_DETAIL_SAGA_RESET = 'GET_ORDER_DETAIL_SAGA_RESET';
const GET_INVENTORY_VALIDATION_SAGA = 'GET_INVENTORY_VALIDATION_SAGA';
const SET_INVENTORY_VALIDATION = 'SET_INVENTORY_VALIDATION';
const ADD_PRODUCT_QUICK_ORDER_FORM = 'ADD_PRODUCT_QUICK_ORDER_FORM';
const REMOVE_PRODUCT_QUICK_ORDER_FORM =
  'REMOVE_PRODUCT_QUICK_ORDER_FORM';
const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY';
const SET_CART_STATUS = 'SET_CART_STATUS';
const CLEAR_QUICK_ORDER_STATE = 'CLEAR_QUICK_ORDER_STATE';
const GET_FORGOT_PASSWORD_EMAIL_SAGA = 'GET_FORGOT_PASSWORD_EMAIL';
const SET_FORGOT_PASSWORD_EMAIL_SAGA = 'SET_FORGOT_PASSWORD_EMAIL';
const SEARCH_CONTACT_DATA = 'SEARCH_CONTACT_DATA';
const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
const CLEAR_CONTACT_LIST = 'CLEAR_CONTACT_LIST';
const SET_ORGANIZATION_ROLES = 'SET_ORGANIZATION_ROLES';

const GET_QUOTE_LIST = 'GET_QUOTE_LIST';
const SET_QUOTE_LIST = 'SET_QUOTE_LIST';
const GET_QUOTE_ID = 'GET_QUOTE_ID';
const SET_QUOTE_DETAILS = 'SET_QUOTE_DETAILS';
const RESET_QUOTE_DETAILS = 'RESET_QUOTE_DETAILS';
const SET_SUB_ORGANIZATION_DETAILS = 'SET_SUB_ORGANIZATION_DETAILS';
const GET_SUB_ORGANIZATION_DETAILS_SAGA =
  'GET_SUB_ORGANIZATION_DETAILS_SAGA';

const GET_CURRENT_ORGANIZATION = 'GET_CURRENT_ORGANIZATION';
const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST';
const SET_REJECT_QUOTE_SUCCESS = 'SET_REJECT_QUOTE_SUCCESS';
const GET_REJECT_QUOTE = 'GET_REJECT_QUOTE';
const REQUEST_QUOTE = 'REQUEST_QUOTE';
const SET_REQUEST_QUOTE = 'SET_REQUEST_QUOTE';
const RESET_REQUEST_QUOTE = 'RESET_REQUEST_QUOTE';
const SET_SUB_ORGANIZATION = 'SET_SUB_ORGANIZATION';

const stateList = [
  {
    code: 'California',
    componentDisplayName: 'sku',
    componentId: 'CA',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
  {
    code: 'Indiana',
    componentDisplayName: 'sku',
    componentId: 'ID',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
  {
    code: 'Texas',
    componentDisplayName: 'sku',
    componentId: 'TX',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
  {
    code: 'Connecticut',
    componentDisplayName: 'sku',
    componentId: 'CT',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
];

const countryList = [
  {
    code: 'United States',
    componentDisplayName: 'sku',
    componentId: 'US',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
];

const roleList = [
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Account Address Manager',
    value: 'accountManager',
  },
  {
    label: 'Buyer',
    value: 'buyer',
  },
];

const addressTypeList = [
  {
    code: 'Both',
    componentDisplayName: 'sku',
    componentId: 'both',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
  {
    code: 'Shipping',
    componentDisplayName: 'sku',
    componentId: 'shipping',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
  {
    code: 'Billing',
    componentDisplayName: 'sku',
    componentId: 'billing',
    componentType: 'sku',
    components: null,
    type: 'sku',
  },
];
const CLEAR_PDP_PRODUCT = 'CLEAR_PDP_PRODUCT';

export {
  //eslint-disable-line
  GET_INVOICE_LIST,
  SET_INVOICE_LIST,
  GET_USERS_SAGA,
  SET_USERS,
  GET_PRODUCT_SAGA,
  SET_PRODUCT,
  GET_HOME_CAROUSEL_SAGA,
  SET_HOME_CAROUSEL,
  SET_PROMOTION,
  GET_PROMOTION_SAGA,
  GET_CUSTOMER_SUPPORT_SAGA,
  SET_CUSTOMER_SUPPORT,
  SET_AUTH,
  GET_AUTH_SAGA,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  GET_RECOMMENDED_PRODUCTS_SAGA,
  SET_RECOMMENDED_PRODUCTS,
  GET_FEATURED_CATEGORIES_SAGA,
  SET_FEATURED_CATEGORIES,
  GET_FEATURED_PRODUCTS_SAGA,
  SET_FEATURED_PRODUCTS,
  SET_ORGANIZATION_DETAILS,
  RESET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS_SAGA,
  SET_PROFILE,
  GET_PROFILE_SAGA,
  GET_ORDERS_SAGA,
  SET_ORDERS,
  ADD_TO_CART_SAGA,
  SET_CART_DATA,
  GET_MINI_CART_ITEMS_SAGA,
  SET_MINI_CART_ITEMS,
  ADD_TO_CART_STATE,
  ADD_TO_CART_INITIATED,
  ADD_TO_CART_DONE,
  ADD_TO_CART_ERROR_MASG,
  UPDATE_ITEM_QUANTITY,
  GET_PRODUCT_SEARCH_DATA_SAGA,
  SET_PRODUCT_SEARCH_DATA,
  GET_CATEGORY_SEARCH_DATA_SAGA,
  SET_PRODUCT_CATEGORY_DATA,
  CLEAR_CATEGORY_DATA,
  SET_SEARCH_TERM,
  CLEAR_SEARCH_RESULT,
  NO_SEARCH_RECORD,
  UPDATE_CART_SAGA,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERROR,
  REMOVE_FROM_CART_SAGA,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_ERROR,
  UPDATE_SELECTED_ADDRESS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILED,
  GET_ORDER_DETAIL_SAGA,
  SET_ORDER_DETAIL,
  SET_ORDER_DETAIL_SUCCESS,
  SET_ORDER_HISTORY,
  GET_ORDER_HISTORY_SAGA,
  SET_ORDERS_ON_PAGINATION,
  GET_ORDERS_ON_PAGINATION_SAGA,
  SET_ORDER_APPROVED,
  SET_QUOTES_ON_PAGINATION,
  GET_QUOTES_ON_PAGINATION_SAGA,
  GET_PRODUCT_LISTING_DATA_SAGA,
  SET_PRODUCT_LISTING_DATA,
  CUSTOMER_EMAIL,
  CUSTOMER_CALL,
  GET_ADDRESS,
  SET_ADDRESS,
  ADD_ADDRESS,
  ERROR_ADDRESS,
  UPDATE_ADDRESS,
  RESET_ADDRESS,
  DELETE_ADDRESS,
  GET_UPDATE_PROFILE_SAGA,
  SET_UPDATE_PROFILE,
  GET_UPDATE_PASSWORD_SAGA,
  SET_UPDATE_PASSWORD,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PASSWORD_SAVED,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_MISMATCH,
  ERROR_PASSWORD_VALIDATION,
  PASSWORD_LENGTH,
  PASSWORD_SHOULD_BE,
  PASSWORD_LIMITATION,
  GET_REGISTRATION_SAGA,
  SET_REGISTRATION,
  SET_ERROR_STATE,
  GET_ORDER_OPTION_LIST,
  SET_ORDER_OPTION_LIST,
  SET_OPTION_LIST,
  GET_OPTION_LIST,
  //  List for dropDown
  stateList,
  countryList,
  addressTypeList,
  GET_INVOICE_DETAILS,
  SET_INVOICE_DETAILS,
  GET_COUNTRY_DETAILS_SAGA,
  GET_STATE_DETAILS_SAGA,
  SET_COUNTRY_DETAILS,
  SET_STATE_DETAILS,
  SET_PASSWORD,
  GET_PASSWORD_SAGA,
  roleList,
  ADD_CONTACTS,
  UPDATE_CONTACT,
  SET_ORDER_DETAIL_RESET,
  GET_ORDER_DETAIL_SAGA_RESET,
  GET_MULTI_INVENTORY_VALIDATION_SAGA,
  SET_MULTI_INVENTORY_VALIDATION,
  ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA,
  GET_INVENTORY_VALIDATION_SAGA,
  SET_INVENTORY_VALIDATION,
  ADD_PRODUCT_QUICK_ORDER_FORM,
  REMOVE_PRODUCT_QUICK_ORDER_FORM,
  UPDATE_PRODUCT_QUANTITY,
  SET_CART_STATUS,
  CLEAR_QUICK_ORDER_STATE,
  GET_FORGOT_PASSWORD_EMAIL_SAGA,
  SET_FORGOT_PASSWORD_EMAIL_SAGA,
  SEARCH_CONTACT_DATA,
  SET_CONTACT_LIST,
  CLEAR_CONTACT_LIST,
  SET_ORGANIZATION_ROLES,
  CLEAR_PDP_PRODUCT,
  GET_QUOTE_LIST,
  SET_QUOTE_LIST,
  GET_QUOTE_ID,
  SET_QUOTE_DETAILS,
  RESET_QUOTE_DETAILS,
  SET_SUB_ORGANIZATION_DETAILS,
  GET_SUB_ORGANIZATION_DETAILS_SAGA,
  GET_ORGANIZATION_LIST,
  SET_ORGANIZATION_LIST,
  GET_CURRENT_ORGANIZATION,
  SET_CURRENT_ORGANIZATION,
  SET_REJECT_QUOTE_SUCCESS,
  GET_REJECT_QUOTE,
  REQUEST_QUOTE,
  SET_REQUEST_QUOTE,
  RESET_REQUEST_QUOTE,
  SET_SUB_ORGANIZATION,
  SET_QUOTE_STATES,
  SET_ORDER_STATES,
  GET_ORDER_STATES,
  SET_QUOTE_ID,
};
