import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_PRODUCT_SAGA, SET_ERROR_STATE } from '../../constants';
import { setProducts } from '../../actions/productListingAction';
import { getProducts } from '../../api/productlistingApi';

function* workerGetProductSaga(data) {
  try {
    const products = yield call(getProducts, data.productId);
    products.data.productList[0] && products.data.productList[0].compositeProducts.map(prod => {
      prod.media.carouselMedia = [ ]
      prod.media.images.fullURIs.map(item => {
        prod.media.carouselMedia.push(
          {
            type: 'img',
            tag: 'img',
            url: item
          }
        );
        return item; 
      });
      prod.media.videos.map(item => {
        if (!item.sourceURI.includes('.mp4' || '.webm')) {
          prod.media.carouselMedia.push({
            type: 'video',
            tag: 'iframe',
            url: item.sourceURI.includes('watch') ? item.sourceURI.replace('watch?v=', 'embed/') : item.sourceURI
          });
        } else {
          prod.media.carouselMedia.push({
            type: 'video',
            tag: 'video',
            url: item.sourceURI
          });
        }
        return item;       
      });
      return prod;
    });
    yield put(setProducts(products.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetProductSaga() {
  yield takeLatest(GET_PRODUCT_SAGA, workerGetProductSaga);
}
