

import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  SET_ORDER_APPROVED,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setOrderDetail,
  setOrdersOnPagination,
} from '../../actions/orderAction';
import { updateOrder } from '../../api/orderApi';

function* workerSetOrderApproved({ payload: { orderId, isApproved } }) {
  try {
    const orgData = yield select(state => state.organizationDetailsReducer.orgData);
    // const orderDetails = yield select(state => state.orderDetailReducer);
    // const orderList = yield select(state => state.ordersOnPagination);

    // const orderStatus = isApproved ? 'confirmed' : 'cancelled';
    // const orderStatusDisplay = isApproved ? 'Confirmed' : 'Cancelled';

    // orderDetails.order_detail.orderStatus = orderStatus;
    // orderDetails.order_detail.orderStatusDisplay = orderStatusDisplay;

    // const currentList = orderList.orders_list.orders_list;
    // const updatedList = {
    //   ...currentList,
    //   orderList: currentList.orderList.map(item => {
    //     if (item.orderNumber !== orderId) {
    //       return item;
    //     }

    //     return {
    //       ...item,
    //       orderStatus,
    //       orderStatusDisplay,
    //     };
    //   })
    // };

    // yield put(setOrderDetail(orderDetails.order_detail));
    // yield put(setOrdersOnPagination(updatedList));
    yield call(updateOrder, orderId, orgData, {
      orderState: isApproved ? 'approve' : 'decline'
    });

  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchSetOrderApproved() {
  yield takeLatest(SET_ORDER_APPROVED, workerSetOrderApproved);
}
