import { put, takeLatest, call } from 'redux-saga/effects';

import {
  UPDATE_SELECTED_ADDRESS,
  SET_ERROR_STATE,
} from '../../constants';
import { setCartData } from '../../actions/cartItemAction';

function* workerUpdateSelectedAddress({
  payload: { order, address, addressType },
}) {
  try {
    if (order) {
      order[`selected${addressType}address`] = address;
    }

    yield put(setCartData(order));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchUpdateSelectedAddress() {
  yield takeLatest(
    UPDATE_SELECTED_ADDRESS,
    workerUpdateSelectedAddress
  );
}
