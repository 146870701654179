import { put, call, takeEvery } from 'redux-saga/effects';

import {
  GET_SUB_ORGANIZATION_DETAILS_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setSubOrganizationDetails } from '../../actions/subOrganizationAction';
import { getSubOrganizationDetails } from '../../api/subOrganizationApi';

function* workerGetSubOrganizationDetailSaga(account) {
  const { payload } = account;
  try {
    const response = yield call(
      getSubOrganizationDetails,
      payload.organizationId,
      payload.profileId
    );

    if (response.data) {
      yield put(setSubOrganizationDetails(response.data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({
        type: SET_ERROR_STATE,
        errorData,
      });
    }
  }
}

export default function* watchGetSubOrganizationDetailSaga() {
  yield takeEvery(
    GET_SUB_ORGANIZATION_DETAILS_SAGA,
    workerGetSubOrganizationDetailSaga
  );
}
