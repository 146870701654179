import { put, takeLatest, call } from 'redux-saga/effects';

import { ADD_ADDRESS, SET_ERROR_STATE } from '../../constants';
import { setAddressData } from '../../actions/addressAction';
import { addAddress, getAddress } from '../../api/addressApi';

function* workerAddAddressDataSaga(data) {
  try {
    const address_response = yield call(addAddress, data);
    if (address_response) {
      const address_data = yield call(getAddress);
      yield put(setAddressData(address_data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchAddAddressDataSaga() {
  yield takeLatest(ADD_ADDRESS, workerAddAddressDataSaga);
}
