import {
  SET_ORDER_DETAIL,
  SET_ORDER_DETAIL_RESET,
} from '../constants';

const initialState = {
  order_detail: null,
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_DETAIL:
      return {
        ...state,
        order_detail: action.payload.order_detail,
      };
    case SET_ORDER_DETAIL_RESET:
      return {
        ...state,
        order_detail: action.payload.order_detail,
      };
    default:
      return state;
  }
}
