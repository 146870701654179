import React from 'react';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { Link } from 'react-router-dom';

const AccessibilityStatement = () => {
  return (
    <PageLayoutHOC showSearch='false'>
      <div className='externalContainer contactPadding'>
        <h1>Accessibility Statement for BEPSY</h1>
        <p>
          BEPSY is committed to ensuring digital accessibility for
          people with disabilities. We are continually improving the
          user experience for everyone, and applying the relevant
          accessibility standards.
        </p>

        <h3>Conformance status</h3>
        <p>
          <Link to='https://www.w3.org/WAI/standards-guidelines/wcag/'>
            The Web Content Accessibility Guidelines (WCAG)
          </Link>{' '}
          defines requirements for designers and developers to improve
          accessibility for people with disabilities. It defines three
          levels of conformance: Level A, Level AA, and Level AAA.
          Citylights WebStore is partially conformant with WCAG 2.1
          level AA. Partially conformant means that some parts of the
          content do not fully conform to the accessibility standard.
        </p>
        <br />

        <h3>Feedback</h3>
        <p>
          We welcome your feedback on the accessibility of BEPSY.
          Please let us know if you encounter accessibility barriers
          on BEPSY:
        </p>
        <ul style={{ padding: '10px' }}>
          <li>Phone: +12 34 567 89 00</li>
          <li>E-mail: accessibility@example.org</li>
          <li>Postal address: PO Box 1, 234 Example Ville</li>
        </ul>

        <p>We try to respond to feedback within 5 business days. </p>
      </div>
    </PageLayoutHOC>
  );
};

export default AccessibilityStatement;
