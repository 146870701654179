import {
  SET_PRODUCT_SEARCH_DATA,
  SET_PRODUCT_CATEGORY_DATA,
  SET_SEARCH_TERM,
  CLEAR_SEARCH_RESULT,
  CLEAR_CATEGORY_DATA,
  SET_OPTION_LIST,
} from '../constants';

const initialState = {
  listingData: null,
  searchTerm: null,
  category: null,
  optionList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_SEARCH_DATA:
      return {
        ...state,
        listingData: action.data,
      };
    case SET_PRODUCT_CATEGORY_DATA:
      return {
        ...state,
        category: action.data,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.data,
      };
    case CLEAR_SEARCH_RESULT:
      return {
        ...state,
        listingData: null,
      };
    case CLEAR_CATEGORY_DATA:
      return {
        ...state,
        category: null,
      };
    case SET_OPTION_LIST:
      return {
        ...state,
        optionList: action.data,
      };
    default:
      return state;
  }
};
