import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_OPTION_LIST } from '../../constants';
import { setOptionList } from '../../actions';
import { getProductListingDataWithTypeAhead } from '../../api/productlistingApi';

function* workerGetOptionlistDataSaga({ payload: { searchTerm } }) {
  const optionList = yield call(
    getProductListingDataWithTypeAhead,
    searchTerm
  );
  yield put(setOptionList(optionList.data));
}

export default function* watchGetOptionListDataSaga() {
  yield takeLatest(GET_OPTION_LIST, workerGetOptionlistDataSaga);
}
