import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  setInvoice,
  getInvoiceAction,
} from '../../actions/invoiceAction';

import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import InvoiceList from './InvoiceList';

import CustomSearchField from '../../components/Shared/customSearchTextField';

//import CustomSelect from '../../components/Shared/customizedSelect';
//import CustomSelect from '../../components/Shared/customizedSelect';
import CustomSelect from '../../components/Shared/noOutlineSelect';
import './Styles.css';
import { Hidden } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

class InvoiceListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      selectedVal: '',
    };
  }

  componentDidMount() {
    this.props.getInvoiceAction('dateIssued desc', null);
  }

  handleChange = (e) => {
    console.log('selected', e.target.value);
    this.props.getInvoiceAction(e.target.value, null);
    this.setState({ selectedVal: e.target.value });
  };

  gotoInvoice = () => {
    this.props.getInvoiceAction(null, null);
  };

  searchTextChangeHandler = (e) => {
    //let searchText = e.target.value;
    this.setState({ searchText: e.target.value });
    console.log('state data', this.state.searchText);
    // alert(e.target.value);
    // this.props.getInvoiceAction(
    //   this.state.selectedVal,
    //   searchText
    // );
    this.setState({ searchText: e.target.value });
  };

  searchEnterClickHandler = (e) => {
    // let searchText = e.target.value;
    // alert(e.target.value);
    console.log('state data', this.state.searchText);
    if (e.keyCode === 13) {
      this.props.getInvoiceAction(
        this.state.selectedVal,
        this.state.searchText
      );
    }
  };

  searchIconClickHandler = (e) => {
    // let searchText = e.target.value;
    //alert(e.target.value);
    console.log('state data', this.state.searchText);
    this.props.getInvoiceAction(
      this.state.selectedVal,
      this.state.searchText
    );
  };

  render() {
    let { sortParams, invoiceList } = this.props;
    let may =
      invoiceList.invoiceList.data !== undefined
        ? invoiceList.invoiceList.data.invoiceList
        : null;

    let sortParamsData =
      invoiceList.invoiceList.data !== undefined
        ? invoiceList.invoiceList.data.sortOptions
        : null;

    return (
      <PageLayoutHOC {...this.props} showSearch='false'>
        <div className='search-field-invoice'>
          <CustomSearchField
            placeholder={'Search Invoice'}
            searchTextChangeHandler={this.searchTextChangeHandler}
            searchEnterClickHandler={this.searchEnterClickHandler}
            searchIconClickHandler={this.searchIconClickHandler}
            searchError={this.searchError}
            searchText={this.state.searchText}
          />
        </div>
        <div style={{ height: '50px' }}></div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: '#ffffff',
            paddingLeft: '1em',
          }}>
          <div style={{ lineHeight: '3em' }}>
            Sort by :&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <CustomSelect
            options={sortParamsData}
            selectedOption={this.state.selectedVal}
            optionChange={this.handleChange}
            defaultValue='Select any'
          />
        </div>
        <InvoiceList data={may} />
        {invoiceList.invoiceList.data &&
          invoiceList.invoiceList.data.invoiceList.length < 1 && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              <h3>
                Sorry, there are no invoices that match this search
                criteria.
              </h3>
              <Button variant='contained' onClick={this.gotoInvoice}>
                Go to Invoice
              </Button>
            </div>
          )}
      </PageLayoutHOC>
    );
  }
}

const mapStateToProps = (state) => ({
  invoiceList: state.invoiceListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceAction: (data, searchTerm) =>
    dispatch(getInvoiceAction(data, searchTerm)),
  setInvoice: (i) => dispatch(setInvoice(i)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceListing);
