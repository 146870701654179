import React from 'react';
import PropTypes from 'prop-types';

const customErrorText = ({ masg, displayType, margin }) => (
  <div
    style={{ display: displayType, margin }}
    className='custom-error'>
    <span>{masg}</span>
  </div>
);

customErrorText.propTypes = {
  masg: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
};

export default customErrorText;
