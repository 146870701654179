import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE
} from '../configurations/config.js';
import { headerData } from './headerData';

//extracting user roles
export function organizationHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  let roles = [];
  for (let i = 0; i < user.contactTypeRole.length; i++) {
    roles.push(user.contactTypeRole[i].roleId);
  }
  const bepsyRoles = {
    'Bepsy-UserRoles': roles.join(',')
  };
  const options = headerData();
  options.headers = { ...options.headers, ...bepsyRoles };
  return options;
}

//  quote states
export function getQuoteStates() {
  let options = headerData();
  const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/states`;
  return axios.get(getOrdersApi, options).then((res) => res.data.states);
}

//  quote list
export function getQuotesOnPagination(
  searchInput,
  currentPage,
  recordPerPage,
  ordersUpto,
  startDate,
  endDate,
  orderRange,
  sortProp,
  sortOrder,
  statusData,
  orgData
) {
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  const options = organizationHeader();

  for (let value of Object.values(options)) {
    options.headers['Bepsy-OrganizationId'] = orgData;
  }

  const getQuotesApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes?profileId=${context.profileId}&orderState=${statusData}&query=${searchInput}&page=${currentPage}&recordsPerPage=${recordPerPage}&sortProperty=${sortProp}&sortOrder=${sortOrder}`;

  // const getQuotesApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes?profileId=${context.profileId}`;
  //const getQuotesApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes?profileId=${context.profileId}`;
  return axios.get(getQuotesApi, options);
}

export function getQuoteDetails(data, orgData) {
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  const options = organizationHeader();
  if (orgData) {
    options.headers['Bepsy-OrganizationId'] = orgData;
  }
  const getQuotesDetailsApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${data.sfid}?profileId=${context.profileId}`;
  return axios.get(getQuotesDetailsApi, options);
}

export function getRejectQuote(data) {
  const options = organizationHeader();
  const reqBody = {
    quoteId: data.sfid
  };
  const getRejectQuotesApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${data.sfid}`;
  return axios.put(getRejectQuotesApi, reqBody, options);
}

export function addRequestQuote(data) {
  const options = organizationHeader();
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  const reqBody = {
    quoteName: data.quoteName,
    requestNote: data.quoteComment,
    orderId: context.orderId,
    profileId: context.profileId
  };
  const requestQuotesApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes`;
  return axios.post(requestQuotesApi, reqBody, options);
}

// order submition
export function submitQuoteApi(body, orgData) {
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  let headerContent = organizationHeader();

  console.log('body====>', body);

  if (body.quote) {
    delete body['quote'];
    if (orgData) {
      headerContent.headers['Bepsy-OrganizationId'] = orgData;
    }
    console.log('insidethe quote api call', body);
    // return true;
    const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

    return axios.put(getOrdersApi, body, headerContent);
    // const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

    // return axios.put(getOrdersApi, body, headerContent);
  }
  // else {
  //   const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

  //   return axios.put(getOrdersApi, body, headerContent);
  // }
  // if (orgData) {
  //   headerContent.headers['Bepsy-OrganizationId'] = orgData;
  // }
  // const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

  // return axios.put(getOrdersApi, body, headerContent);
  // const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

  // return axios.put(getOrdersApi, body, headerContent);
}
