import {
  SET_MULTI_INVENTORY_VALIDATION,
  GET_MULTI_INVENTORY_VALIDATION_SAGA,
  ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA,
  GET_INVENTORY_VALIDATION_SAGA,
  ADD_PRODUCT_QUICK_ORDER_FORM,
  REMOVE_PRODUCT_QUICK_ORDER_FORM,
  UPDATE_PRODUCT_QUANTITY,
  SET_CART_STATUS,
  CLEAR_QUICK_ORDER_STATE,
} from '../constants';

export function setMultiInventoryValidation(data) {
  return {
    type: SET_MULTI_INVENTORY_VALIDATION,
    payload: { data },
  };
}
export function getMultiInventoryValidationSaga(body) {
  return {
    type: GET_MULTI_INVENTORY_VALIDATION_SAGA,
    payload: { body },
  };
}
export function addtoQuoteMultipleItemsSaga(items, clearCart) {
  return {
    type: ADD_TO_QUOTE_MULTIPLE_ITEMS_SAGA,
    payload: { items, clearCart },
  };
}

export function getInventoryDetailsSaga(sku, qty) {
  return {
    type: GET_INVENTORY_VALIDATION_SAGA,
    payload: { sku, qty },
  };
}

export function addProductQuickOrderForm(product) {
  return { type: ADD_PRODUCT_QUICK_ORDER_FORM, product };
}

export function removeProductQuickOrderForm(index) {
  return { type: REMOVE_PRODUCT_QUICK_ORDER_FORM, index };
}

export function updateProductQuantity(data) {
  return { type: UPDATE_PRODUCT_QUANTITY, data };
}

export function setCartStatus(status) {
  return { type: SET_CART_STATUS, status };
}

export function clearQuickOrderState() {
  return { type: CLEAR_QUICK_ORDER_STATE };
}
