import React, { useRef } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Hidden,
  Button,
  Modal,
  makeStyles,
} from '@material-ui/core';
import Moment from 'react-moment';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import Shipping from '@material-ui/icons/LocalShippingOutlined';
import Billing from '@material-ui/icons/AccountBalanceWallet';
import ContentLoader from 'react-content-loader';
//import ContentLoader from '../Shared/customLoader';
import '../../containers/Orders/Styles.css';

import AuthorizationForm from '../../containers/Orders/AuthorizationForm.pdf';
import Print from '../Print';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import BrushOutlined from '@material-ui/icons/BrushOutlined';
import { Redirect } from 'react-router-dom';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }
function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 350,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const pdfFile = AuthorizationForm;

const handlePrint = (event) => {
  event.preventDefault();
  window.open(pdfFile, 'Print-Window');
};

export default function OrderDetails(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [quoteCheckout, setQuoteCheckout] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let dataToPrint;
  const componentRef = useRef();
  let prods = [];
  let order = props.order;
  order =
    (order && order.order_detail) ||
    (props.quoteDetails && props.quoteDetails.quoteDetails);
  if (order) {
    console.log('======> Order Details', order);
    const shipment = order.EOrderShipments[0];
    const shippingAddress = shipment.shippingAddress;

    const billing = order.EPaymentDetails[0];
    
    const billingAddress = billing ? billing.billingAddress
      : '';
       console.log("billingAddress ====>", order);
    order.EOrderItems.map((item, index) => {
      prods.push({
        productName: item.productName,
        SKU: item.sku,
        listPrice: item.listPrice.toFixed(2),
        quantity: item.quantity,
        finalPrice: item.finalItemAmount.toFixed(2),
      });
    });
   
    dataToPrint = {
      products: prods,
      cartSummary: {
        subTotal: order.subTotalAmount.toFixed(2),
        tax: order.finalTaxAmount,
        shippingCharges: order.finalShippingAmount.toFixed(2),
        discount: order.subTotalDiscountAmount.toFixed(2),
        finalTotal: order.finalOrderAmount.toFixed(2),
      },
      address: {
        billingAddress: {
          billTo: '',
          address1: billingAddress.address1,
          city: billingAddress.city,
          postalCode: billingAddress.postalCode,
          country: billingAddress.country,
        },
        shippingAddress: {
          billTo:
            shippingAddress.firstName +
            ' ' +
            shippingAddress.lastName,
          address1: shippingAddress.address1,
          city: shippingAddress.city,
          postalCode: shippingAddress.postalCode,
          country: shippingAddress.country,
        },
      },
      orderNumber: order.orderNumber,
    };
  }
  const placeOrder = (e) => {
    setQuoteCheckout(true);
  };

  const handleReturnOrder = (e) => {};
  if (quoteCheckout) {
    return <Redirect to='/quoteCheckout' />;
  }
  if (order) {
    const shipment =
      order.EOrderShipments && order.EOrderShipments[0];
    const shippingAddress = shipment
      ? shipment.shippingAddress
      : null;
    const billing = order.EPaymentDetails && order.EPaymentDetails[0];
    const billingAddress = billing ? billing.billingAddress : null;
    return (
      <div className='order-details-wrapper'>
        <div className='order-inner-wrapper'>
          <div className='order-top-content'>
            <div className='order-container'>
              <div className='order-data'>
                <Grid container justify='space-between'>
                  <Grid item>
                    {' '}
                    {order.quoteInfo
                      ? `Quote ID: ${order.orderNumber}`
                      : `Account Name: ${
                          shippingAddress.companyName
                        }${' '}`}
                    {/* Account Name: {shippingAddress.companyName}{' '} */}
                  </Grid>
                  <Grid item>
                    {order.quoteInfo
                      ? `${
                          order.quoteInfo.expirationDate
                            ? 'Expires'
                            : ''
                        } `
                      : `Order Placed:${' '}`}
                    {order.orderSubmitDate ? (
                      <Moment format='MMMM Do, YYYY'>
                        {order.orderSubmitDate}
                      </Moment>
                    ) : null}
                    {order.quoteInfo &&
                    order.quoteInfo.expirationDate ? (
                      <Moment format='MMMM Do, YYYY'>
                        {order.quoteInfo.expirationDate}
                      </Moment>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <h2>
                      {order.quoteInfo
                        ? `${
                            order.quoteInfo.nickName
                              ? order.quoteInfo.nickName
                              : ''
                          }`
                        : `${order.orderName}`}
                    </h2>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify='space-between'
                  className='customer-info'>
                  <Grid item>
                    <div>
                      {order.quoteInfo
                        ? `Created by ${order.quoteInfo.creatorName}`
                        : `Customer Number:${' '}`}
                      {order.quoteInfo ? null : (
                        <span className='upperCase'>
                          {order.user}
                        </span>
                      )}
                    </div>
                  </Grid>
                  {order.quoteInfo ? null : (
                    <Grid item>
                      <div>
                        Customer PO #:{' '}
                        <span className='upperCase'>
                          {order.PONumber}
                        </span>
                      </div>
                      <div>
                        {/* < a href = "/invoiceDetails/" >
                      Invoice# :{' '}
                      <span className='upperCase'>12345789</span>
                      </a> */}
                        Invoice#:{' '}
                        <Link
                          to={{
                            pathname: '/invoiceDetails',
                            state: {
                              invoiceId: 'inv1234567894',
                            },
                          }}>
                          <span className='upperCase'>12345789</span>
                        </Link>
                        {order.creditTerms !== '' &&
                        order.creditTerms !== null ? (
                          <div>
                            <span> Invoice Credit terms#: </span>
                            <span className='upperCase'>
                              {order.creditTerms} days
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid container justify='space-between'>
                  <Grid item>
                    {/* TODO: quote status is missing */}
                    {order.quoteInfo ? (
                      <div>Status: {order.orderStatusDisplay}</div>
                    ) : (
                      <div>Order Status: {order.orderStatus}</div>
                    )}
                  </Grid>
                </Grid>

                <h4>{order.quoteInfo ? `Items` : `Order Items`}</h4>
                <Divider />
                <Hidden only={['xl', 'lg', 'md', 'sm']}>
                  {order &&
                    order.EOrderItems.map((item, index) => (
                      <span key={index}>
                        <Grid container direction='row'>
                          <Grid item xs={12}>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Part #
                              </Grid>
                              <Grid item xs={6}>
                                {item.sku}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Product
                              </Grid>
                              <Grid item xs={6}>
                                {item.productName}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Price per Unit
                              </Grid>
                              <Grid
                                item
                                xs={6}>{`$${item.listPrice}`}</Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Quantity
                              </Grid>
                              <Grid item xs={6}>
                                {item.quantity}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Total
                              </Grid>
                              <Grid item xs={6}>
                                ${item.finalItemAmount}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                      </span>
                    ))}
                </Hidden>
                <Hidden only={['xs']}>
                  <Table>
                    <TableHead className='table-head'>
                      <TableRow>
                        <TableCell>Part #</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Price per unit</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className='table-body'>
                      {order &&
                        order.EOrderItems.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.sku}</TableCell>
                            <TableCell>{item.productName}</TableCell>
                            <TableCell>{`$${item.listPrice}`}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell className='priceItem'>
                              ${item.finalItemAmount}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Hidden>

                <div className='order-footer-data'>
                  <Grid container spacing={4}>
                    {order.quoteInfo ? (
                      <Grid
                        item
                        md={8}
                        sm={6}
                        className='billing-wrapper'>
                        <div className='billing-title headers'>
                          Comments:
                        </div>
                        <Divider />
                        <div className='notes-data'>
                          Provider note:{' '}
                          {order.quoteInfo.notes.map(
                            (item) =>
                              item.providerNote &&
                              item.providerNote + '. '
                          )}
                        </div>
                        <div className='notes-data'>
                          Request note:{' '}
                          {order.quoteInfo.notes.map(
                            (item) =>
                              item.requesterNote &&
                              item.requesterNote + '. '
                          )}
                        </div>
                        <div className='notes-data'>
                          Reject note:{' '}
                          {order.quoteInfo.notes.map(
                            (item) =>
                              item.rejectionNote &&
                              item.rejectionNote + '. '
                          )}
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        md={8}
                        sm={6}
                        className='billing-wrapper'>
                        <div className='billing-title headers'>
                          Billing and Shipping Information
                        </div>
                        <Divider />
                        <Grid
                          container
                          className='billing-shipping-container'>
                          <Grid item md={12}>
                            <Grid
                              container
                              direction='row'
                              spacing={2}>
                              <Grid item xs={12} md={4} sm={12}>
                                <Card>
                                  <CardHeader
                                    avatar={
                                      <IconButton>
                                        <Shipping />
                                      </IconButton>
                                    }
                                    title='Shipping Address'
                                  />
                                  <CardContent>
                                    <div>
                                      {shippingAddress.firstName}{' '}
                                      {shippingAddress.lastName}
                                    </div>
                                    <div>
                                      {shippingAddress.address1}
                                    </div>
                                    <div>{shippingAddress.city}</div>
                                    <div>
                                      {shippingAddress.postalCode}
                                    </div>
                                    <div>
                                      {shippingAddress.country}
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                              <Grid item xs={12} md={4} sm={12}>
                                <Card>
                                  <CardHeader
                                    avatar={
                                      <IconButton>
                                        <Billing />
                                      </IconButton>
                                    }
                                    title='Billing Address'
                                  />
                                  <CardContent>
                                    <div>
                                      {billingAddress.address1}
                                    </div>
                                    <div>{billingAddress.city}</div>
                                    <div>
                                      {billingAddress.postalCode}
                                    </div>
                                    <div>
                                      {billingAddress.country}
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                          container
                          className='billing-shipping-container'>
                          <Grid item xs={12} md={12} sm={12}>
                            <Grid
                              container
                              direction='row'
                              spacing={2}>
                              <Grid item xs={12} md={12} sm={12}>
                                <Card>
                                  <CardContent>
                                    <div>
                                      Shipping Carrier :
                                      {shippingAddress.carrier}
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justify='flex-start'
                          spacing={1}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}>
                          <Modal
                            aria-labelledby='simple-modal-title'
                            aria-describedby='simple-modal-description'
                            open={open}
                            onClose={handleClose}>
                            <div
                              style={modalStyle}
                              className={classes.paper}>
                              {/* <h2 id='simple-modal-title'>
                              Text in a modal
                            </h2> */}
                              <p id='simple-modal-description'>
                                To return this order, please contact
                                BEPSY at 1(800) 123 4567 or at
                                orders@bepsy.com.
                              </p>
                              <Button
                                variant='contained'
                                className='view-details-btn'
                                // onClick={handleReturnOrder}>
                                onClick={handleClose}>
                                Close
                              </Button>
                            </div>
                          </Modal>
                          <Grid item>
                            <Button
                              variant='contained'
                              className='view-details-btn'
                              onClick={handleOpen}>
                              Return Order
                            </Button>
                          </Grid>
                          {props.hasApprovalActions && (
                            <React.Fragment>
                              <Grid item>
                                <Button
                                  variant='contained'
                                  className='view-details-btn'
                                  onClick={props.onReject}>
                                  Reject
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant='contained'
                                  className='view-details-btn'
                                  onClick={props.onApprove}>
                                  Approve
                                </Button>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      item
                      md={4}
                      xs={12}
                      sm={6}
                      className='order-summary-wrapper'>
                      {order.quoteInfo ? null : (
                        <div>
                          <div className='summary-title headers'>
                            Payment
                          </div>
                          <Divider />

                          <Table className='order-summary'>
                            <TableBody className='order-summary-content'>
                              <TableRow>
                                <TableCell className='headers'>
                                  Payment Term
                                </TableCell>
                                <TableCell>
                                  {' '}
                                  {billing.paymentMethod}{' '}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      )}
                      <br />
                      <div className='summary-title headers'>
                        {order.quoteInfo
                          ? `Summary`
                          : `Order Summary`}
                      </div>
                      <Divider />

                      <Table className='order-summary'>
                        <TableBody className='order-summary-content'>
                          <TableRow>
                            <TableCell className='headers'>
                              SubTotal
                            </TableCell>
                            <TableCell>
                              ${order.subTotalAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          {order.quoteInfo ? null : (
                            <TableRow>
                              <TableCell className='headers'>
                                Tax
                              </TableCell>
                              <TableCell>
                                $
                                {order.finalTaxAmount
                                  ? order.finalTaxAmount
                                  : 0}
                              </TableCell>
                            </TableRow>
                          )}
                          {order.quoteInfo ? null : (
                            <TableRow>
                              <TableCell className='headers'>
                                Shipping
                              </TableCell>
                              <TableCell>
                                $
                                {order.finalShippingAmount
                                  ? order.finalShippingAmount
                                  : 0}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell className='headers'>
                              Discounts
                            </TableCell>
                            <TableCell>
                              <span className='color-red'>
                                $
                                {order.subTotalDiscountAmount.toFixed(
                                  2
                                )}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className='headers'>
                              {order.quoteInfo
                                ? `Total`
                                : `Order Total`}
                            </TableCell>
                            <TableCell className='price'>
                              ${order.finalOrderAmount}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Grid>
                        {/* {order.quoteInfo && (
                          <Grid
                            container
                            direction='row'
                            justify='flex-end'>
                            <Button
                              variant='text'
                              className='reject-quote'
                              disabled={
                                order.orderStatus === 'approvedQuote'
                                  ? false
                                  : true
                              }
                              // className='view-details-btn'
                              // onClick={handleReturnOrder}>
                              onClick={props.rejectQuote}>
                              Reject Quote
                            </Button>
                            <Button
                              variant='contained'
                              className='view-details-btn'
                              disabled={
                                order.orderStatus === 'approvedQuote'
                                  ? false
                                  : true
                              }
                              // onClick={handleReturnOrder}>
                              onClick={placeOrder}>
                              Place Order
                            </Button>
                          </Grid>
                        )} */}
                        {(
                          < Hidden smDown>
                          < Grid style = {
                            {
                              paddingBottom: '20px'
                            }
                          } >
                            <div className='summary-title headers'>
                              Documentation
                            </div>
                            <Divider />
                            <div className='footer-icons'>
                              {/* <a>
                              <PrintOutlinedIcon />
                              <p>Print Quote</p>
                            </a> */}
                              <ReactToPrint
                                trigger={() => (
                                  <a>
                                    <PrintOutlinedIcon />
                                    <p>Print Quote</p>
                                  </a>
                                )}
                                content={() => componentRef.current}
                              />

                              <div style={{ display: 'none' }}>
                                <Print
                                  data={dataToPrint}
                                  ref={componentRef}
                                />
                              </div>
                              {/* <a href={AuthorizationForm} download> */}
                              <a
                                onClick={props.handleSignedQuote}
                                download='QuoteOrder.pdf'>
                                <BrushOutlined />
                                <p> Signed Quote </p>
                              </a>
                              <a onClick={handlePrint}>
                                <DescriptionOutlined />
                                <p> Customer Authorization </p>
                              </a>
                            </div>
                          </Grid>
                          </Hidden>
                        )}
                        <Divider></Divider>
                        {order.quoteInfo && (
                          <Grid
                            container
                            direction='row'
                            justify='flex-end'
                            style={{paddingTop:'20px'}}>
                            <Button
                              variant='text'
                              className='reject-quote'
                              disabled={
                                order.orderStatus === 'approved'
                                  ? false
                                  : true
                              }
                              // className='view-details-btn'
                              // onClick={handleReturnOrder}>
                              onClick={props.rejectQuote}>
                              Reject Quote
                            </Button>
                            <Button
                              variant='contained'
                              className='view-details-btn'
                              disabled={
                                order.orderStatus === 'approved'
                                  ? false
                                  : true
                              }
                              // onClick={handleReturnOrder}>
                              onClick={placeOrder}>
                              Place Order
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='alignCenter' style={{ zIndex: 99999 }}>
        {/* <ContentLoader
          speed={2}
          primaryColor='#f3f3f3'
          secondaryColor='#ecebeb'
          style={{
            zIndex: 99999
          }}></ContentLoader> */}
        <h3 style={{ marginTop: 15 }}>Loading ...</h3>
      </div>
    );
  }
}
