import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_ORDER_DETAIL_SAGA_RESET } from '../../constants';
import { setOrderDetailReset } from '../../actions/orderAction';

function* workerGetOrderDetailSagaReset({ payload: { orderId } }) {
  yield put(setOrderDetailReset(null));
}

export default function* watchGetOrderDetailSagaReset() {
  yield takeLatest(
    GET_ORDER_DETAIL_SAGA_RESET,
    workerGetOrderDetailSagaReset
  );
}
