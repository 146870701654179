import { put, takeLatest, call, select } from 'redux-saga/effects';

import {
  GET_ORDER_DETAIL_SAGA,
  SET_ORDER_DETAIL_SUCCESS,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setOrderDetail,
  setOrderDetailSuccess,
} from '../../actions/orderAction';
import { getOrderDetail } from '../../api/orderApi';

function* workerGetOrderDetailSaga({ payload: { orderId } }) {
  try {
    const orgData = yield select(
      (state) => state.organizationDetailsReducer.orgData
    );
    
    const response = yield call(getOrderDetail, orderId, orgData);
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;
    if (data) {
      yield put(setOrderDetailSuccess(SET_ORDER_DETAIL_SUCCESS));
    }
    yield put(setOrderDetail(data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetOrderDetailSaga() {
  yield takeLatest(GET_ORDER_DETAIL_SAGA, workerGetOrderDetailSaga);
}
