import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import './Styles.css';
import { Grid, Button } from '@material-ui/core';
import 'font-awesome/css/font-awesome.min.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Link from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Edit from '@material-ui/icons/Edit';
import { getUpdatePasswordSaga } from '../../actions/profileAction';
import {
  getProfileSaga,
  getUpdateProfileSaga,
} from '../../actions/profileAction';
import Loading from '../../containers/Loading';
import CircularProgressBar from '../Shared/spanLoader';
import { CustomTextField } from '../Shared/customTextField';
import CustomError from '../Shared/customErrorText';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PASSWORD_SAVED,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_MISMATCH,
  ERROR_PASSWORD_VALIDATION,
  PASSWORD_LENGTH,
  PASSWORD_SHOULD_BE,
  PASSWORD_LIMITATION,
} from '../../constants';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileForm: false,
      firstName: null,
      lastName: null,
      showLoader: false,
      disabled: false,
      showFirstNameError: false,
      showLastNameError: false,
      newFirstName: '',
      newLastName: '',
      // Update password related states
      showUpdatePasswordForm: false,
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      showPwLoader: false,
      pwdisabled: true,
      showOldPwError: false,
      showNewPwError: false,
      shownewConfirmPwError: false,
      invalidPassword: false,
      passwordMismatch: false,
    };
  }

  componentDidMount() {
    this.props.getProfileSaga();
  }
  componentDidUpdate(props) {
    this.toastManager = props.toastManager;
  }
  profileForm = () => {
    this.setState({
      showProfileForm: true,
      firstName:
        this.state.newFirstName === ''
          ? this.props.profile[0].profile_user.firstName
          : this.state.newFirstName,
      lastName:
        this.state.newLastName === ''
          ? this.props.profile[0].profile_user.lastName
          : this.state.newLastName,
      email: this.props.profile[0].profile_user.email,
      showLoader: false,
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };
  handleSave = (e) => {
    e.preventDefault();
    const fName = this.state.firstName;
    const lName = this.state.lastName;
    this.setState({
      showProfileForm: true,
      showLoader: true,
      newFirstName: fName,
      newLastName: lName,
      disabled: true,
    });

    this.props.getUpdateProfileSaga({
      fName,
      lName,
    });

    const toastMsg = setInterval(() => {
      if (
        this.props.updatedProfile[0].updatedProfile.status === 200
      ) {
        this.setState({
          showLoader: false,
          showProfileForm: false,
        });

        this.toastManager.add(PROFILE_UPDATE_SUCCESS, {
          appearance: 'success',
          autoDismiss: true,
        });
        clearInterval(toastMsg);
        // Set local storage user as per new changes

        let args = JSON.parse(window.localStorage.getItem('user'));
        args.firstName = this.state.firstName;
        args.lastName = this.state.lastName;

        localStorage.setItem('user', JSON.stringify(args));
        //window.location.reload();
      } else if (
        this.props.updatedProfile[0].updatedProfile.status === 401
      ) {
        this.setState({
          showLoader: false,
          showProfileForm: false,
        });
        this.toastManager.add(PROFILE_UPDATE_FAIL, {
          appearance: 'warning',
          autoDismiss: true,
        });
        clearInterval(toastMsg);
      }
    }, 1000);
  };
  changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    if ((val === null || val === '') && nam === 'firstName') {
      this.setState({
        //disabled: true,
        showFirstNameError: true,
      });
    } else if (val === null || (val === '' && nam === 'lastName')) {
      this.setState({
        //disabled: true,
        showLastNameError: true,
      });
    } else if ((val !== null || val !== '') && nam === 'firstName') {
      this.setState({
        showFirstNameError: false,
      });
    } else if ((val !== null || val !== '') && nam === 'lastName') {
      this.setState({
        showLastNameError: false,
      });
    }

    this.setState({
      [nam]: val,
    });
  };

  handleCancel = () => {
    this.setState({
      firstName:
        this.state.newFirstName === ''
          ? this.props.profile[0].profile_user.firstName
          : this.state.newFirstName,
      lastName:
        this.state.newLastName === ''
          ? this.props.profile[0].profile_user.lastName
          : this.state.newLastName,
      showLoader: false,
      showProfileForm: false,
      disabled: false,
      showUpdatePasswordForm: false,
      oldPassword: null,
      newPassword: null,
      newConfirmPassword: null,
      invalidPassword: false,
      passwordMismatch: false,
      showFirstNameError: false,
      showLastNameError: false,
    });
  };

  // Update Password
  handleSavePassword = (e) => {
    e.preventDefault();
    const oldPassword = this.state.oldPassword;
    const newPassword = this.state.newPassword;
    const newConfirmPassword = this.state.newConfirmPassword;

    if (oldPassword && newPassword && newConfirmPassword) {
      this.setState({
        showPwLoader: true,
        pwdisabled: true,
      });
      this.props.getUpdatePasswordSaga({
        oldPassword,
        newPassword,
        newConfirmPassword,
      });
    } else {
      this.toastManager.add('Please enter all the details', {
        appearance: 'warning',
        autoDismiss: true,
      });

      this.setState({
        showPwLoader: false,
        pwdisabled: false,
      });
    }

    //  Show toast messages
    const passwordToastMsg = setInterval(() => {
      const prop = this.props.updatedPassword[0];

      if (prop.response) {
      }
      if (
        prop.updatedPassword.updatedPassword &&
        prop.updatedPassword.updatedPassword.status === 200
      ) {
        this.setState({
          showPwLoader: false,
          showUpdatePasswordForm: false,
          showProfileForm: false,
        });

        this.toastManager.add(PASSWORD_SAVED, {
          appearance: 'success',
          autoDismiss: true,
        });

        clearInterval(passwordToastMsg);
      } else if (
        prop.updatedPassword.updatedPassword &&
        prop.updatedPassword.updatedPassword.response.status === 400
      ) {
        this.setState({
          showPwLoader: false,
          showUpdatePasswordForm: false,
          oldPassword: '',
          newPassword: '',
          newConfirmPassword: '',
        });
        this.toastManager.add(PASSWORD_UPDATE_ERROR, {
          appearance: 'warning',
          autoDismiss: true,
        });
        clearInterval(passwordToastMsg);
      }
    }, 1000);
  };

  passwordChangeHandler = (event) => {
    let pwName = event.target.name;
    let pwValue = event.target.value;

    if (
      pwValue === null ||
      (pwValue === '' && pwName === 'oldPassword')
    ) {
      this.setState({
        pwdisabled: true,
        showOldPwError: true,
      });
    } else if (
      pwValue === null ||
      (pwValue === '' && pwName === 'newPassword')
    ) {
      this.setState({
        pwdisabled: true,
        showNewPwError: true,
      });
    } else if (
      pwValue === null ||
      (pwValue === '' && pwName === 'newConfirmPassword')
    ) {
      this.setState({
        pwdisabled: true,
        shownewConfirmPwError: true,
      });
    } else if (pwValue !== null || pwValue !== '') {
      this.setState({
        showOldPwError: false,
        showNewPwError: false,
        shownewConfirmPwError: false,
        pwdisabled: false,
      });
    }
    this.setState({
      [pwName]: pwValue,
    });
    this.validateForm();
  };
  validateForm = () => {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      !this.state.invalidPassword &&
      !this.state.showPwLoader &&
      this.state.newPassword === this.state.newConfirmPassword
    );
  };
  validatePassword = () => {
    let newPassword = this.state.newPassword;
    let patternMatch = /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{7,15})$/i;
    let requiredPassword = newPassword.match(patternMatch);

    if (requiredPassword !== null) {
      this.setState({
        invalidPassword: false,
      });
    } else {
      this.setState({
        invalidPassword: true,
      });
    }
    if (this.state.newPassword !== this.state.newConfirmPassword) {
      this.setState({
        passwordMismatch: true,
      });
    } else {
      this.setState({
        passwordMismatch: false,
      });
    }
  };

  updatePwCancel = () => {
    this.setState({
      showUpdatePasswordForm: false,
    });
  };
  render() {
    let userProfile = this.props;
    userProfile =
      userProfile.profile[0] && userProfile.profile[0].profile_user;
    if (!userProfile) return <Loading />;
    else {
      if (
        (this.state.showProfileForm &&
          this.props.updatedProfile !== null) ||
        this.state.showLoader
      ) {
        return (
          <div className='app-content'>
            <form onSubmit={this.handleSubmit}>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='required'>
                <CustomTextField
                  id='firstName'
                  label='First Name:'
                  required={true}
                  onchange={this.changeHandler}
                  value={this.state.firstName}
                  type='text'
                  name='firstName'
                />
                {this.state.showFirstNameError && (
                  <CustomError
                    masg='Please enter a valid First Name.'
                    displayType='block'
                    margin='8px'
                  />
                )}
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className='required'>
                <CustomTextField
                  label='Last Name:'
                  required={true}
                  onchange={this.changeHandler}
                  value={this.state.lastName}
                  type='text'
                  name='lastName'
                />
                {this.state.showLastNameError && (
                  <CustomError
                    masg='Please enter a valid Last Name.'
                    displayType='block'
                    margin='8px'
                  />
                )}
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <CustomTextField
                  label='Email id:'
                  required={true}
                  value={this.state.email}
                  type='text'
                  name='email'
                  disabled={true}
                />
              </Grid>
              <Button
                variant='contained'
                color='secondary'
                disabled={
                  this.state.showFirstNameError ||
                  this.state.showLastNameError
                }
                onClick={this.handleSave}
                className='buttonClass'
                style={{
                  backgroundColor: '#e41e26',
                  color: '#ffffff',
                  marginRight: '20px',
                  marginLeft: '8px',
                }}>
                Save
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                onClick={this.handleCancel}
                className='buttonClass'
                style={{
                  border: '1px solid #e41e26',
                  color: '#e41e26',
                  marginRight: '20',
                }}>
                Cancel
              </Button>

              <span style={{ position: 'absolute', marginLeft: 5 }}>
                {this.state.showLoader &&
                  this.props.updatedProfile !== null && (
                    <CircularProgressBar />
                  )}{' '}
              </span>
              <br />
              <br />
            </form>

            {/* Form  for update password */}
            <form onSubmit={this.handleSubmit}>
              {
                <Grid>
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className='required'>
                    <CustomTextField
                      label='Old Password:'
                      required={true}
                      onchange={this.passwordChangeHandler}
                      value={this.state.oldPassword}
                      type='password'
                      name='oldPassword'
                    />
                    {this.state.showOldPwError && (
                      <CustomError
                        masg='Please enter a valid Old Password'
                        displayType='block'
                        margin='8px'
                      />
                    )}
                  </Grid>
                  <div>
                    <Hidden smDown>
                      <Grid style={{ display: 'flex' }}>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          className='required'>
                          <CustomTextField
                            label='New Password:'
                            required={true}
                            onchange={this.passwordChangeHandler}
                            value={this.state.newPassword}
                            type='password'
                            name='newPassword'
                            onblur={this.validatePassword}
                          />
                        </Grid>
                        <span className='toopTip'>
                          <Tooltip
                            title={
                              <ul className='tooltipFont'>
                                <li>{PASSWORD_LENGTH}</li>
                                <li>{PASSWORD_SHOULD_BE}</li>
                                <li>{PASSWORD_LIMITATION}</li>
                              </ul>
                            }
                            style={{ alignSelf: 'center' }}>
                            <InfoIcon />
                          </Tooltip>
                        </span>
                      </Grid>
                      {this.state.invalidPassword && (
                        <CustomError
                          masg={ERROR_PASSWORD_VALIDATION}
                          displayType={
                            this.state.invalidPassword
                              ? 'block'
                              : 'none'
                          }
                          margin='8px'
                        />
                      )}
                    </Hidden>{' '}
                  </div>
                  <div>
                    <Hidden mdUp>
                      <Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          className='required'>
                          <CustomTextField
                            label='New Password:'
                            required={true}
                            onchange={this.passwordChangeHandler}
                            value={this.state.newPassword}
                            type='password'
                            name='newPassword'
                            onblur={this.validatePassword}
                          />
                        </Grid>

                        <Grid
                          item
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{
                            alignSelf: 'center',
                          }}
                          className='mobPadding'>
                          <ul className='tooltipFont'>
                            <li>{PASSWORD_LENGTH}</li>
                            <li>{PASSWORD_SHOULD_BE}</li>
                            <li>{PASSWORD_LIMITATION}</li>
                          </ul>
                        </Grid>
                      </Grid>
                      {this.state.invalidPassword && (
                        <CustomError
                          masg={ERROR_PASSWORD_VALIDATION}
                          displayType={
                            this.state.invalidPassword
                              ? 'block'
                              : 'none'
                          }
                          margin='8px'
                        />
                      )}
                    </Hidden>{' '}
                  </div>
                  <Grid
                    item
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className='required'>
                    <CustomTextField
                      label='New Confirm Password:'
                      required={true}
                      onchange={this.passwordChangeHandler}
                      value={this.state.newConfirmPassword}
                      type='password'
                      name='newConfirmPassword'
                      onblur={this.validatePassword}
                    />
                    {this.state.shownewConfirmPwError && (
                      <CustomError
                        masg='Please enter a valid new Confirmation Password'
                        displayType='block'
                        margin='8px'
                      />
                    )}
                    {/* passwordMismatch */}
                    {this.state.passwordMismatch && (
                      <CustomError
                        masg={PASSWORD_MISMATCH}
                        displayType='block'
                        margin='8px'
                      />
                    )}
                  </Grid>
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={!this.validateForm()}
                    onClick={this.handleSavePassword}
                    className='buttonClass'
                    style={{
                      backgroundColor: '#e41e26',
                      color: '#ffffff',
                      marginRight: '20px',
                      marginLeft: '8px',
                    }}>
                    Save
                  </Button>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={this.handleCancel}
                    className='buttonClass'
                    style={{
                      border: '1px solid #e41e26',
                      color: '#e41e26',
                    }}>
                    Cancel
                  </Button>
                  <span
                    style={{ position: 'absolute', marginLeft: 5 }}>
                    {this.state.showPwLoader &&
                      this.props.updatedPassword !== null && (
                        <CircularProgressBar />
                      )}{' '}
                  </span>
                </Grid>
              }
            </form>
          </div>
        );
      }
    }
    return (
      <Grid>
        <Hidden xsDown>
          <div className='inner-wrap externalContainer'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='text-bold' align='right'>
                    First Name
                  </TableCell>
                  <TableCell className='text-bold' align='right'>
                    Last Name
                  </TableCell>
                  <TableCell className='text-bold' align='right'>
                    E-mail
                  </TableCell>
                  <TableCell className='text-bold' align='right'>
                    Password
                  </TableCell>
                  <TableCell className='text-bold' align='right' />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className='row-dimension'>
                  <TableCell scope='row'>
                    {this.state.firstName === null
                      ? userProfile.firstName
                      : this.state.firstName}
                  </TableCell>
                  <TableCell align='right'>
                    {this.state.lastName === null
                      ? userProfile.lastName
                      : this.state.lastName}
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{
                      textTransform: 'lowercase',
                    }}>
                    {userProfile.email}
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{
                      textTransform: 'lowercase',
                    }}>
                    ******
                  </TableCell>
                  <TableCell align='right'>
                    <i className='editIcon'>
                      <Edit onClick={this.profileForm} />
                    </i>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Hidden>
        <Hidden smUp>
          <Grid>
            <Grid className='externalContainer' item xs={12} sm={12}>
              <div>
                <i style={{ float: 'right', padding: 15 }}>
                  <Edit onClick={this.profileForm} />
                </i>
              </div>
              <div className='mobile-profile'>
                First Name: <br />
                <p>
                  {this.state.firstName === null
                    ? userProfile.firstName
                    : this.state.firstName}
                </p>
                Last Name: <br />
                <p>
                  {' '}
                  {this.state.lastName === null
                    ? userProfile.lastName
                    : this.state.lastName}
                </p>
                <br />
                Email: <br />
                <p
                  style={{
                    textTransform: 'lowercase',
                  }}>
                  {userProfile.email}
                </p>
                <br />
                <p> Password: ****** </p>
                <br />
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}
Profile.propTypes = {
  getProfileSaga: PropTypes.func.isRequired,
  // firstName: PropTypes.string.isRequired,
  // lastName: PropTypes.string.isRequired,
  // email: PropTypes.string.isRequired,
  profile: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  profile: [state.profileReducer.profile],
  updatedProfile: [state.updateProfileReducer],
  updatedPassword: [state.updatePasswordReducer],
});

const mapDispatchToProps = (dispatch) => ({
  getProfileSaga: () => dispatch(getProfileSaga()),
  getUpdateProfileSaga: (updatedProfile) =>
    dispatch(getUpdateProfileSaga(updatedProfile)),
  getUpdatePasswordSaga: (updatedPassword) =>
    dispatch(getUpdatePasswordSaga(updatedPassword)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Profile));
