import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

export function forgotPasswordApi(data) {
  const body = {
    username: data.email,
  };
  const forgotpasswordURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/oauth/forgotPassword`;
  return axios.post(forgotpasswordURL, body, {
    headers: {
      'Bepsy-OrganizationId': localStorage.getItem('organizationId'),
    },
  });
}
