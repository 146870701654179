import { put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_UPDATE_PROFILE_SAGA,
  SET_ERROR_STATE,
} from '../../constants';
import { setUpdateProfile } from '../../actions/profileAction';
import { getupdateProfile } from '../../api/profileApi';

function* workerGetUpdatedProfileSaga({
  payload: { firstName, lastName },
}) {
  try {
    const updatedProfile = yield call(
      getupdateProfile,
      firstName,
      lastName
    );

    yield put(setUpdateProfile(updatedProfile));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetUpdatedProfileSaga() {
  yield takeLatest(
    GET_UPDATE_PROFILE_SAGA,
    workerGetUpdatedProfileSaga
  );
}
