import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MobileFilterType from '../ProductSearchPage/mobileFilterType';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    color: 'white',
    backgroundColor: 'rgb(64, 169, 243)',
    fontSize: 12,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: 16,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />;
});

const mobileFilter = ({
  isMobileFilterOpen,
  mobilerFilterClickHandler,
  facetData,
  filterOptionClickHandler,
  categoryClickHandler,
  clearAllFilter,
  mobileFilterApplyHandler,
  adjustments,
  category,
}) => {
  const classes = useStyles();
  const mobileFacetData = [];
  facetData.map((facet, i) => {
    if (facet.type === 'heirarchical') {
      mobileFacetData.push(facet);
    }
  });

  facetData.map((facet, i) => {
    if (facet.type !== 'heirarchical') {
      mobileFacetData.push(facet);
    }
  });
  return (
    <div>
      <Dialog
        fullScreen
        open={isMobileFilterOpen}
        onClose={mobilerFilterClickHandler}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={mobilerFilterClickHandler}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Filter
            </Typography>
            <Button color='inherit' onClick={clearAllFilter}>
              Clear All
            </Button>
            <Button
              color='inherit'
              onClick={mobileFilterApplyHandler}>
              Apply
            </Button>
          </Toolbar>
        </AppBar>
        <MobileFilterType
          facetData={category ? category.facets : mobileFacetData}
          adjustments={adjustments}
          filterOptionClickHandler={filterOptionClickHandler}
          categoryClickHandler={categoryClickHandler}
          isMobile={isMobileFilterOpen}
          category={category}
        />
      </Dialog>
    </div>
  );
};

export default mobileFilter;
