import React from 'react';
import Grid from '@material-ui/core/Grid';
//import BootstrapButton from './bootstrapButton';
import './style.css';

const customHeroBanner = ({
  itemId,
  itemLabel,
  imgUrl,
  buttonClickHandler,
}) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <div style={{ marginBottom: 20 }}>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className='bannerText'>
          {itemLabel}
        </Grid>
        <img src={imgUrl} className='bannerBG' alt='' />
        {/* <BootstrapButton
            isDisabled={false}
            btnLabel={itemLabel}
            margin='0'
            buttonClick={buttonClickHandler}
            buttonClickParams={[itemId]}
          /> */}
      </div>
    </Grid>
  );
};

export default customHeroBanner;
