import { put, takeLatest, call } from 'redux-saga/effects';

import {
  REMOVE_FROM_CART_SAGA,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_ERROR,
  SET_ERROR_STATE,
} from '../../constants';
import {
  setCartData,
  removeFromCartSaga,
  updateCartState,
} from '../../actions/cartItemAction';
import { removeFromCart } from '../../api/cartApi';

function* workerRemoveFromCart({
  payload: { skuId, orderId, productId, quantity, commerceItemId },
}) {
  try {
    const response = yield call(
      removeFromCart,
      skuId,
      orderId,
      productId,
      quantity,
      commerceItemId
    );
    const data =
      response.data && response.data.orderList
        ? response.data.orderList[0]
        : null;

    // removeFromCart api return 'null' for last item removal from cart...adding null check as resolve to handle this case
    if (data) {
      yield put(updateCartState(REMOVE_FROM_CART_SUCCESS));
    } else {
      yield put(updateCartState(REMOVE_FROM_CART_ERROR));
    }
    if (data === null) {
      yield put(updateCartState(REMOVE_FROM_CART_SUCCESS));
      const userData = localStorage.getItem('context');
      const parsedData = JSON.parse(userData);
      parsedData.orderId = '';
      localStorage.setItem('context', JSON.stringify(parsedData));
    }
    yield put(setCartData(data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
  /*
   * const users = yield call(updateCart);
   * yield put(setCartData(users.data.orderList[0]));
   *console.log('log card data from saga===>', users.data.orderList[0]);
   */
}

export default function* watchRemoveFromCartSaga() {
  yield takeLatest(REMOVE_FROM_CART_SAGA, workerRemoveFromCart);
}
