import {
  SET_CART_DATA,
  SET_MINI_CART_ITEMS,
  ADD_TO_CART_STATE,
  UPDATE_CART_SAGA,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERROR,
  UPDATE_ITEM_QUANTITY,
  REMOVE_FROM_CART_SAGA,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_ERROR,
  UPDATE_SELECTED_ADDRESS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILED,
  SET_ORDER_DETAIL,
  SET_ORDER_DETAIL_SUCCESS,
  SET_ORDER_OPTION_LIST,
} from '../constants';

const initialState = {
  addToCartState: false,
  cart: null,
  updateCartState: false,
  orders: null,
  submitOrderState: false,
  setOrderDetailSuccess: false,
  order_detail: null,
  orderoptionList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_DATA:
      return {
        ...state,
        cart: action.data,
      };
    case SET_MINI_CART_ITEMS:
      return {
        ...state,
        cart: action.data,
      };
    case ADD_TO_CART_STATE:
      return {
        ...state,
        addToCartState: action.data,
      };
    // case UPDATE_CART_SAGA:
    //   return {
    //     ...state,
    //     cart: action.data,
    //   };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        updateCartState: action.data,
      };
    case UPDATE_CART_ERROR:
      return {
        ...state,
        updateCartState: action.data,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        updateCartState: action.data,
      };
    case REMOVE_FROM_CART_ERROR:
      return {
        ...state,
        updateCartState: action.data,
      };
    case UPDATE_ITEM_QUANTITY:
      return {
        ...state,
        cart: action.data,
      };
    case UPDATE_SELECTED_ADDRESS:
      return {
        ...state,
        cart: action.data,
      };
    case SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.data,
      };
    case SUBMIT_ORDER_FAILED:
      return {
        ...state,
        submitOrderState: action.data,
      };
    case SET_ORDER_OPTION_LIST:
      return {
        ...state,
        orderoptionList: action.data,
      };
    case SET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        setOrderDetailSuccess: action.data,
      };
    /*
     * case SET_ORDER_HISTORY:
     * console.log("SET_ORDER_HISTORY reducer", action.payload.orderHistory);
     *   return {
     *     ...state,
     *     orderHistory: action.payload.orderHistory
     *   };
     * case SET_ORDER_DETAIL:
     *   return {
     *     ...state,
     *     order_detail: action.payload.order_detail
     *   };
     */
    default:
      return state;
  }
};
