import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OrderDetails from '../../components/Details/detail';
import PageLayoutHOC from '../../HOC/PageLayoutHOC';
import { getOrderDetail } from '../../api/orderApi';
import { setOrderApproved } from '../../actions/orderAction';

function OrderDetailsContainer(props) {
  const orderId = props.match.params.id;
  const [order, setOrder] = useState();
  const [isUserAdmin, setUserAdmin] = useState();
  const updateApprovedStatus = (isApproved) => {
    setOrder({
      ...order,
      orderStatus: isApproved ? 'confirmed' : 'cancelled',
      orderStatusDisplay: isApproved ? 'Confirmed' : 'Cancelled',
    });
  };
  const handleApprove = () => {
    props.setOrderApproved(order.orderNumber, true);
    updateApprovedStatus(true);
  };
  const handleReject = () => {
    props.setOrderApproved(order.orderNumber, false);
    updateApprovedStatus(false);
  };

  // Can use internal state since no redux selectors are used inside of OrderDetails
  useEffect(() => {
    getOrderDetail(orderId)
      .then((res) => {
        const orderDetails = res.data.orderList[0];
        setOrder(orderDetails);
      });

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.contactTypeRole) {
        user.contactTypeRole.map((data) => {
          if (data.roleId === 'admin') {
            setUserAdmin(true);
          }
        });
      }
    } catch(err) {
      console.error(err);
    }
  }, [orderId]);

  const orderStatus = order && order.orderStatus;
  const hasApprovalActions = orderStatus === 'pendingApproval' && isUserAdmin;

  return (
    <PageLayoutHOC {...props} showSearch='false'>
      <OrderDetails
        order={{
          order_detail: order
        }}
        onApprove={handleApprove}
        onReject={handleReject}
        hasApprovalActions={hasApprovalActions}
      />
    </PageLayoutHOC>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  // getQuoteDetails: (quoteId) => dispatch(getQuoteDetailsAction(quoteId)),
  setOrderApproved: (orderId, isApproved) => dispatch(setOrderApproved(orderId, isApproved)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsContainer);