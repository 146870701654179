import {
  GET_FORGOT_PASSWORD_EMAIL_SAGA,
  SET_FORGOT_PASSWORD_EMAIL_SAGA,
} from '../constants';

export function getForgotPasswordEmailSaga(email) {
  return {
    type: GET_FORGOT_PASSWORD_EMAIL_SAGA,
    email,
  };
}
export function setForgotPasswordEmailSaga(data) {
  return {
    type: SET_FORGOT_PASSWORD_EMAIL_SAGA,
    data,
  };
}
