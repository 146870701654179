import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import './Styles.css';
import Grid from '@material-ui/core/Grid';
import ContentLoader from 'react-content-loader';
import { getHomeCarouselSaga } from '../../actions';
import BootstrapButton from '../../components/Shared/bootstrapButton';
import {
  Route,
  Switch,
  Link,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';


function getCurrentContext() {
  try {
    return JSON.parse(localStorage.getItem('context'));
  } catch(err) {
    return null;
  }
}

class HomePageBannerCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionUrl: null,
    };
  }
  componentDidMount() {
    this.props.getHomeCarouselSaga();
  }
  findABikeClickHandler = (e, actionUrl) => {
    this.setState({
      actionUrl: actionUrl,
    });
  };

  render() {
    let { home_carousel } = this.props;
    home_carousel = home_carousel[0].items;
    if (!home_carousel) return <ContentLoader />;
    // {
    //   /* // If button clicked redirect page */
    // }
    if (this.state.actionUrl) {
      return <Redirect to={`${this.state.actionUrl}`} />;
    }

    const currentContext = getCurrentContext();
    const isSiteUs = (currentContext && currentContext.siteId) === 'siteUS';

    return (
      <Grid>
        <Carousel
          autoPlay
          infiniteLoop={true}
          stopOnHover={true}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          dynamicHeight={true}
          className='externalContainer container'>
          {home_carousel.map((image, i) => {
            return (
              <div
                className='setWidthCarousel'
                style={{ backgroundImage: `url(${image.img_url})` }}
                key={`homeCarousel-${image.id}-${image.name}`}>
                <img className='imageResize' src={image.img_url} />
                <div className='centered'>
                  <h1>{image.short_text}</h1>
                  { isSiteUs ? <BootstrapButton
                    className='btnClick'
                    btnLabel='FIND A BIKE'
                    margin='0'
                    color='red'
                    buttonClick={(e) =>
                      this.findABikeClickHandler(e, image.action_url)
                    }
                  /> : null }
                </div>
              </div>
            );
          })}
        </Carousel>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  home_carousel: [state.homeCarouselReducer.home_carousel],
});

const mapDispatchToProps = (dispatch) => ({
  getHomeCarouselSaga: () => dispatch(getHomeCarouselSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageBannerCarousel);
