import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE
} from '../configurations/config.js';
import { headerData } from './headerData';
import { organizationHeader } from './quoteApi';

//  order list
export function getOrders() {
  let context = localStorage.getItem('context');

  context = JSON.parse(context);

  let options = headerData();
  const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${context.profileId}&orderState=submitted`;
  return axios.get(getOrdersApi, options);
}

//  order states
export function getOrderStates() {
  let options = headerData();
  const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders/states`;
  return axios.get(getOrdersApi, options).then((res) => res.data.states);
}

// Api call on view Order
export function getOrderDetail(orderId, orgData) {
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;

  const { profileId } = context;

  let options = headerData();
  if (orgData) {
    options.headers['Bepsy-OrganizationId'] = orgData;
  }
  const getOrdersDetailsApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders/${orderId}/?profileId=${profileId}`;

  return axios.get(getOrdersDetailsApi, options);
}

export function updateOrder(orderId, orgData, payload) {
  let context = localStorage.getItem('context');
  let headerContent = organizationHeader();

  context = JSON.parse(context);
  const endpointUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders/${orderId}?profileId=${context.profileId}`;

  return axios.put(endpointUrl, payload, headerContent);
}

// order submition
export function submitOrderApi(body, orgData) {
  let context = localStorage.getItem('context');
  context = JSON.parse(context);
  let headerContent = organizationHeader();

  if (body.quote) {
    delete body['quote'];
    if (orgData) {
      headerContent.headers['Bepsy-OrganizationId'] = orgData;
    }
    console.log('insidethe quote api call', body);
    // return true;
    const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/quotes/${body.orderId}/submit?profileId=${context.profileId}`;

    return axios.put(getOrdersApi, body, headerContent);
  } else {
    // const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${context.profileId}&orderState=submitted`;

    // return axios.put(getOrdersApi, body, headerContent);
    const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${context.profileId}&orderState=submitted`;

    return axios.put(getOrdersApi, body, headerContent);
  }
  // const getOrdersApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${context.profileId}&orderState=submitted`;

  // return axios.put(getOrdersApi, body, headerContent);
}

// order list on pagination
export function getOrdersOnPagination(
  searchInput,
  currentPage,
  recordPerPage,
  ordersUpto,
  startDate,
  endDate,
  orderRange,
  sortProp,
  sortOrder,
  statusData,
  orgData
) {
  let setordersUpto = orderRange ? '' : ordersUpto;
  let context =
    localStorage.getItem('context') &&
    localStorage.getItem('context');
  context = JSON.parse(context);
  const profileId = context && context.profileId;
  let headerContent = headerData();
  let getOrdersOnPaginationApi = '';
  const searchQuery = searchInput ? searchInput : ''

  for (let value of Object.values(headerContent)) {
    headerContent.headers['Bepsy-OrganizationId'] = orgData;
  }
  
  if (!orderRange) {
    getOrdersOnPaginationApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${profileId}&orderState=${statusData}&query=${searchQuery}&page=${currentPage}&recordsPerPage=${recordPerPage}&sortProperty=${sortProp}&sortOrder=${sortOrder}`;
  } else if (orderRange && profileId) {
    // Removed includeItems=true flag for CT, might be needed for OCC. Need to check when code gets merged into OCC branch.
    getOrdersOnPaginationApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/orders?profileId=${profileId}&orderState=${statusData}&page=${currentPage}&recordsPerPage=${recordPerPage}`;    
  }
  if (profileId) {
  return axios.get(getOrdersOnPaginationApi, headerContent);
  }
  else return null

  
}
