import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import './style.css';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
    '&:focus > $content': {
      backgroundColor: 'white',
      color: 'var(--tree-view-color)',
      fontSize: '14px',
      fontFamily: 'SourceSansPro',
      color: '#1e90ff',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: '14px',
      fontFamily: 'SourceSansPro',
      color: '#5b5e5f',
    },
  },
  group: {
    marginLeft: 7,
    '& $content': {
      paddingLeft: theme.spacing(0),
    },
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
  },
  expanded: {
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
  },
  label: {
    fontWeight: 'inherit',
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.7, 0),
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    // color: '#5b5e5f',  // remove color to add other color for identification of clicked option
  },
  labelCount: {
    textAlign: 'right',
    fontSize: '14px',
    fontFamily: 'SourceSansPro',
    color: '#5b5e5f',
    marginLeft: 7,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelTitle,
    name,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    clickHandler,
    ...other
  } = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} name={name}>
          <Typography
            title={labelTitle}
            variant='body2'
            className={classes.labelText}
            color={color}
            name={name}
            onClick={clickHandler}>
            {labelText}
          </Typography>
          <Typography
            variant='caption'
            className={classes.labelCount}
            name={name}>
            {labelInfo}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  // labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
  },
});

const formatLabel = (strData) => {
  if (strData.length < 20) return strData;
  else {
    strData = strData.substring(0, 20);
    strData += '...';
    return strData;
  }
};

const heirarchicalFilter = ({
  data,
  categoryClickHandler,
  isMobile,
}) => {
  const classes = useStyles();

  const ancesters =
    data && data.ancesters ? data.ancesters.length : 0;
  const options = data && data.options ? data.options : null;

  let treeNodes = [];
  let currentNode = 0;

  if (ancesters > 0) {
    const node = (
      <StyledTreeItem
        id='ancestor'
        labelTitle={data.ancesters[ancesters - 1].label}
        nodeId='1'
        color='secondary'
        name={data.ancesters[ancesters - 1].value}
        labelText={data.ancesters[ancesters - 1].label}
        clickHandler={categoryClickHandler}>
        {options.map((option, i) => (
          <StyledTreeItem
            key={i}
            labelTitle={option.label}
            nodeId={`"${i + 5}"`}
            name={option.value}
            labelText={
              isMobile ? option.label : formatLabel(option.label)
            }
            labelInfo={option.count.toString()} //  Convert the numer to string as defined by propTypes.
            bgColor='#e8f0fe'
            clickHandler={categoryClickHandler}
          />
        ))}
      </StyledTreeItem>
    );

    treeNodes.push(node);

    for (let i = ancesters - 2; i >= 0; --i) {
      const oldNode = treeNodes[currentNode];
      ++currentNode;
      const newNode = (
        <StyledTreeItem
          id='parent'
          labelTitle={data.ancesters[i].label}
          nodeId='2'
          color='secondary'
          name={data.ancesters[i].value}
          labelText={data.ancesters[i].label}
          clickHandler={categoryClickHandler}>
          {oldNode}
        </StyledTreeItem>
      );
      treeNodes = [...treeNodes, newNode];
    }
  }

  return (
    <React.Fragment>
      {treeNodes.length > 0 && (
        <TreeView
          className={classes.root}
          defaultExpanded={['1', '2']}>
          {treeNodes[treeNodes.length - 1]}
        </TreeView>
      )}

      {treeNodes.length === 0 &&
        data.options &&
        data.options.map((option, index) => {
          return (
            <TreeView className={classes.root} key={index}>
              <StyledTreeItem
                labelTitle={option.label}
                nodeId={`"${index}"`}
                name={option.value}
                labelText={
                  isMobile ? option.label : formatLabel(option.label)
                }
                labelInfo={option.count.toString()}
                color='#5b5e5f'
                bgColor='#e8f0fe'
                clickHandler={categoryClickHandler}
              />
            </TreeView>
          );
        })}
    </React.Fragment>
  );
};

export default heirarchicalFilter;
