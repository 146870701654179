import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_COUNTRY_DETAILS_SAGA } from '../../constants';
import { setCountryData } from '../../actions/countryStateAction';
import { getCountries } from '../../api/countryStateApi';

function* workerGetCountriesSaga() {
  const countries = yield call(getCountries);
  yield put(setCountryData(countries.data.countries));
}

export default function* watchCountriesSaga() {
  yield takeLatest(GET_COUNTRY_DETAILS_SAGA, workerGetCountriesSaga);
}
