import { put, takeLatest, call } from 'redux-saga/effects';

import { ADD_CONTACTS, SET_ERROR_STATE } from '../../constants';
import { setContactList } from '../../actions/organizationAction';
import {
  addContactDetails,
  getSearchContactList,
} from '../../api/contactApi';

function* workerAddContactDataSaga(ContactData) {
  try {
    const contact_response = yield call(
      addContactDetails,
      ContactData.data
    );
    if (contact_response) {
      const contact_list = yield call(getSearchContactList, {
        query: '',
        page: 0,
        recordsPerPage: 10,
      });
      yield put(setContactList(contact_list.data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchAddContactDataSaga() {
  yield takeLatest(ADD_CONTACTS, workerAddContactDataSaga);
}
