import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Styles.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ContentLoader from 'react-content-loader';
import { getFeaturedCategoriesSaga } from '../../actions';
import 'font-awesome/css/font-awesome.min.css';

class FeaturedCategories extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getFeaturedCategoriesSaga();
  }

  render() {
    var { featured_categories } = this.props;
    featured_categories = featured_categories[0].items;

    if (!featured_categories) return <ContentLoader />;
    return (
      <Grid>
        <div className='externalContainer containerCategory'>
          <div className='catContainer'>
            <h1 className='centerTextCat'>Featured Categories</h1>
            {featured_categories.map((image, i) => {
              return (
                <div
                  className='outerBorder'
                  key={`featuredCategories-${image.categories_name}`}>
                  <div className='responsive'>
                    <div className='gallery'>
                      <a target='_blank' href={image.action_url}>
                        <img
                          src={image.img_url}
                          alt='category-image'
                        />
                        <div className='desc'>
                          {image.categories_name}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='view'>
            <a className='viewText' title='View all'>
              View all <span className='fa'>&#xf107;</span>
            </a>
          </div>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  featured_categories: [
    state.featuredCategoriesReducer.featured_categories,
  ],
});

const mapDispatchToProps = (dispatch) => ({
  getFeaturedCategoriesSaga: () =>
    dispatch(getFeaturedCategoriesSaga()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedCategories);
