import {
  SET_MINI_CART_ITEMS,
  GET_MINI_CART_ITEMS_SAGA,
  ADD_TO_CART_STATE,
  ADD_TO_CART_SAGA,
  SET_CART_DATA,
  UPDATE_ITEM_QUANTITY,
  UPDATE_CART_SAGA,
  UPDATE_CART_SUCCESS,
  REMOVE_FROM_CART_SAGA,
  REMOVE_FROM_CART_SUCCESS,
} from '../constants';

export function setMiniCartItems(minicart_items) {
  return {
    type: SET_MINI_CART_ITEMS,
    minicart_items,
  };
}

export function getMiniCartItemsSaga() {
  return {
    type: GET_MINI_CART_ITEMS_SAGA,
  };
}

export const addToCartState = (data) => ({
  type: ADD_TO_CART_STATE,
  data,
});

export const addToCartSaga = (skuId, quantity) => ({
  type: ADD_TO_CART_SAGA,
  payload: { skuId, quantity },
});

export const setCartData = (data) => {
  console.log('set cart data ==> ', data);
  return {
    type: SET_CART_DATA,
    data,
  };
};

export const updateItemQuantity = (
  order,
  skuId,
  quantity,
  action
) => ({
  type: UPDATE_ITEM_QUANTITY,
  payload: { order, skuId, quantity, action },
});

//  update cart
export const updateCartSaga = (
  skuId,
  orderId,
  productId,
  quantity,
  commerceItemId
) => ({
  type: UPDATE_CART_SAGA,
  payload: { skuId, orderId, productId, quantity, commerceItemId },
});

//  update cart state
export const updateCartState = (data) => ({
  type: UPDATE_CART_SUCCESS,
  data,
});

export const removeFromCartSaga = (
  skuId,
  orderId,
  productId,
  quantity,
  commerceItemId
) => ({
  type: REMOVE_FROM_CART_SAGA,
  payload: { skuId, orderId, productId, quantity, commerceItemId },
});

export const removeFromCartState = (data) => ({
  type: REMOVE_FROM_CART_SUCCESS,
  data,
});
