import { SET_PASSWORD, GET_PASSWORD_SAGA } from '../constants';
// Added for Set Password
export function setPassword(password) {
  return {
    type: SET_PASSWORD,
    payload: {
      password,
    },
  };
}
//Sagas
export default function getPasswordSaga(props) {
  const email = props.email;
  const oldPassword = props.oldPassword;
  const newPassword = props.newPassword;
  const newConfirmPassword = props.newConfirmPassword;
  return {
    type: GET_PASSWORD_SAGA,
    payload: {
      email,
      oldPassword,
      newPassword,
      newConfirmPassword,
    },
  };
}
