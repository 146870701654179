import React from 'react';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BepsyImage } from '../../components/BepsyImage';

const MiniCart = ({ order }) => (
  <div>
    <div className='miniCart'>
      <Link to='/cart'>
        <h4 className='headerMinicart'>
          CART(
          {order &&
          order[0].cart &&
          order[0].cart.EOrderItems.length > 0
            ? order[0].cart.EOrderItems.length
            : '0'}
          )
        </h4>
      </Link>
      {/* Added code from minicart layout component for desktop */}
      {order &&
        order[0].cart &&
        order[0].cart.EOrderItems.length > 0 && (
          <Hidden smDown>
            <div className='miniCart-position mob-padding '>
              <div className='set-cs'>
                <div className='miniCart-layout externalContainer'>
                  {order[0] &&
                    order[0].cart &&
                    order[0].cart.EOrderItems.length > 0 &&
                    order[0].cart &&
                    order[0].cart.EOrderItems.map((items) => (
                      <Card className='card-style' key={items.sfid}>
                        <Grid style={{ display: 'flex' }}>
                          <Grid>
                            <div className='mini-cart-img'>
                              <BepsyImage
                                src={items.EItemMedia.thumbURI}
                                alt='product'
                              />
                            </div>
                          </Grid>
                          <Grid className='mini-cart-details'>
                            <div className='product-text'>
                              {items.productName}
                            </div>
                            <small>Part # : {items.product}</small>
                            <div className='productPriceCart'>
                              <Grid item className='qty'>
                                Qty : {items.quantity}
                              </Grid>
                              <Grid item className='sale-price'>
                                Price :{' '}
                                {items.salePrice != null &&
                                items.salePrice > 0
                                  ? items.salePrice
                                  : items.listPrice}
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    ))}
                </div>
                {/* Add Button */}
                <div className='button-wrap externalContainer'>
                  <Link to='/cart'>
                    <Button className='view-button'>
                      <p className='show-text'> View Cart </p>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Hidden>
        )}
    </div>
  </div>
);
MiniCart.propTypes = {
  order: PropTypes.array.isRequired,
};

export default MiniCart;
