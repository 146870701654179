import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { headerData } from './headerData';

// Address list
export const getAddress = () => {
  const access_token = localStorage.getItem('access_token');
  const headersProfile = `Bearer ${access_token}`;
  const reqHeader = {
    ...headerData().headers,
    'Content-type': 'application/JSON',
    Authorization: headersProfile,
  };
  const addressGETURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/addresses`;
  return axios.get(addressGETURL, {
    headers: reqHeader,
  });
};

//  Add address
export const addAddress = (addressData) => {
  const reqBody = {
    address: addressData.data,
  };

  const addAddressURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/addresses`;
  let options = headerData();
  return axios.post(addAddressURL, reqBody, options);
};

//  delete address
export const deleteAddress = (addressData) => {
  const reqBody = {
    address: addressData.data,
  };

  const repositoryId = reqBody.address.repositoryId;
  delete reqBody.address['repositoryId'];

  const deleteAddressURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/addresses/${repositoryId}`;
  let options = headerData();
  return axios.delete(deleteAddressURL, {
    headers: options.headers,
    data: reqBody,
  });
};

//  update adress
export const updateAddress = (addressData) => {
  const reqBody = {
    address: addressData.data,
  };

  const repositoryId = reqBody.address.repositoryId;
  delete reqBody.address['repositoryId'];

  const updateAddressURL = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/organization/addresses/${repositoryId}`;
  let options = headerData();
  return axios.put(updateAddressURL, reqBody, options);
};
