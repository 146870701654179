import {
  GET_USERS_SAGA,
  SET_USERS,
  GET_HOME_CAROUSEL_SAGA,
  SET_HOME_CAROUSEL,
  GET_PROMOTION_SAGA,
  SET_PROMOTION,
  GET_CUSTOMER_SUPPORT_SAGA,
  SET_CUSTOMER_SUPPORT,
  GET_AUTH_SAGA,
  SET_AUTH,
  GET_RECOMMENDED_PRODUCTS_SAGA,
  SET_RECOMMENDED_PRODUCTS,
  GET_FEATURED_CATEGORIES_SAGA,
  SET_FEATURED_CATEGORIES,
  GET_FEATURED_PRODUCTS_SAGA,
  SET_FEATURED_PRODUCTS,
  GET_PRODUCT_SEARCH_DATA_SAGA,
  GET_CATEGORY_SEARCH_DATA_SAGA,
  CLEAR_CATEGORY_DATA,
  SET_PRODUCT_CATEGORY_DATA,
  SET_PRODUCT_SEARCH_DATA,
  SET_SEARCH_TERM,
  CLEAR_SEARCH_RESULT,
  SET_ORDER_OPTION_LIST,
  GET_ORDER_OPTION_LIST,
  SET_OPTION_LIST,
  GET_OPTION_LIST,
} from '../constants';

export function setUsers(users) {
  return {
    type: SET_USERS,
    users,
  };
}

// Sagas
export function getUsersSaga() {
  return {
    type: GET_USERS_SAGA,
  };
}

export function setAuth(auth) {
  return {
    type: SET_AUTH,
    auth,
  };
}

// Sagas
export function getAuthSaga() {
  return {
    type: GET_AUTH_SAGA,
  };
}

//  set home carousel images
export function setHomeCarousel(home_carousel) {
  return {
    type: SET_HOME_CAROUSEL,
    home_carousel,
  };
}
// Sagas
export function getHomeCarouselSaga() {
  return {
    type: GET_HOME_CAROUSEL_SAGA,
  };
}
// Added for Home Page Featured Promotion
export function setPromotion(promotion) {
  return {
    type: SET_PROMOTION,
    promotion,
  };
}

export function getPromotionSaga() {
  return {
    type: GET_PROMOTION_SAGA,
  };
}
// Added for Home Page Featured CUSTOMER_SUPPORT
export function setCustomerSupport(customer_support) {
  return {
    type: SET_CUSTOMER_SUPPORT,
    customer_support,
  };
}

// Sagas
export function getCustomerSupportSaga() {
  return {
    type: GET_CUSTOMER_SUPPORT_SAGA,
  };
}

// Added for Recommended Products
export function setRecommendedProducts(recommended_products) {
  return {
    type: SET_RECOMMENDED_PRODUCTS,
    recommended_products,
  };
}

export function getRecommendedProductsSaga() {
  return {
    type: GET_RECOMMENDED_PRODUCTS_SAGA,
  };
}

// Added for Featured Categories
export function setFeaturedCategories(featured_categories) {
  return {
    type: SET_FEATURED_CATEGORIES,
    featured_categories,
  };
}

export function getFeaturedCategoriesSaga() {
  return {
    type: GET_FEATURED_CATEGORIES_SAGA,
  };
}
// Added for Featured Products
export function setFeaturedProducts(featured_products) {
  return {
    type: SET_FEATURED_PRODUCTS,
    featured_products,
  };
}

// Sagas
export function getFeaturedProductsSaga() {
  return {
    type: GET_FEATURED_PRODUCTS_SAGA,
  };
}

export const getProductSearchDataSaga = (searchTerm, adjustments) => {
  return {
    type: GET_PRODUCT_SEARCH_DATA_SAGA,
    payload: { searchTerm, adjustments },
  };
};

export const setProductListingData = (data) => {
  return {
    type: SET_PRODUCT_SEARCH_DATA,
    data,
  };
};

export const getCategorySearchData = (adjustments) => {
  return {
    type: GET_CATEGORY_SEARCH_DATA_SAGA,
    payload: { adjustments },
  };
};
export const setProductCategoryData = (data) => {
  return {
    type: SET_PRODUCT_CATEGORY_DATA,
    data,
  };
};

export const clearCategoryData = () => {
  return {
    type: CLEAR_CATEGORY_DATA,
  };
};

export const setSearchTerm = (data) => {
  return {
    type: SET_SEARCH_TERM,
    data,
  };
};

export const clearSearchResult = (data) => {
  return {
    type: CLEAR_SEARCH_RESULT,
    data,
  };
};

export const getOrderOptionList = (searchTerm) => {
  return {
    type: GET_ORDER_OPTION_LIST,
    payload: { searchTerm },
  };
};

export const setOrderOptionList = (data) => {
  return {
    type: SET_ORDER_OPTION_LIST,
    data,
  };
};

export const getOptionList = (searchTerm) => {
  return {
    type: GET_OPTION_LIST,
    payload: {
      searchTerm,
    },
  };
};

export const setOptionList = (data) => {
  return {
    type: SET_OPTION_LIST,
    data,
  };
};
