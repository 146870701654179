import axios from 'axios';
import {
  BASE_URL,
  VERSION,
  APPLICATION_NAME_BE,
} from '../configurations/config.js';
import { getProductIdFromURL } from '../utils/common';
import { headerData } from './headerData';

export function getMiniCartItems() {
  let context = localStorage.getItem('context');
  context = context && JSON.parse(context);

  const getMiniCartApi = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current?profileId=${context.profileId}`;
  let options = headerData();
  return axios.get(getMiniCartApi, options);
}

export const addToCart = (skuId, quantity, id) => {
  const productId = getProductIdFromURL();
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;

  const reqBody = {
    skuId,
    orderId: '',
    productId: id ? id : productId,
    quantity,
    profileId: context.profileId,
  };

  const productApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current`;
  let options = headerData();
  return axios.post(productApiUrl, reqBody, options);
};

// update cart
export const updateCart = (
  skuId,
  orderId,
  productId,
  quantity,
  commerceItemId
) => {
  // const productId1 = getProductIdFromURL();
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;

  const reqBody = {
    skuId,
    orderId,
    productId,
    quantity,
    profileId: context.profileId,
    commerceItemId,
  };

  const updateCartApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current`;
  /*
   * const updateCartApiUrl =
   *   'https://bepsy-api.objectedge.com/oe_commerce_api/occ/v1/carts/updatecart';
   */
  let options = headerData();
  return axios.put(updateCartApiUrl, reqBody, options);
};

// update cart
export const removeFromCart = (
  skuId,
  orderId,
  productId,
  quantity,
  commerceItemId
) => {
  // const productId2 = getProductIdFromURL();
  const context =
    localStorage !== null && localStorage.context !== null
      ? JSON.parse(localStorage.context)
      : null;

  const reqBodyRC = {
    skuId,
    orderId,
    productId,
    quantity,
    profileId: context.profileId,
    commerceItemId,
  };

  /*
   * const updateCartApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/updatecart`;
   *https://bepsy-api.objectedge.com/oe_commerce_api/occ/v1/carts/removefromcart
   */
  const removeFromCartApiUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current`;

  let options = headerData();
  return axios.delete(removeFromCartApiUrl, {
    headers: options.headers,
    data: reqBodyRC,
  });
};

// Email cart

export const emailCartApi = (emailto) => {
  let emailArr = emailto.split(',');
  const context =
    localStorage !== null && localStorage.context !== null ?
    JSON.parse(localStorage.context) :
    null;
    const user = localStorage !== null && localStorage.context !== null ? JSON.parse(localStorage.getItem('user')) : null;
    const fName = user.firstName;
    const email = user.email;
   
  const reqBody = {
    "firstName":fName,
    "emailFrom":email,
    "emailTo": emailArr,
  };
  const emailCartUrl = `${BASE_URL}/oe_commerce_api/${APPLICATION_NAME_BE}/${VERSION}/carts/current/send?profileId=${context.profileId}`;
  let options = headerData();
  return axios.post(emailCartUrl, reqBody, options).then(response => {
    localStorage.setItem("emailFlag", true);
  }).catch(error => {
    localStorage.setItem("emailFlag", false);
  });
  };


