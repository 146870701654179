import React, {
    Component
} from 'react'
import {
    connect
} from 'react-redux';
import {
    withToastManager
} from 'react-toast-notifications';
import CSVReader from 'react-csv-reader';
import {
    getOrderOptionList,
} from '../../actions';
import './style.css';
import {
    Button
} from '@material-ui/core';
class Upload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sku: '',
            qty: '1',
            data: '',
            showError:false,
        }
    }
    componentDidUpdate(prevProps) {
        this.toastManager = prevProps.toastManager;

        if (this.props.optionList !== prevProps.optionList && this.props.optionList.response.totalRecords > 0) {
            let optionList = this.props && this.props.optionList && this.props.optionList.response && this.props.optionList.response.records[0]
            const data = this.state.data;
            data && data.map(item => {
                var checkOPtion = (optionList && optionList.SKU);
                let checkFileData = item.sku && (item.sku).toString();
                if (checkFileData === checkOPtion) {
                    if (item.qty>0) {
                    this.props.getOption(optionList, item.qty);
                    }
                }
            });

        }
    }

    handleForce = (data, fileInfo) => {
        if(fileInfo.size <= 8){
             this.toastManager.add("Please check data in CSV", {
                 appearance: 'error',
                 autoDismiss: true,
             });
        }
        else{
            if (data[0] && !("sku" in data[0]) || data[0] && !("qty" in data[0])) {
                this.toastManager.add("Invalid file", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
            else {
                 const {
                     orderoptionList
                 } = this.props;
                 let options = orderoptionList ?
                     orderoptionList.response.records :
                     null;
                 data && data.map(item => {
                     this.setState({
                         sku: item.sku,
                         qty: item.qty,
                         option: options,
                         data: data,
                     });
                     this.props.getOrderOptionList(this.state.sku);
                 })
                 }
            }            
            
       };

    papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    render() {
        const {
            orderoptionList
        } = this.props
        return ( <div className='importFile'>
	<div className='padRight'>
            <
            CSVReader cssClass = "react-csv-input"
            label='Upload CSV file for import'
            onFileLoaded = {
                this.handleForce
            }
            parserOptions = {
                this.papaparseOptions
            }
            />
            </div>
            < div className = 'padLeft' >

            <Button
                variant='contained'
                size='large'
                id = 'clearOrder'
                disabled = {
                    this.props.listProductsLength === 0
                }
                 onClick = {
                     this.props.clearOrder
                 }
                 className='button1'
                style = {
                    {
                        backgroundColor: '#e41e26',
                        color: '#ffffff',
                        textTransform:'capitalize',
                        fontWeight: "bold",
                        padding:'10px'
                    }
                }
                 >
                Clear Form
              </Button>
              </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderoptionList: state.orderReducer.orderoptionList,
});

const mapDispatchToProps = (dispatch) => ({
    getOrderOptionList: (term) => dispatch(getOrderOptionList(term)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withToastManager(Upload));

