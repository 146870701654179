import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import Grid from '@material-ui/core/Grid';
import ProductImage from '../../components/ProductDetailsPage/productImage';
import ProductDetails from '../../components/ProductDetailsPage/productDetails';
import {
  getProductSaga,
  clearProduct,
} from '../../actions/productListingAction';
import {
  addToCartState,
  addToCartSaga,
} from '../../actions/cartItemAction';

import {
  ADD_TO_CART_INITIATED,
  ADD_TO_CART_DONE,
} from '../../constants';
import './style.css';

class ProductBrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseVariantType: null,
      selectedColor: null,
      selectedSize: -1,
      selectedQuantity: 1,
      selectedSKU: null,
      selectedSKUDetails: null,
      showError: false,
      showSizeError: false,
      addToQuote: true,
      modelIsOpen: false,
      selectedRecmndProductId: null,
    };
  }

  componentDidMount() {
    // this.props.getProductSaga();
    // window.scroll(0, 0); // remove beacuse it scroll the bottom data to Top
  }

  componentDidUpdate(prevProps) {
    this.toastManager = prevProps.toastManager;
    if (this.props.errorResponse[0] !== prevProps.errorResponse[0]) {
      const errorMessage = this.props.errorResponse[0];
      this.toastManager.add(errorMessage.message, {
        appearance: 'error',
        autoDismiss: true,
      });
      this.props.addToCartState(null);
    } else {
      // condition to show toast message in case of success
      if (
        this.props.order[0].addToCartState !==
          prevProps.order[0].addToCartState &&
        this.props.order[0].addToCartState == ADD_TO_CART_DONE
      ) {
        this.props.toastManager.add(ADD_TO_CART_DONE, {
          appearance: 'success',
          autoDismiss: true,
        });
      }
    }
  }
  componentWillUnmount() {
    this.props.addToCartState(null);
    this.props.clearProduct();
  }

  colorClickHandler = (e) => {
    e.stopPropagation();
    if (!e) return;
    this.props.addToCartState(null);
    const selectedColor = e.target.attributes.name.value;
    //const selectedColorName = e.target.attributes.name.value;
    const selectedSKU = selectedColor;
    let product = this.props.products;
    if (product !== undefined && product[0].products.length) {
      product = product[0].products;
      const { compositeProducts } = product[0];
      compositeProducts.map((data, i) => {
        if (data.variantProperties.x_color.name === selectedColor) {
          this.setState({
            ...this.state,
            selectedColor,
            selectedSize: -1,
            selectedSKU,
            selectedSKUDetails: data,
            showError: false,
            showSizeError: false,
          });
        }
        return data;
      });
    }
  };

  onPrimaryVariantSelect = (event) => {
    event.stopPropagation();
    if (!event) return;
    const selectedColor = event.target.value;
    this.props.addToCartState(null);
    //const selectedColorName = e.target.attributes.name.value;
    const selectedSKU = selectedColor;
    let product = this.props.products;
    if (product !== undefined && product[0].products.length) {
      product = product[0].products;
      const { compositeProducts } = product[0];
      compositeProducts.map((data, i) => {
        if (
          (
            data.variantProperties['x_Availability'] ||
            data.variantProperties['x_No_of_Boxes']
          ).name === selectedColor
        ) {
          this.setState({
            ...this.state,
            selectedColor,
            selectedSize: -1,
            selectedSKU: selectedSKU,
            selectedSKUDetails: data,
            showError: false,
            showSizeError: false,
          });
        }
        return data;
      });
    }
  };

  sizeChangeHandler = (event) => {
    event.stopPropagation();
    if (!event) return;
    this.props.addToCartState(null);
    const selectedSize = event.target.value;
    const selectedSKU = selectedSize;
    let product = this.props.products;
    if (product !== undefined && product[0].products.length) {
      product = product[0].products;
      const { compositeProducts } = product[0];
      compositeProducts.map((data, i) => {
        if (data.componentSku === selectedSKU) {
          this.setState({
            selectedSize,
            selectedSKU,
            selectedSKUDetails: data,
            showError: false,
            showSizeError: false,
          });
        }
        return data;
      });
    }
  };

  quantityChangeHandler = (e) => {
    e.stopPropagation();
    this.props.addToCartState(null);
    const reg = /^[0-9\b]+$/;
    if (reg.test(e.target.value)) {
      this.setState({
        selectedQuantity: e.target.value,
        showError: false,
        showSizeError: false,
      });
    }
  };

  stepperClickHandler = (e, type) => {
    e.stopPropagation();
    this.props.addToCartState(null);
    const quantity = parseInt(this.state.selectedQuantity);
    if (type === 'increase' && quantity < 1000) {
      this.setState({ selectedQuantity: quantity + 1 });
    } else if (type === 'decrease' && quantity !== 0) {
      this.setState({ selectedQuantity: quantity - 1 });
    }
  };

  addToQuoteClickHandler = (e) => {
    e.stopPropagation();
    this.props.addToCartState(ADD_TO_CART_INITIATED);
    const product = this.props.products;
    let skuDetails = null;
    if (this.state.selectedSKUDetails) {
      skuDetails = this.state.selectedSKUDetails;
    } else if (
      product &&
      product[0].products &&
      product[0].products.length
    ) {
      const { compositeProducts } = product[0].products[0];
      compositeProducts.map((data, i) => {
        if (data.componentSku === product[0].products[0].SKU) {
          skuDetails = data;
        }
      });
    }
    const skuInventory = skuDetails.skuInventoryItem.qtyAvailable;
    if (this.state.selectedQuantity > parseInt(skuInventory)) {
      this.setState({ showError: true, modelIsOpen: false }, () => {
        this.props.addToCartState(null);
      });
      return;
    }
    if (
      this.state.selectedColor == null ||
      this.state.selectedColor ===
        (
          skuDetails.variantProperties.x_color ||
          skuDetails.variantProperties.x_Availability ||
          skuDetails.variantProperties.x_No_of_Boxes
        ).name
    ) {
      this.props.addToCartSaga(
        skuDetails.componentSku,
        this.state.selectedQuantity
      );
    } else {
      this.setState(
        { showSizeError: true, modelIsOpen: false },
        () => {
          this.props.addToCartState(null);
        }
      );
    }
    // this.props.addToCartSaga(
    //   skuDetails.componentSku,
    //   this.state.selectedQuantity
    // );
    // this.setState({ modelIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modelIsOpen: false });
  };

  recmdProductClickHandler = (e, productId) => {
    e.stopPropagation();
    window.location.href = `/product/${productId}`;
  };

  render() {
    let productDescription = '';
    let compositeProducts = [];
    let skuDetails = this.state.selectedSKUDetails;
    let productName = '';
    let variants = null;
    const sizeList = [];
    const variantItems = [];
    let proudctImgURL = null;
    let selectedSize = null;
    let primaryVariantType;
    let secondaryVariantType;
    let selectedColor = null;
    let orderData = null;
    let addToCartState = null;
    const recommendedProducts = [];

    const products =
      this.props.products !== undefined &&
      this.props.products[0].products.length
        ? this.props.products[0].products[0]
        : null;

    orderData =
      this.props.order && this.props.order[0].cart
        ? this.props.order[0].cart[0]
        : null;

    addToCartState = this.props.order
      ? this.props.order[0].addToCartState
      : null;

    if (products !== null) {
      if (products.sfdcName && products.sfdcName !== null)
        productName = products.sfdcName;
      else productName = products.alternateName;

      productDescription = products.longDesc;
      compositeProducts = products.compositeProducts;
      variants = products.variants;
      if (skuDetails === null)
        compositeProducts.map((e, i) => {
          if (e.componentSku === products.SKU) {
            skuDetails = e;
          }
        });

      proudctImgURL = skuDetails.media ? skuDetails.media : null;

      selectedSize = skuDetails.componentSku;
      selectedColor = this.state.selectedColor
        ? this.state.selectedColor
        : skuDetails.variantProperties[variants.componentId] &&
          skuDetails.variantProperties[variants.componentId].name;

      if (
        variants !== null &&
        (variants.componentId === 'x_color' ||
          variants.componentId === 'x_Availability' ||
          variants.componentId === 'x_No_of_Boxes')
      ) {
        primaryVariantType = variants.componentId;
        const itemData = variants.components[selectedColor];
        secondaryVariantType = itemData.componentId;
        const sizes = itemData && itemData.components;
        const sizeKeys = sizes && Object.keys(sizes);

        sizeKeys &&
          sizeKeys.map((e, i) => {
            sizeList.push(sizes[e]);
          });
      } else if (variants !== null) {
        primaryVariantType = variants.componentId;
        const sizes = variants.components;
        const sizeKeys = sizes && Object.keys(sizes);

        sizeKeys &&
          sizeKeys.map((e, i) => {
            sizeList.push(sizes[e]);
          });
      }

      if (
        products.ERelatedProducts &&
        products.ERelatedProducts.length
      ) {
        const recmdProducts = products.ERelatedProducts;

        recmdProducts.map((data, i) => {
          const stubRecomndProducts = {
            id: '',
            name: '',
            title: '',
            imgUrl: '',
            altMsg: '',
            price: '',
          };
          stubRecomndProducts.id = data.productId;
          stubRecomndProducts.name = data.shortDesc;
          stubRecomndProducts.title = data.sfdcName;
          stubRecomndProducts.imgUrl =
            data.compositeProducts[0].EProductMedia.smallURI;
          stubRecomndProducts.altMsg =
            data.compositeProducts[0].EProductMedia.fullAltMessage;
          stubRecomndProducts.price =
            data.compositeProducts[0].priceEntry.listPrice;
          recommendedProducts.push(stubRecomndProducts);
        });
      }
    }

    return (
      <div id='product-breif'>
        {products !== null && skuDetails && (
          <Grid
            container
            className='product-details externalContainer'>
            <ProductImage imgUrl={proudctImgURL} />
            <ProductDetails
              productName={productName}
              selectedSKU={skuDetails.componentSku}
              skuDetails={skuDetails}
              productSpecs={products.productSpecs}
              variants={variants}
              primaryVariantType={primaryVariantType}
              secondaryVariantType={secondaryVariantType}
              colorClick={this.colorClickHandler}
              selectedColor={selectedColor}
              selectedSize={selectedSize}
              sizeList={sizeList}
              sizeChangeHandler={this.sizeChangeHandler}
              onPrimaryVariantSelect={this.onPrimaryVariantSelect}
              selectedQuantity={this.state.selectedQuantity}
              quantityChangeHandler={this.quantityChangeHandler}
              stepperClickHandler={this.stepperClickHandler}
              addToQuoteClickHandler={this.addToQuoteClickHandler}
              showError={this.state.showError}
              showSizeError={this.state.showSizeError}
              addToCartState={addToCartState}
            />
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: [state.productReducer],
  order: [state.orderReducer],
  errorResponse: [state.errorReducer.error_data],
});

const mapDispatchToProps = (dispatch) => ({
  getProductSaga: () => dispatch(getProductSaga()),
  addToCartSaga: (skuId, quantity) =>
    dispatch(addToCartSaga(skuId, quantity)),
  addToCartState: (status) => dispatch(addToCartState(status)),
  clearProduct: () => dispatch(clearProduct()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ProductBrief));
