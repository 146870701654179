import { SET_FORGOT_PASSWORD_EMAIL_SAGA } from '../constants';

const initialState = {
  response: null,
};

export default function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_EMAIL_SAGA:
      return {
        ...state,
        response: action,
      };
    default:
      return state;
  }
}
