import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import DeleteIcon from '@material-ui/icons/Delete';
import QuickOrderQuantityStepper from '../Shared/quickOrderQuantityStepper';
import './style.css';

const card = ({
  quantityChangeHandler,
  stepperClickHandler,
  handleRemoveItem,
  product,
  handleChange,
  index,
}) => {
  const compositeProducts = product.compositeProducts[0];
  return (
    <div id={product.SKU} className='rectangle'>
      <Grid container spacing={3} className='cards'>
        {compositeProducts.EProductMedia.smallURI ? (
          <Grid item xs={3} sm={2} lg={2}>
            <img
              className='image'
              src={compositeProducts.EProductMedia.smallURI}></img>
          </Grid>
        ) : null}
        <Grid item xs={9} sm={6} lg={6}>
          <div className='displayProp'>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <div>
                <div className='nameSize'>
                  {product.sfdcName
                    ? product.sfdcName
                    : product.alternateName}
                </div>
                <div className='items'>SKU: {product.SKU}</div>
                <div className='items'>
                  Items remaining:{' '}
                  {product.inventory > 0 ? product.inventory : 0}
                </div>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div>
                <Hidden only={['xs']}>
                  <div className='priceAlign'>Price</div>
                </Hidden>

                <div className='price'>
                  ${compositeProducts.priceEntry.listPrice}
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <Grid item xs={2}>
            {' '}
          </Grid>
        </Hidden>

        <Grid item xs={8} sm={3} lg={3} className='quickOrderStepper'>
          <Hidden only={['xs']}>
            <div>Quantity</div>
          </Hidden>
          <div>
            {product.inventory > 0 ? (
              <QuickOrderQuantityStepper
                selectedQuantity={product.quantity}
                stepperClickHandler={stepperClickHandler}
                index={index}
                product={product}
                inventory={product.inventory}
                handleChange={handleChange}
              />
            ) : (
              <div className='noStock'>Out Of Stock</div>
            )}
          </div>
          {
            product.message !== null ? < div className='alertMsg'> {product.message} </div> : null
          }
        </Grid>
        <Grid item xs={2} sm={1} id='deleteDiv'>
          <div className='dustBin'>
            <DeleteIcon onClick={(e) => handleRemoveItem(index)} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default card;
