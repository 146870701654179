import React, { useState, useEffect } from 'react';

export function BepsyImage({ src }) {
  const [backgroundSize, setBackgroundSize] = useState('100% auto');

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      console.log(img.width);

      if (img.width > img.height) {
        setBackgroundSize('100% auto');
      } else {
        setBackgroundSize('auto 100%');
      }
    };
  }, [src]);

  return (
    <div
      style={{
        backgroundImage: `url("${src}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: backgroundSize,
        width: '100%',
        height: '100%',
      }}
    />
  );
}
