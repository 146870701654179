import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_ORDERS_ON_PAGINATION_SAGA } from '../../constants';
import { setOrdersOnPagination } from '../../actions/orderAction';
import { getOrdersOnPagination } from '../../api/orderApi';

function* workerGetOrdersOnPaginationSaga({
  payload: {
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    setStartdate,
    setEndDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData,
  },
}) {
  const ordersOnPagination = yield call(
    getOrdersOnPagination,
    searchInput,
    currentPage,
    recordPerPage,
    ordersUpto,
    setStartdate,
    setEndDate,
    orderRange,
    sortProp,
    sortOrder,
    statusData,
    orgData
  );
  yield put(setOrdersOnPagination(null));

  yield put(setOrdersOnPagination(ordersOnPagination && ordersOnPagination.data));
}

export default function* watchGetOrdersOnPaginationSaga() {
  yield takeLatest(
    GET_ORDERS_ON_PAGINATION_SAGA,
    workerGetOrdersOnPaginationSaga
  );
}
