import { SET_ORDER_HISTORY } from '../constants';

const initialState = {
  orderHistory: null,
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload.orderHistory,
      };
    default:
      return state;
  }
}
