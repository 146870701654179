import { SET_UPDATE_PASSWORD } from '../constants';

const initialState = {
  updatedPassword: [],
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_UPDATE_PASSWORD:
      return {
        ...state,
        updatedPassword: action.payload,
      };
    default:
      return state;
  }
}
