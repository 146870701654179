import { SET_QUOTES_ON_PAGINATION, SET_QUOTE_STATES } from '../constants';

const initialState = {
  orders_list: null,
  quoteStates: [],
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_QUOTE_STATES:
      return {
        ...state,
        quoteStates: action.payload,
      };
    case SET_QUOTES_ON_PAGINATION:
      return {
        ...state,
        orders_list: action.payload,
      };
    default:
      return state;
  }
}
