import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';

export default function CompanyDetails(props) {
  if (props.display) {
    return (
      <div className='company-card'>
        <div id='company-name'>Company Name:</div>

        <div className='company'>
          <p>{props.organizationDetails.companyName}</p>
          {props.Address ? (
            <div>
              {' '}
              <p>{props.Address.address2}</p>
              <p>{props.Address.city}</p>
              <p>{props.Address.companyName}</p>
              <p>{props.Address.country}</p>
              <p>{props.Address.postalCode}</p>
            </div>
          ) : null}
          <p>
            <a>{props.organizationDetails.organizationEmail}</a>
          </p>
          <p>{props.organizationDetails.organizationPhone}</p>
        </div>
      </div>
    );
  }
}
