import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_ADDRESS, SET_ERROR_STATE } from '../../constants';
import { setAddressData } from '../../actions/addressAction';
import { getAddress } from '../../api/addressApi';

function* workerGetAddressDataSaga() {
  try {
    const address_data = yield call(getAddress);
    yield put(setAddressData(address_data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetAddressDataSaga() {
  yield takeLatest(GET_ADDRESS, workerGetAddressDataSaga);
}
