import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import './Styles.css';
import { Link } from 'react-router-dom';

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Hidden,
} from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import BrushOutlined from '@material-ui/icons/BrushOutlined';

import Shipping from '@material-ui/icons/LocalShippingOutlined';
import Billing from '@material-ui/icons/AccountBalanceWallet';
import ContentLoader from 'react-content-loader';
import CustomLoader from '../../components/Shared/customLoader';
import Header from '../Header';

const OrderDetails = (props) => {
  if (props) {
    const shipment = props.order.EOrderShipments[0];
    const { shippingAddress } = shipment;

    const billing = props.order.EPaymentDetails[0];
    //change specific to CT//
    const { billingAddress } = billing;
    //const billingAddress = billing;

    return (
      <div className='order-details-wrapper'>
        <div className='order-inner-wrapper'>
          <div className='order-top-content'>
            <div className='order-container'>
              <div className='order-data'>
                <h4>Order Items</h4>
                <Divider />
                <Hidden only={['xl', 'lg', 'md', 'sm']}>
                  {props.order &&
                    props.order.EOrderItems.map((item) => (
                      <span key={item.productName}>
                        <Grid container direction='row'>
                          <Grid item xs={12}>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Part #
                              </Grid>
                              <Grid item xs={6}>
                                {item.sku}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Product
                              </Grid>
                              <Grid item xs={6}>
                                {item.productName}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Price per Unit
                              </Grid>
                              <Grid
                                item
                                xs={6}>{`$${item.itemAmount.toFixed(
                                2
                              )}`}</Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Quantity
                              </Grid>
                              <Grid item xs={6}>
                                {item.quantity}
                              </Grid>
                            </Grid>
                            <Grid container direction='row'>
                              <Grid item xs={6} className='headers'>
                                Total Price
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className='priceConfirmation'>
                                $ {item.finalItemAmount.toFixed(2)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                      </span>
                    ))}
                </Hidden>
                <Hidden only={['xs']}>
                  <Table>
                    <TableHead className='table-head'>
                      <TableRow>
                        <TableCell>Part #</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Price per unit</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className='table-body'>
                      {props.order &&
                        props.order.EOrderItems.map((item) => (
                          <TableRow key={item.productName}>
                            <TableCell>{item.sku}</TableCell>
                            <TableCell>{item.productName}</TableCell>
                            <TableCell>{`$${item.listPrice.toFixed(
                              2
                            )}`}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell className='priceConfirmation'>
                              $ {item.finalItemAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Hidden>

                <div className='order-footer-data'>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      md={8}
                      sm={6}
                      className='billing-wrapper'>
                      <div className='billing-title headers'>
                        Billing and Shipping Information
                      </div>
                      <Divider />
                      <Grid
                        container
                        className='billing-shipping-container'>
                        <Grid item md={12}>
                          <Grid container direction='row' spacing={2}>
                            <Grid item xs={12} md={4} sm={12}>
                              <Card>
                                <CardHeader
                                  avatar={
                                    <IconButton>
                                      <Shipping />
                                    </IconButton>
                                  }
                                  title='Shipping Address'
                                />
                                <CardContent>
                                  <div>
                                    {shippingAddress.address1}
                                  </div>
                                  <div>{shippingAddress.city}</div>
                                  <div>
                                    {shippingAddress.postalCode}
                                  </div>
                                  <div>{shippingAddress.country}</div>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={4} sm={12}>
                              <Card>
                                <CardHeader
                                  avatar={
                                    <IconButton>
                                      <Billing />
                                    </IconButton>
                                  }
                                  title='Billing Address'
                                />
                                <CardContent>
                                  <div>{billingAddress.address1}</div>
                                  <div>{billingAddress.city}</div>
                                  <div>
                                    {billingAddress.postalCode}
                                  </div>
                                  <div>{billingAddress.country}</div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xs={12}
                      sm={6}
                      className='order-summary-wrapper'>
                      <div className='summary-title headers'>
                        Payment Details
                      </div>
                      <Divider />

                      <Table className='order-summary'>
                        <TableBody className='order-summary-content'>
                          <TableRow className='order-summary'>
                            <TableCell className='headers'>
                              Payment Method
                            </TableCell>
                            <TableCell>
                              {' '}
                              {billing.paymentMethod}{' '}
                            </TableCell>
                          </TableRow>
                          {props.order.creditTerms !== null &&
                          props.order.creditTerms !== '' ? (
                            <TableRow className='order-summary'>
                              <TableCell className='headers'>
                                Invoice Credit Terms
                              </TableCell>
                              <TableCell>
                                {' '}
                                {props.order.creditTerms} days{' '}
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                      <br />

                      <div className='summary-title headers'>
                        Order Summary
                      </div>
                      <Divider />

                      <Table className='order-summary'>
                        <TableBody className='order-summary-content'>
                          <TableRow>
                            <TableCell className='headers'>
                              SubTotal:
                            </TableCell>
                            <TableCell>
                              ${' '}
                              {props.order.subTotalAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='headers'>
                              Tax:
                            </TableCell>
                            <TableCell>
                              $
                              {props.order.finalTaxAmount !== null
                                ? props.order.finalTaxAmount.toFixed(
                                    2
                                  )
                                : '0.00'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className='headers'>
                              Discounts:
                            </TableCell>
                            <TableCell>
                              <span className='color-red'>
                                ${' '}
                                {props.order.subTotalDiscountAmount.toFixed(
                                  2
                                )}
                              </span>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className='headers'>
                              Order Total
                            </TableCell>
                            <TableCell className='price'>
                              ${' '}
                              {props.order.finalSubTotalAmount.toFixed(
                                2
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid item md={6} xs={12} sm={6}>
                      <CardHeader title='Documentation' />
                      <Divider />
                      <div className='footer-icons'>
                        <a>
                          <PrintOutlinedIcon />
                          <p>Print Quote</p>
                        </a>
                        <a>
                          <DescriptionOutlined />
                          <p> Signed Quote </p>
                        </a>
                        <a>
                          <BrushOutlined />
                          <p> Customer Authorization </p>
                        </a>
                      </div>
                    </Grid>
                  </Grid>
                  <div className='bottom-links'>
                    <Link to='/'> Continue Shopping </Link>
                    <Link to='/orders'> Order List </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

class Confirmation extends Component {
  state = {
    loader: true,
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ loader: false });
    }, 300);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    let retrievedObject = sessionStorage.getItem('orderDetails');
    retrievedObject = JSON.parse(retrievedObject);

    let date = null;
    let time = null;
    if (retrievedObject) {
      const orderDateTime = retrievedObject.orderSubmitDate;
      date = orderDateTime.slice(0, 10);
      time = orderDateTime.slice(12, 19);
    } else {
      return (
        <Grid className='successMsg'>
          <h3>Page Not Found.</h3>
          <a href='/'>
            <h4>Return Home</h4>
          </a>
        </Grid>
      );
    }

    return (
      <div>
        {this.state.loader ? (
          <>
            <div className='loaderMargin'>
              <ContentLoader
                height={80}
                width={400}
                speed={2}
                primaryColor='#f3f3f3'
                secondaryColor='#ecebeb'></ContentLoader>
              {/* <CustomLoader /> */}
            </div>
            <div className='loaderMargin'>
              <ContentLoader
                height={200}
                width={400}
                speed={2}
                primaryColor='#f3f3f3'
                secondaryColor='#ecebeb'></ContentLoader>

              {/* <CustomLoader /> */}
            </div>
          </>
        ) : (
          ''
        )}

        <Header showToggle='false' />
        <div className='externalContainer confirmationPageLayout successMsg'>
          <h3>
            <i
              className='fa fa-hand-peace-o fa-3x'
              aria-hidden='true'
            />
            &nbsp;&nbsp;Order Placed successfully
          </h3>
          <h4>
            Thank you for shopping with Bepsy. Your Order ID number
            is&nbsp;
            {retrievedObject.orderNumber}
            &nbsp;placed on {date} at {time}
          </h4>
          <Link to='/'>
            <Button>Continue Shopping</Button>
          </Link>
        </div>
        <div className='externalContainer confirmationPageLayout'>
          <OrderDetails order={retrievedObject}> </OrderDetails>
        </div>
      </div>
    );
  }
}

export default Confirmation;
