import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Hidden,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Moment from 'react-moment';

import OrderDetails from '../Details/detail';

import CustomSearchField from './customSearchTextField';
import Pagination from './customPagination';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import ContentLoader from 'react-content-loader';

import './style.css';

function formatFilterValue(v) {
  v = v.replace(/[a-z][A-Z]/, (txt) => txt.charAt(0) + ' ' + txt.charAt(1)).toLowerCase();

  return v.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export default function OrderTable({
  data,
  expanded,
  handleSearch,
  handleChange,
  filteredOrders,
  viewOrder,
  order_detail,
  quoteDetails,
  rejectQuote,
  orderNumber,
  currentPage,
  recordPerPage,
  totalRecords,
  handlePageClick,
  handleSelect,
  handleSubmit,
  handleEnterSearch,
  searchError,
  searchText,
  clearOrderSearchResult,
  sortProp,
  sortOrder,
  tableCell,
  handleSelectStatus,
  statusData,
  handleSelectOrg,
  orgData,
  orgList,
  pageTitle,
  filterValues,
  sortValues,
  ...props
}) {
  // let orders = data;
  let orderHistory = data !== undefined ? data : null;

  let orders = data !== undefined ? data : null;

  if (
    orders === null ||
    orders === undefined ||
    orders.orders_list === null
  ) {
    return (
      <ContentLoader
        height={200}
        width={400}
        speed={2}
        primaryColor='#f3f3f3'
        secondaryColor='#ecebeb'></ContentLoader>
    );
  }
  return (
    <div className='orders-wrapper'>
      <div className='orders-inner-wrapper'>
        <div className='orders-container'>
          <Hidden only={['lg', 'xl']}>
            <div className='page-title'>{pageTitle}</div>

            <div className='ordersTopRow'>
              <Grid container direction='row' justify='space-between'>
                <Grid
                  container
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  direction='row'
                  className='selectOrder'>
                  <label className='labelCss'>Organization : </label>

                  <select
                    name='selectSortProp'
                    onChange={handleSelectOrg}
                    value={orgData}
                    className='selectDrop'>
                    {orgList &&
                      orgList.map((d, i) => (
                        <option value={d.id} key={i}>
                          {d.name}
                        </option>
                      ))}
                  </select>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  direction='row'
                  className='selectOrder'>
                  <label className='labelCss'>Filter By : </label>
                  <select
                    name='selectStatusData'
                    onChange={handleSelectStatus}
                    value={`Status-${statusData}`}
                    className='selectDrop'>
                    {filterValues &&
                      filterValues.map((fv, i) => (
                        <option
                          key={i}
                          value={`Status-${fv}`}>{`Status-${formatFilterValue(fv)}`}</option>
                      ))}
                  </select>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  direction='row'
                  className='selectOrder'>
                  <label className='labelCss'>Sort By : </label>
                  <select
                    name='selectSortProp'
                    onChange={handleSelect}
                    value={`${sortProp}-${sortOrder}`}
                    className='selectDrop'>
                    {sortValues &&
                      sortValues.map((sv, i) => (
                        <option key={i} value={sv.value}>
                          {sv.key}
                        </option>
                      ))}
                  </select>
                </Grid>

                <Grid
                  item
                  className='orderSearch'
                  sm={6}
                  xs={12}
                  md={6}>
                  <Grid item xs={12} sm={12} md={12}>
                    <CustomSearchField
                      placeholder={'Search by order id / sku id'}
                      searchTextChangeHandler={
                        handleSearch
                          ? handleSearch
                          : this.handleSearch
                      }
                      searchEnterClickHandler={
                        handleEnterSearch
                          ? handleEnterSearch
                          : this.handleEnterSearch
                      }
                      searchIconClickHandler={
                        handleSubmit
                          ? handleSubmit
                          : this.handleSubmit
                      }
                      searchText={searchText}
                    />
                    {searchError ? (
                      <FormHelperText
                        id='component-error-text'
                        style={{ color: '#FFA500' }}>
                        Please Enter atleast 3 letters
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    style={{
                      display: searchText ? 'flex' : 'none',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      marginLeft: '5px',
                    }}>
                    <Button
                      onClick={clearOrderSearchResult}
                      style={{
                        padding: '0px',
                        textTransform: 'capitalize',
                      }}>
                      {' '}
                      <a> Clear</a>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Hidden>
          {/* Hidden on mobile  */}
          <Hidden only={['xs', 'sm', 'md']}>
            <div className='ordersTopRow'>
              <Grid container direction='row' justify='space-between'>
                <Grid item className='orderSearch' lg={12} xl={12}>
                  <Grid
                    className='page-title orderSearch'
                    item
                    md={1}
                    lg={1}
                    xl={1}>
                    {pageTitle}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={5}
                    md={5}
                    direction='row'
                    className='selectOrder'>
                    <label className='labelCss'>
                      Organization :{' '}
                    </label>
                    <select
                      name='selectSortProp'
                      onChange={handleSelectOrg}
                      value={orgData}
                      className='selectDrop'>
                      {orgList &&
                        orgList.map((d, i) => (
                          <option value={d.id} key={i}>
                            {d.name}
                          </option>
                        ))}
                    </select>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    direction='row'
                    className='selectOrder'>
                    <label className='labelCss'>Filter By : </label>
                    <select
                      name='selectStatusData'
                      onChange={handleSelectStatus}
                      value={`Status-${statusData}`}
                      className='selectDrop'>
                      {filterValues &&
                        filterValues.map((fv, i) => (
                          <option
                            key={i}
                            value={`Status-${fv}`}>{`Status-${formatFilterValue(fv)}`}</option>
                        ))}
                    </select>
                  </Grid>
                  <Grid
                    container
                    item
                    className='selectOrder'
                    lg={6}
                    xl={6}
                    justify='flex-end'>
                    <label className='labelCss'>Sort By : </label>
                    <select
                      name='selectSortProp'
                      onChange={handleSelect}
                      value={`${sortProp}-${sortOrder}`}
                      className='selectDrop'>
                      {sortValues &&
                        sortValues.map((sv, i) => (
                          <option key={i} value={sv.value}>
                            {sv.key}
                          </option>
                        ))}
                    </select>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    xl={5}
                    justify='flex-end'>
                    <CustomSearchField
                      placeholder={'Search by order id / sku id'}
                      searchTextChangeHandler={
                        handleSearch
                          ? handleSearch
                          : this.handleSearch
                      }
                      searchEnterClickHandler={
                        handleEnterSearch
                          ? handleEnterSearch
                          : this.handleEnterSearch
                      }
                      searchIconClickHandler={
                        handleSubmit
                          ? handleSubmit
                          : this.handleSubmit
                      }
                      searchText={searchText}
                    />
                    {searchError ? (
                      <FormHelperText
                        id='component-error-text'
                        style={{ color: '#FFA500' }}>
                        Please Enter atleast 3 letters
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{
                      display: searchText ? 'flex' : 'none',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      marginLeft: '5px',
                    }}>
                    <Button
                      onClick={clearOrderSearchResult}
                      style={{
                        padding: '0px',
                        textTransform: 'capitalize',
                      }}>
                      {' '}
                      <a> Clear</a>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Hidden>
        </div>

        <div className='orders-top-content'>
          <div className='orders-container'>
            <div className='orders-data externalContainer'>
              {orders && orders.length > 0 ? (
                <Grid>
                  <Hidden only={['xl', 'lg', 'sm', 'md']}>
                    {orders &&
                      orders.map((order, index) => (
                        <ExpansionPanel
                          expanded={expanded === `orderPanel${index}`}
                          onChange={handleChange(
                            `orderPanel${index}`
                          )}
                          key={index}>
                          <Grid
                            container
                            direction='row'
                            className='mobile-grid'>
                            <Grid
                              item
                              xs={6}
                              className='text-right listHeaders'>
                              {/* <div>Order Number</div>
                                <div>Date</div>
                                <div>Amount</div>
                                <div>Status</div> */}
                              {tableCell &&
                                tableCell.map((lable, i) => (
                                  <Grid item xs={12} key={i}>
                                    {lable}
                                  </Grid>
                                ))}
                            </Grid>
                            <ExpansionPanelSummary>
                              <Grid
                                item
                                xs={12}
                                onClick={viewOrder.bind(this, order)}>
                                {tableCell.includes('Id') && (
                                  <Grid item xs={12}>
                                    {order.orderNumber}
                                  </Grid>
                                )}

                                {tableCell.includes('Creator') && (
                                  <Grid item xs={12}>
                                    {order.quoteInfo.creatorName}
                                  </Grid>
                                )}

                                {tableCell.includes('Nickname') && (
                                  <Grid item xs={12}>
                                    {order.quoteInfo.nickName}
                                  </Grid>
                                )}

                                {tableCell.includes('Expires In') && (
                                  <Grid item xs={12}>
                                    {`${order.quoteInfo.expireIn_Days} Days`}
                                  </Grid>
                                )}

                                {tableCell.includes('Date') && (
                                  <Grid item xs={12}>
                                    <Moment format='MM/DD/YYYY'>
                                      {order.orderSubmitDate}
                                    </Moment>
                                  </Grid>
                                )}

                                {tableCell.includes('Amount') && (
                                  <Grid
                                    item
                                    xs={12}
                                    className='priceOrder'>{`$ ${order.finalOrderAmount}`}</Grid>
                                )}

                                {tableCell.includes('Status') &&
                                  order.orderStatus && (
                                    <Grid item xs={12}>
                                      {order.orderStatusDisplay}
                                    </Grid>
                                  )}

                                <Grid item xs={12}>
                                  <Button
                                    variant='contained'
                                    className='view-details-btn'
                                   >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                              {/* <Grid
                                item
                                xs={5}
                                className='view-details-btn-mob'>
                                <Button
                                  variant='contained'
                                  className='view-details-btn'
                                  onClick={viewOrder.bind(
                                    this,
                                    order
                                  )}>
                                  View Details
                                </Button>
                              </Grid> */}
                            </ExpansionPanelSummary>
                          </Grid>

                          {order_detail === null ? (
                            <div className='alignCenter'>
                              <ContentLoader />{' '}
                            </div>
                          ) : (
                            <ExpansionPanelDetails className='order-details-panel'>
                              <OrderDetails
                                orgData={orgData}
                                order={order_detail}
                                quoteDetails={quoteDetails}
                                rejectQuote={rejectQuote}
                                expanded={expanded}
                                orderNumber={orderNumber}
                                {...props}
                              />{' '}
                            </ExpansionPanelDetails>
                          )}
                        </ExpansionPanel>
                      ))}
                  </Hidden>
                  <Hidden only={'xs'}>
                    <Table>
                      <TableHead className='table-head'>
                        <TableRow>
                          {tableCell &&
                            tableCell.map((lable, i) => (
                              <TableCell
                                className='cell-width customWidth'
                                key={i}>
                                {lable}
                              </TableCell>
                            ))}
                          {/* <TableCell className='cell-width'></TableCell> */}
                          {/* <TableCell className="cell-width"></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders &&
                          orders.map((order, index) => (
                            <TableRow
                              className='order-rows'
                              key={index}>
                              <TableCell colSpan={8}>
                                <ExpansionPanel
                                  expanded={
                                    expanded === `orderPanel${index}`
                                  }
                                  onChange={handleChange(
                                    `orderPanel${index}`
                                  )}
                                  key={index}>
                                  <ExpansionPanelSummary className='zero-padding'>
                                    <Table
                                      onClick={viewOrder.bind(
                                        this,
                                        order
                                      )}>
                                      <TableBody>
                                        <TableRow>
                                          {tableCell.includes(
                                            'Id'
                                          ) && (
                                            <TableCell
                                              scope='col'
                                              className='cell-width customWidth'>
                                              {order.orderNumber}
                                            </TableCell>
                                          )}

                                          {tableCell.includes(
                                            'Creator'
                                          ) && (
                                            <TableCell
                                              scope='col'
                                              className='cell-width customWidth'>
                                              {
                                                order.quoteInfo
                                                  .creatorName
                                              }
                                            </TableCell>
                                          )}
                                          {tableCell.includes(
                                            'Nickname'
                                          ) && (
                                            <TableCell
                                              scope='col'
                                              className='cell-width customWidth'>
                                              {
                                                order.quoteInfo
                                                  .nickName
                                              }
                                            </TableCell>
                                          )}

                                          {tableCell.includes(
                                            'Expires In'
                                          ) && (
                                            <TableCell className='cell-width customWidth'>
                                              {`${order.quoteInfo.expireIn_Days} Days`}
                                            </TableCell>
                                          )}

                                          {tableCell.includes(
                                            'Date'
                                          ) && (
                                            <TableCell className='cell-width customWidth'>
                                              <Moment format='MM/DD/YYYY'>
                                                {
                                                  order.orderSubmitDate
                                                }
                                              </Moment>
                                            </TableCell>
                                          )}
                                          {tableCell.includes(
                                            'Amount'
                                          ) && (
                                            <TableCell className='cell-width customWidth'>{`$ ${order.finalOrderAmount}`}</TableCell>
                                          )}
                                          {tableCell.includes(
                                            'Status'
                                          ) &&
                                            order.orderStatus && (
                                              <TableCell className='cell-width customWidth'>
                                                {
                                                  order.orderStatusDisplay
                                                }
                                              </TableCell>
                                            )}
                                          <TableCell className='cell-width customWidth'>
                                            <Button
                                              variant='contained'
                                              className='view-details-btn'
                                              >
                                              View Details
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </ExpansionPanelSummary>
                                  {order_detail === null ? (
                                    <div className='alignCenter'>
                                      <ContentLoader />{' '}
                                    </div>
                                  ) : (
                                    <ExpansionPanelDetails className='order-details-panel'>
                                      <OrderDetails
                                        orgData={orgData}
                                        order={order_detail}
                                        quoteDetails={quoteDetails}
                                        rejectQuote={rejectQuote}
                                        expanded={expanded}
                                        orderNumber={orderNumber}
                                        {...props}
                                      />
                                    </ExpansionPanelDetails>
                                  )}
                                </ExpansionPanel>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Hidden>
                  <div className='pagination-row'>
                    <Pagination
                      currentPage={currentPage}
                      recordPerPage={recordPerPage}
                      totalRecords={totalRecords}
                      handlePageClick={handlePageClick}
                      // currentPage='1'
                      // recordPerPage='10'
                      // totalRecords='100'
                      // handlePageClick={handlePageClick}
                    />
                  </div>
                </Grid>
              ) : (
                <div className='no-order-text'>
                  <h4>No data found for your search</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
