import {
  SET_REGISTRATION,
  GET_REGISTRATION_SAGA,
} from '../constants';

// Added for Registration
export function setRegistration(registration) {
  return {
    type: SET_REGISTRATION,
    payload: {
      registration,
    },
  };
}

//Sagas
export const getRegistrationSaga = (props) => {
  const firstName = props.firstName;
  const lastName = props.lastName;
  const phoneNumber = props.phoneNumber;
  const email = props.email;
  const address1 = props.address1;
  const address2 = props.address2;
  const city = props.city;
  const state = props.state;
  const country = props.country;
  const postalCode = props.postalCode;
  const companyName = props.companyName;
  const comments = props.comments;

  return {
    type: GET_REGISTRATION_SAGA,
    payload: {
      firstName,
      lastName,
      phoneNumber,
      email,
      address1,
      address2,
      city,
      state,
      country,
      postalCode,
      companyName,
      comments,
    },
  };
};
