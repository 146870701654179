import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_ORDER_STATES, SET_ERROR_STATE } from '../../constants';
import { getOrderStates } from '../../api/orderApi';
import { getQuoteStates } from '../../api/quoteApi';
import { setOrderStates } from '../../actions/orderAction';
import { setQuoteStates } from '../../actions/quoteAction';

function* workerGetOrderStatesSaga() {
  try {
    const orderStates = yield call(getOrderStates);
    yield put(setOrderStates(orderStates));

    const quoteStates = yield call(getQuoteStates);
    yield put(setQuoteStates(quoteStates));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchOrderStatesSaga() {
  yield takeLatest(GET_ORDER_STATES, workerGetOrderStatesSaga);
}
