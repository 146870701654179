import { put, takeLatest, call } from 'redux-saga/effects';

import axios from 'axios';
import { push } from 'react-router-redux';
import { GET_PROFILE_SAGA, SET_ERROR_STATE } from '../../constants';
import { setProfile } from '../../actions/profileAction';
import { getProfile } from '../../api/profileApi';
//

import { history } from '../../store';
import {
  BASE_URL,
  APPLICATION_NAME,
  VERSION,
  APPLICATION_NAME_BE,
} from '../../configurations/config.js';

// IN watcher

/*
 * const getAccessTokenApi =
 *   BASE_URL + '/oe_commerce_api/' + APPLICATION_NAME_BE + '/' + VERSION + '/oauth/login';
 */

/*
 * const getProfileApi =
 *   BASE_URL + '/oe_commerce_api/' + APPLICATION_NAME_BE + '/' + VERSION + '/profile';
 */

function* workerGetProfileSaga() {
  try {
    const profile = yield call(getProfile);
    yield put(setProfile(profile.data));
  } catch (err) {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      yield put({ type: SET_ERROR_STATE, errorData });
    }
  }
}

export default function* watchGetProfileSaga() {
  yield takeLatest(GET_PROFILE_SAGA, workerGetProfileSaga);
}
