import React from 'react';
import Box from '@material-ui/core/Box';
import FilterType from './filterType';
import HierarchialFilter from './heirarchicalFilter';
import './style.css';

const searchFacet = ({
  facetData,
  adjustments,
  filterOptionClickHandler,
  categoryClickHandler,
  clearAllFilter,
}) => {
  return (
    <div>
      <div className='listing-facet'>
        <div className='listing-header'>
          <Box display='flex' p={0}>
            <Box p={1} flexGrow={1}>
              <span className='filter'>FILTER</span>
            </Box>
            <Box p={1}>
              <div className='clear-all' onClick={clearAllFilter}>
                Clear all
              </div>
            </Box>
          </Box>
        </div>
      </div>
      <div className='divider' />

      {facetData.map(
        (facet, i) =>
          facet.type === 'heirarchical' && (
            <div key={`${facet.label}-${i}-facet`}>
              <div
                className='filter-type-container'
                key={`${facet.label}-${i}-facet`}>
                <p className='filter-type-label'>{facet.label}:</p>
                <div className='heirarchical-option'>
                  <HierarchialFilter
                    data={facet}
                    categoryClickHandler={categoryClickHandler}
                  />
                </div>
              </div>
              <div className='divider' />
            </div>
          )
      )}

      <FilterType
        facet={facetData}
        adjustments={adjustments}
        filterOptionClickHandler={filterOptionClickHandler}
      />
    </div>
  );
};

export default searchFacet;
