import {
  SET_ORGANIZATION_DETAILS,
  SET_ADDRESS,
  ERROR_ADDRESS,
  RESET_ORGANIZATION_DETAILS,
  SET_CONTACT_LIST,
  CLEAR_CONTACT_LIST,
  SET_ORGANIZATION_ROLES,
  SEARCH_CONTACT_DATA,
  //GET_ORGANIZATION_LIST,
  SET_ORGANIZATION_LIST,
  //GET_CURRENT_ORGANIZATION,
  SET_CURRENT_ORGANIZATION,
  SET_SUB_ORGANIZATION
} from '../constants';

const initialState = {
  organization_details: null,
  address_list: null,
  address_error: null,
  contact_list: null,
  organization_roles: null,
  pagination_data: null,
  orgData: null
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATION_DETAILS:
      return {
        ...state,
        organization_details: action.organization_details,
      };

    case SET_ORGANIZATION_LIST:
      return {
        ...state,
        organization_details: action.organization_details,
      };

    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        organization_details: action.organization_details,
      };

    case SET_ADDRESS:
      return {
        ...state,
        address_list: action.data,
      };

    case ERROR_ADDRESS:
      return {
        ...state,
        address_error: action.data,
      };
    case SET_CONTACT_LIST:
      return {
        ...state,
        contact_list: action.data,
      };

    case CLEAR_CONTACT_LIST:
      return {
        ...state,
        contact_list: [],
      };

    case SEARCH_CONTACT_DATA:
      return {
        ...state,
        pagination_data: action.data,
      };
    case SET_ORGANIZATION_ROLES:
      return {
        ...state,
        organization_roles: action.data,
      };

    case RESET_ORGANIZATION_DETAILS:
      return {
        ...initialState,
      };
    case SET_SUB_ORGANIZATION:
      return {
        ...state,
        orgData: action.data,
      }
    default:
      return state;
  }
}
