import { SET_PASSWORD } from '../constants';

const initialState = {
  password: [],
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    default:
      return state;
  }
}
