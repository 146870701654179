import React, { useRef } from 'react';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Hidden,
  Grid,
  Divider,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@material-ui/core';
import Shipping from '@material-ui/icons/LocalShippingOutlined';
import Billing from '@material-ui/icons/AccountBalanceWallet';
import PrintIcon from '@material-ui/icons/Print';
import Print from '../../components/Print';
import Logo from '../../assets/Logo/logo.png';
import ReactToPrint from 'react-to-print';
import './Styles.css';
import { Link } from 'react-router-dom';
import Loader from '../../components/Shared/circularProgressBar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
    background: '#273142',
  },
}));
// const printInvoice = () => {
//   const divToPrint = document.getElementById('DivIdToPrint');
//   let newWin = window.open('', 'Print-Window');
//   newWin.document.open();
//   newWin.document.write(
//     '<html><body onload="window.print()">' +
//       divToPrint.innerHTML +
//       '</body></html>'
//   );
//   newWin.document.close();
//   setTimeout(function() {
//     newWin.close();
//   }, 10);
// };
const isInvoiceOpen = (invoiceStatus) => {
  let status = invoiceStatus === 'Open' ? true : false;
  return status;
};
export default function SimpleTable(data) {
  const classes = useStyles();
  let dataToPrint;
  const componentRef = useRef();
  console.log('===>Details data===>', data);
  let m = data;
  // let may = m.data;
  console.log('===>may===>', m.data);
  let may = data !== undefined ? m.data : null;

  let prods = [];
  if (m.data) {
    m.data.orderItems.map((item, index) => {
      prods.push({
        productName: item.displayName,
        SKU: item.SKU,
        listPrice: item.itemAmount.toFixed(2),
        quantity: item.quantity,
        finalPrice: item.finalItemAmount.toFixed(2),
      });
    });
    dataToPrint = {
      products: prods,
      cartSummary: {
        subTotal: m.data.originalAmount.toFixed(2),
        tax: m.data.orderTaxes[0].taxAmount.toFixed(2),
        shippingCharges: 0.0,
        discount: 0.0,
        finalTotal: m.data.originalAmount.toFixed(2),
        freightCost: 1.0,
        tradeDiscount: 1.0,
        miscellaneous: 1.0
      },
      address: {
        billingAddress: {
          billTo: m.data.soldTo.organizationName,
          address1: m.data.billingAddress.addressFirstline,
          city: m.data.billingAddress.city,
          postalCode: m.data.billingAddress.postalCode,
          country: m.data.billingAddress.country,
        },
        shippingAddress: {
          billTo: m.data.soldTo.organizationName,
          address1: m.data.billingAddress.addressFirstline,
          city: m.data.billingAddress.city,
          postalCode: m.data.billingAddress.postalCode,
          country: m.data.billingAddress.country,
        },
      },
      invoiceDetails: {
        invoice: m.data.invoice,
        creditTerms: m.data.creditTerms,
        order: m.data.order,
        dateIssued: m.data.dateIssued,
        dateDue: m.data.dateDue,
        customerId: m.data.soldTo.customerId,
        salesPersonId: m.data.salesPersonId,
        shippingMethod: m.data.orderShipments[0].shippingMethod
      },
    };
  }

  if (m.data === null || m.data === undefined) {
    return <Loader></Loader>;
  }
  return (
    <div
      id='DivIdToPrint'
      className='externalContainer checkoutContainer'>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <br />
          <span>
            <img src={Logo} alt='logo' />
          </span>
        </Grid>
        < Hidden smDown >
        <Grid item xs={6}>
          <span className='paymentStatus'>
            <ReactToPrint
              trigger={() => (
                <IconButton>
                  <PrintIcon />
                </IconButton>
              )}
              content={() => componentRef.current}
            />
          </span>

          <div style={{ display: 'none' }}>
            <Print data={dataToPrint} ref={componentRef} />
          </div>
        </Grid>
        </Hidden>
      </Grid>
      <br />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <small>3500 Lacey Rd #200,</small>
          <br />
          <small>Downers Grove ,IL</small>
          <br />
          <small>60515</small>
        </Grid>
        <Grid item xs={6}>
          <small className='paymentStatus'>Wire information,</small>
          <br />
          <small className='paymentStatus'>Account: 12345678</small>
          <br />
          <small className='paymentStatus'>Routing: 12345678</small>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br /> <br />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          Invoice # :<h3>{m.data.invoice}</h3>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='contained'
            className='view-details-btn paymentStatus'
            disabled={!isInvoiceOpen(m.data.status)}>
            Pay Invoice
          </Button>
        </Grid>
        <Grid item xs={6}>
          {/* dummy */}
        </Grid>
        {m.data.creditTerms !== '' && m.data.creditTerms !== null ? (
          <Grid item xs={6} className='alignRight'>
            <span>Invoice Credit Terms: </span> {m.data.creditTerms}{' '}
            days
          </Grid>
        ) : null}
      </Grid>
      <br />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <small>Associated Quote :Lorem Ipsum</small>
          <br />
          <small>Order # :{m.data.order}</small>
        </Grid>
        <Grid item xs={6}>
          <small className='paymentStatus'>
            Date :{m.data.dateIssued}
          </small>
          <br />
          <small className='paymentStatus'>
            Due Date :{m.data.dateDue}
          </small>
        </Grid>
      </Grid>
      <br />
      <br />
      <Hidden only={'xs'}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Order Items</TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Item No</TableCell>
              <TableCell>SKU ID</TableCell>
              <TableCell align='right'>PRODUCT</TableCell>
              <TableCell align='right'>PRICE PER UNIT</TableCell>
              <TableCell align='right'>QUANTITY</TableCell>
              <TableCell align='right'>TOTAL PRICE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {m.data &&
              m.data.orderItems.length > 0 &&
              m.data.orderItems.map((row) => (
                <TableRow key={row.invoice}>
                  <TableCell align='right'>{row.product}</TableCell>
                  <TableCell align='right'>{row.SKU}</TableCell>
                  <TableCell align='right'>
                    {row.displayName}
                  </TableCell>
                  <TableCell align='right'>
                    {row.itemAmount}
                  </TableCell>
                  <TableCell align='right'>{row.quantity}</TableCell>
                  <TableCell align='right'>
                    $ {row.finalItemAmount}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <Grid
          item
          xs={12}
          className='customPadding mobileInvoiceLayout'>
          <div>
            <strong>Order Items</strong>
          </div>
        </Grid>
        {m !== undefined &&
          m.data.orderItems &&
          m.data.orderItems.length > 0 &&
          m.data.orderItems.map((row) => (
            <Grid
              container
              spacing={0}
              className='mobileInvoiceLayout'>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>Item No : </strong>
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>{row.product}</div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>SKU ID : </strong>
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>{row.SKU}</div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>PRODUCT :</strong>
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>{row.displayName}</div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>PRICE PER UNIT : </strong>
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>{row.itemAmount}</div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>QUANTITY : </strong>
                  {row.order}
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>{row.quantity}</div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>
                  <strong>TOTAL PRICE : </strong>
                  {row.order}
                </div>
              </Grid>
              <Grid item xs={6} className='customPadding'>
                <div>$ {row.finalItemAmount}</div>
              </Grid>
            </Grid>
          ))}
      </Hidden>
      <br />
      <br />
      <br />
      {/* <Paper className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Grid className='container mobileInvoiceLayout'>
              Billing and Shippping Information
            </Grid>
            Invoice # :<h3>{m.data.invoice}</h3>
          </Grid>

          <Grid item xs={4}>
            <Grid className='container mobileInvoiceLayout'>Order Summery</Grid>
            Invoice # :<h3>{m.data.invoice}</h3>
          </Grid>
        </Grid>
      </Paper> */}
      <Grid container spacing={4}>
        <Grid item md={8} sm={6} className='billing-wrapper'>
          <div className='billing-title headers'>
            Billing and Shipping Information
          </div>
          <Divider />
          <Grid container className='billing-shipping-container'>
            <Grid item md={12}>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={12} md={4} sm={12}>
                  <Card>
                    <CardHeader
                      avatar={
                        <IconButton>
                          <Shipping />
                        </IconButton>
                      }
                      title='Shipping Address'
                    />
                    <CardContent>
                      <div>
                        <strong>
                          {m.data.soldTo.organizationName}
                        </strong>
                      </div>
                      <div>
                        {m.data.billingAddress.addressFirstline}
                      </div>
                      <div>{m.data.billingAddress.city}</div>
                      <div>{m.data.billingAddress.postalCode}</div>
                      <div>{m.data.billingAddress.country}</div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sm={12}>
                  <Card>
                    <CardHeader
                      avatar={
                        <IconButton>
                          <Billing />
                        </IconButton>
                      }
                      title='Billing Address'
                    />
                    <CardContent>
                      <div>
                        <strong>
                          {m.data.soldTo.organizationName}
                        </strong>
                      </div>
                      <div>
                        {m.data.billingAddress.addressFirstline}
                      </div>
                      <div>{m.data.billingAddress.city}</div>
                      <div>{m.data.billingAddress.postalCode}</div>
                      <div>{m.data.billingAddress.country}</div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid>Customer ID : {m.data.soldTo.customerId}</Grid>
          <Grid>Sales Person ID : {m.data.salesPersonId}</Grid>
          <Grid>
            Shipping Method :{' '}
            {m.data.orderShipments[0].shippingMethod}
          </Grid>
        </Grid>

        <Grid
          item
          md={4}
          xs={12}
          sm={6}
          className='order-summary-wrapper'>
          {/* <div className='summary-title headers'>Order Summery</div>
          <Divider />

          <Table className='order-summary'>
            <TableBody className='order-summary-content'>
              <TableRow>
                <TableCell className='headers'>
                  Payment Method
                </TableCell>
                <TableCell>billing.paymentMethod}</TableCell>
              </TableRow>
            </TableBody>
          </Table> */}

          <div className='summary-title headers'>Order Summary</div>
          <Divider />

          <Table className='order-summary'>
            <TableBody className='order-summary-content'>
              <TableRow>
                <TableCell className='headers'>SubTotal:</TableCell>
                <TableCell>$ {m.data.originalAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='headers'>Tax:</TableCell>
                <TableCell>
                  $ {m.data.orderTaxes[0].taxAmount}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='headers'>Discounts:</TableCell>
                <TableCell>$ 0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='headers'>
                  Trade Discounts:
                </TableCell>
                <TableCell>$ 0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='headers'>
                  Freight Costs:
                </TableCell>
                <TableCell>$ 0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='headers'>
                  Miscellaneous Costs:
                </TableCell>
                <TableCell>$ 0</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className='headers'>Order Total</TableCell>
                <TableCell className='price'>
                  $ {m.data.originalAmount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            className='order-summary-wrapper'>
            <div className='summary-title headers'>Comments</div>
            <Divider />
            It is a long established fact that a reader will be
            distracted by the readable content of a page when looking
            at its layout.
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
