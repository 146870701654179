import React from 'react';
import './Styles.css';
import 'font-awesome/css/font-awesome.min.css';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../Shared/customTextField';
import CustomSelect from '../Shared/customMultiSelect';
import CustomError from '../Shared/customErrorText';
import Loader from '../Shared/spanLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginLeft: 10,
    marginBottom: 10,
    width: 180,
    height: 41,
  },
}));

export default function ContactForm({
  state,
  handleContactChange,
  cancelContact,
  submitContact,
  refProp,
  roleList,
}) {
  const classes = useStyles();
  const { contactForm } = state;
  return (
    <TableBody ref={refProp}>
      <TableRow id='contactForm'>
        <TableCell>
          <CustomTextField
            name='firstName'
            maxLength={20}
            required={true}
            type='text'
            onchange={handleContactChange}
            value={contactForm.firstName}
          />
          {contactForm.errorFirstName && (
            <CustomError
              masg='Please enter valid First Name'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </TableCell>
        <TableCell>
          <CustomTextField
            name='lastName'
            type='text'
            maxLength={20}
            onchange={handleContactChange}
            value={contactForm.lastName}
          />
          {contactForm.errorLastName && (
            <CustomError
              masg='Please enter valid Last Name'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </TableCell>
        <TableCell>
          <CustomTextField
            name='email'
            type='text'
            maxLength={50}
            required={true}
            onchange={handleContactChange}
            value={contactForm.email}
          />
          {contactForm.errorEmail && (
            <CustomError
              masg='Please enter valid email'
              displayType='block'
              margin='5px 0 0 0'
            />
          )}
        </TableCell>
        <TableCell>
          <CustomSelect
            name='role'
            variants={roleList}
            defaultValue='Select Any'
            selectedVariant={contactForm.role}
            variantChange={handleContactChange}
          />
        </TableCell>
        <TableCell>
          <div style={{ cursor: 'pointer', marginTop: 20 }}>
            <Close onClick={cancelContact} />
          </div>
        </TableCell>
      </TableRow>
      <TableRow id='buttonSave'>
        <TableCell colSpan={5} align='center'>
          <div style={{ display: 'inline-flex' }}>
            <Button
              variant='contained'
              color='secondary'
              disabled={contactForm.isDisable}
              onClick={submitContact}
              className={classes.button}
              style={{
                backgroundColor: '#e41e26',
                color: '#ffffff',
              }}>
              {state.editContact ? 'Save Changes' : 'Add New User'}
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              onClick={cancelContact}
              className={classes.button}
              style={{
                border: '1px solid #e41e26',
                color: '#e41e26',
              }}>
              Cancel
            </Button>
            &nbsp;
            {state.isLoading ? <Loader /> : null}
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
