import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

//import BootstrapButton from './bootstrapButton';
//import './style.css';

const NotificationCarousel = ({
  headerText,
  dataList,
  btnLabel,
  clickHandler,
}) => {
  const dataLength = dataList ? dataList.length : 0;
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 1000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    dataList &&
    dataList.length > 0 && (
      <Grid
        className='externalContainer'
        style={{ backgroundColor: '#2e2e2e', color: '#ffffff' }}>
        <div className='custom-carousel'>
          <p className='carousel-header'>{headerText}</p>
          <Grid className='notificationSubText'>
            These tasks may require your immediate attention
          </Grid>
          <Slider {...settings} className='slick'>
            {dataList.map((data, i) => (
              <div className='card-container' key='data'>
                <div
                  className='card'
                  style={{
                    backgroundColor: '#232323',
                    border: '0px',
                  }}>
                  <div className='notifyMainSection'>
                    <div className='notifySection'>
                      <div className='notifyTitle'>
                        <span style={{ float: 'left' }}>
                          Invoices
                        </span>
                        <span style={{ float: 'right' }}>
                          INV123456 &nbsp;&nbsp;&nbsp; X
                        </span>
                      </div>
                    </div>
                    <div className='notifyMsg'>
                      This invoice is due in 3 days
                    </div>
                    <div className='notifyData'>
                      <Grid style={{ marginTop: '20px' }}>
                        <Grid xs={12}>
                          <Grid xs={12} className='notifyDataHeading'>
                            Order Total
                          </Grid>
                          <Grid
                            xs={12}
                            className='notifyDataHeadingValue'>
                            $1234,56
                          </Grid>
                        </Grid>

                        <Grid xs={12}>
                          <Grid xs={12} className='notifyDataHeading'>
                            Items
                          </Grid>
                          <Grid
                            xs={12}
                            className='notifyDataHeadingValue'>
                            23
                          </Grid>
                        </Grid>
                        <Grid xs={12}>
                          <Grid xs={12} className='notifyDataHeading'>
                            Due Date
                          </Grid>
                          <Grid
                            xs={12}
                            className='notifyDataHeadingValue'>
                            01/01/2021
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      className='notifyAction'
                      style={{ paddingTop: '60px' }}>
                      <Grid container>
                        <Grid xs={6} sm={6} md={6}>
                          Dismiss
                        </Grid>
                        <Grid xs={6} sm={6} md={6}>
                          Pay Now
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Grid>
    )
  );
};

NotificationCarousel.propTypes = {
  headerText: PropTypes.string.isRequired,
  dataList: PropTypes.array.isRequired,
  btnLabel: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default NotificationCarousel;
